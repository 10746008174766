import React, { memo, useMemo, forwardRef, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';
import { Input, Select } from 'antd';
import { List } from 'react-virtualized';
import { Resizable } from 're-resizable';

import Loader from 'Components/atoms/spinner/loading';
import ApiRender from 'Components/atoms/apiRender';
import requestState from 'Services/api/status';
import {getShortenedMchj} from "Utils/toMchj";
// NOTE custom components
import { Checkbox } from 'Components/atoms/checkbox';
import { DatePicker } from 'Components/atoms/datePicker';
// NOTE constants
import {
  SET_INPUT_TIN_NAME,
  ONE_SELECT,
  ALL_SELECT,
  SET_FROM_TO_INPUT_FIELDS,
  SET_DATE_FIELDS,
  SET_NUMBER_FIELDS,
  SET_CONTRACT_FIELDS,
  REACT_SELECT_CHANGE_HANDLER,
  WITHOUT_OBJECT_VALUE,
  BLOCK_CHECKBOX,
  SET_COUNTER_AGENT
} from 'Hooks/useFilter';

// ANCHOR constant
import { propertiesOfGetFilterListFromBackend } from 'Components/molecules/documentList/table/index.common';
import ReactSelect from 'Components/atoms/reactSelect';

// NOTE style
import { StyledFilterCard, StyledRowText, StyledCountBadge, StyledFilterItemWrapper } from './filterCart.style';
import BaseStyledLabel from 'Common/Style/styledLabel';
import BaseStyledFormItemWrapper from 'Common/Style/styledFormItemWrapper';
import BaseFlex from 'Common/Style/styledFlex';

const Filter = forwardRef((props, ref) => {
  const { t } = useTranslation(['translation', 'common']);

  const { filterData, filterDispatch, height, width } = props;

  const {
    searchedPropertyValueLIst,
    checkedBoxes,
    identify,
    status,
    selectedProperty,
    inputNameTin,
    onePropertyValueList,
    number,
    sum,
    date,
    contract,
    branchName,
    blocked,
    counterAgent
  } = filterData;

  const isArrayCheckedBoxes = useMemo(() => {
    return Array.isArray(checkedBoxes[selectedProperty]);
  }, [filterData]);

  const renderedList = useMemo(() => {
    if (status === 'header_input_search') return searchedPropertyValueLIst[selectedProperty];
    return Array.isArray(onePropertyValueList[selectedProperty]) ? onePropertyValueList[selectedProperty] : [];
  }, [status, searchedPropertyValueLIst, onePropertyValueList, selectedProperty]);

  const checkDisable = (value) => {
    let disabled = false;
    switch (selectedProperty) {
      case 'constructionObject':
        disabled = checkedBoxes[selectedProperty].some((_v) => _v === WITHOUT_OBJECT_VALUE);

        if (disabled && value !== WITHOUT_OBJECT_VALUE) {
          return true;
        } else {
          return false;
        }

      default:
        return false;
    }
  };

  function isChecked(item) {
    if (isArrayCheckedBoxes) return checkedBoxes[selectedProperty].includes(item);
    return false;
  }

  function _rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The Card is currently being scrolled
    isVisible, // This row is visible within the Card (eg it is not an overscanned row)
    style // Style object to be applied to row (to position it)
  }) {
    let row = renderedList[index],
      value = row[identify];

    const onChange = () => {
      if (!checkDisable(row[identify])) {
        if (selectedProperty === 'constructionObject' && value === WITHOUT_OBJECT_VALUE && isChecked(value)) {
          return filterDispatch({ type: ALL_SELECT });
        }

        filterDispatch({ type: ONE_SELECT, value });
      }
    };

    return (
      <div className="list_item" key={key} style={style}>
        <Checkbox disabled={checkDisable(value)} name={selectedProperty} onChange={onChange} checked={isChecked(value)}>
          <StyledRowText width={width - 60} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {(selectedProperty.startsWith('tin') || selectedProperty === 'sellerName' || selectedProperty === 'buyerName') ? (
              <Fragment>
                <span style={{ width: '35%', marginRight: '5%' }}>
                  {row['tin']} - {getShortenedMchj(row['name'])}
                </span>
              </Fragment>
            ) : (
              <Fragment>
                <span>{row['name']}</span>
                {row['count'] && <StyledCountBadge> {row['count']} </StyledCountBadge>}
              </Fragment>
            )}
          </StyledRowText>
        </Checkbox>
      </div>
    );
  }

  const _renderCardContent = () => {
    switch (selectedProperty) {
      case 'tin':
      case 'name':
      case 'name2':
      case 'tin2':
      case 'typeName':
      case 'state':
      case 'constructionObject':
      case 'subtypeName':
      case 'recipient':
      case 'groupName':
      case 'employeeName':
      case 'employeeTin':
      case 'positionName':
      case 'branchName':
      case 'roleName':
      case 'productName':
      case 'catalogCodeAndName':
      case 'statusName':
      case 'contractState':
      case 'sellerName':
      case 'buyerName':
      case 'docTypeName':
      case 'constructionObjectName':
      case 'sellerBranchName':
      case 'buyerBranchName':
        return (
          <>
            {_renderCardHeader()}
            <List width={width - 20} height={height - 55} rowCount={renderedList?.length} rowHeight={30} rowRenderer={_rowRenderer} />
          </>
        );
      case 'dateAndNumber':
        return _renderDateAndNumberFields();
      case 'sum':
        return _renderFromToInputs();
      case 'contract':
        return _renderContractFields();
      /*     case 'branchName':
        return _renderBranchNameFields(); */
      case 'dateReceived':
      case 'createdAt':
        return _renderDateFromTo();
      case 'contractDate':
        return _renderContractFieldsForContractList();
      case 'partnerName':
      case 'partnerTin':
        return _renderFilterCounterAgentList();
      default:
        return <></>;
    }
  };

  function _renderContractFields() {
    return (
      <>
        <BaseStyledLabel>{t('contractNumber')}</BaseStyledLabel>
        <Input.Search
          onChange={(e) => filterDispatch({ type: SET_CONTRACT_FIELDS, value: e.target.value, name: e.target.name })}
          name="contractNumber"
          label={t('contractNumber')}
          placeholder={t('contractNumber')}
          value={contract.contractNumber}
        />
        <DatePicker
          name="contractDate"
          label={t('contractDate')}
          value={contract.contractDate}
          onChange={(e, dateName) => filterDispatch({ type: SET_CONTRACT_FIELDS, value: e, name: dateName })}
        />
      </>
    );
  }

  function _renderDateFromTo() {
    return (
      <>
        <BaseStyledFormItemWrapper>
          <DatePicker
            label={t('from')}
            name="dateFrom"
            value={date.dateFrom}
            onChange={(e, dateName) => filterDispatch({ type: SET_DATE_FIELDS, e, dateName })}
          />
        </BaseStyledFormItemWrapper>
        <BaseStyledFormItemWrapper>
          <DatePicker
            label={t('to')}
            name="dateTo"
            value={date.dateTo}
            onChange={(e, dateName) => filterDispatch({ type: SET_DATE_FIELDS, e, dateName })}
          />
        </BaseStyledFormItemWrapper>
      </>
    );
  }

  // NOTE date fields
  function _renderDateAndNumberFields() {
    return (
      <>
        {_renderDateFromTo()}
        <div>
          <BaseStyledLabel>Префикс/Суффикс</BaseStyledLabel>
          <Select
            defaultValue={number.function}
            style={{ width: '100%' }}
            onChange={(value) => filterDispatch({ type: SET_NUMBER_FIELDS, value, name: 'function' })}>
            <Select.Option value="numberStartWith">{t('filter.startWith')}</Select.Option>
            <Select.Option value="numberEndWith">{t('filter.endWith')}</Select.Option>
          </Select>
        </div>
        <div>
          <BaseStyledLabel>{t('filter.value')} </BaseStyledLabel>
          <Input
            name="number"
            value={number.number}
            placeholder={t('filter.value')}
            onChange={(e) => filterDispatch({ type: SET_NUMBER_FIELDS, value: e.target.value?.trim(), name: e.target.name })}
          />
        </div>
        <div>
          <BaseStyledLabel>ID</BaseStyledLabel>
          <Input
            name="id"
            value={number.id}
            placeholder="ID"
            onChange={(e) => filterDispatch({ type: SET_NUMBER_FIELDS, value: e.target.value, name: e.target.name })}
          />
        </div>
      </>
    );
  }

  // NOTE sum property  fields
  function _renderFromToInputs() {
    return (
      <>
        <div>
          <BaseStyledLabel>{t('from')}</BaseStyledLabel>
          <Input.Search
            placeholder={t('from')}
            value={sum.inputFrom}
            name="inputFrom"
            onChange={(e) => filterDispatch({ type: SET_FROM_TO_INPUT_FIELDS, e })}
          />
        </div>
        <div>
          <BaseStyledLabel>{t('to')}</BaseStyledLabel>
          <Input.Search
            placeholder={t('to')}
            value={sum.inputTo}
            name="inputTo"
            onChange={(e) => filterDispatch({ type: SET_FROM_TO_INPUT_FIELDS, e })}
          />
        </div>
      </>
    );
  }

  function isCheckAll() {
    if (isArrayCheckedBoxes) {
      if (selectedProperty === 'constructionObject') {
        return renderedList.length - 1 === checkedBoxes[selectedProperty].length;
      } else {
        return renderedList.length === checkedBoxes[selectedProperty].length;
      }
    }
    return false;
  }

  function _renderCardHeader() {
    let count = renderedList.reduce((accumulator, current) => (accumulator += current.count), 0);

    return (
      <header>
        <Input.Search
          placeholder={t('common:search')}
          name={selectedProperty}
          onChange={(e) => filterDispatch({ type: SET_INPUT_TIN_NAME, e })}
          value={inputNameTin}
        />
        <div className="select_all" style={{ display: 'flex' }}>
          <BaseFlex className="flex-direction-column" style={{ width: '100%' }}>
            <Checkbox
              name="all"
              style={{ marginBottom: '0.625em' }}
              disabled={status === requestState.loading}
              checked={isCheckAll()}
              onChange={() => filterDispatch({ type: ALL_SELECT })}>
              {t('filter.selectAll')}
            </Checkbox>
            {selectedProperty === 'tin' && (
              <Checkbox
                name="blocked"
                style={{ marginBottom: '0.625rem' }}
                disabled={status === requestState.loading}
                checked={blocked}
                onChange={() => filterDispatch({ type: BLOCK_CHECKBOX, value: !blocked })}>
                {t('filter.blackList')}
              </Checkbox>
            )}
          </BaseFlex>
          {isNaN(count) ? '' : <StyledCountBadge>{count}</StyledCountBadge>}
        </div>
      </header>
    );
  }

  function _renderBranchNameFields() {
    const branchStatus = props.branchesStatus;
    const employeeStatus = props.employeeState.status;

    const onChange = (e, property) =>
      filterDispatch({ type: REACT_SELECT_CHANGE_HANDLER, value: e, property, parentProperty: 'branchName' });

    const loader = <BaseStyledFormItemWrapper>{_renderLoader()}</BaseStyledFormItemWrapper>;

    const error = <BaseStyledFormItemWrapper>Yuklashda Xatolik</BaseStyledFormItemWrapper>;

    const employeeValue = Array.isArray(branchName.currentEmployeeId)
      ? branchName.currentEmployeeId.filter((item) => {
          if (item) {
            return true;
          } else {
            return false;
          }
        })
      : [];

    const employeeSelect = (
      <>
        <BaseStyledLabel>{t('common:signForm.employee')}</BaseStyledLabel>
        <ReactSelect
          autoHeightOption
          autoHeightControl
          placeholder={t('common:signForm.employee')}
          options={[{ label: t('translation:withoutEmployee'), value: { id: 0 } }, ...props.employeeOptions]}
          onChange={(e) => onChange(e, 'currentEmployeeId')}
          isMulti={true}
          defaultValue={branchName.currentEmployeeId}
          value={employeeValue}
        />
      </>
    );

    return (
      <>
        <StyledFilterItemWrapper className={employeeStatus}>
          <ApiRender status={branchStatus} loading={loader} error={error}>
            {branchStatus === requestState.success && (
              <>
                <BaseStyledLabel>{t('branchName')}</BaseStyledLabel>
                <ReactSelect
                  options={props.brachesSelectOptions}
                  placeholder={t('branchName')}
                  onChange={(e) => onChange(e, 'currentBranchId')}
                  isMulti={true}
                  defaultValue={branchName.currentBranchId}
                  value={branchName.currentBranchId}
                />
              </>
            )}
          </ApiRender>
        </StyledFilterItemWrapper>

        <StyledFilterItemWrapper>
          <ApiRender status={employeeStatus} loading={loader} error={error} initial={employeeSelect}>
            {employeeStatus === requestState.success && employeeSelect}
          </ApiRender>
        </StyledFilterItemWrapper>
      </>
    );
  }


  function _renderContractFieldsForContractList() {
    return (
      <>
        <BaseStyledLabel>{t('contractNumber')}</BaseStyledLabel>
        <Input.Search
          onChange={(e) => filterDispatch({ type: SET_CONTRACT_FIELDS, value: e.target.value, name: e.target.name })}
          name="key"
          label={t('contractNumber')}
          placeholder={t('contractNumber')}
          value={contract.key}
        />
        {_renderDateFromTo()}
      </>
    );
  }

  function _renderFilterCounterAgentList() {
    return (
      <>
        <BaseStyledLabel>{t('filter.value')} </BaseStyledLabel>
        <Input
          name={selectedProperty}
          placeholder=''
          value={counterAgent[selectedProperty]}
          onChange={(e) => filterDispatch({type: SET_COUNTER_AGENT, value: e.target.value, name: e.target.name})}
        />
      </>
    )
  }

  return (
    <StyledFilterCard ref={ref} left={filterData.left} width={width} height={height}>
      <Resizable
        size={{ width }}
        defaultSize={{
          width
        }}
        onResizeStop={(e, direction, ref, d) => {
          props.handleResize(d, 'width', direction);
        }}
        className="resizeable">
        {status === requestState.loading && propertiesOfGetFilterListFromBackend.includes(selectedProperty) ? (
          <div>
            {_renderCardHeader()}
            <div className="status_loading">{_renderLoader()}</div>
          </div>
        ) : (
          <div>
            {_renderCardContent()}
            <footer className="filterCart__footer">{props.footer}</footer>
          </div>
        )}
      </Resizable>
    </StyledFilterCard>
  );

  function _renderLoader() {
    return <Loader Component={ClipLoader} size={40} />;
  }
});

export default memo(Filter);
