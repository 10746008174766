import styled from 'styled-components';
import { colors } from 'Style/colors';

export const StyleMainLayout = styled.section`
  &.none-scroll {
    .App {
      transition: width 300ms, left 300ms;
    }
  }
`;

export const AntdLayout = styled.section`
  width: 100%;
  height: calc(100% - 75px);
  display: flex;
  flex-direction: row;
  & aside {
    width: 5.8%;
    overflow: hidden;
    transition: width 300ms;
    -webkit-transition: width 300ms;
    will-change: width;
  }
  &.site-layout-background {
    background-color: white;
  }
`;

export const StyledContentLayout = styled.div`
  &.App {
    overflow-y: scroll;
 
    // width: ${(props) => `${props.layoutWidth}`};
    width:95%; //fixed content width because of overlapping subsidebarmenu
    max-width: 100%;
    background-color: ${(props) => props?.isNewUi ? colors.layoutBackground : 'rgb(229, 229, 229)'};
    padding: 0 0.425rem 0 0.625rem;
    display: flex;
    flex-direction: column;
    /* position: ${(props) => (props.isFixed ? 'fixed' : 'static')}; */
    transition: width 300ms;
    /* right:0; */
  }
  &.fixed {
    min-height: 100%;

    & table {
      & > tbody {
        height: ${({ zoom }) => `${(65 * 100) / zoom}`};
        overflow-y: auto;
      }
    }
  }
`;
