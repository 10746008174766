import { useState, useCallback } from 'react';
import debounce from 'lodash.debounce';

export default (request) => {
  const [value, setValue] = useState('');

  const debounceCallback = useCallback(
    debounce((e) => {
      setValue(e.target.value);
      if (request) {
        request({ key: e.target.name, value: e.target.value });
      }
    }, 1000),
    []
  );

  const onChange = (e) => {
    e?.persist();
    setValue(e?.target?.value);
    debounceCallback(e);
  };

  return {
    onChange,
    value,
    setValue
  };
};
