import styled from "styled-components";

export const StyledTin = styled.div`
   
`;

export const IconHolder = styled.div`
    width: 15px;
    height: 15px;
`;
