import React from 'react';

const PhoneSvg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="currentColor" {...props}>
      <path d="M3.6017 8.70023C5.07489 11.5235 7.37845 13.827 10.2017 15.3002L12.4017 13.1002C12.5191 12.9558 12.6832 12.8568 12.8657 12.8202C13.0482 12.7837 13.2378 12.812 13.4017 12.9002C14.5573 13.3097 15.7758 13.5128 17.0017 13.5002C17.2611 13.5178 17.5054 13.6288 17.6893 13.8126C17.8732 13.9965 17.9842 14.2408 18.0017 14.5002V18.0002C18.0017 18.2655 17.8963 18.5198 17.7088 18.7073C17.5213 18.8949 17.2669 19.0002 17.0017 19.0002C14.7687 19.0021 12.5573 18.5636 10.4939 17.7099C8.43054 16.8563 6.55574 15.6041 4.97678 14.0252C3.39782 12.4462 2.14568 10.5714 1.292 8.50804C0.438323 6.44467 -0.000138437 4.23323 0.00170277 2.00024C-0.00631687 1.86682 0.0140549 1.73321 0.0614729 1.60825C0.108891 1.48329 0.182276 1.36982 0.276784 1.27531C0.371292 1.1808 0.484772 1.10742 0.609732 1.06001C0.734692 1.01259 0.868289 0.992218 1.0017 1.00024H4.5017C4.63512 0.992218 4.76871 1.01259 4.89367 1.06001C5.01863 1.10742 5.13211 1.1808 5.22662 1.27531C5.32113 1.36982 5.39451 1.48329 5.44193 1.60825C5.48935 1.73321 5.50972 1.86682 5.5017 2.00024C5.50576 3.22478 5.70839 4.44056 6.1017 5.60023C6.14169 5.77153 6.14453 5.94941 6.11003 6.1219C6.07553 6.29439 6.0045 6.45748 5.9017 6.60023L3.6017 8.70023Z" fill="currentColor"/>
    </svg>
  )
}

export default PhoneSvg;
