import React from 'react';

import Render from './render';
import Loading from './loading';
import Error from './error';

const ApiRender = (props) => {
  return <Render {...props} />;
};

export { Loading, Error };

export default ApiRender;
