
export default function getPackageNameObjKey (currentLanguage) {
  let pcNameKey = 'nameRu';
  switch (currentLanguage) {
    case 'ru': {
      pcNameKey = 'nameRu';
      break;
    }
    case 'latin': {
      pcNameKey = 'nameLat';
      break;
    }
    case 'cyrillic': {
      pcNameKey = 'nameUz';
      break;
    }
    default: {
      pcNameKey = 'nameRu';
      break;
    }
  }
  return pcNameKey;
};