import * as con from 'Store/constant'
import requestState from "Services/api/status";

const initialState = {
  status: requestState.initial,
  tariff: {}
};

const tariffReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case con.GET_TARIFF_DATA:
      return {
        ...initialState,
        status: requestState.loading
      };
    case con.GET_TARIFF_DATA_SUCCESS:
      return {
        ...initialState,
        tariff: payload,
        status: requestState.success
      };
    case con.GET_TARIFF_DATA_FAIL:
      return {
        ...initialState,
        status: requestState.error
      };
    default:
      return state;
  }
}

export default tariffReducer;