import React from 'react';
import get from 'lodash.get';

// ANCHOR style
import { StyledRow } from './virtualizeTableRow.style';

export const VirtualizeTableRowComponent = (props) => {
  const { style, isEven, cellValue, columns, key, rowData, onDoubleClick = () => { } } = props;

  return (
    <StyledRow isEven={isEven} style={{ display: 'flex', ...style }} padding="0px" key={key} className={props?.effect} onDoubleClick={() => onDoubleClick(props)}>
      {columns.map((column, key) => {
        return (
          <div key={column.key || key} {...column.props} className={`${get(column, 'props.className')}`}>
            {cellValue(props.index, column, rowData)}
          </div>
        );
      })}
    </StyledRow>
  );
};
