import styled from 'styled-components';
export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // & > a {
  //   width: 177px;
  //   height: 100%;
  // }
  // & div {
  //   width: 90%;
  // }

  & > a > div {
    height: 100%;
  }

  & > a > div > img {
    width: 177px;
    height: 56px;
    object-fit: contain;
    display: block;
    filter: hue-rotate(-10deg);
  }
  & div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    color: #444;
  }
`;
