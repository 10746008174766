import { useReducer } from 'react';
import client from 'Services/api/client';
import requestState from 'Services/api/status';
import notification from 'Components/atoms/toast';

const initialState = {
  error: {},
  data: {},
  status: 'initial',
  successMessage: ''
};

export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = `API_SUCCESS`;
export const API_ERROR = 'API_ERROR';
export const API_RESET_DATA = 'API_RESET_DATA';
export const CHANGE_STATUS_INITIAL = 'CHANGE_STATUS_INITIAL';

const usefetchReducer = (state, action) => {
  switch (action.type) {
    case API_REQUEST:
      return {
        ...state,
        status: action.payload
      };
    case API_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: requestState.success,
        successMessage: action.message
      };
    case API_ERROR:
      return {
        ...state,
        status: requestState.error,
        error: { message: action.message }
      };
    case API_RESET_DATA:
      return initialState;
    case CHANGE_STATUS_INITIAL:
      return {
        ...state,
        status: 'initial'
      };
    default:
      return state;
  }
};

const useFetch = () => {
  const [data, fetchDispatch] = useReducer(usefetchReducer, initialState);

  const getData = async (method = 'GET', url, body) => {
    fetchDispatch({ type: API_REQUEST, payload: requestState.loading });

    const { data = {}, fail, message } = await client(method, url, body);

    if (!fail) return fetchDispatch({ type: API_SUCCESS, data: data.hasOwnProperty('data') ? data.data : data, message });

    fetchDispatch({ type: API_ERROR, message });
  };

  const universal = async (asyncFunc, arg = {}, loadingText = '') => {
    fetchDispatch({ type: API_REQUEST, payload: loadingText || requestState.loading });
    const { fail, message, data } = await asyncFunc(arg);

    if (!fail) {
      if (data?.hasUpdated && data?.message) {
        notification.setType('error').setMessage(data?.message).alert();
      }
      let savedData = data?.data ? data?.data : data;
      fetchDispatch({
        type: API_SUCCESS,
        data: savedData,
        message
      });

      return savedData;
    }

    fetchDispatch({ type: API_ERROR, message });
  };

  return [data, getData, fetchDispatch, universal];
};

export default useFetch;
