import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { MyFallbackComponent } from 'Components/atoms/errorBoundry/ErrorBoundry';
import * as _ from '../../container/auth.style';
import ApiLogin from 'Services/api/controller/login';
import { useForm } from 'react-hook-form';
import notification from 'Components/atoms/toast';
import './style.css';
import FinishPage from './finishPage';
import ApprovePage from './approvePage';
import browserStorage from 'Services/browserStorage';
const Registration = ({ onFinishFailed, handleCondition }) => {
  const [receiveSMS, setReceiveSMS] = useState(false);
  const [loadingSMS, setLoadingSMS] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [numberDisabled, setNumberDisabled] = useState(false);
  const [isEmail, setIsEmail] = useState(false); // Note!! isEmail - user dastlab email kiritganmi yoki telefon raqamligini tekshirish uchun. Agar email bo'lsa true, aks holda false qaytaradi
  const [valuePhoneOrEmail, setValuePhoneOrEmail] = useState(''); // Note! valuePhoneOrEmail - user dastlab email yoki telefon raqamni kiritgan bo'lsa usha qiymatni olib inputni disable qilish uchun olingan

  const [isFail, setIsFail] = useState(true); // Note! Bu yerda isFail agar SMS tasdiq kodi to'g'ri kiritilsa falsega o'zgaradi, lekin dastlabki qiymati truedan boshlanadi

  const { handleSubmit, register, errors } = useForm();

  const getSMS = handleSubmit(async (values) => {
    //Note! bu funksiya SMS olish uchun ishlatiladi
    const phoneRegex = /^\+?998\d{9}$/;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (emailRegex.test(values.tel)) {
      setIsEmail(true);
      setReceiveSMS(true);
      setNumberDisabled(true);
      setLoadingSMS(true);
    } else if (phoneRegex.test(values.tel)) {
      setIsEmail(false);
      setNumberDisabled(true);
      setReceiveSMS(true);
      setLoadingSMS(true);
    } else {
      notification.setType('error').setMessage('Неправильно введен номер телефона или электронная почта').alert();
      setReceiveSMS(false);
      setNumberDisabled(false);
      setLoadingSMS(false);
    }

    await ApiLogin.getSMS(values.tel);

    setValuePhoneOrEmail(values.tel);
  });

  const getSMSagain = handleSubmit(async () => {
    //Note! bu funksiya qayta SMS olish uchun ishlatiladi
    setLoadingApprove(false);
    await ApiLogin.getSMS(valuePhoneOrEmail);
  });

  const onSubmit = handleSubmit(async (values) => {
    //Note! bu funksiya kelgan SMS ni tasdiqlash uchun kerak
    setLoadingApprove(true);
    const res = await ApiLogin.getApprove(values.approve);
    if (!res.fail) {
      setIsFail(false);
    } else {
      notification.setType('error').setMessage(res.message).alert();
    }
  });

  const onFinish = handleSubmit(async (values) => {
    //Note! bu funksiya registratsiya jarayonini yakunlash uchun kerak
    const body = {};
    if (isEmail) {
      body.email = valuePhoneOrEmail;
      body.phoneNumber = values.tel;
    } else {
      body.email = values.email;
      body.phoneNumber = valuePhoneOrEmail;
    }
    body.tinOrPinfl = values.tin;
    body.fullName = values.name;
    body.password = values.password;
    const res = await ApiLogin.createUser(body);
    if (!res.fail) {
      browserStorage.set('token', res.data);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      notification.setType('error').setMessage(res.message).alert();
    }
  });

  return (
    <>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <_.StyledForm
          id="registr__page"
          initialValues={{ remember: true }}
          onFinish={handleSubmit(onSubmit)}
          onFinishFailed={onFinishFailed}>
          <_.Text esmw color={'#000017'}>
            {!receiveSMS && 'Номер телефона/Электронная почта'}
            {/*{receiveSMS && isEmail ? 'Электронная почта' : receiveSMS && !isEmail ? 'Номер телефона' : ''}*/}
          </_.Text>
          <_.FormItem>
            <_.StyledInput
              disabled={numberDisabled}
              name={`${isEmail ? 'email' : 'tel'}`}
              ref={register}
              placeholder={'Введите номер телефона или электронную почту'}
              style={{ marginTop: '10px' }}
            />
          </_.FormItem>
          {receiveSMS && isFail && (
            <ApprovePage register={register} getSMSagain={getSMSagain} isEmail={isEmail} loadingApprove={loadingApprove} />
          )}
          {/* NOTE!!! ApprovePage komponenti SMS jo'natish va qabul qilib olish uchun javob beradi */}
          {!isFail && <FinishPage register={register} onFinish={onFinish} isEmail={isEmail} errors={errors} />}{' '}
          {/* NOTE!!! FinishPage komponenti registratsiya jarayonini yakunlash uchun javob beradi */}
          {!receiveSMS && isFail && (
            <_.FormItem>
              <_.StyledButton ref={register} htmlType="submit" onClick={getSMS} disabled={loadingSMS}>
                {'Далее'}
              </_.StyledButton>
            </_.FormItem>
          )}
          <_.RightBottom>
            <_.Text sm color={'#000017'}>
              Уже есть профиль?
            </_.Text>
            <_.Text sm color={'#1F87E5'}>
              <a onClick={() => handleCondition(2)}>Войти</a>
            </_.Text>
          </_.RightBottom>
        </_.StyledForm>
      </ErrorBoundary>
    </>
  );
};

export default Registration;
