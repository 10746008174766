// ANCHOR npm packages
import React, { memo, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// ANCHOR custom components
import { Header } from 'Components/organisms/header/header';
import NewSideBar from 'Components/organisms/newSidebar';
import SubSideBar from 'Components/organisms/subSideBar';
import Advertisment from 'Components/organisms/advertisment';
import ContractDrawerController from 'Components/molecules/forms/_commonFormComponents/contractDrawer';
// ANCHOR style imports

// ANCHOR selectors
import { selectZoom } from 'Store/selectors/ui.js';

import { AntdLayout, StyledContentLayout, StyleMainLayout } from './layout.style.js';

export const LayoutComponent = (props) => {
  const zoom = useSelector(selectZoom);
  const isNewUi = useSelector((state) => state.ui.isNewUi)

  const location = useLocation();

  const pathname = useMemo(() => location.pathname, [location]);

  const isFixed = useMemo(() => {
    if (pathname.includes('create') || pathname.includes('catalog') || pathname.includes('bdmDocInfo')) {
      return;
    } else {
      return true;
    }
  }, [pathname]);

  const { subSidebarRef, layoutWidth, boxMenuWidth, onToggle, isOpen, setBlackBackground, isSubSideBar, isAdvertisment, onHideAdvertisment } = props;

  return (
    <>

      <StyleMainLayout className={isFixed ? 'none-scroll' : 'scroll'}>
        <Header />
        <AntdLayout className="site-layout-background" width={boxMenuWidth}>
          <NewSideBar onToggle={onToggle} isOpen={isOpen} onClick={setBlackBackground} />
          <SubSideBar subSidebarRef={subSidebarRef} onToggle={onToggle} className={isSubSideBar ? 'visible sub-sidebar' : 'hide'} />
          <StyledContentLayout
            isNewUi={isNewUi}
            layoutWidth={layoutWidth}
            zoom={zoom}
            className={`App Fade ${isFixed ? 'fixed' : 'no-fixed'}`}
            style={{ overflow: 'auto' }}>
            {props.children}
          </StyledContentLayout>
          {isAdvertisment && (
            <Suspense fallback={() => <>kuting</>}>
              <Advertisment isView={isAdvertisment} onHide={onHideAdvertisment} width={props.advertismentBlockWith} />
            </Suspense>
          )}
          <ContractDrawerController />
        </AntdLayout>
      </StyleMainLayout>

      {/* <WrapperOffline /> */}
    </>
  );
};

export default memo(LayoutComponent);
