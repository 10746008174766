import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import get from 'lodash.get';
import { DatePicker as AntDatePicker } from "antd";
import DatePicker from 'react-datepicker';


export const StyledDatePicker = styled(DatePicker)`
`;

export const StyledAntDatePicker = styled(AntDatePicker)`
  height: ${({ theme }) => get(theme, 'screens.laptop.formElements.height')};
  padding-left: 0.8rem;
  border: ${({ theme }) => theme.screens.laptop.formElements['border']} !important;
  background-color: ${({ theme }) => theme.screens.laptop.formElements['bgColor']};
  border: ${({ theme }) => theme.screens.laptop.formElements['border']};
  border-radius: ${({ theme }) => theme.screens.laptop.formElements['borderRadius']};
  width: 100%;
  .anticon-calendar {
    display: none;
  }
`;

export const StyledNumberFormat = styled(NumberFormat)`
  height: ${({ theme }) => get(theme, 'screens.laptop.formElements.height')};
  padding-left: 0.8rem;
  border: ${({ theme }) => theme.screens.laptop.formElements['border']} !important;
`;
