import { isAct, isContractHandler, isInvoiceHandler, isTaxEmpowerment, isZayavkaHandler, isWayBillTax  } from './docTypes';

export function getDefaultOpenKeys (doctype) {
  switch (true) {
    case (isWayBillTax(doctype) || doctype === 10 || doctype === 25 || doctype === 37 || doctype === 38 || doctype === 150): {
      return ['sub0'];
    }
    case isZayavkaHandler(doctype): {
      return ['sub0', 'sub6'];
    }
    case isInvoiceHandler(doctype): {
      return ['sub0', `sub1`];
    }
    case isContractHandler(doctype): {
      return ['sub0', 'sub2'];
    }
    case isTaxEmpowerment(doctype): {
      return ['sub0', 'sub4'];
    }
    case isAct(doctype): {
      return ['sub0', 'sub5'];
    }
    case (doctype === 28 || doctype === 33): {
      return ['sub0', 'sub100', 'sub3'];
    }
    default: {
      return ['sub0', 'sub24', `sub2`, 'sub100'];
    }
  }
}