import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup.js';
import { object, string, array } from 'yup';
import { CopyToClipboard } from "react-copy-to-clipboard";
import NumberFormat from 'react-number-format';
import { useSelector } from "react-redux";

import requestState from 'Services/api/status.js';
import useUploadFile from 'Hooks/useUploadFile';
import { Checkbox } from 'Components/atoms/checkbox';
import { ThemeProvider } from 'styled-components';
import useFetch from "Hooks/useFetch";
import { GET_DOCUMENT_URL } from "Services/api/endPoints";
import ApiDocument from 'Services/api/controller/document';

import Button from 'Components/atoms/button';
import { BaseFlex } from 'Modules/di/components/cartItem/style';
import BaseStyledInput from 'Common/Style/styledInput';
import { CopyClipboard } from "Assets/icons/common/copyClipboard";
import {
  changedTheme,
  StyledForm,
  StyledList,
  StyledTab,
  FlexListStyle,
  ReStyledBaseLabel,
  BaseLabelWrapper,
  TagItem,
  TagBtn,
  ErrorStyle,
  HasError,
  InputStyle,
  MultipleInputContainer
} from './form.style.js';
import { cAlert } from 'Components/atoms/toast/index.js';

const SendToPublicViewForm = (props) => {
  const { t } = useTranslation(['common', 'translation']);
  const { status } = props;
  const createdDocId = useSelector(state => state?.signs?.createdDocId);
  const [url, getUrl] = useFetch();
  const [pdfFile, , , getPdfFile] = useFetch();
  const [onCopy, setOnCopy] = useState(false);
  const [selectedWays, setSelectedWays] = useState({ email: false, sms: false, telegram: false });
  const [multipleEmails, setMultipleEmails] = useState({
    items: [],
    value: "",
    error: null
  })
  const [multiplePhones, setMultiplePhones] = useState({
    items: [],
    value: "",
    error: null
  })

  useEffect(() => {
    if (multiplePhones?.items?.length > 0) {
      setSelectedWays(prev => ({ ...prev, sms: true }));
    } else {
      setSelectedWays(prev => ({ ...prev, sms: false }));
    }

    if (multipleEmails?.items?.length > 0) {
      setSelectedWays(prev => ({ ...prev, email: true }));
    } else {
      setSelectedWays(prev => ({ ...prev, email: false }));
    }
  }, [multiplePhones?.items?.length, multipleEmails?.items?.length])

  const { span, change, path: filePath, onResetInputFile, setPath, uploadFileChangeHandler, file } = useUploadFile();

  const validationSchema = object().shape({
    telegram: string().test('required', t('translation:required'), (value) => !(selectedWays.telegram && !value)),
  });

  const { register, errors, handleSubmit, control, setValue } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (values) => {
    values.email = [...multipleEmails?.items];
    values.phone = [...multiplePhones?.items];
    if (file) values.logo = file;

    if (!selectedWays.telegram) values.telegram = '';


    if (selectedWays.sms && values.phone?.length < 1) {
      setMultiplePhones((prev) => { return { ...prev, error: 'Обязательный' } })
    }
    if (selectedWays.email && values.email?.length < 1) {
      setMultipleEmails((prev) => { return { ...prev, error: 'Обязательный' } })
    }

    if ((selectedWays.sms && values.phone?.length >= 1) || (selectedWays.email && values.email?.length >= 1)) {
      props.onSubmit(values);
    }
  };


  const changeSendType = (key) => {
    setSelectedWays(prev => ({ ...prev, [key]: !prev[key] }));
  }
  const disableSubmit = Object.values(selectedWays).reduce((disable, val) => disable ? !val : disable, true),
    byEmail = selectedWays.email,
    bySms = selectedWays.sms,
    byTelegram = selectedWays.telegram;

  const isInList = (email) => {
    return !!multipleEmails?.items?.includes(email);
  }

  const isInPhoneList = (phone) => {
    return !!multiplePhones?.items?.includes(phone);
  }

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  const isPhone = (phone) => {
    return /^[+]\d{3}\s\d{2}\s\d{3}\s\d{2}\s\d{2}$/.test(phone);
  }

  const isValid = (email) => {
    let error = null;
    if (isInList(email)) {
      error = `${email} уже добавлен.`;
    }
    if (!isEmail(email)) {
      error = `*Не действительная электронная почта`;
    }
    if (error) {
      setMultipleEmails((prev) => { return { ...prev, error } });
      return false;
    }
    return true;
  }

  const isPhoneValid = (phone) => {
    let error = null;
    if (isInPhoneList(phone)) {
      error = `${phone} уже добавлен.`;
    }
    if (!isPhone(phone)) {
      error = `*Не действительная номер телефона`;
    }
    if (error) {
      setMultiplePhones((prev) => { return { ...prev, error } });
      return false;
    }
    return true;
  }

  const handleKeyDown = evt => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      let value = multipleEmails.value.trim();

      if (value && isValid(value)) {
        setMultipleEmails((prev) => { return { ...prev, items: [...prev?.items, prev?.value], value: '' } })
      }
    }
  };

  const handlePhoneKeyDown = evt => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      let value = multiplePhones.value.trim();

      if (value && isPhoneValid(value)) {
        setMultiplePhones((prev) => { return { ...prev, items: [...prev?.items, prev?.value], value: '' } })
      }
    }
  };

  const handleOnBlur = evt => {
    evt.preventDefault();
    let value = multipleEmails.value.trim();

    if (value && isValid(value)) {
      setMultipleEmails((prev) => { return { ...prev, items: [...prev?.items, prev?.value], value: '' } })
    }

  };

  const handlePhoneOnBlur = evt => {
    evt.preventDefault();
    let value = multiplePhones.value.trim();

    if (value && isPhoneValid(value)) {
      setMultiplePhones((prev) => { return { ...prev, items: [...prev?.items, prev?.value], value: '' } })
    }
  };

  const handleChange = evt => {
    evt.persist();
    setMultipleEmails((prev) => { return { ...prev, value: evt.target.value, error: null } })
  };

  const handlePhoneChange = evt => {
    evt.persist();
    setMultiplePhones((prev) => { return { ...prev, value: evt.target.value, error: null } })
  };

  const handleDelete = item => {
    setMultipleEmails((prev) => { return { ...prev, items: prev?.items.filter(i => i !== item) } })
  };

  const handlePhoneDelete = item => {
    setMultiplePhones((prev) => { return { ...prev, items: prev?.items.filter(i => i !== item) } })
  };

  const handlePaste = evt => {
    evt.preventDefault();

    let paste = evt.clipboardData.getData("text");
    let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      let toBeAdded = emails.filter(email => !isInList(email));
      setMultipleEmails((prev) => { return { ...prev, items: [...prev?.items, ...toBeAdded] } })
    }
  };

  const handlePhonePaste = evt => {
    evt.preventDefault();

    let paste = evt.clipboardData.getData("text");
    let phones = paste.match(/^[+]\d{3}\s\d{2}\s\d{3}\s\d{2}\s\d{2}$/g);

    if (phones) {
      let toBeAdded = phones.filter(phone => !isInList(phone));
      setMultiplePhones((prev) => { return { ...prev, items: [...prev?.items, ...toBeAdded] } })
    }
  };

  useEffect(() => {
    if (props.documentId || createdDocId) {
      getUrl('GET', `${GET_DOCUMENT_URL}/${props.documentId || createdDocId}`)
    }
  }, [])
  useEffect(() => {
    if (onCopy) {
      setTimeout(() => setOnCopy(false), 1000)
    }
  }, [onCopy])

  const handleDownload = () => {
    if (pdfFile?.status === requestState.success) {
      let elm = document?.createElement('a');
      elm.href = URL?.createObjectURL(pdfFile.data);
      elm?.setAttribute('download', `document1.pdf`);
      elm?.click();
    } else if (pdfFile.status === requestState.error) {
      cAlert('error', 'download pdf file failed: ' + pdfFile?.error?.message);
    }
  };

  const id = props.documentId || createdDocId;
  useEffect(() => {
    if (id) {
      getPdfFile(ApiDocument.getDocumentPdfFile, props.documentId || createdDocId);
    }
  }, [id])

  return (
    <ThemeProvider theme={changedTheme}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <h2 style={{ marginBottom: '35px' }}>{t('sendDocToOthers.title')}</h2>
        {/* <p>{t('sendDocToOthers.subtitle1')}</p>
        <p>{t('sendDocToOthers.subtitle2')}</p> */}

        <StyledList>
          {/* <StyledTab>
            <ReStyledBaseLabel>{t('sendDocToOthers.responsiblePerson')}</ReStyledBaseLabel>
            <BaseLabelWrapper>
              <ReStyledBaseLabel absolute requiredLabel={false} className={!!errors.receiver && 'table-out-error-field'}>ФИО:</ReStyledBaseLabel>
              <BaseStyledInput placeholder={'ФИО'} ref={register} name='receiver' />
            </BaseLabelWrapper>
          </StyledTab> */}
          <StyledTab>
            {/* <Checkbox checked={selectedWays.email} onChange={() => changeSendType('email')}>{t('sendDocToOthers.sendByEmail')}</Checkbox> */}
            <BaseLabelWrapper style={{ width: '100%' }}>
              <ReStyledBaseLabel
                absolute
                requiredLabel={byEmail}
              >{
                  // t('translation:email')
                  t('sendDocToOthers.sendByEmail')
                }:
              </ReStyledBaseLabel>
              <MultipleInputContainer>
                {multipleEmails?.items?.map(item => (
                  <TagItem key={item}>
                    {item}
                    <TagBtn
                      type="button"
                      onClick={() => handleDelete(item)}
                    >
                      &times;
                    </TagBtn>
                  </TagItem>
                ))}
                <BaseStyledInput
                  placeholder={t('translation:email')}
                  ref={register}
                  name='email'
                  style={{ ...(multipleEmails.error ? HasError : {}), ...InputStyle }}
                  value={multipleEmails.value}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  onPaste={handlePaste}
                  onBlur={handleOnBlur}
                />
              </MultipleInputContainer>
              {multipleEmails.error && <p style={ErrorStyle}>{multipleEmails.error}</p>}
            </BaseLabelWrapper>
          </StyledTab>
          <StyledTab>
            {/* <Checkbox checked={selectedWays.sms} onChange={() => changeSendType('sms')}>{t('sendDocToOthers.sendByMessage')}</Checkbox> */}
            <BaseLabelWrapper style={{ width: '100%' }}>
              <ReStyledBaseLabel
                absolute
                requiredLabel={bySms}
              >{
                  // t('sendDocToOthers.mobilePhone')
                  t('sendDocToOthers.sendByMessage')
                }:
              </ReStyledBaseLabel>
              <MultipleInputContainer>
                {multiplePhones?.items?.map(item => (
                  <TagItem key={item}>
                    {item}
                    <TagBtn
                      type="button"
                      onClick={() => handlePhoneDelete(item)}
                    >
                      &times;
                    </TagBtn>
                  </TagItem>
                ))}
                <Controller
                  name="phone"
                  control={control}
                  render={(arg) => (
                    <NumberFormat
                      {...arg}
                      customInput={BaseStyledInput}
                      mask="_"
                      placeholder={t('sendDocToOthers.mobilePhone')}
                      format="+### ## ### ## ##"
                      style={{ ...(multiplePhones.error ? HasError : {}), ...InputStyle }}
                      value={multiplePhones.value}
                      onKeyDown={handlePhoneKeyDown}
                      onChange={handlePhoneChange}
                      onPaste={handlePhonePaste}
                      onBlur={handlePhoneOnBlur}
                    />
                  )}
                />
              </MultipleInputContainer>
              {multiplePhones.error && <p style={ErrorStyle}>{multiplePhones.error}</p>}
            </BaseLabelWrapper>
          </StyledTab>
        </StyledList>

        <BaseFlex className="flex align-items-center justify-content-end">
          <Button loading={pdfFile?.status === requestState.loading} color='red' className='mr-1' onClick={handleDownload} >
            {t('translation:download') + ' pdf'}
          </Button>
          <CopyToClipboard text={props?.documentUrl || url?.data} onCopy={() => setOnCopy(true)}>
            <Button className='mr-1' loading={url?.status === requestState.loading} title={onCopy && 'Скопировано'} disabled={url?.status === requestState.loading}>
              <CopyClipboard />&ensp;{t('sendDocToOthers.copyDocumentLink')}
            </Button>
          </CopyToClipboard>
          <Button htmlType={'submit'} disabled={status === requestState.loading || disableSubmit} loading={status === requestState.loading}>{t('translation:send')}</Button>
        </BaseFlex>
      </StyledForm>
    </ThemeProvider>
  )
}

export default SendToPublicViewForm;