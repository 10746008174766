import { useMemo } from 'react';
import requestState from 'Services/api/status';
const SCROLL_LIMIT = 50;
const useVirtualData = (data, status, total) => {
  const tableData = useMemo(() => {
    let list = Array.isArray(data) ? data : [];

    switch (status) {
      case requestState.loading:
        return [...list, { isLoading: true }];
      case requestState.success:
        if (list.length === total && list.length > SCROLL_LIMIT) return [...list, { isEnd: true }];

        return list;

      case requestState.error:
        return [...list, { isError: true }];
      default:
        return [];
    }
  }, [status, data, data?.status, total]);

  return tableData;
};

export { SCROLL_LIMIT };

export default useVirtualData;
