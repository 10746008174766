import { getBaseLineClampElement } from 'Common/Style/getBaseLineClampElement';
import styled, { css } from 'styled-components';

const BaseCellContent = styled(getBaseLineClampElement('p'))`
  white-space: pre-wrap;
  line-height: 16px;
  margin: 0;
  width: 100%;
`;

const StyledSelectWrapper = styled.div`
  position: relative; 
  height: 54px;
  overflow: hidden;

  .textContent {
    display: flex;
    align-items: center;
    justify-content: center;
    
    position: absolute;
    z-index: 2;
    top: 3px;
    right: 0;
    height: 48px;
    width: 100%;
    
    span {
      max-width: 100%;
      font-size: 13px;
      line-height: 16px;
      padding-left: 6px;
      padding-right: 18px;
      text-align: center;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }
  }

  select {
    position: relative;
    z-index: 3;
    background-color: transparent;

    font-size: 0 !important;
    line-height: 0;
    option {
      font-size: 12px !important;
    }
  }
`

const StyledSelect = styled.select`
  border: none;
  height: 54px;
  width: 95%;
  padding: 4px 6px;
  border-radius: 3px;
  box-shadow: 0 0 1px 1px transparent;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  outline: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }
  &::-ms-expand {
    display: ${props => props.optionsCount > 1 ? 'block' : 'none'} !important;
  }
  ${props => !(props.optionsCount > 1) ? css`
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      text-indent: 1px;
      text-overflow: '';
    ` : ''}
`;
const CenteredCellItem = ({children, ...rest}) => <div style={{display: 'flex', alignItems: 'center', height: '100%'}}><BaseCellContent {...rest}>{children}</BaseCellContent></div>

export {
  StyledSelect,
  CenteredCellItem,
  BaseCellContent,
  StyledSelectWrapper
}