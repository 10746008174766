import * as allDocTypes from 'Store/docTypes';

export const invoiceTypes = [
  allDocTypes.INVOICE_STANDART_TAX,
  allDocTypes.NEW_TYPE_INVOICE,
  allDocTypes.INVOICE_EXCISE_CONSTRUCTION_SERVICE,
  allDocTypes.INVOICE_EXCISE_TAX,
  allDocTypes.INVOICE_EXCISE_SERVICE,
  allDocTypes.INVOICE_EXCISE_CONSTRUCTION_SERVICE,
  allDocTypes.GSM_TAX,
  allDocTypes.INVOICE_THREE_SIDE_STANDART_TAX,
  allDocTypes.INVOICE_PHARM1_TAX,
  allDocTypes.INVOICE_COMMITENT_TAX,
  allDocTypes.INVOICE_COMMISIONER_TAX,
  allDocTypes.INVOICE_PHARM2_TAX,
  allDocTypes.INVOICE_SUPPLIES,
  allDocTypes.INVOICE_TRANSPORT,
  allDocTypes.INVOICE_GSM,
  allDocTypes.INVOICE_CMR,
  allDocTypes.INVOICE_VED,
  allDocTypes.INVOICE_BUFFER_DEPARTMENT,
  allDocTypes.INVOICE_IT_DEPARTMENT,
  allDocTypes.INVOICE_PTO,
  allDocTypes.INVOICE_ADVERTISEMENT,
  allDocTypes.INVOICE_FINANCIAL,
  allDocTypes.INVOICE_SUPPLIES_SERVICE,
  allDocTypes.INVOICE_MATERIALS,
  allDocTypes.INVOICE_PROJECT_MANAGER,
  allDocTypes.INVOICE_SMALL_MECHANIZATION,
  allDocTypes.INVOICE_LABORATORY, // SMALL MECHANIZATION
  allDocTypes.INVOICE_ACCOUNTING, // ACCOUNTING
  allDocTypes.INVOICE_SALES_DEPARTMENT, // SALES_DEPARTMENT
  allDocTypes.INVOICE_MANAGEMENT, // MANAGEMENT
  allDocTypes.INVOICE_AXO, // АХО
  allDocTypes.INVOICE_LEGAL_DEPARTMENT, // LEGAL_DEPARTMENT
  allDocTypes.INVOICE_ENERGY,
  allDocTypes.INVOICE_IMPORT,
  26,
  allDocTypes.INVOICE_SERVICESE,
  allDocTypes.INVOICE_CONSTRUCTION_SERVICESE,
  allDocTypes.INVOICE_TRANSPORTION
];

export function isContractHandler(currentDoctype) {
  let contractDocTypes = [
    allDocTypes.CONTRACT,
    allDocTypes.CONTRACT_TAX,
    allDocTypes.NEW_TYPE_CONTRACT,
    allDocTypes.CONTRACT_TRANSPORT, // TRANSPORT
    allDocTypes.CONTRACT_GSM, // GSM
    allDocTypes.CONTRACT_VED, // VED
    allDocTypes.CONTRACT_BUFFER_DEPARTMENT, // BUFFER_DEPARTMENT
    allDocTypes.CONTRACT_IT_DEPARTMENT, // IT_DEPARTMENT
    allDocTypes.CONTRACT_PTO, // PTO
    allDocTypes.CONTRACT_ADVERTISEMENT, // ADVERTISEMENT
    allDocTypes.CONTRACT_SUPPLIES, // SUPPLIES
    allDocTypes.CONTRACT_FINANCIAL, // Financial
    allDocTypes.CONTRACT_SUPPLIES_SERVICE, // SUPPLIES SERVICE
    allDocTypes.CONTRACT_MATERIALS, // materials
    allDocTypes.CONTRACT_PROJECT_MANAGER, // PROJECT MANAGER
    allDocTypes.CONTRACT_SMALL_MECHANIZATION, // SMALL MECHANIZATION
    allDocTypes.CONTRACT_LABORATORY, // _LABORATORY
    allDocTypes.CONTRACT_ACCOUNTING, // ACCOUNTING
    allDocTypes.CONTRACT_SALES_DEPARTMENT, // SALES_DEPARTMENT
    allDocTypes.CONTRACT_MANAGEMENT, // MANAGEMENT
    allDocTypes.CONTRACT_AXO, // АХО
    allDocTypes.CONTRACT_LEGAL_DEPARTMENT,
    allDocTypes.CONTRACT_TAX_1,
    allDocTypes.CONTRACT_TAX_2,
    allDocTypes.CONTRACT_TA_3
  ];
  return contractDocTypes.indexOf(currentDoctype) > -1;
}

export function isInvoiceHandler(currentDoctype) {
  return invoiceTypes.indexOf(currentDoctype) > -1;
}

export function isAct(currentDocType) {
  return currentDocType === allDocTypes.ACT_TAX || currentDocType === allDocTypes.ACT_TRANSPORT || // TRANSPORT
    currentDocType === allDocTypes.ACT_GSM || // GSM
    currentDocType === allDocTypes.ACT_VED || // VED
    currentDocType === allDocTypes.ACT_BUFFER_DEPARTMENT || // BUFFER_DEPARTMENT
    currentDocType === allDocTypes.ACT_IT_DEPARTMENT || // IT_DEPARTMENT
    currentDocType === allDocTypes.ACT_PTO || // PTO
    currentDocType === allDocTypes.ACT_ADVERTISEMENT || // ADVERTISEMENT
    currentDocType === allDocTypes.ACT_SUPPLIES || // SUPPLIES
    currentDocType === allDocTypes.ACT_FINANCIAL || // Financial
    currentDocType === allDocTypes.ACT_SUPPLIES_SERVICE || // SUPPLIES SERVICE
    currentDocType === allDocTypes.ACT_MATERIALS || // materials
    currentDocType === allDocTypes.ACT_PROJECT_MANAGER || // PROJECT MANAGER
    currentDocType === allDocTypes.ACT_SMALL_MECHANIZATION || // SMALL MECHANIZATION
    currentDocType === allDocTypes.ACT_LABORATORY ||
    currentDocType === allDocTypes.NEW_TYPE_ACT ||
    currentDocType === allDocTypes.ACT_ACCOUNTING;
}

export function isTaxHandler(currentDoctype) {
  if (currentDoctype === allDocTypes.CONTRACT) return false;

  return isInvoiceHandler(currentDoctype) || isContractHandler(currentDoctype) || isTaxEmpowerment(currentDoctype) || isWayBillTax(currentDoctype) || isAct(currentDoctype);
}

export function isTaxEmpowerment(currentDoctype) {
  return currentDoctype === allDocTypes.EMPOWERMENT_TAX;
}

export function isWayBillTax(currentDoctype) {
  return currentDoctype === allDocTypes.WAYBILL_TAX;
}

export function isZayavkaHandler(currentDoctype) {
  const zayavkaDoctypes = [
    allDocTypes.APPLICATION,
    allDocTypes.UNIVERSAL_ZAYAVKA,
    allDocTypes.HILTON_JOB_REQ_FORM,
    allDocTypes.HILTON_SALARY_CHANGE_FORM,
    allDocTypes.HILTON_PURCHASE_ORDER_FORM,
    allDocTypes.HILTON_VOCATION_FORM,
    allDocTypes.ADDITIONAL_AGREEMENT
  ];

  return zayavkaDoctypes.indexOf(currentDoctype) > -1;
}

//Those functions decides which docTypes works with AI
export function isAiHandler(currentDoctype) {
  const aiDoctypes = [
    allDocTypes.CONTRACT,
    allDocTypes.CONTRACT_WORK,
    allDocTypes.CONTRACT_TAX_1,
    allDocTypes.CONTRACT_TAX_2,
    allDocTypes.CONTRACT_TA_3,
    64, 65, 66, 67
  ];

  return aiDoctypes.indexOf(currentDoctype) > -1;
}
