import ApiUser from 'Services/api/controller/user';
import * as con from 'Store/constant';

export const fetchFlexListData = (value = '') => {
  return async (dispatch) => {
    dispatch({ type: con.FETCH_FLEX_LIST });
    value = value.trim();

    if (value.split('').length === 9 || value.split('').length === 14) {
      const globalDatabase = await ApiUser.getSignerFromTaxDatabase(value);
      if (!globalDatabase.fail) return dispatch({ type: con.FETCH_FLEX_LIST_SUCCESS, payload: [globalDatabase.data] });

      if (globalDatabase.fail) return dispatch({ type: con.FETCH_FLEX_LIST_FAIL });
    } else {
      const flexListSelfDatabse = await ApiUser.getSignerFromLocalDatabase(value);

      if (flexListSelfDatabse?.data?.length > 0 && !flexListSelfDatabse?.fail)
        return dispatch({ type: con.FETCH_FLEX_LIST_SUCCESS, payload: flexListSelfDatabse?.data });

      if (flexListSelfDatabse?.fail) return dispatch({ type: con.FETCH_FLEX_LIST_FAIL });
    }
  };
};
