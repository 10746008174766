import moment from 'moment';
import nth from 'lodash.nth';
export const BASE_DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_FOR_BACKEND = 'YYYY-MM-DD';
export const STAMP_DATE_FORNAT = `${BASE_DATE_FORMAT} hh:mm:ss`;
export const AUTH_PAGE_DATE_FORMAT = 'YYYY.MM.DD';

export const formatTime = (date = '', format = BASE_DATE_FORMAT) => {
  if (!date) return '';
  return moment(date).format(format);
};


export function onReplaceDashToDot(date) {
  if (!date) return;

  const arr = date.split('');

  return `${nth(arr, 8)}${nth(arr, 9)}.${nth(arr, 5)}${nth(arr, 6)}.${nth(arr, 2)}${nth(arr, 3)}`;
}