import 'core-js';
import 'core-js/features/set/map';
import size from 'lodash.size';

(function () {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

// NOTE polyfill allSetled;
(() => {
  Promise.allSettled = function (promises) {
    let mappedPromises = promises.map((p) => {
      return p
        .then((value) => {
          return {
            status: 'fulfilled',
            value
          };
        })
        .catch((reason) => {
          return {
            status: 'rejected',
            reason
          };
        });
    });
    return Promise.all(mappedPromises);
  };
})();

// NOTE new function flaTArrayItem

Array.prototype.flatObj = function (key) {
  let result = [];

  this.forEach((item, index) => {
    if (size(item[key]) === 0 || !item.hasOwnProperty(key)) {
      result = [...result, item];
    } else {
      result = [...result, ...item[key].flatObj(key)];
    }
  });

  return result;
};

Array.prototype.findAndUpdateObjByValue = function (key = '', value = '', newListItem = {}) {
  try {
    let requestedList = [];

    for (let i = 0; i < this.length; i++) {
      if (this[i][key] === value) {
        this[i] = newListItem;
      }
      requestedList.push(this[i]);
    }
    return requestedList;
  } catch (e) {
    return [];
  }
};

Array.prototype.findAndRemoveObjByValue = function (key = '', value = '') {
  let filteredList = [];

  for (let i = 0; i < this.length; i++) {
    if (this[i][key] === value) {
      continue;
    }

    filteredList.push(this[i]);
  }

  return filteredList;
};

Array.prototype.findAndRemovePrimitive = function (value = '') {
  let filteredList = [];
  for (let i = 0; i < this.length; i++) {
    if (this[i] === value) {
      this.splice(i, 1);
      break;
    }

    filteredList.push(this[i]);
  }
};

// NOTE new function String

String.prototype.toInt = function (value) {
  if (!this) return value;
  return parseInt(this);
};

String.prototype.toCustomFixed = function (len) {
  if (!this || isNaN(this)) return this;

  let parsedValue = parseFloat(this);

  return parsedValue.toFixed(len);
};
//NOTE NEW FUNCTION TO CONVERT CYRILLIC TO LATIN
String.prototype.converterCyrillicToLatin = function () {
  let Chars = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo', 'ж': 'j', 'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 's', 'ч': 'ch', 'ш': 'sh', 'щ': 'sh', 'ъ': '', 'ы': 'i', 'ь': '', 'э': 'e', 'ю': 'yu', 'я': 'ya', 'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'YE', 'Ё': 'YO', 'Ж': 'J', 'З': 'Z', 'И': 'I', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N', 'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'S', 'Ч': 'CH', 'Ш': 'SH', 'Щ': 'SH', 'Ъ': '', 'Ы': 'I', 'Ь': '', 'Э': 'E', 'Ю': 'YU', 'Я': 'YA'
  },
    t = this;
  for (let i in Chars) { t = t.replace(new RegExp(i, 'g'), Chars[i]); }
  return t;
};


Number.prototype.toCustomFixed = function (len) {
  if (!this || isNaN(this)) return this;

  return this.toFixed(len);
};

// NOTE define array methods
const noEnum = function (v) {
  return {
    value: v,
    enumerable: false,
    writable: true,
    configurable: true
  };
}

Object.defineProperty(Array.prototype, 'flatObj', noEnum(Array.prototype.flatObj));
Object.defineProperty(Array.prototype, 'findAndUpdateObjByValue', noEnum(Array.prototype.findAndUpdateObjByValue));
Object.defineProperty(Array.prototype, 'findAndRemoveObjByValue', noEnum(Array.prototype.findAndRemoveObjByValue));
Object.defineProperty(Array.prototype, 'findAndRemovePrimitive', noEnum(Array.prototype.findAndRemovePrimitive));

// NOTE

Object.defineProperty(Object.prototype, 'getProp', {
  value: function (prop, defaultValue) {
    var key,
      self = this;
    for (key in self) {
      if (key.toLowerCase() == prop.toLowerCase()) {
        return self[key];
      }
    }
    return defaultValue;
  }
});
