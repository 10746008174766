import React from 'react';

const SVG = ({ style = {}, fill = '#000', width = '100%', onClick, className = '', viewBox = '0 0 40 26' }) => (
  <svg
    width={width}
    style={style}
    onClick={onClick}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M15 24.2217C15.809 24.2217 16.4648 23.5658 16.4648 22.7568C16.4648 21.9478 15.809 21.292 15 21.292C14.191 21.292 13.5352 21.9478 13.5352 22.7568C13.5352 23.5658 14.191 24.2217 15 24.2217Z"
      fill="white"
    />
    <path
      d="M15 0.579102C6.70992 0.579102 0 7.28791 0 15.5791C0 23.8692 6.70881 30.5791 15 30.5791C23.2901 30.5791 30 23.8703 30 15.5791C30 7.28902 23.2912 0.579102 15 0.579102ZM15 28.2354C8.00525 28.2354 2.34375 22.5748 2.34375 15.5791C2.34375 8.58436 8.00432 2.92285 15 2.92285C21.9947 2.92285 27.6562 8.58342 27.6562 15.5791C27.6562 22.5738 21.9957 28.2354 15 28.2354Z"
      fill="white"
    />
    <path
      d="M15 8.1084C12.4153 8.1084 10.3125 10.2112 10.3125 12.7959C10.3125 13.4431 10.8371 13.9678 11.4844 13.9678C12.1316 13.9678 12.6562 13.4431 12.6562 12.7959C12.6562 11.5036 13.7077 10.4521 15 10.4521C16.2923 10.4521 17.3438 11.5036 17.3438 12.7959C17.3438 14.0882 16.2923 15.1396 15 15.1396C14.3528 15.1396 13.8281 15.6643 13.8281 16.3115V19.2412C13.8281 19.8884 14.3528 20.4131 15 20.4131C15.6472 20.4131 16.1719 19.8884 16.1719 19.2412V17.3351C18.1914 16.8134 19.6875 14.9761 19.6875 12.7959C19.6875 10.2112 17.5847 8.1084 15 8.1084Z"
      fill="white"
    />
  </svg>
);

export default SVG;
