import styled from 'styled-components';

const StyledFilterContainer=styled.div`
  position: absolute;
  top:40px;
`;


export {
    StyledFilterContainer
}