import React from "react";

const SVGComponent = (props) => (
    <svg
        width={17}
        height={18}
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16.0037 6.59452C16.0078 6.50305 15.9889 6.41201 15.9487 6.32974C15.9085 6.24746 15.8484 6.17656 15.7737 6.12352L8.21774 0.134519C8.11729 0.0477461 7.98898 0 7.85624 0C7.7235 0 7.59519 0.0477461 7.49474 0.134519L0.200737 6.12352C0.133951 6.18179 0.0812546 6.25445 0.0466121 6.33603C0.0119696 6.41762 -0.00371324 6.506 0.000740356 6.59452V17.3945C-0.00237212 17.4747 0.0106264 17.5547 0.0389674 17.6297C0.0673085 17.7048 0.110413 17.7734 0.165737 17.8315C0.215902 17.8857 0.276901 17.9288 0.344776 17.9579C0.412652 17.987 0.485889 18.0015 0.559738 18.0005H4.89674C4.97575 18.0036 5.05453 17.9901 5.12805 17.961C5.20157 17.9319 5.26822 17.8878 5.32374 17.8315C5.37889 17.7733 5.42181 17.7047 5.44998 17.6296C5.47815 17.5546 5.49098 17.4746 5.48774 17.3945V10.7665H10.4827V17.3945C10.4795 17.4746 10.4923 17.5546 10.5205 17.6296C10.5487 17.7047 10.5916 17.7733 10.6467 17.8315C10.7023 17.8878 10.7689 17.9319 10.8424 17.961C10.916 17.9901 10.9947 18.0036 11.0737 18.0005H15.4127C15.4917 18.0036 15.5705 17.9901 15.644 17.961C15.7176 17.9319 15.7842 17.8878 15.8397 17.8315C15.8949 17.7733 15.9378 17.7047 15.966 17.6296C15.9941 17.5546 16.007 17.4746 16.0037 17.3945V6.59452Z"
            fill="#7A7B8F"
        />
    </svg>
);
export default SVGComponent;
