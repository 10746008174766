import React, { useCallback, useState } from 'react'; 
import { Modal } from 'antd';
import styled from 'styled-components';

import NotificationPopup from './NotificationPopup';
import { useSelector } from 'react-redux';
import { DispatchNotification } from './actions';
import { synteticDelayPromise } from 'Utils/synteticDelayPromise';


export const StyledModal = styled(Modal)`
  width: 700px !important;
  
  & .ant-modal-body {
    padding: 0;
    width: 100%;
  }

  & .ant-modal-close {
    visibility: hidden;
  }
`;


const GlobalNotificationModal = () => {
  const notifications = useSelector(state => state.notification.list);
  const count = notifications.length;

  const [transform, setTransform] = useState('scale(1)');
  const animateOnClose = useCallback(async function animater () {
    setTransform('scale(1.015)');
    await synteticDelayPromise(80);
    setTransform('scale(1.005)');
    await synteticDelayPromise(80);
    setTransform('scale(1.01)');
    await synteticDelayPromise(80);
    setTransform('scale(1)');
  }, []);

  const onOk = useCallback((id) => {
    DispatchNotification.remove(id);
  }, []);

  if(count === 0) return null;
  const data = notifications[0];
  
  return (
    <StyledModal
      visible={true}
      footer={null}
      style={{padding: 0, transform, transition: 'transform 0.08s ease'}}
      closeIcon={<div/>}
      zIndex={1010}
      // onCancel={animateOnClose}
      key={data.id}
    >
      <NotificationPopup data={data} onOk={onOk}/>
    </StyledModal>
  )
};

export default GlobalNotificationModal;