import { useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
const useUploadFile = () => {
  const { t } = useTranslation();
  const [fileStatus, setFileStatus] = useState('initial');
  const [filePath, setPath] = useState('');
  const [file, setFile] = useState('');
  const span = useRef();

  const change = useCallback(
    (e = ' ') => {
      const value = e.target.value;
      if (!value) return alert(t('writeIn'));
      const path = value.split('\\')[value.split('\\').length - 1];
      setPath(e.target.files[0]);
      span.current.innerText = path;
      setFileStatus('fill');
    },
    [span, filePath]
  );

  const onResetInputFile = useCallback(() => {
    if (span?.current) {
      span.current.innerText = '';
    }

    if (document.getElementById('file-upload')) {
      document.getElementById('file-upload').value = '';
    }
    setPath('');
  }, [filePath, span]);

  const uploadFileChangeHandler = useCallback(
    (e) => {
      setFile(e.target.files[0]);
      change(e);
    },
    [file, span, filePath]
  );

  const getFileUrl = () => {
    if (filePath) {
      try {
        return URL.createObjectURL(filePath) || '';
      } catch (err) {
        console.log(err);
      }
    }
  };

  return {
    span,
    change,
    path: filePath,
    onResetInputFile,
    setPath,
    uploadFileChangeHandler,
    file,
    getFileUrl
  };
};

export default useUploadFile;
