import { colors } from 'Style/colors';
import styled from 'styled-components';
import { Col, Collapse } from 'antd';

export const StyledCollapse = styled(Collapse)`
  &.ant-collapse {
    border: 1px solid #ffffff;

    .ant-collapse-item {
      border-bottom: 1px solid #ffffff;

      .ant-collapse-header {
        align-items: center;

        .ant-collapse-arrow svg {
          transition: transform 0.24s;
        }
      }

      .ant-collapse-content {
        border-top: 0px solid #ffffff;
      }
    }
  }
`;

// root {
//     --primary: #115cef;
//     --secondary: #76767b;
//     --black: #090909;
// }

// new design
export const CardWrapperTest = styled.div`
  max-width: 1280px;
  width: 100%;
  margin-top: 3rem;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

export const Plans = styled.div`
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: flex;
  & > *:nth-child(1) {
    margin-left: 0;
  }
`;

export const Card = styled(Col)`
  width: 100%;
  max-width: 390px;
  height: 355px;
  transition: opacity 400ms ease 0s, transform 400ms ease 0s;
  opacity: 1;
  transform: none;
  visibility: visible;
  background-color: white;
  margin: auto;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  ${(props) => props.isActive && 'background-color:#f0f0f0;'}
  ${(props) => props.isLanding && 'border-radius: 15px;'};
  @media (max-width: 440px) {
    max-width: 350px;
  }
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
`;

export const Badge = styled.div`
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
  &:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #79a70a;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79a70a;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #79a70a;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79a70a;
  }
`;

export const CardInline = styled.div`
  padding: 26px 25px 24px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;

  @media (max-width: 700px) {
    padding: 26px 10px 24px;
  }
`;

export const CardHeader = styled.div`
  //padding-left: 40px;
  //padding-right: 40px;
`;

export const CardHeaderH2 = styled.h2`
  font-size: 40px;
  line-height: 46px;
  margin-bottom: 10px;
  font-weight: 700;
  @media (max-width: 700px) {
    font-size: 35px;
  }
`;

export const CardHeaderH1 = styled.h1`
  font-size: 56px;
  line-height: 64px;
  color: ${colors.primary} !important;
  margin-bottom: 8px;
  @media (max-width: 700px) {
    font-size: 40px;
  }
`;

export const CardHeaderH5 = styled.h5`
  font-size: 22px;
  line-height: 26px;
  color: #71717a;
  font-weight: 600;
  ${(props) => props.isLanding && ' color: #76767b;'}
`;

export const CardBottom = styled.div`
  //padding-left: 40px;
  //padding-right: 40px;
  margin-top: auto;
  box-sizing: border-box;
`;

export const CardBottomButton = styled.button`
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 24px;
  padding: 16px 32px;
  align-items: center;
  outline: 0;
  border-radius: 15px;
  border: 0;
  margin: 0;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #eff6ff;
  background-color: ${colors.primary};
  width: 100%;
  box-shadow: none;
  text-transform: none;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  &:hover {
    color: ${colors.primary};
    background-color: #ffffff;
    border: 1px solid ${colors.primary};
    padding: 15px 32px;
    box-sizing: border-box;
  }
`;

export const Compares = styled.div`
  max-width: 100%;
  width: 1280px;
  margin-top: 3rem;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: flex;
  margin-bottom: 6rem;
  flex-direction: column;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

  ${(props) => props.isLanding && ' border-radius: 15px;'};

  @media (max-width: 1280px) {
    overflow-x: scroll;
  }
`;

export const ComparesHeader = styled.div`
  width: 1200px;
  align-items: center;
  padding: 2rem 1rem;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  display: flex;
  justify-content: space-between;
`;

export const ComparesHeaderText = styled.p`
  margin: 0;
  font-weight: 700;
  flex: auto;
  font-size: 40px;
  line-height: 46px;
  margin-right: 16px;
  color: #bdbdbd;
  ${(props) => props.isLanding && ' color: #76767b;'}
`;

export const ComparesHeaderItem = styled.div`
  flex: 0 0 160px;
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
`;

export const ComparesHeaderItemHeaderText = styled.div`
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 4px;
  font-weight: 700;
`;

export const ComparesHeaderItemFooterText = styled.div`
  font-size: 1.5rem;
  line-height: 24px;
  font-weight: 600;
  color: #71717a;
  ${(props) => props.isLanding && ' color: #76767b;'}
`;

export const ComparesList = styled.div`
  width: 1200px;
  padding-bottom: 12px;
  ${(props) => props.isLanding && ' border-radius: 15px;'}
`;

export const PanelItem = styled.div`
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  ${(props) => props.isLanding && ' border-radius: 15px;'}
`;

export const PanelBorder = styled.div`
  height: 4px;
  background-color: #f1f3f5;
  border-radius: 16px;
`;

export const PanelItemText = styled.div`
  flex: auto;
  margin-right: 10px;
  font-size: 16px;
  line-height: 20px;
  width: 75%;
`;

export const PanelItemTextContent = styled.div`
  font-size: 20px;
  line-height: 26px;
  color: #71717a;
  font-weight: 700;
  ${(props) => props.isLanding && ' color: #76767b;'}
`;

// new design
/* 
export const CardWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px 0px 0px;
  max-height: 90vh;
`;

export const CardItem = styled.div`
    width: calc((100% - 40px) / 4.7);
    height: 1600px;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => (props.isActive ? '#64c5e1;' : '#fff;')} 
    border: 1px solid #64c5e1;
    padding: 60px 30px;
    border-radius: 15px;
    align-items: center;
    transition: background-color 0.4s ease, color 0.4s ease, fill 0.4s ease, border 0.4s ease;
    margin-bottom:30px;
`;

export const IconHolder = styled.div`
  margin-bottom: 20px;
  height: 50px;
  width: auto;
  transition: background-color 0.4s ease, color 0.4s ease, fill 0.4s ease, border 0.4s ease;
`;

export const Cardtitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px;
  ${(props) => props.isActive && 'color:#FFFFFF;'}
`;

export const SubscriptionPrice = styled.div`
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 35px;
`;

export const Currency = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  padding-top: 4px;
  color: #64c5e1;
  ${(props) => props.isActive && 'color:#FFFFFF;'}
`;

export const StyledPriceLabel = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.3px;
  color: #64c5e1;
  white-space: nowrap;
  ${(props) => props.isActive && 'color:#FFFFFF;'}
`;
 */
export const StyledText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #222;
  ${(props) => props.isActive && 'color:#FFFFFF;'}

  &:first-child {
    font-size: 18px;
    margin-top: 7px;
  }

  &.warning {
    font-size: 18px;
    margin-top: 7px;
  }
`;

export const OptionLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 17px;
  color: #333;
  transition: color 0.3s ease, border-color 0.3s ease;
  width: 50%;

  &:last-child {
    border-bottom: none;
    width: 30%;
  }
`;
export const DiscountBadge = styled.div`
    background: ${(props) => (props.discountNum === 10 ? 'linear-gradient(100.32deg, #005CFF -15.82%, #0BD3FF 115.19%)' : 'linear-gradient(101.03deg, #6BF400 -26.75%, #00B7DF 111.41%)')};
    border-radius: 3px;
    color: white;
    padding: 3px 5px;
    align-self: center;
    font-size: 12px;
`;
