import styled from 'styled-components';
import get from 'lodash.get';
import { colors } from 'Style/colors';

const warningClass = 'table-out-error-field';

const BaseStyledLabel = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights['bold']};
  font-size: ${({ theme }) => theme.screens.laptop.sizes['sm']};
  width: 100%;
  text-overflow: ellipsis;
  color: ${colors.textColor};

  &.toggle {
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: flex-end;

    & > span {
      font-size:12px;
      margin-bottom: 2px;
      margin-right: 1rem;

      &.lowOpacity {
        opacity: 0.6;
      }
    }
  }

  &.toggle1 {
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;

    & > span > span {
      font-size:12px;
      margin-bottom: 2px;
      margin-right: 1rem;

      &.lowOpacity {
        opacity: 0.6;
      }
    }
  }

  &.none-margin {
    margin-right: 0px !important;
  }

  & {
    &.table-out-error-field-file {
      color: ${colors.warning};
    }

    & > span {
      display: block;
      height: 20px;
    }

    &.table-out-error-field {
      color: ${colors.warning};
      & > span {
        color: ${colors.warning};
      }
    }
  }

  & > span {
    color: ${colors.textColor};
  }

  &.bigLabel {
    font-size:20px;
    width:auto;
    margin-right:20px;
  }

  &.signed {
    font-size:24px;
    width:100%;
    padding:18px auto;
    background-color:#00C32A;
    text-transform:uppercase;
    color:white;
    text-align:center;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
  }
`;

export {
  warningClass
}

export default BaseStyledLabel;
