import styled from 'styled-components';
import get from 'lodash.get';
import { Modal } from 'antd';


export const StyledButtonContainer = styled.div`
  & button {
    height: ${({ theme }) => get(theme, 'screens.laptop.formElements.height')};
    & {
      margin-right: 10px;
      &:last-child {
        margin-right: 15px;
      }
    }
  }
`;

export const StyledModal = styled(Modal)`
  & .ant-modal-header,
  & .ant-modal-footer {
    padding: 10px !important;
    & button {
      height: 33px;
      line-height: 20px;
    }
  }

  & .ant-modal-close-x {
    line-height: 36px;
  }
`;
