import { createSelector } from 'reselect';

// ANCHOR Utils and Services functions\
import { getTypesArray } from 'Utils/getDocTypes';
import { onFlatMenuList } from 'Utils/flatMenuList';
import { onGenerateMenuItems } from 'Utils/generateMenuItems';
import { onThreeSideDocument } from "Utils/threeSideDocument";

import * as con from 'Store/constant';
import browserStorage from 'Services/browserStorage';

import { defaultOpenKeys, defaultSelectedId, defaultAgreements } from './sideBarMenu';

const initialState = {
  status: 'initial',
  menuPending: false,
  items: [],
  menuFail: false,
  doctype: defaultSelectedId(),
  openKeys: defaultOpenKeys(),
  editDocId: null,
  editTemplateId: null,
  formsExcelData: [],
  isMarking: false,
  correctionValue: null,
  width: 0,
  doubleSideTypes: [],
  threeSideTypes: [],
  activeSideBar: browserStorage.get('activeIndex') ? parseInt(browserStorage.get('activeIndex')) : 0,
  selectedDoubleTypes: [],
  selectedTripleTypes: [],
  allDoubleTypes: [],
  allTripleTypes: [],
  isSubsidebar: false,
  isReport: false,
  type: 1,
  actualLanguage: '',//To do bu parameterni tekshirish kerak, ishlatilmayotganga o'xshidi
  agreements: defaultAgreements(),
};

const normalizerForRenderMenu = (payload) => {
  const { data, secondParty, thirdPartyTitle } = payload;

  let result = [
    { id: 0, title: secondParty, part: 2, children: [] },
    { id: 100, title: thirdPartyTitle, part: 3, children: [] }
  ];

  for (let i = 0; i < data.length; i++) {
    let containerEl = result.find((item) => item.part === data[i].part),
      index = result.findIndex((item) => item.part === data[i].part);

    if (data[i].title) {
      containerEl = {
        ...containerEl,
        children: [...containerEl.children, { id: data[i].id, title: data[i].title, children: data[i].children }]
      };
    } else {
      containerEl = { ...containerEl, children: [...containerEl.children, ...data[i].children] };
    }
    result[index] = containerEl;
  }

  result.forEach((item, index) => {
    if (item.children.length === 0) return result.splice(index, 1);
  });

  return result;
};

const menuReducer = (state = initialState, { type, payload }) => {
  let { selectedTripleTypes, selectedDoubleTypes } = state;
  switch (type) {
    case con.FETCH_MENU_ITEMS:
      return {
        ...state,
        menuPending: true,
        isReport: false,
        status: 'loading'
      };
    case con.FETCH_MENU_ITEMS_SUCCESS:
      const items = normalizerForRenderMenu(payload),
        doubleSideTypes = getTypesArray(onFlatMenuList([], items, 2)),
        threeSideTypes = getTypesArray(onFlatMenuList([], items, 3));
      browserStorage.set('doctype', state.doctype);
      return {
        ...state,
        menuFail: false,
        menuPending: false,
        items,
        doctype: state.doctype,
        doubleSideTypes,
        threeSideTypes,
        status: 'success'
      };
    case con.FETCH_REPORTS_MODULE_ITEMS_SUCCESS:
      const items2 = normalizerForRenderMenu(payload);
      let doubleTypes = getTypesArray(onFlatMenuList([], items2, 2));
      let tripleTypes = getTypesArray(onFlatMenuList([], items2, 3));
      payload?.data[0]?.children?.forEach((item, index) => {
        if (onThreeSideDocument(payload?.data[0]?.children[index].type)) {
          tripleTypes.push(item['type']);
        } else {
          doubleTypes.push(item['type']);
        }
      });
      return {
        ...state,
        menuFail: false,
        menuPending: false,
        items: items2,
        allDoubleTypes: doubleTypes,
        allTripleTypes: tripleTypes,
        isReport: true,
        status: 'success'
      };
    case con.FETCH_MENU_ITEMS_FAIL:
      return {
        ...state,
        menuFail: true
      };
    case con.CHANGE_DOCTYPES:
      const { currentTypes, part } = payload;
      return {
        ...state,
        doubleSideTypes: part === 2 ? currentTypes : state.doubleSideTypes,
        threeSideTypes: part === 3 ? currentTypes : state.threeSideTypes
      };

    case con.DOC_TYPE:
      browserStorage.set('doctype', payload);
      return {
        ...state,
        doctype: payload
      };
    case con.MENU_OPENKEYS:
      return {
        ...state,
        openKeys: payload
      };
    case con.EDIT_DOC_ID:
      return {
        ...state,
        editDocId: payload
      };
    case con.EDIT_TEMPLATE_ID:
      return {
        ...state,
        editTemplateId: payload
      };
    case con.IS_MARKING:
      return {
        ...state,
        isMarking: payload
      };
    case con.CORRECTION_DOC:
      return {
        ...state,
        correctionValue: payload
      };
    case con.FORMS_EXCEL_DATA:
      return {
        ...state,
        formsExcelData: payload
      };
    case con.DOC_TYPE_FOR_DISPLAY:
      return {
        ...state,
        type: payload
      };
    case con.LAYOUT_WIDTH:
      return {
        ...state,
        width: payload
      };
    case con.CHANGE_ACTIVE_SIDEBAR:
      return {
        ...state,
        activeSideBar: payload
      };

    case con.CHANGE_SELECT_MULTIPLE_TYPES:


      if (onThreeSideDocument(payload)) {
        if (selectedTripleTypes.includes(payload)) {
          selectedTripleTypes = selectedTripleTypes.filter((type) => type !== payload);
        } else {
          selectedTripleTypes = [...selectedTripleTypes, payload];
        }
      } else {
        if (selectedDoubleTypes.includes(payload)) {
          selectedDoubleTypes = selectedDoubleTypes.filter((type) => type !== payload);
        } else {
          selectedDoubleTypes = [...selectedDoubleTypes, payload];
        }
      }

      return {
        ...state,
        selectedDoubleTypes,
        selectedTripleTypes,
      };
    case con.CHANGE_DOUBLE_TYPES:
      return {
        ...state,
        selectedDoubleTypes: payload
      };
    case con.CHANGE_TRIPLE_TYPES:
      return {
        ...state,
        selectedTripleTypes: payload
      };
    case con.SHOW_AND_HIDE_SUBSIDEBAR:
      return {
        ...state,
        isSubsidebar: payload
      };
    case con.SET_NEW_LANGUAGE:
      return {
        ...state,
        actualLanguage: payload
      };
    case con.SET_AGREEMENTS_SUBMENU:
      browserStorage.set('agreements', payload);
      return {
        ...state,
        agreements: payload
      };
    case con.MENU_SET_INITIAL_STATUS:
      return {
        ...state,
        status: 'initial'
      };
    case con.RESET_MENU_REDUCER:
      return { ...initialState, doctype: browserStorage.get('doctype') || 43 };
    case con.CLEAR_EDIT_DATA: {
      return {
        ...state,
        editDocId: null,
        correctionValue: null
      };
    }
    default:
      return state;
  }
};

// NOTE  selected types

export const selectTypes = createSelector(
  (state) => state.menues,
  (_, part) => part,
  (menues, part) => {
    const { doubleSideTypes, threeSideTypes } = menues;

    if (part === 1) {
      return [...doubleSideTypes, ...threeSideTypes];
    } else if (part === 2) {
      return doubleSideTypes;
    } else if (part === 3) {
      return threeSideTypes;
    }

    if (menues.activeSideBar === 4) return [...menues?.selectedDoubleTypes, ...menues?.selectedTripleTypes];

    return [];
  }
);

// NOTE inbox and outbox table search inputs options selector

export const selectSeacrhInputOptions = createSelector(
  (state) => state.menues,
  (_, part) => part,
  (menues, part) => {
    let { items } = menues;

    items = [...onGenerateMenuItems(items, undefined, part)];

    items = [...onFlatMenuList([], items, part)];

    let options = [];

    for (let item of items) {
      options = [...options, { value: item.type, label: item.name }];
    }

    return options;
  }
);

// NOTE select active sidebar
export const selectMenues = (state) => state.menues;

export const selectActiveSideBar = createSelector(selectMenues, (arg) => arg?.activeSideBar);

export default menuReducer;
