import React, {useCallback, useEffect, useState} from 'react';
import { Col, Dropdown, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toCurrency } from "Utils/toCurrencyFormat";
import Tooltip from '../../../atoms/tooltip';

import ApiInfo from "Services/api/controller/info";
import CommonIcon from 'Assets/icons/common';
import { MoneyPopupWrapper, DropdownLink, MoneyPopupInner, StyledMenuItem, FlexCol, FlexRow } from './style';
import { StyledAiOutlineDollar } from '../navbar.style';
import { colors } from 'Style/colors';

const MoneyPopup = () => {
  const { t } = useTranslation(['layout']);
  const user = useSelector((state) => state.accountData.user);
  const isNewUi = useSelector((state) => state.ui.isNewUi)
  const [docLimitInfo, setDocLimitInfo] = useState(0);

  const { name = '', tin = '', balance, pinfl = '', isActive, tariffName } = user;

  const docLimit = useCallback(async () => {
    let res = await ApiInfo.getDocLimitInfo();
    setDocLimitInfo(res?.data?.data);
  },[])

  useEffect(() => {
    docLimit();
  }, []);

  const menu = () => {
    return (
      <MoneyPopupInner isNewUi={isNewUi}>
        <StyledMenuItem key={1}>
          <Link className="support-item" to={'/dashboard/payment'}>
            <CommonIcon width={'30'} height={'31'} className="support-item__img" viewBox="0 0 35 30" fill={isNewUi ? colors.iconColor : colors.white} name={'balance'} />
            <div className="support-item__content">
              <p style={{ marginBottom: 0, fontWeight: 500 }}>{t('layout:navbar.paymentAndTariff')}</p>
              <Row>
                <Col span={12}>
                  <span style={{ fontWeight: 'bold', letterSpacing: '1px', textTransform: 'capitalize' }}>
                    {tariffName ? tariffName : 'Individual'}
                  </span>
                </Col>
                <Col span={12}>
                  <span className="balanceStatusSpan" style={{ color: `${isActive == 1 ? '#05f75b' : '#f70505'}` }}>
                    {isActive == 1 ? ' Active' : ' Not Active'}
                  </span>
                </Col>
                <Col span={12}>
                  <span style={{ fontWeight: 'bold', letterSpacing: '1px', textTransform: 'capitalize' }}>
                    {t('layout:navbar.balance')} :
                  </span>
                </Col>
                <Col span={12}>
                  <span className="balanceStatusSpan" style={{ color: `${isActive == 1 ? '#05f75b' : '#f70505'}` }}>
                    {balance ? toCurrency(balance) : '0'}
                  </span>
                </Col>
              </Row>
            </div>
          </Link>
        </StyledMenuItem>
      </MoneyPopupInner>
    );
  };

  // return (
  //   <MoneyPopupWrapper>
  //     <Dropdown down placement="bottomCenter" overlay={menu} trigger={['click']}>
  //       <DropdownLink className="ant-dropdown-link tourSelector wallet-hint" onClick={(e) => e.preventDefault()}>
  //         <StyledAiOutlineDollar />
  //       </DropdownLink>
  //     </Dropdown>
  //   </MoneyPopupWrapper>
  // );

  return (
    <Link to={'/dashboard/payment?tab=2'}>
      <Tooltip placement={'bottom'} color={'#ff0000'} title={isActive === 0 ? t('layout:navbar.userNotActiveText') : ''}>
      <FlexCol isActive={isActive}>
        <FlexRow>
          <p style={{ marginBottom: 0, fontWeight: 700 }}>{t('layout:navbar.description')}:</p>
          <span style={{ textTransform: 'capitalize', fontWeight: 500 }}>
            {tariffName ? tariffName : 'Individual'} {(!tariffName || tariffName.includes('Individual')) && docLimitInfo+'/50'}  {isActive === 0 ? `(${t('layout:navbar.userNotActive')})` : ''}
          </span>
        </FlexRow>
        <FlexRow>
          <p style={{ marginBottom: 0, fontWeight: 700 }}>{t('layout:navbar.balance')}:</p>
          <span className="balanceStatusSpan" style={{ fontWeight: 500 }}>
            {`${balance ? toCurrency(balance) : '0'} ${t('layout:navbar.currency')}`}
          </span>
        </FlexRow>
      </FlexCol>
      </Tooltip>
    </Link>
  )
};

export default MoneyPopup;
