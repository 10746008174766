import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 15px 0;
`;

export const DetailsBtn = styled.a`
  background: #115cef;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;

  &:hover {
    background: #053ba5;
    color: #fff;
  }
`;
