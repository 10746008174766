// ANCHOR npm packages
import React, { useState, useMemo, memo } from 'react';
import { useContext } from 'use-context-selector';
import omit from 'lodash.omit';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List } from 'react-virtualized';

// ANCHOR style
import { customStyles, StyledSelect } from './flexList.style';

// ANCHOR functions in module document
import { autoComplitedDataGenerate } from 'Utils/formatForFlexList';

const onGenerateFlexListOption = (flexList = [], name, isOnlyPinfl) => {
  let options = [];

  switch (name) {
    case 'oldFactura':
      let oldFacturaOptions = []
      if (flexList[0] && flexList[0]?.oldFacturaId) {
        for (let item of flexList) {
          oldFacturaOptions = [...oldFacturaOptions, { ...autoComplitedDataGenerate(item, name) }];
        }
      }

      options = [...oldFacturaOptions]
      break;

    default:
      let otherOptions = [];
      if (flexList[0] && !flexList[0]?.oldFacturaId) {
        for (let item of flexList) {
          otherOptions = [...otherOptions, { ...autoComplitedDataGenerate(item, name) }];
        }
      }

      options = [...otherOptions]
      break;
  }

  if (isOnlyPinfl) {
    options = options.filter((obj) => obj?.value?.pinfl)
  }


  return options;
};

const FlexListComponent = ({ value, ...props }) => {
  // NOTE language contoller
  const { t } = useTranslation(['translation', 'common']);

  const [flexList, pending] = useSelector((state) => {
    return [state.flexList.flexList, state.flexList.pending];
  });

  // NOTE native redux function
  const dispatch = useDispatch();

  // NOTE props data
  const { minInputLengthForFetch, name, styles = {} } = props;
  // NOTE select list
  let options = useMemo(() => onGenerateFlexListOption(flexList, name, props.isOnlyPinfl), [flexList, name]);
  //  NOTE input value
  const [inputValue, setInputValue] = useState('');
  //  NOTE input change handler
  const onFlexInputChangeHandler = (value = '', reason) => {
    let inputValueLength = value.split('').length;
    if (reason.action === 'input-blur' || reason.action === 'menu-close') return;

    setInputValue(value);
    if (inputValueLength < minInputLengthForFetch) return;

    if (minInputLengthForFetch === 1) {
      value = { value, tin: props?.tin };
    }
    dispatch(props.action(value));
  };

  // NOTE render select option

  // NOTE select option height
  const height = 35;
  // NOTE render select option
  const _renderSelectOption = (props) => {
    const { options, children, maxHeight, getValue } = props;

    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List height={maxHeight} rowCount={children.length} rowHeight={20} scrollTop={initialOffset}>
        {({ index, style }) => (
          <div style={style}>
            {index} {children[index]}
          </div>
        )}
      </List>
    );
  };

  // NOTE main render

  const _renderMessage = () => {
    if (pending) return t('common:loading');
    if (!pending && inputValue !== '' && isNaN(parseInt(inputValue))) return <span style={{ color: '#ffae00' }}> {t('searchByTin')}</span>;
    if (!pending && flexList.length === 0) return <span>{t('noData')} </span>;
  };

  return (
    <>
      <StyledSelect
        components={_renderSelectOption}
        isClearable={true}
        value={value || ''}
        isDisabled={props.disabled}
        onChange={props.onChange}
        className="flex-list-select"
        styles={props.children ? { ...customStyles, ...styles } : omit({ ...customStyles, ...styles }, ['dropdownIndicator'])}
        isSearchable
        inputValue={inputValue}
        placeholder={props.placeholder}
        name={props.name || 'inn'}
        options={options}
        onInputChange={(value, reason) => onFlexInputChangeHandler(value, reason)}
        noOptionsMessage={() => <span>{_renderMessage()}</span>}
      />
      {props.children}
    </>
  );
};

export default memo(FlexListComponent);
