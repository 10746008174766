import React, { useEffect, useState, lazy, Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillPlusCircle } from 'react-icons/ai';
import uniqby from 'lodash.uniqby';
import get from 'lodash.get';
import { useDispatch } from 'react-redux';
import useFilter, { SET_INITIAL_SITUATION } from 'Hooks/useFilter';
import sort from 'Utils/sort';
import useFetch, { API_RESET_DATA } from 'Hooks/useFetch';

import Button from 'Components/atoms/button';
import { colors } from 'Style/colors';
import Header from 'Pages/_Parameters_section/_components/header';
import Table from 'Pages/_Parameters_section/Products/table';
import requestState from 'Services/api/status';
import notification, { alertTypes, cAlert } from 'Components/atoms/toast';
import { SCROLL_LIMIT } from '../../../Modules/settings/hooks/common/useVirtualData';
import ApiClientProducts from 'Services/api/controller/clientProducts';
import crudTypes from 'Utils/crudTypes';
import ImagesModal from './imagesModal';
import ApiSettings from 'Services/api/controller/settings';
import { onFetchClientProducts } from './reducer/actions';
import uniqBy from 'lodash.uniqby';
import { selectZoom } from 'Store/selectors/ui';
import { useSelector } from 'react-redux';
import Tooltip from 'Components/atoms/tooltip';
const CreateForm = lazy(() => import('Pages/_Parameters_section/Products/create'));
const EditForm = lazy(() => import('Pages/_Parameters_section/Products/edit'));
const Delete = lazy(() => import('Pages/_Parameters_section/Products/delete'));

export const getRequestBodyForClientProduct = (body) => {
  const formdata = new FormData();
  const price = parseFloat(body.price) || parseFloat(body.price) === 0 ? parseFloat(body.price) : 0;
  const count = parseFloat(body.count) || parseFloat(body.count) === 0 ? parseFloat(body.count) : 0;

  formdata.append('productName', body.productName);
  formdata.append('catalogCode', body.catalogCode.value.classCode);
  formdata.append('catalogName', body.catalogCode.value.className);
  formdata.append('packages', JSON.stringify(body.catalogCode.value.packages));
  body.catalogCode.value.internationalCodeList &&
    body.catalogCode.value.internationalCodeList?.length &&
    formdata.append(
      'internationalCode',
      body.catalogCode.value.internationalCodeList.reduce((all, i) => all + ',' + i)
    );
  formdata.append('price', price);
  formdata.append('count', count);
  formdata.append('origin', body?.origin || '1');

  formdata.append('defaultInternationalCode', body.defaultInternationalCode?.value || '');
  formdata.append('defaultPackage', JSON.stringify(body.defaultPackage.value));

  for (const pair of formdata.entries()) {

  }

  if (body.images?.length) {
    body.images.forEach((obj) => {
      formdata.append('images', obj.file);
    });
  }

  return formdata;
};

const MyProducts = ({ isModalMode, onSelectForTableInput, onMutateClientProductList = () => { } }) => {
  const { t } = useTranslation(['settings', 'common']);
  const dispatch = useDispatch();

  const [modal, setModal] = useState('closed');
  const zoom = useSelector(selectZoom);
  const layoutWidth = useSelector((state) => state.menues.width);
  const isNewUi = useSelector((state) => state.ui.isNewUi)
  const [isFilterColumnOpen, setIsFilterColumnOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [mode, setMode] = useState('scroll');
  const [data, setData] = useState([]);

  const {
    filterData,
    filterDispatch,
    getPositionFromLeft,
    hideFilter,
    onFilterIconHandler,
    width,
    height,
    handleResize,
    filterInitialState
  } = useFilter({
    layoutWidth,
    zoomValue: zoom
  });

  const [productsState, , , getProducts] = useFetch();
  const [addProductState, , addDispatch, addProduct] = useFetch();
  const [updateProductState, , updateDispatch, updateProduct] = useFetch();
  const [deleteProductState, , deleteDispatch, deleteProduct] = useFetch();
  const [synchronizeState, , synchronizeDispatch, synchronize] = useFetch();

  const { totalCount, currentPage } = useMemo(() => {
    return {
      totalCount: get(productsState, 'data.totalCount', 0),
      currentPage: get(productsState, 'data.pageNumber', 1)
    };
  }, [productsState]);

  const getProductsRequest = async (body) => {
    body = { ...body, limit: SCROLL_LIMIT };
    if (body.keyword) {
      await getProducts(ApiClientProducts.searchProducts, body);
    } else {
      await getProducts(ApiClientProducts.getAllProducts, body);
    }
  };

  const onScrollHandler = useCallback(
    async ({ clientHeight, scrollHeight, scrollTop }) => {
      let wholeHeight = parseInt(scrollTop + clientHeight),
        isHave = data?.length < totalCount;
      if ((wholeHeight === scrollHeight + 1 || wholeHeight === scrollHeight - 1 || wholeHeight === scrollHeight) && isHave) {
        if (productsState.status !== requestState.loading) {
          setMode('scroll');
          await getProductsRequest({ page: currentPage + 1 });
        }
      }
    },
    [data, currentPage, totalCount]
  );

  const addProductRequest = useCallback(async (body) => {
    const requestBody = getRequestBodyForClientProduct({ ...body });
    await addProduct(ApiClientProducts.addProduct, requestBody);
  }, []);

  const updateProductRequest = useCallback(
    async (body) => {
      let requestBody = getRequestBodyForClientProduct({ ...body });
      requestBody.id = selectedProduct.id;
      await updateProduct(ApiClientProducts.updateProduct, requestBody);
    },
    [selectedProduct]
  );

  const deleteProductRequest = useCallback(async () => {
    await deleteProduct(ApiClientProducts.deleteProduct, selectedProduct.catalogCode);
  }, [selectedProduct]);

  const onCloseDrawer = useCallback(() => {
    if (updateProductState.status !== requestState.loading && addProductState.status !== requestState.loading) {
      setModal('closed');
    }
  }, []);

  const onCloseImageModal = useCallback(() => {
    setModal('closed');
    setSelectedProduct({});
  }, []);

  const onOpenCreateDrawer = useCallback(() => {
    setModal('create');
  }, []);

  const onOpenEditDrawer = useCallback(
    (index) => {
      let selectedProduct = data[index];
      setSelectedProduct(selectedProduct);
      setSelectedIndex(index);
      setModal('edit');
    },
    [data]
  );

  function generateBody(filterData) {
    return {
      productIds: get(filterData, 'checkedBoxes.catalogCodeAndName', [])
    };
  }

  const refetch = useCallback(
    async (filterData) => {
      const body = generateBody(filterData);
      const response = await ApiClientProducts.getFilteredProducts(body);

      if (!response.fail) {
        setData(() => response.data.data.clientProductsDTOS);
      } else {
        cAlert(alertTypes.error, response.message);
      }
    },
    [filterData]
  );

  const resetFilter = () => {
    filterDispatch({ type: SET_INITIAL_SITUATION });
    refetch(filterInitialState);
  };

  const onOpenDeleteModal = useCallback(
    (index) => {
      setModal('delete');
      const selectedProduct = data[index];
      setSelectedIndex(index);
      setSelectedProduct(selectedProduct);
    },
    [data]
  );

  const onOpenImagesModal = useCallback(
    (index) => {
      setModal('images');
      const selectedProduct = data[index];
      setSelectedProduct(selectedProduct);
    },
    [data]
  );

  const onFilterRequest = useCallback(
    async ({ key, value }) => {
      setMode('filter');
      await getProductsRequest({ page: currentPage, [key]: value });
    },
    [currentPage]
  );

  const synchronizeRequest = useCallback(async () => {
    await synchronize(ApiSettings.synchronize);
  }, []);

  useEffect(() => {
    getProductsRequest({ page: 1 });
  }, []);

  useEffect(() => {
    if (productsState.status === requestState.success) {
      setData((prevData) => {
        let newList = [];
        const _data = Array.isArray(productsState.data) ? productsState.data : productsState.data?.clientProductsDTOS;
        if (mode === 'scroll') {
          newList = uniqby([..._data, ...prevData], 'id');
        } else {
          newList = _data;
        }
        return newList;
      });
    }

    if (productsState.status === requestState.error) {
      cAlert('error', get(productsState, 'error.message'));
    }
  }, [productsState, mode]);

  useEffect(() => {
    if (addProductState.status === requestState.success) {
      onMutateClientProductList(crudTypes.create, addProductState.data);
      notification.setType('success').setMessage(get(addProductState, 'successMessage')).alert();
      setData((prevData) => {
        let newList = uniqBy([addProductState.data, ...prevData], 'id');
        newList.sort((a, b) => sort(a, b, 'catalogCode'));

        return newList;
      });
      onCloseDrawer();
      addDispatch({ type: API_RESET_DATA });
    }

    if (addProductState.status === requestState.error) {
      notification.setType('error').setMessage(get(addProductState, 'error.message')).alert();
      addDispatch({ type: API_RESET_DATA });
    }
  }, [addProductState]);

  useEffect(() => {
    if (updateProductState.status === requestState.success) {
      onMutateClientProductList(crudTypes.update, updateProductState.data);
      notification.setType('success').setMessage(get(updateProductState, 'successMessage')).alert();
      setData((prevData) => {
        let newList = [...prevData];
        newList[selectedIndex] = updateProductState.data;
        newList.sort((a, b) => sort(a, b, 'catalogCode'));
        return newList;
      });

      onCloseDrawer();
      updateDispatch({ type: API_RESET_DATA });
    }

    if (updateProductState.status === requestState.error) {
      notification.setType('error').setMessage(get(updateProductState, 'error.message')).alert();
      updateDispatch({ type: API_RESET_DATA });
    }
  }, [updateProductState, selectedIndex, selectedProduct]);

  useEffect(() => {
    if (deleteProductState.status === requestState.success) {
      notification.setType('success').setMessage(t('common:deleteSuccess')).alert();
      setData((prevData) => {
        let newList = [...prevData];
        const deletedProduct = newList.splice(selectedIndex, 1);
        onMutateClientProductList(crudTypes.delete, deletedProduct?.[0]?.catalogCode);
        return newList;
      });
      onCloseDrawer();
      deleteDispatch({ type: API_RESET_DATA });
    }

    if (deleteProductState.status === requestState.error) {
      notification.setType('error').setMessage(get(deleteProductState, 'error.message')).alert();
      deleteDispatch({ type: API_RESET_DATA });
    }
  }, [selectedIndex, deleteProductState]);

  useEffect(() => {
    if (synchronizeState.status === requestState.success) {
      cAlert('success', synchronizeState.successMessage);
      synchronizeDispatch({ type: API_RESET_DATA });
      getProductsRequest({ page: 1 });
      dispatch(onFetchClientProducts());
    }
    if (synchronizeState.status === requestState.error) {
      cAlert('error', synchronizeState.error?.message);
      synchronizeDispatch({ type: API_RESET_DATA });
    }
  }, [synchronizeState]);

  return (
    <div>
      {modal === 'create' && (
        <Suspense fallback={<div />}>
          <CreateForm
            status={addProductState.status}
            onClose={onCloseDrawer}
            title={t('settings:my_products.addProduct')}
            submitText={t('submit')}
            onSubmit={addProductRequest}
          />
        </Suspense>
      )}
      {modal === 'edit' && (
        <Suspense fallback={<div />}>
          <EditForm
            status={updateProductState.status}
            onClose={onCloseDrawer}
            title={t('settings:my_products.editProduct')}
            submitText={t('edit')}
            onSubmit={updateProductRequest}
            selectedProduct={selectedProduct}
          />
        </Suspense>
      )}
      {modal === 'delete' && (
        <Suspense fallback={<div />}>
          <Delete product={selectedProduct} onClose={onCloseDrawer} onDelete={deleteProductRequest} status={deleteProductState.status} />
        </Suspense>
      )}
      {modal === 'images' && (
        <Suspense fallback={<div />}>
          <ImagesModal selectedProduct={selectedProduct} onClose={onCloseImageModal} />
        </Suspense>
      )}
      <Header onFilterRequest={onFilterRequest} title={t('settings:my_products.title')} isReset={true} resetFilter={resetFilter}>
        <Tooltip isNewUi={isNewUi} title={t('translation:tasnifTip')} placement={'bottomRight'}>
          <Button className="ml-1" >
            <a href='https://tasnif.soliq.uz/' target='_blank'>
              {t('translation:tasnif')}
            </a>
          </Button>
        </Tooltip>
        <Button onClick={onOpenCreateDrawer} icon={<AiFillPlusCircle />} style={{ marginLeft: 10 }}>
          {t('create')}
        </Button>
        <Button
          onClick={synchronizeRequest}
          loading={synchronizeState.status === requestState.loading}
          style={{ marginLeft: 10 }}>
          {t('synchronize')}
        </Button>
      </Header>
      <Table
        onScrollHandler={onScrollHandler}
        data={data}
        onFilterIconHandler={onFilterIconHandler}
        filterData={filterData}
        filterDispatch={filterDispatch}
        getPositionFromLeft={getPositionFromLeft}
        hideFilter={hideFilter}
        width={width}
        height={height}
        refetch={refetch}
        setData={setData}
        status={productsState.status}
        onOpenEditDrawer={onOpenEditDrawer}
        onOpenDeleteModal={onOpenDeleteModal}
        onOpenImagesModal={onOpenImagesModal}
        isModalMode={isModalMode}
        onSelectForTableInput={onSelectForTableInput}
        totalCount={totalCount}
        handleResize={handleResize}
        isFilterColumnOpen={isFilterColumnOpen}
      />
    </div>
  );
};

export default MyProducts;
