
export function getShortenedMchj(originalText = '') {
  let short = originalText;
  if (typeof short !== 'string') return short;
  const PossibleShorts = {
    1: 'MCHJ',
    2: 'МЧЖ',
    3: 'ООО',
    // --- --- ---
    4: 'XT',
    5: 'ХТ',
    6: 'ЧП',
    // --- --- ---
    7: 'YaTT',
    8: 'ЯТТ',
    9: 'ИП',
    // --- --- ---
    10: 'OK',
    11: 'ЧП'
  }

  const PossibleTexts = {
    1: [
      "MAS`ULIYATI CHEKLANGAN JAMIYATI",
      "MAS'ULIYATI CHEKLANGAN JAMIYATI",
      "MAS‘ULIYATI CHEKLANGAN JAMIYATI",

      "MAS`ULIYATI CHEKLANGAN JAMIYAT",
      "MAS'ULIYATI CHEKLANGAN JAMIYAT",
      "MAS‘ULIYATI CHEKLANGAN JAMIYAT",

      'MASULIYATI CHEKLANGAN JAMIYATI',
      'MASULIYATI CHEKLANGAN JAMIYAT',
    ],
    2: [
      'МАЪСУЛИЯТИ ЧЕКЛАНГАН ЖАМИЯТИ',
      'МАСЪУЛИЯТИ ЧЕКЛАНГАН ЖАМИЯТИ',
      'МАСУЛИЯТИ ЧЕКЛАНГАН ЖАМИЯТИ',
      'МАЬСУЛИЯТИ ЧЕКЛАНГАН ЖАМИЯТ',
      'МАСЪУЛИЯТИ ЧЕКЛАНГАН ЖАМИЯТ',
      'МАСУЛИЯТИ ЧЕКЛАНГАН ЖАМИЯТ',
    ],
    3: [
      'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ'
    ],
    // --- --- --- --- --- --- --- --- --- ---
    4: [
      'XUSUSIY KORXONASI',
      'XUSUSIY KORXONA',
    ],
    5: [
      'ХУСУСИЙ КОРХОНАСИ',
      'ХУСУСИЙ КОРХОНА',
    ],
    6: [
      "ЧАСТНОЕ ПРЕДПРИЯТИЕ",
      "ЧАСТНАЯ ФИРМА",
    ],
    // --- --- --- --- --- --- --- --- --- ---
    7: [
      'YAKKA TURDAGI TADBIRKOR',
    ],
    8: [
      'ЯККА ТАРТИБДАГИ ТАДБИРКОР',
    ],
    9: [
      'ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ', // YaTT (рус = ИП)
      'ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛ',
    ],
    // --- --- --- --- --- --- --- --- --- ---
    10: [
      'KORXONA'
    ],
    11: [
      'КОРХОНА'
    ]
  }

  short = Object.entries(PossibleTexts).reduce((shortened = '', [index, textsList]) => {
    
    return textsList.reduce((shortened = '', longText) => {
      if(shortened.toUpperCase().includes(longText)) {
        const idx = shortened.toUpperCase().indexOf(longText);
        const replacedOrgTextPart = shortened.slice(idx, idx + longText.length);
        return shortened.replace(replacedOrgTextPart, PossibleShorts[index]);
      };
      
      return shortened;
    }, shortened);

  }, originalText);

  return short;
}