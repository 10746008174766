import client from 'Services/api/client';
import ApiClientProducts from './clientProducts';
import { addOneProductToList } from 'Pages/_Parameters_section/Products/reducer/actions';

const ApiSettings = {
  async searchClassifications(value) {
    return await client('GET', `/classifications/search?keyword=${value}`);
  },
  async addClassifications(value) {
    const response = await client('POST', `/classifications/add?classCode=${value}`);
    const fullClassCodeData = response.data?.data;

    if (!response.fail) await createAlsoProduct(fullClassCodeData);

    return response;
  },
  async deleteClassification(value) {
    return await client('POST', `/classifications/delete?classCode=${value}`);
  },
  async synchronizeClassifications() {
    return await client('POST', `/classifications/synchronize`);
  },
  async synchronize() {
    return await client('POST', '/classifications/synchronize');
  },
  async getClassifications() {
    // TODO - clientProduct yaxshi ishlab ketsa buni chopamiz
    // await ApiSettings.synchronize();
    const res = await ApiClientProducts.getAllProducts({ page: 1, limit: 99999 });
    if (!res.fail) {
      res.data.data = getClassificationFromProductList(res.data.data.clientProductsDTOS);
    } else {
      res.data.data = [];
    }
    return res;
    // return await client(undefined, '/classifications');
  },

  async getPrivileges() {
    return await client(undefined, '/privileges');
  },
  async getTaxPrivileges() {
    return await client(undefined, '/employee/permit/get/list');
  },
  async updateAndCreateRoute(body) {
    return await client('POST', `/routes/documentType/${body.documentType}/${body.routeType}`, body.body);
  },
  async offeredDocumentNumber(docType) {
    return await client(undefined, `info/current/doc/num/${docType}`);
  },
  async verificationContact(contact) {
    return await client('POST', `/user/send-verification-code?emailOrPhone=${contact}`);
  },
  async sendDataForLinkingAccounts(body) {
    return await client('POST', '/user/connect-email-or-phone', body);
  }
};

export default ApiSettings;

// ----------------------------------- TODO HOZIRCHA turaversin keyin alohida faylga chiqazaman  -----------------------------

// NOTE - Client productsdan classcode ga o'girib olish, buni backenddan shunaqa nomda kelishini talab qilish kerak
function getClassificationFromProductList(data = []) {
  return data.map(getOneClassificationFromProduct);
}

function getOneClassificationFromProduct(product = {}, index, list = []) {
  return {
    id: product.id,
    classCode: product.catalogCode,
    className: product.catalogName,
    packageNames: product.packagesList,
    internationalCode: product.defaultInternationalCode,
    internationalCodeList: product.internationalCodeList,
    defaultPackageDTO: product.defaultPackageDTO
    // groupCode - not exist in product object
  };
}

// NOTE - exceldan product qo'shsa tovarni yaratib ketish;
async function createAlsoProduct(data) {
  const body = new FormData();
  body.append('catalogCode', data.classCode);
  body.append('catalogName', data.className);
  body.append('packages', JSON.stringify(data.packages));
  body.append('defaultPackage', JSON.stringify(data.packages[0]));

  const internationalCode = getDefaultIntCode(data.internationalCode);
  body.append('internationalCode', data.internationalCode);
  internationalCode && body.append('defaultInternationalCode', internationalCode);

  const res = await ApiClientProducts.addProduct(body);
  // NOTE - reduxga saqlab ketish
  !res.fail && addOneProductToList(res.data.data);
}

function getDefaultIntCode(internationalCode) {
  let internationalCodeList = [];
  try {
    internationalCodeList = internationalCode.split(',').map((i) => (i + '')?.trim());
  } catch (err) {
    internationalCodeList = internationalCode ? [internationalCode?.trim()] : [];
  }
  return internationalCodeList[0];
}
