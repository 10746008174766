import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { MyFallbackComponent } from 'Components/atoms/errorBoundry/ErrorBoundry';
import * as _ from '../../container/auth.style';
import ApiLogin from 'Services/api/controller/login';
import { useForm } from 'react-hook-form';
import notification from 'Components/atoms/toast';

import './style.css';
import ApprovePage from './approvePage';

const Restore = ({ onFinishFailed, handleCondition }) => {
  const [receiveSMS, setReceiveSMS] = useState(false);
  const [loadingSMS, setLoadingSMS] = useState(false);
  const [numberDisabled, setNumberDisabled] = useState(false);
  const [isEmail, setIsEmail] = useState(false); // Note!! isEmail - user dastlab email kiritganmi yoki telefon raqamligini tekshirish uchun. Agar email bo'lsa true, aks holda false qaytaradi
  const [valuePhoneOrEmail, setValuePhoneOrEmail] = useState(''); // Note! valuePhoneOrEmail - user dastlab email yoki telefon raqamni kiritgan bo'lsa usha qiymatni olib inputni disable qilish uchun olingan

  const { handleSubmit, register, errors } = useForm();

  const getSMS = handleSubmit(async (values) => {
    //Note! bu funksiya SMS olish uchun ishlatiladi
    const phoneRegex = /^\+?998\d{9}$/;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (emailRegex.test(values.tel)) {
      setIsEmail(true);
      setReceiveSMS(true);
      setNumberDisabled(true);
      setLoadingSMS(true);
    } else if (phoneRegex.test(values.tel)) {
      setIsEmail(false);
      setNumberDisabled(true);
      setReceiveSMS(true);
      setLoadingSMS(true);
    } else {
      notification.setType('error').setMessage('Неправильно введен номер телефона или электронная почта').alert();
      setReceiveSMS(false);
      setNumberDisabled(false);
      setLoadingSMS(false);
    }

    await ApiLogin.getSMS(values.tel);

    setValuePhoneOrEmail(values.tel);
  });

  const getSMSagain = handleSubmit(async () => {
    //Note! bu funksiya qayta SMS olish uchun ishlatiladi

    await ApiLogin.getSMS(valuePhoneOrEmail, true);
  });

  const onSubmit = handleSubmit(async (values) => {
    //Note! bu funksiya registratsiya jarayonini yakunlash uchun kerak
    const body = {};
    body.emailOrPhone = valuePhoneOrEmail;
    body.code = values.code;
    body.repeatPassword = values.repeatPassword;
    body.newPassword = values.newPassword;
    const res = await ApiLogin.changeUser(body);
    if (!res.fail) {
      notification.setType('success').setMessage('Пароль успешно изменён!').alert();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      notification.setType('error').setMessage(res.message).alert();
    }
  });

  return (
    <>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        {/*<_.Text md color={'#1890ff'} style={{ marginBottom: '1rem' }}>*/}
        {/*  Восстановление Пароля*/}
        {/*</_.Text>*/}
        <_.StyledForm
          id="registr__page"
          initialValues={{ remember: true }}
          onFinish={handleSubmit(onSubmit)}
          onFinishFailed={onFinishFailed}>
          <_.Text esmw color={'#000017'}>
            {!receiveSMS && 'Номер телефона/Электронная почта'}
            {/*{receiveSMS && isEmail ? 'Электронная почта' : receiveSMS && !isEmail ? 'Номер телефона' : ''}*/}
          </_.Text>
          <_.FormItem>
            <_.StyledInput
              disabled={numberDisabled}
              name={`${isEmail ? 'email' : 'tel'}`}
              ref={register}
              placeholder={'Введите Номер телефона или Электронную почту'}
              style={{ marginTop: '10px' }}
            />
          </_.FormItem>
          {receiveSMS && <ApprovePage errors={errors} register={register} getSMSagain={getSMSagain} isEmail={isEmail} />}

          {!receiveSMS && (
            <_.FormItem>
              <_.StyledButton ref={register} htmlType="submit" onClick={getSMS} disabled={loadingSMS}>
                {'Далее'}
              </_.StyledButton>
            </_.FormItem>
          )}
          <_.RightBottom>
            {/*<_.Text sm color={'#000017'}>*/}
            {/*  Уже есть профиль?*/}
            {/*</_.Text>*/}
            <_.Text sm color={'#1F87E5'}>
              <a onClick={() => handleCondition(2)}>Войти</a>
            </_.Text>
          </_.RightBottom>
        </_.StyledForm>
      </ErrorBoundary>
    </>
  );
};

export default Restore;
