import { createSelector } from 'reselect';
import clonedeep from 'lodash.clonedeep';
import * as con from 'Store/constant';
import { onReplaceDashToDot } from 'Services/time/formatTime';

export const DOCUMENT_LIST_REDUCER_ACTIONS = {
  REPLACE_DOCUMENT: 'REPLACE_DOCUMENT'
};

const initialState = {
  pending: false,
  fail: false,
  documentList: [],
  remove: {
    loading: null,
    error: [],
    success: [],
    message: '',
    mode: 'one'
  },
  count: 0,
  types: [],
  part: 2,
  params: {},
  docId: null,
  status: 'delete_initial',
  limit: 50,
  filterDataFromDashboard: null
};

const documentList = (state = initialState, { type, payload }) => {
  switch (type) {
    case con.GET_OUTGOING_DATA_LIST:
      return {
        ...state,
        fail: false,
        pending: true,
        status: 'delete_initial'
      };
    case con.GET_OUTGOING_DATA_LIST_SUCCESS:
      let wholeData = payload.scroll ? [...state.documentList, ...payload.data] : payload.data;

      return {
        ...initialState,
        part: state.part,
        limit: state.limit,
        documentList: wholeData,
        count: payload.count,
        types: payload.documentTypes,
        params: payload.params || state.params,
        extractedData: state.extractedData,
        docId: state.docId
      };
    case con.GET_OUTGOING_DATA_LIST_FAIL:
      return {
        ...state,
        fail: true,
        pending: false,
        isSearh: false
      };
    case con.DELETE_OUTGOING_DOCUMENT_SUCCESS:
      return {
        ...state,
        remove: {
          ...state.remove,
          loading: null,
          message: payload.message,
          ...(
            state.remove.mode === 'multiple' ? {
              success: [...state.remove.success, payload]
            } : {}
          )
        },
        types: payload.types,
        isSearch: false,
        status: 'delete_success'
      };
    case con.DELETE_OUTGOING_DOCUMENT:
      return {
        ...state,
        remove: {
          ...state.remove,
          loading: payload.id,
          mode: payload?.mode || 'one'
        },
        status: 'delete_loading'
      };
    case con.DELETE_OUTGOING_DOCUMENT_FAIL:
      return {
        ...state,
        remove: {
          ...state.remove,
          loading: null,
          message: payload.message,
          ...(
            state.remove.mode === 'multiple' ? {
              error: [...state.remove.error, payload]
            } : {}
          )
        },
        status: 'delete_error'
      };
    case 'CLEAR_DELETE':
      return {
        ...state,
        status: 'delete_initial'
      };
    case con.SET_INITIAL_PART:
      return {
        ...state,
        part: 2
      };
    case con.SET_CURRENT_DOCUMENT:
      return {
        ...state,
        docId: payload
      };
    case con.CHANGE_LIMIT_OF_FETCH_DATA_COUNT:
      return { ...state, limit: payload };
    case 'CLEAR':
      return { ...initialState, limit: state.limit, part:state.part };
    case con.CANCEL_ACTION:
      return {
        ...state,
        remove: {
          ...initialState.remove
        }
      };
    case 'CHANGE_PART':
      return {
        ...state,
        part: payload
      };
    case con.CHANGE_DELETE_MODE:
      return {
        ...state,
        mode: 'one'
      };
    case DOCUMENT_LIST_REDUCER_ACTIONS.REPLACE_DOCUMENT:
      const { updatedDocument } = payload;
      let newList = clonedeep(state.documentList);

      let index = newList.findIndex((_doc) => _doc.id === updatedDocument.id);

      newList[index] = updatedDocument;

      return {
        ...state,
        documentList: newList
      };
    case con.SET_FILTER_DATA_FROM_DASHBOARD: {
      return {
        ...state,
        filterDataFromDashboard: payload
      }
    }
    default:
      return state;
  }
};

// NOTE select buyer tin

export const selectDocumentReducer = (state) => state.outDocuments;

export const selectPart = createSelector(selectDocumentReducer, (reducer) => {
  return reducer.part;
});

export const fieldContract = (item) => {
  if (item.contractNumber && item.contractDate) {
    return `${item.contractNumber} от ${onReplaceDashToDot(item.contractDate)}`;
  }
  return `${item.contractNumber || ''}  ${onReplaceDashToDot(item.contractDate) || ''}`;
};

export const selectDocumentList = createSelector(selectDocumentReducer, (reducer) => {
  let { isSearch, documentList, filteredList, part } = reducer;

  let renderedList = [];
  for (let item of documentList) {
    let tin = `${item.tin !== 'null' ? item.tin : ''} - ${item.name !== 'null' ? item.name : ''}`;
    let tin2 = `${item.tin2 !== 'null' ? item.tin2 : ''} - ${item.name2 !== 'null' ? item.name2 : ''}`;
    tin2 = '_' + tin2;
    let branchName = item.branchName + (item.employeeName ? `(${item.employeeName})` : '');

    renderedList = [
      ...renderedList,
      {
        id: item.id,
        tin: `${tin} ${part !== 2 ? tin2 : ''}`,
        state: item.state,
        type: item.type,
        typeName: item.typeName,
        dateAndNumber: item.number + ' от ' + onReplaceDashToDot(item.date),
        date:item.date,
        number:item.number,
        contract: fieldContract(item),
        branchName,
        filialNum: item.filialNum,
        ageInDays: item.ageInDays,
        sum: item.sum,
        constructionObject: item.constructionObject,
        constructionObjectId: item.constructionObjectId,
        financierVerified:item.financierVerified,
        blocked:item.blocked,
        blockReason:item.blockReason
      }
    ];
  }

  return renderedList;
});

export const selectNumberOfDocumentonSpesificState = createSelector(
  selectDocumentList,
  (_, docProperty) => docProperty,
  (list, docProperty) => {}
);

export const selectLimit = createSelector(selectDocumentReducer, (reducer) => reducer.limit);

export default documentList;
