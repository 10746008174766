import React, { Suspense, useState } from "react";
import ExcelPublicUpload from "Components/molecules/forms/components/excelPublicUpload";
import * as _ from './style'

const FileUpload = () => {

    const [isLoading, setIsLoading] = useState(false)

    const onHandleExcelFile = () => {

    }

    const onSaveExcelFile = () => {

    }

    return (
        <_.Wrapper>
            <_.StyledContainer>
                {
                    <Suspense fallback={<div />}>
                        <ExcelPublicUpload change={onHandleExcelFile} onSave={onSaveExcelFile} isLoading={isLoading} />
                    </Suspense>
                }
            </_.StyledContainer>
        </_.Wrapper>
    )
}

export default FileUpload;
