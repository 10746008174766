import React, { useEffect, useState } from 'react';
import * as _ from '../style';
import { ProfessionalIcon, TrueIcon, FalseIcon } from '../icons';
import { useTranslation } from 'react-i18next';
import { DiscountBadge } from "../style";
import ChoosingTariffsDiscount from "../component/choosingTariffsDiscount/choosingTariffsDiscount";

const Professional = ({ setSelectedPlan, showSelectModal, isActive, isLanding, setIsAnnualPlan, setIsSemiAnnualPlan }) => {
  const { t } = useTranslation(['login']);
  const [discountNum, setDiscountNum] = useState(0);

  useEffect(() => {
    setIsSemiAnnualPlan(false);
    setIsAnnualPlan(false);
    if (discountNum === 10) {
      setIsSemiAnnualPlan(true);
    } else if (discountNum === 20) {
      setIsAnnualPlan(true);
    } else {
      setIsSemiAnnualPlan(false);
      setIsAnnualPlan(false);
    }
  }, [discountNum]);

  return (
    <>
      <_.Card isActive={isActive} isLanding={isLanding}>
        <_.BadgeWrapper>
          <_.Badge>POPULAR</_.Badge>
        </_.BadgeWrapper>
        <_.CardInline>
          <_.CardHeader>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <_.CardHeaderH2>Business</_.CardHeaderH2>
              {discountNum !== 0 && (<DiscountBadge discountNum={discountNum}>Скидка {discountNum}%</DiscountBadge>)}
            </div>
            <_.CardHeaderH1>{discountNum === 10 ? '45 000' : discountNum === 20 ? '40 000' : '50 000'}</_.CardHeaderH1>
            <_.CardHeaderH5>сум / месяц</_.CardHeaderH5>
          </_.CardHeader>
          <ChoosingTariffsDiscount discountNum={discountNum} setDiscountNum={setDiscountNum} name="Business" />
          <_.CardBottom>
            <_.CardBottomButton
              isLanding={isLanding}
              onClick={() => {
                setSelectedPlan(2);
                showSelectModal();
              }}>
              Выбрать
            </_.CardBottomButton>
          </_.CardBottom>
        </_.CardInline>
      </_.Card>
    </>
  );
};

export default Professional;
