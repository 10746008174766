import styled from 'styled-components';
import { Form, Select } from 'antd';

const { Option } = Select;

export const FormItem = styled(Form.Item)`
    //width: 430px;
    margin: 20px auto 20px;
   
    @media (max-width: 1300px) {
      margin: 10px auto;
      width: 350px;
    }
`;

export const StyledSelect = styled(Select)`
    

    & .ant-select-selector {
        height: auto  !important;
        background: #F2F3F4 !important;
        border-radius: 8px !important;
        margin: 0px !important;
        padding: 10px 16px !important;
        max-height: 60px;
        font-size: 16px !important;
        line-height: 20px !important;
    }

    & .ant-select-selector .ant-select-selection-search {
        display:flex;
        align-items:center;
    }

    & .ant-select-selection-placeholder {
      font-size:12px;
    }
   .date {
     display: none;
   }
`;

export const StyledOption = styled(Option)`
`;

export const WrapperDiv = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;

    & > div {
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        justify-content:space-between;
    }
`;



export const Text = styled.p`
    color: #000017;
    text-align:center;
    margin:0px;
    font-style: normal;
    
    ${props => props.sm &&
    'font-weight: 500; font-size: 16px; line-height: 20px; letter-spacing: 0.15px;'
  };

    ${props => props.esm &&
    'font-weight: 400; font-size: 10px; line-height: 16px; letter-spacing: 0.4px;'
  };

  ${props => props.radio &&
    'display: flex;  align-items: center; gap: 8px;'
  };

  ${props => props.color &&
    `color: ${props.color};`
  };

    ${props => props.withBorder &&
    'border: 1px solid #00000010; border-radius: 4px; padding: 4px 8px; gap: 10px;'
  };

`;