import React, { memo } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { onFetchMenuItems } from 'Components/molecules/menu/action';
import i18n from 'i18n';
import browserStorage from 'Services/browserStorage';
/* ---------------------------------- style --------------------------------- */
import { LangContainer, SelectLanguageContainer, OptionCustomStyle } from './lang.selector.style';
import { images } from './img.route';
// import { ArrowSvg, LanguageSvg } from 'Assets/svg';

const { Option } = Select;



const LanguageSelector = (props) => {
  const dispatch = useDispatch();

  const activeSideBar = useSelector((state) => state.menues.activeSideBar);

  const changeLanguage = (value) => {
    browserStorage.set('lang', value);

    dispatch(onFetchMenuItems(activeSideBar - 1));

    i18n.changeLanguage(value);
  };

  return (
    <LangContainer>
      {/*<LanguageSvg />*/}

      <SelectLanguageContainer
        // suffixIcon={<ArrowSvg />}
        bordered={false}
        className={'lang-hint'}
        defaultValue={browserStorage.get('lang') || 'ru'}
        onChange={changeLanguage}
        style={{ width: '100px' }}
      >
        {images.map((item, key) => {
          const { text, value } = item;
          return (
            <Option
              value={value}
              key={key}
              style={OptionCustomStyle}>
              {text}
            </Option>
          );
        })}
      </SelectLanguageContainer>
    </LangContainer >
  );
};

export default memo(LanguageSelector);
