import React from 'react';
import logo from "Assets/images/monetary_logo.svg";
import illustration from "Assets/images//monetary_chart.svg";
import {SecondLeftSideWrapper} from "../../container/auth.style";
import Button from 'Components/atoms/button';
import * as _ from "../../container/auth.style";
import {HeadPhoneSvg} from "Assets/svg";
import image from "Assets/images/WhiteAndRedPoster.jpg";

const SecondLeftSide = () => {
  return (
    <SecondLeftSideWrapper>
      {/* <img src={image} alt="advertisement" /> */}
      <div className='block'>
       <span className='col-1'>
         <img src={logo} alt="monetary logo"/>
         <h1>Следите за всеми своими банковскими счетами</h1>
         <p>С нами вы сможете контролировать все банковские счета из одной точки и сэкономите свое драгоценное время.</p>
         <Button onClick={() => {window.open(`https://monetary.uz/`);}}>Перейти на сайт</Button>
       </span>
       <span className='col-2'>
         <img src={illustration} alt="monetary illustration" className="illustration"/>
       </span>
      </div>
      <_.SecondLeftBottom>
       <div className="compName">
         <p>© ООО «THE MIND»</p>
       </div>
       <div className="callCenter">
       <HeadPhoneSvg/>
         <div>
         <p>Техническая поддержка</p>
         <p>+998 95 194 24 24</p>
         </div>
       </div>
      </_.SecondLeftBottom>
    </SecondLeftSideWrapper>
  );
};

export default SecondLeftSide;