import React from 'react';
import ReportIcon from 'Assets/icons/sidebar-icons/report';
import SideBarIcon from 'Assets/icons/sidebar-icons';
import * as url from 'Services/api/endPoints';
import { colors } from 'Style/colors';

export let parentLinks = [
  // {
  //   id: 11,
  //   text: '',
  //   children: <SideBarIcon name="home" />,
  //   link: '/dashboard/home',
  //   isSubSideBar: false,
  //   isHide: true,
  //   requestUrl: ''
  // },
  {
    id: 1,
    text: '',
    children: <SideBarIcon name="create" />,
    link: '/dashboard/create',
    isSubSideBar: true,
    isHide: false,
    requestUrl: url.DOCUMENT_MENU_ITEMS
  },
  {
    id: 2,
    text: '',
    children: <SideBarIcon name="incoming" />,
    link: '/dashboard/inbox/1',
    isSubSideBar: false,
    isHide: true,
    requestUrl: `${url.BOX_MENU_ITEMS}1`
  },
  {
    id: 3,
    text: '',
    children: <SideBarIcon name="outgoing" />,
    link: '/dashboard/outbox/2',
    isSubSideBar: false,
    isHide: true,
    requestUrl: `${url.BOX_MENU_ITEMS}2`
  },
  // {
  //   id: 4,
  //   text: '',
  //   children: <img style={{ width: 29 }} src={DocMg} alt="" />,
  //   link: '/dashboard/sidebar',
  //   isSubSideBar: false,
  //   isHide: false,
  //   requestUrl: ''
  // },
  {
    id: 5,
    text: '',
    children: <ReportIcon />,
    link: '/dashboard/reports',
    isSubSideBar: true,
    isHide: false,
    requestUrl: url.REPORTS_MENU_ITEMS
  },
  {
    id: 6,
    text: '',
    children: <SideBarIcon className={'img'} alt="code" name={'discover'} />,
    link: '/dashboard/di',
    isSubSideBar: false,
    isHide: true
  },
  {
    id: 7,
    text: '',
    children: <SideBarIcon className={'img'} alt="settings" name={'settings'} width="35" height="35" viewBox={'0 0 35 35'} fill={colors.sideBarIconColor} />,
    link: '/dashboard/user/settings',
    isSubSideBar: false,
    isHide: false,
    requestUrl: ''
  },
  {
    id: 8,
    text: '',
    children: <SideBarIcon className={'img'} alt="settings" name={'helpful'} width="35" height="35" viewBox={'0 0 35 35'} fill={colors.sideBarIconColor} />,
    link: '/dashboard/helpful-information',
    isSubSideBar: false,
    isHide: false
  },
  {
    id: 10,
    text: '',
    children: <SideBarIcon name="agreements" />,
    link: '/dashboard/agreements',
    isSubSideBar: true,
    isHide: false,
    requestUrl: ''
  },
  {
    id: 9,
    text: '',
    children: <SideBarIcon name={'finance'} />,
    link: '/dashboard/finance',
    isSubSideBar: true,
    isHide: false
  },
  // {
  //   id: 11,
  //   text: '',
  //   children: <SideBarIcon name="parameters" />,
  //   link: '/dashboard/parameters',
  //   isSubSideBar: false,
  //   isHide: false,
  //   requestUrl: ''
  // },
  // {
  //   id: 12,
  //   text: '',
  //   children: <SideBarIcon name="admin" />,
  //   link: '/dashboard/admin',
  //   isSubSideBar: false,
  //   isHide: false,
  //   requestUrl: ''
  // },
  // {
  //   id: 13,
  //   text: '',
  //   children: <SideBarIcon name="forum" />,
  //   link: '/dashboard/forum',
  //   isSubSideBar: false,
  //   isHide: false,
  //   requestUrl: ''
  // },
];

export const keysLanguage = [
  // 'home',
  'createDocument',
  'inComing',
  'outgoing',
  'reports',
  'di',
  'settings',
  'catalog',
  'agreements',
  'finance',
  // 'parameters',
  // 'admin',
  // 'forum'
];
