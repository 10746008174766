import React from "react";
import * as _ from './style'

const MyInput = ({ ...props }) => {

  return (
    <_.StyledInput {...props}>

    </_.StyledInput>
  )
}

export default MyInput