// ANCHOR npm packages
import React from 'react';
import { useSelector } from 'react-redux';
import { isTaxHandler } from 'Utils/docTypes';
import SoliqIcona from 'Assets/images/soliq_logo.svg';
// ANCHOR style
import { SoliqIcon } from './style';
import { Link } from 'react-router-dom';
const InOutBoxTitle = (props) => {
  const { text, type, isReport } = props;
  const textLength = text?.length;

  const activeIndex = useSelector((state) => state.menues.activeSideBar);

  return (
    <>
      {textLength >= 24 ? (
        <div>
          <p style={{ margin: '0px', padding: '0px', lineHeight: '18px' }}> {text?.slice(0, 24)} </p>
          <p style={{ margin: '0px', padding: '0px', lineHeight: '18px' }}> {text?.slice(24)} </p>
        </div>
      ) : props.path ? (
        <Link style={{ lineHeight: '18px' }} to={props.path}>
          {text}
        </Link>
      ) : (type === 10 ?
        <span>{text}</span> :
        <span style={{ lineHeight: '18px' }}>{text}</span>
      )}
      {isTaxHandler(type) && activeIndex !== 4 ? (
        <SoliqIcon style={{ marginLeft: isReport ? 'auto' : '0px' }}>
          <img src={SoliqIcona} alt="Uzb soliq logo for bdm" />
        </SoliqIcon>
      ) : (
        ''
      )}
    </>
  );
};

export default InOutBoxTitle;
