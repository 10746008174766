import styled from 'styled-components';
import { Select } from 'antd';
import { colors } from 'Style/colors';

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LangContainer = styled(FlexBox)`
    .ant-select-selector{
      color: white;
    }
`;

export const SelectLanguageContainer = styled(Select)`
  color: #ffffff!important;
  text-transform: uppercase;

  & > .ant-select-selector {
    border: none;
    /* Adapt the colors based on primary prop */
    background: transparent!important;
    color: white;

    text-transform: uppercase;
  }

  & > .ant-select-arrow {
    border: none !important;
    color: white;
  }

  & > .ant-select-selection-search-input {
    border: none !important;
    color: white !important;
    box-shadow: none !important;
  }
`;
