export const SEND_TO_RECEIVER=10;
export const SEND_TO_AGENT=12;
export const AGENT_ACCEPTED=31;
export const RECEIVER_ACCEPTED=30;
export const REJECTED=60;
export const CANCELED=71;
export const SAVED=11;
export const TOTALY_REMOVED=70;
export const SENDING=5;
export const WAITING_NOT_APPROVED=13;
export const NOT_APPROVED=14;