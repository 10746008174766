export let defaultItem = {
    id: 1,
    name: '',
    typeName: '',
    number: '',
    date: '',
    sum: '',
    isFake: true
  };
  
  // index 0 dan boshlanadi NOTE
  
  export let doubleSideTableColumns = [
    {
      label: 'Checkbox',
      width: 0.05,
      dataKey: 'checkbox'
    },
    {
      label: 'status',
      width: 0.06,
      dataKey: 'state'
    },
    {
      label: 'senderName',
      width: 0.19,
      dataKey: 'tin'
    },
    {
      label: 'documentType',
      width: 0.15,
      dataKey: 'typeName'
    },
    {
      label: 'document',
      width: 0.1,
      dataKey: 'dateAndNumber'
    },
    {
      label: 'contract',
      width: 0.15,
      dataKey: 'contract'
    },
    {
      label: 'branchName',
      width: 0.15,
      dataKey: 'branchName'
    },
    // {
    //   label: 'constructionObject',
    //   width: 0.1,
    //   dataKey: 'constructionObject'
    // },
    {
      label: 'ageInDays',
      width: 0.05,
      dataKey: 'ageInDays'
    },
    {
      label: 'sum',
      width: 0.1,
      dataKey: 'sum'
    }
  ];
  
  export const docTypes = [];
  
  export const propertiesOfGetFilterListFromBackend = ['state', 'tin', 'tin2', 'typeName', 'constructionObject', 'name','mainSignerName','registererName'];
  