import requestState from 'Services/api/status';
import * as con from 'Store/constant';
import crudTypes from 'Utils/crudTypes';
import uniqBy from 'lodash.uniqby';

const initialDrawerState = {
  visible: false,
  status: 'initial',
  fail: false,
  message: '',
  data: {},
  editProduct: null,
  lastCreatedProduct: null,
  uniqueTrId: null
}

const initialClientsModalState = {
  visible: false,
  lastSelectedProduct: null,
  uniqueTrId: null
};

const initialState = {
  data: [],
  fail: false,
  message: '',
  drawer: initialDrawerState,
  tableModal: initialClientsModalState
}


export default function clientProductsReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    // FETCH ON OPEN CREATE PAGE
    case con.SET_CLIENT_PRODUCTS: {
      return { ...state, fail: false, data: payload };
    }
    case con.FAIL_CLIENT_PRODUCTS: {
      return { ...state, fail: true, message: payload };
    }
    // NOTE - class code qo'shish apisi ishlasa tovar yaratib clientListga qo'shvorish
    case con.ADD_ONE_CLIENT_PRODUCT: {
      return { ...state, fail: true, data: uniqBy([payload, ...state.data], 'id') };
    }

    // DRAWER STATE
    case con.OPEN_DRAWER_CLIENT_PRODUCTS: {
      return { ...state, drawer: { ...state.drawer, visible: true, uniqueTrId: payload?.uniqueTrId || null, editProduct: payload?.uniqueTrId ? null : payload } };
    }
    case con.CLOSE_DRAWER_CLIENT_PRODUCTS: {
      return { ...state, drawer: initialDrawerState };
    }
    case con.START_DRAWER_CLIENT_PRODUCTS: {
      return { ...state, drawer: { ...state.drawer, status: requestState.loading } };
    }
    case con.SUCCESS_DRAWER_CLIENT_PRODUCTS: {
      const newData = state.data.filter(item => item.catalogCode !== payload.data?.catalogCode);
      return { ...state, data: uniqBy([payload.data, ...newData], 'id'), drawer: { ...state.drawer, status: requestState.success, message: payload.message, lastCreatedProduct: payload.data } };
    }
    case con.FAIL_DRAWER_CLIENT_PRODUCTS: {
      return { ...state, drawer: { ...state.drawer, status: requestState.error, message: payload } };
    }

    // CLIENT PRODUCTS ON TABLE MODAL
    case con.OPEN_CLIENT_PRODUCTS_MODAL: {
      return { ...state, tableModal: { ...state.tableModal, visible: true, uniqueTrId: payload?.uniqueTrId || null, } };
    }
    case con.CLOSE_CLIENT_PRODUCTS_MODAL: {
      return { ...state, tableModal: initialClientsModalState };
    }
    case con.SET_CLIENT_PRODUCT_FROM_TABLE: {
      return { ...state, tableModal: { ...state.tableModal, lastSelectedProduct: payload } };
    }
    case con.FORCE_SET_CLIENT_PRODUCT_LIST: {
      switch (payload.type) {
        case crudTypes.create: {
          return { ...state, data: uniqBy([payload.data, ...state.data], 'id') };
        }
        case crudTypes.update: {
          const newData = state.data.map(item => {
            if (item.catalogCode === payload.data?.catalogCode) {
              return payload.data;
            } else {
              return item;
            }
          })
          return { ...state, data: uniqBy(newData, 'id') };
        }
        case crudTypes.delete: {
          const newData = state.data.filter(item => item.catalogCode !== payload.data);
          return { ...state, data: uniqBy(newData, 'id') };
        }
        default: {
          return state;
        }
      }
    }

    // DEFAULT STATE
    default: {
      return state;
    }
  }
}

