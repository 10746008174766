import { colors } from 'Style/colors';
import styled from 'styled-components';

const BaseStyledFormItemWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;

  &.flex {
    display: flex;
  }
    
  &.block {
    display: block;
  }

  &.justify-content-center {
    justify-content: center;
  }

  &.align-items-center {
    align-items: center;
  }

  &.align-items-flex-end {
    align-items: flex-end;
  }

  &.flex-direction-column {
    flex-direction: column;
  }

  &.justify-content-space-between {
    justify-content: space-between;
  }

  &.justify-content-space-around {
    justify-content: space-around;
  }

  &.justify-content-flex-end {
    justify-content: flex-end;
  }

  &.mt {
    margin-top: ${(props) => props.marginTop};
  }
  &.mb {
    margin-bottom: ${(props) => props.marginBottom};
  }
  &.ml {
    margin-left: ${(props) => props.marginLeft};
  }
  &.mr {
    margin-right: ${(props) => props.marginRight};
  }

  &.mt-20 {
    margin-top: 20px;
  }

  &.height-100 {
    height: 100%;
  }

  &.waybill-radio {
    display: flex;
    width: auto;
    min-width: 200px; 
    align-items: center;
  }
`;

export const StyledComplexWrapper = styled(BaseStyledFormItemWrapper)`
  display: flex;
  justify-content: space-between;
  & > div {
    ${(props) => `width:calc(100% / ${props.count} - ${(props.count - 1) * 0.625}px)`};
    margin-right: 0.625rem;
    &:last-child {
      margin-right: 0px;
    }
  }
`;
export const BaseRadio = styled.div`
  display: flex;
  & > input {
    width: 12%;

    &:focus {
      box-shadow: 0 0 0 0 white;
    }
  }
  & > label {
    display: flex;
    align-items: center;
  }
`;
export default BaseStyledFormItemWrapper;
