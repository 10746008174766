import styled from "styled-components";

export const Wrapper = styled.div`   
    max-width: 65vw;
    min-width: 60vw;
     max-height: 52vh;
     min-height: 51vh;
    // height:450px;
    // width:980px;
    
    margin:0 auto;
    position: relative;
    z-index: 200;
    background-color:#fff;
    margin-top:20px;
    // margin-bottom:20px;

    @media (max-height:800px){
        max-height: 62vh;
        min-height: 61vh;
    }

    @media (max-width: 800px) {
        max-width: 92vw;
        max-height: 90vh;
        min-width: 90vw;
        min-height: 41vh;
        margin:0 auto;
        position: relative;
      }
`

export const StyledContainer = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    border-radius: 11.056px;
    border: 1.843px solid #D8E5FE;
    background: rgba(242, 246, 255, 0.20);
    min-height: 51vh;
    width: 100%;
    height: 100%;
    overflow-y:hiddden;

    @media (max-width: 800px) {
        max-width: 92vw;
        max-height: 90vh;
        min-width: 90vw;
        min-height: 41vh;
        margin:0 auto;
        position: relative;
      }
`