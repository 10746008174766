import { Input } from "antd";
import styled from "styled-components";

export const StyledInput = styled(Input)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 15px 13px 5px;
    gap: 10px;
    background: #F2F3F4;
    border-radius: 8px;

    & .ant-input {
        background: #F2F3F4 !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        letter-spacing: 0.5px !important;
        color: #8B94B2 !important;
    }
`;