import styled from "styled-components";

const StyledErrorFallback = styled.div`
  /* height: 100vh; */
  /* width: 100vw; */
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;

  a {
    color: #222;
    font-weight: 500;
    text-decoration: underline;
  }

  ul {
    margin: 0;
    list-style: none;
  }

  .main-content {
    max-width: 70%;
    margin: 0 auto;
    background-color: #2977FF;
    border-radius: 10px;
    padding: 30px;
  }

  .main-content__inner {
    display: flex;
    gap: 30px;
    align-items: center;
  }

  .main-content__logo-img {
    display: flex;
    gap: 30px;
    align-items: center;
  }

  .main-content__left {
    width: 100px;
    flex-shrink: 0;
    svg {
      width: 100px;
      height: auto;
      color: #FF8A00;
    }
  }

  .main-content__btns {
    display: flex;
    justify-content: flex-end;
  }

  .main-content__right {
  
  }

  ._fz32 {
    font-size: 32px;
    line-height: 1.2;
  }
  ._fz24 {
    font-size: 24px;
  }
  ._fz16 {
    font-size: 16px;
  }
`;

export {
  StyledErrorFallback
};