import * as con from 'Store/constant';
import requestState from 'Services/api/status';
const initialState = {
  status: 'initial',
  fail: {},
  view: {}
};

const readReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case con.VIEW_OUTGOING_DOCUMENT:
      return {
        ...initialState,
        status: requestState.loading
      };
    case con.VIEW_OUTGOING_DOCUMENT_SUCCESS:
      return {
        ...initialState,
        view: payload,
        status: requestState.success
      };
    case con.VIEW_OUTGOING_DOCUMENT_FAIL:
      return {
        ...state,
        fail: payload,
        status: requestState.error
      };
    case con.RESET_VIEW_REDUCER:
      return initialState
    default:
      return state;
  }
};

export default readReducer;
