import React from 'react';
import { colors } from 'Style/colors';

const SVGComponent = ({ style = { marginBottom: '10px' }, className = '', onClick, viewBox = '-3.5 0 22 17.5' }) => (
  <svg
    style={style}
    onClick={onClick}
    width={26}
    height={26}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.4 6.4L4 5L9 0L14 5L12.6 6.4L10 3.8V14H8V3.8L5.4 6.4Z"
      fill={colors.sideBarIconColor}
    />
    <path
      d="M2 9.99997L2 18H16V9.99997H18V18C18 19.1 17.1 20 16 20H2C0.899999 20 0 19.1 0 18V9.99997H2Z"
      fill={colors.sideBarIconColor}
    />
  </svg>
);
export default SVGComponent;