import React from 'react';
import { ErrorBoundary } from "react-error-boundary";
import { BsArrowLeft } from "react-icons/all";
import * as _ from "../../container/auth.style";
import { AuthMainSvg, EmailSvg, KeySvg, LockSvg } from "Assets/svg";
import loginMainSvg from "Assets/svg/logo_main_illustration.svg"
import logo from "Assets/images/logo.png"
import { MyFallbackComponent } from "Components/atoms/errorBoundry/ErrorBoundry";
import MySelect from "../select/select";
import MyInput from "../input";
import requestState from "Services/api/status";
import Registration from "../registration";
import Restore from "../restore";

const RightSide = ({ isLogin, isRegistration, isRestore, handleRadio, radioValue, onFinish, onFinishFailed, handleChange, data, email, handleEmailChange, passwordEye, openPassword, handlePasswordChange, handleCondition, apiData, password, eye }) => {
  return (
    <_.RightContainer>
      <_.SvgDiv>
        <img src={loginMainSvg} alt="" />
        {/*<AuthMainSvg />*/}
      </_.SvgDiv>

      {isLogin && !isRegistration && !isRestore ? (
        <_.LoginWrapper>
          <p className='authing'>Авторизация</p>
          <ErrorBoundary FallbackComponent={MyFallbackComponent}>
            <_.StyledRadioGroup name="radiogroup" onChange={handleRadio} value={radioValue}>
              <_.StyledRadio value={'byPassword'} isActive={radioValue === 'byPassword'}>
                <_.Text color={radioValue === 'byPassword' ? '#000017' : '#5F6C94'} esm radio>
                  {'Войти с паролем'}
                  <LockSvg style={{ marginBottom: '5px' }} />
                </_.Text>
              </_.StyledRadio>

              <_.StyledRadio value={'byKey'} isActive={radioValue === 'byKey'}>
                <_.Text color={radioValue === 'byKey' ? '#000017' : '#5F6C94'} esm radio>
                  {'Войти с ключом'}
                  <KeySvg style={{ marginBottom: '5px' }} />
                </_.Text>
              </_.StyledRadio>
            </_.StyledRadioGroup>

            <_.StyledForm initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              {radioValue === 'byKey' ? (
                <MySelect data={data} name="login" handleChange={handleChange} />
              ) : (
                <>
                  <_.FormItem>
                    <MyInput
                      prefix={<EmailSvg />}
                      name="email"
                      placeholder={'Эл. адрес'}
                      style={{ marginTop: '10px' }}
                      value={email}
                      className={'loginMyInput'}
                      onChange={handleEmailChange}
                    />
                  </_.FormItem>

                  <_.FormItem style={{ position: 'relative' }}>
                    <MyInput
                      prefix={<LockSvg width={24} height={24} />}
                      placeholder={'Пароль'}
                      name="password"
                      style={{ padding: '12px 15px 12px 10px', gap: '5px', color: 'red' }}
                      value={password}
                      type={passwordEye ? 'password' : 'text'}
                      className={'loginMyInput'}
                      onChange={handlePasswordChange}
                    />
                    <span onClick={openPassword} style={{ position: 'absolute', zIndex: 10, top: '18px', right: '24px' }}>
                      {eye()}
                    </span>
                  </_.FormItem>
                  <_.RightBottom>
                    <_.Text sm color={'#1F87E5'}>
                      <a onClick={() => handleCondition(3)}>Забыли пароль?</a>
                    </_.Text>
                  </_.RightBottom>
                </>
              )}

              <_.FormItem>
                <_.StyledButton htmlType="submit" loading={apiData.status === requestState.loading}>
                  {'Войти'}
                </_.StyledButton>
              </_.FormItem>
              <_.RightBottom>
                <_.Text sm color={'#000017'}>
                  Нет аккаунта?
                </_.Text>
                <_.Text sm color={'#1F87E5'}>
                  <a onClick={() => handleCondition(1)}>Регистрация</a>
                </_.Text>
              </_.RightBottom>
            </_.StyledForm>
          </ErrorBoundary>
        </_.LoginWrapper>
      ) : !isLogin && isRegistration && !isRestore ? (
        <div className="form-control" style={{ border: 0 }}>
          <Registration onFinishFailed={onFinishFailed} handleCondition={handleCondition} />
        </div>
      ) : !isLogin && !isRegistration && isRestore ? (
        <div className="form-control" style={{ border: 0 }}>
          <Restore onFinishFailed={onFinishFailed} handleCondition={handleCondition} />
        </div>
      ) : (
        ''
      )}
      {/*<_.ArrowLeft href={process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://24m.uz'}><BsArrowLeft/></_.ArrowLeft>*/}
      <_.ArrowLeft href={process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://24m.uz'}><img src={logo} alt="" /></_.ArrowLeft>
    </_.RightContainer>
  );
};

export default RightSide;