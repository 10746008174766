import { useEffect } from 'react';
import get from 'lodash.get';
import { HIDE_FILTER_CARD } from 'Hooks/useFilter';

const calendarEventClassNames = [
  'ant-picker-super-prev-icon',
  'ant-picker-header-super-prev-btn',
  'ant-picker-header-prev-btn',
  'ant-picker-prev-icon',
  'ant-picker-month-btn',
  'ant-picker-year-btn',
  'ant-picker-header-next-btn',
  'ant-picker-next-icon',
  'ant-picker-header-super-next-btn',
  'ant-picker-super-next-icon',
  'ant-picker-cell-inner',
  'ant-picker-decade-btn',
  'ant-picker-header-view',
  'ant-picker-body'];

const useClick = (ref, filterDispatch, isFilterActive, actionType) => {
  let antSelectOptions = document.getElementsByClassName('ant-select-item');

  function closeFilterCard(e) {
    try {
      if (!isFilterActive) return;
      let isOption = false;
      let isCalendar = calendarEventClassNames?.includes(`${e?.target?.className}`);

      let elClassList = e.target.classList, firstClass = get(e, 'target.classList[0]', '');
      isOption = Array.from(antSelectOptions).some((option) => option.contains(e.target));

      if (!ref?.current.contains(e.target) && !isOption && !isCalendar && !firstClass.includes('css')) filterDispatch({ type: actionType });
    } catch (e) {
      alert(e.message);
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeFilterCard);
    return () => {
      document.removeEventListener('click', closeFilterCard);
    };
  });
};

export default useClick;
