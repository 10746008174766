import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import useFetch from "Hooks/useFetch";
import Blogs from "Services/api/controller/blogs";
import requestState from "Services/api/status";

import {Col, Row} from "antd";
import {StyledCards} from "./style";

const Cards = () => {
  const dispatch = useDispatch();
  const [blogsState,,,getBlogs] = useFetch();

  useEffect(() => {
    getBlogs(Blogs.getBlogs);
  }, []);

  return (
    <Row gutter={[30, 30]}>
      {blogsState.status === requestState.success &&
        blogsState.data.map(obj => {
          // const decodedContent = obj?.description && Buffer.from(obj?.description, 'base64').toString('utf-8');
          const handleDispatchId = () => dispatch({type: 'EDIT_DOC_ID', payload: obj?.articleId})
          const formattedTitle = obj.title.converterCyrillicToLatin().toLowerCase().replace(/\s/g, '_')
          return (
            <Col xs={24} span={8} lg={8} onClick={handleDispatchId}>
              <StyledCards>
                <Link to={`blogpost/${formattedTitle}#${obj?.articleId}`}>
                  <img src={obj?.imageAsBase64 || null} alt={formattedTitle}/>
                </Link>
                <div className="texts">
                  <Link to={`blogpost/${formattedTitle}#${obj?.articleId}`}><h4>{obj?.title}</h4></Link>
                  <p>{obj?.description}</p>
                  {/*<p dangerouslySetInnerHTML={{__html: decodedContent}}/>*/}
                </div>
              </StyledCards>
            </Col>
          )
        })}
    </Row>
  );
};

export default Cards;