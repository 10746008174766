import * as con from 'Store/constant';
import requestState from 'Services/api/status';
const initialState = {
  copyFail: {},
  copyData: {},
  status: 'initial'
};

const copyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case con.COPY_DOCUMENT:
      return {
        ...state,
        status: requestState.loading
      };
    case con.COPY_DOCUMENT_SUCCESS:
      return {
        ...state,
        status: requestState.success,
        copyData: payload
      };
    case con.COPY_DOCUMENT_FAIL:
      const { id, message } = payload;
      return {
        ...state,
        status: requestState.error,
        copyFail: {
          id,
          message
        }
      };
    case con.CANCEL_ACTION:
      return initialState;
    case 'CLEAR_COPY':
      return initialState;
    default:
      return state;
  }
};

export default copyReducer;
