import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import Pdf from './pdf';
import Button from 'Components/atoms/button';
import ApiDocument from "Services/api/controller/document";
import notification from 'Components/atoms/toast';
import { selectAccountData } from "Store/selectors/selectors";
import {UPDATE_USER_DATA} from "Store/constant";

import { StyledButtonContainer } from './pdf.style';
import { useDispatch, useSelector } from "react-redux";

const OffertaPdf = ({ setVisible, visible, boxType }) => {
    const { t } = useTranslation(['translation', 'modalMessages']);
    const dispatch = useDispatch();
    const accountData = useSelector(selectAccountData);

    const onCancel = (text) => {
        if (text === 'close') {
            setVisible(false)
        }
    }

    const onSign = () => {
        if (accountData.offerId) {
            ApiDocument.acceptOfferta(accountData.offerId)
                .then(e => {
                    if (e?.data?.accept) {
                        notification.setType('success').setMessage(e?.data?.message).alert();
                        setVisible(false)
                        dispatch({ type: 'UPDATE_USER_DATA', payload: { ...accountData, offerId: null } });

                        if (boxType === '2' || boxType === '1') {
                            setTimeout(() => { window.location.reload() }, 100)
                        }

                        //Google Analytics Event Tracker Function
                        ReactGA.event({
                            category: 'Accepting Offerta Doc',
                            action: `Successfully Accepted Offerta Doc`
                        });
                    } else {
                        notification.setType('error').setMessage(e?.message).alert();
                        //Google Analytics Event Tracker Function
                        ReactGA.event({
                            category: 'Fail in Signing Offerta Doc',
                            action: `Tried but Failed to Sign Offerta Doc because of ${e?.message}`
                        });
                    }
                })
        }
    }

    const buttons = (
        <StyledButtonContainer>
            <Button onClick={() => onSign()}>
                {t('translation:sign')}
            </Button>
            {/*<Button onClick={() => onCancel('close')}>{t('translation:close')}</Button>*/}
            <Button disabled={true}><h3>{t('modalMessages:needOfertaSign')}</h3></Button>
        </StyledButtonContainer>
    );

    return <Pdf header={buttons} change={onCancel} visible={visible} footer={null} />;
}

export default OffertaPdf;




