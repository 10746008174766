import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import styled from 'styled-components';
import Helmet from "Components/organisms/head/Helmet";

import useFetch from "Hooks/useFetch";
import Blogs from "Services/api/controller/blogs";
import Header from "Pages/Info/components/header/Header";
import Footer from "Pages/Info//components/footer/Footer";
import StyledBlogpost from "./style";

const ImageContainer = styled.div`
  text-align: center;
  margin-bottom: 16px;
`;

const Image = styled.img`
  max-width: 70%;
  max-height: 100%;
  object-fit: contain;
`;

const Blogpost = () => {
  const {hash: articleId} = useLocation();
  const [blogState, , , getBlog] = useFetch();

  useEffect(() => {
    getBlog(Blogs.getBlogById, articleId.slice(1))
  }, [articleId]);

  const decodedContent = blogState?.data?.description && Buffer.from(blogState?.data?.description, 'base64').toString('utf-8');
  const imageSrc = blogState?.data?.imageAsBase64 ? blogState?.data?.imageAsBase64 : null;
  const formattedTitle = blogState?.data?.title?.converterCyrillicToLatin().toLowerCase().replace(/\s/g, '_')

  return (
    <div className="infoContainer">
      <Helmet title={blogState?.data?.title} href={formattedTitle} content={decodedContent}/>
      <Header/>
      <StyledBlogpost className="container">
        <h1 className="title">{blogState?.data?.title}</h1>
        {imageSrc && (
          <ImageContainer>
            <Image src={imageSrc} alt={formattedTitle}/>
          </ImageContainer>
        )}
        <div dangerouslySetInnerHTML={{__html: decodedContent}}/>
      </StyledBlogpost>
      <Footer/>
    </div>
  );
};

export default Blogpost;