import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
const DiPublicSubContract = lazy(() => import('../DiPublicSubContract'));
const PublicDocumentView = lazy(() => import('../PublicDocumentView'));

export default function Public() {
  return (
    <React.Suspense fallback={<div/>}>
      <Route exact component={DiPublicSubContract} path="/public/subContract/:docId" />
      <Route exact component={PublicDocumentView} path="/public/document/:docId" />
    </React.Suspense>
  );
}
