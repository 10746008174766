import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'antd';
import ApiUser from 'Services/api/controller/user';
import notification from 'Components/atoms/toast';
import { PlanIcon } from './icons';
import { planOptions } from './planOptions';
import { BasicPlan, ProfessionalPlan, EnterprisePlan } from './plans';

import * as _ from './style';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ComparesHeader from './compares/comparesHeader';
import ComparesList from './compares/ComparesList';
import { selectAccountData } from 'Store/selectors/selectors';

const SubscriptionPlans = ({ isLanding }) => {
  const [selectModal, setSelectModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(undefined);
  const [isSemiAnnualPlan, setIsSemiAnnualPlan] = useState(false);
  const [isAnnualPlan, setIsAnnualPlan] = useState(false);
  const user = useSelector((state) => state.accountData.user);
  const accountData = useSelector(selectAccountData);
  const { t } = useTranslation(['login', 'modalMessages']);
  const { tariffId } = user;

  let neededAmount = 0;
  if (selectedPlan === 2) {
    if (isSemiAnnualPlan) {
      neededAmount = (accountData?.balance - 270000 < 0) ? 270000 - accountData?.balance : 0;
    } else if (isAnnualPlan) {
      neededAmount = (accountData?.balance - 480000 < 0) ? 480000 - accountData?.balance : 0;
    } else {
      neededAmount = (accountData?.balance - 50000 < 0) ? 50000 - accountData?.balance : 0;
    }
  }

  let plan2NeededAmountMessage = (neededAmount > 0) ?
    `${t('modalMessages:message.needMoneyPart1')} 
    ${accountData?.balance} 
    ${t('modalMessages:message.needMoneyPart2')} 
    ${neededAmount} 
    ${t('modalMessages:message.needMoneyPart3')}` :
    '';

  const changeUserPlan = () => {
    if (selectedPlan) {
      ApiUser.updateUserPlan(selectedPlan, isSemiAnnualPlan, isAnnualPlan).then((res) => {
        if (res?.data === 'Успешно!') {
          notification.setType('success').setMessage('Тариф изменён').alert();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          notification.setType('error').setMessage(res.message).alert();
        }
      });
    }
  };

  // Select MODAL
  const showSelectModal = () => {
    setSelectModal(true);
  };
  const hideSelectModal = () => {
    setSelectModal(false);
  };

  return (
    <_.CardWrapperTest>
      <Row gutter={[30, 30]}>
        <BasicPlan
          xs={24} span={8} lg={8}
          isLanding={isLanding}
          showSelectModal={showSelectModal}
          setSelectedPlan={setSelectedPlan}
          isActive={tariffId === 1 ? true : false}
        />
        <ProfessionalPlan
          xs={24} span={8} lg={8}
          isLanding={isLanding}
          showSelectModal={showSelectModal}
          setSelectedPlan={setSelectedPlan}
          isActive={tariffId === 2 ? true : false}
          setIsAnnualPlan={setIsAnnualPlan}
          setIsSemiAnnualPlan={setIsSemiAnnualPlan}
        />
        <EnterprisePlan
          xs={24} span={8} lg={8}
          isLanding={isLanding}
          showSelectModal={showSelectModal}
          setSelectedPlan={setSelectedPlan}
          isActive={tariffId === 3 ? true : false}
          setIsAnnualPlan={setIsAnnualPlan}
          setIsSemiAnnualPlan={setIsSemiAnnualPlan}
        />
      </Row>

      <_.Compares isLanding={isLanding}>
        <ComparesHeader isLanding={isLanding} />
        <ComparesList isLanding={isLanding} />
      </_.Compares>

      <Modal
        title={selectedPlan === 3 ?
          'Свяжитесь с нами!' :
          <_.StyledText>{t("modalMessages:title.warning")}</_.StyledText>
        }
        visible={selectModal}
        color={'black'}
        width={'40%'}
        cancelText={'Отменить'}
        onClose={hideSelectModal}
        okText={'Подтверждаю'}
        onOk={() => {
          changeUserPlan();
          hideSelectModal();
        }}
        onCancel={hideSelectModal}
        footer={selectedPlan === 3 ? null : undefined}>
        <>
          <div>
            <_.StyledText className='warning'>
              {selectedPlan === 3 ?
                t('modalMessages:message.warningPlan3') :
                t('modalMessages:message.warning1')}
            </_.StyledText>
          </div>
          <div style={{ margin: '20px 0px' }}>
            <_.StyledText className='warning'>{plan2NeededAmountMessage}</_.StyledText>
          </div>
        </>
      </Modal>
    </_.CardWrapperTest>
  );
};

export default SubscriptionPlans;