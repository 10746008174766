import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from "react-router-dom";
import { Dropdown, } from 'antd';

import { useTranslation } from 'react-i18next';
import logout from 'Utils/logout';
// style
import {
  DropdownLink,
  MenuItem,
  MenuItemContainer,
  ProfileMenuContainer,
  QuitComponentText,
  SettingsRightContainer
} from './ProfileMenu.style';
// SVG Image
import CommonIcon from 'Assets/icons/common';

import { zoomStep, INCREMENT_ZOOM, DECREMENT_ZOOM } from 'Components/organisms/layout/reducer/ui';
import {RiRefreshLine, VscAccount} from 'react-icons/all';
import ApiUser from '../../../Services/api/controller/user';
import { getShortenedMchj } from 'Utils/toMchj';
import { colors } from 'Style/colors';
import Modal from 'Components/atoms/modal';
import OperatorList from './OperatorList';
import styled from 'styled-components';

const ReStyledModal = styled(Modal)`
  .ant-modal-content {
    border: 8px solid #2977FF;
  }
`

const ProfileMenu = () => {
  // NOTE redux store data
  const dispatch = useDispatch();
  const history = useHistory();
  const zoomValue = useSelector((state) => state.ui.zoom);
  const user = useSelector((state) => state.accountData.user);
  const isNewUi = useSelector((state) => state.ui.isNewUi)
  const [taxStatus, setTaxStatus] = useState('')
  const { t } = useTranslation(['layout', 'common', 'settings']);
  const [operatorModal, setOperatorModal] = useState(false)
  const [operatorList, setOperatorList] = useState([])

  const { name = '', tin = '', balance, pinfl = '', isActive, tariffName } = user;

  const changeZoom = (type) => {
    if (zoomValue === zoomStep) return;

    if (type === 'inc') {
      dispatch({ type: INCREMENT_ZOOM, payload: { value: zoomStep } });
    } else {
      dispatch({ type: DECREMENT_ZOOM, payload: { value: zoomStep } });
    }
  };

  const onclick = () => {
    setOperatorModal(true)
  };

  const goToTheAccount = () => history.push('/dashboard/user/settings?tab=7');

  useEffect(() => {
    if (pinfl) {
      ApiUser.getAnalyticsCompanyStatus(pinfl).then((res) => {
        setTaxStatus(res?.data?.data?.name);
      });
    } else if (tin) {
      ApiUser.getAnalyticsCompanyStatus(tin).then((res) => {
        setTaxStatus(res?.data?.data?.name);
      });
    }
  }, [pinfl, tin]);

  const menu = (
    <ProfileMenuContainer isNewUi={isNewUi}>
      {/* ---------------------------- Название компании --------------------------- */}
      <span className="menu__title">{getShortenedMchj(name)}</span>

      {/* ----------------------------------- ИНН ---------------------------------- */}
      <span className="menu__subtitle">
        {t('common:tin')}: {pinfl ? pinfl : tin}
      </span>

      {/* -------------------------- Налоговый статус ------------------------- */}
      <MenuItemContainer key="3" isNewUi={isNewUi}>
        <MenuItem className='profile-hint'>
          <CommonIcon alt="settings" name={'info'} width="30" height="31" viewBox={'0 0 20 21'} fill={isNewUi ? colors.iconColor : colors.white} />

          <SettingsRightContainer isNewUi={isNewUi}>
            <p className="dropdown-text" style={{ margin: 0 }}>
              {t('layout:navbar.userSoliqStatus')} :
            </p>
            <a title={taxStatus}>{taxStatus?.length > 22 ? `${taxStatus.substring(0, 22)}...` : taxStatus}</a>
          </SettingsRightContainer>
        </MenuItem>
      </MenuItemContainer>

      {/* -------------------------- Account ------------------------- */}
      <MenuItemContainer key="4" isNewUi={isNewUi} onClick={goToTheAccount}>
        <MenuItem>
          <VscAccount style={{fontSize: '30px', margin: '7px 0'}} fill={isNewUi ? colors.iconColor : colors.white}/>
          <SettingsRightContainer isNewUi={isNewUi}>
            <p style={{margin: '0'}}>{t('settings:personalArea')}</p>
          </SettingsRightContainer>
        </MenuItem>
      </MenuItemContainer>

      {/* -------------------------- Добавить BDM в список операторов ЭСФ ------------------------- */}
      <MenuItemContainer key="5" onClick={onclick} isNewUi={isNewUi}>
        <MenuItem>
          <RiRefreshLine style={{ fontSize: "30px", color: 'white', margin: '7px 0' }} fill={isNewUi ? colors.iconColor : colors.white} />
          <QuitComponentText isNewUi={isNewUi} className="dropdown-text" >
            <p style={{ whiteSpace: 'pre-wrap' }}>{t('layout:navbar.addToEsfList')}</p>
          </QuitComponentText>
        </MenuItem>
      </MenuItemContainer>

      {/* ---------------------------------- Выйти --------------------------------- */}
      <MenuItemContainer style={{ padding: '0.47rem 0.9rem' }} key="6" onClick={logout} isNewUi={isNewUi}>
        <MenuItem style={{ alignItems: 'center', padding: '5px 3px' }}>
          <CommonIcon width={'30'} height={'30'} viewBox="0 0 35 30" fill={isNewUi ? colors.backward : colors.white} name={'multimedia'} />
          <QuitComponentText className="dropdown-text" style={{ marginLeft: '15px' }}>
            <p>{t('profileMenu.quit')}</p>
          </QuitComponentText>
        </MenuItem>
      </MenuItemContainer>

    </ProfileMenuContainer>
  );

  return (
    <>
      <Dropdown placement="bottomCenter" overlay={menu} trigger={['click']}>
        <DropdownLink className="ant-dropdown-link tourSelector profile-hint" onClick={(e) => e.preventDefault()}>
          <CommonIcon name={'user'} width="24" height="30" viewBox="0 0 24 30" className="user-image" alt="drop-down" />
        </DropdownLink>
      </Dropdown>

      <ReStyledModal
        title={t('settings:listOfCounterAgents')}
        visible={operatorModal}
        onCancel={() => setOperatorModal(false)}
        width={700}
        bodyStyle={{ minHeight: '150px', fontSize: '18px', fontWeight: '450' }}
        footer={null}
      >
        <OperatorList callback={() => setOperatorModal(false)} />
      </ReStyledModal>
    </>
  );
};

export default ProfileMenu;
