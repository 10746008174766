import * as con from 'Store/constant';

const initialState = {
  visible: false,
  cb: null,
  data: null,
  loading: false
};

function sendDocToPublicViewReducer(state = initialState, action) {

  switch (action.type) {
    // SAVE DATA AFTER CLOSING MODAL
    case con.SAVE_DATA_PUBLIC_VIEW_MODAL: {
      const { data } = action.payload;
      return { ...state, data };
    }

    // OPEN - CLOSE
    case con.OPEN_SEND_PUBLIC_VIEW_MODAL: {
      const { cb } = action.payload;
      return { ...state, visible: true, cb };
    }
    case con.CLOSE_SEND_PUBLIC_VIEW_MODAL: {
      return { ...state, visible: false, cb: null };
    }
    
    // SENDING TO API
    case con.START_SENDING_PUBLIC_VIEW_MODAL: {
      return { ...state, loading: true };
    }
    case con.FINISH_SENDING_PUBLIC_VIEW_MODAL: {
      return { ...state, loading: false };
    }

    case con.CLEAR_SEND_PUBLIC_VIEW_MODAL: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}

export default sendDocToPublicViewReducer;
