import React from 'react';
import { Link } from 'react-router-dom';

import { Logo as BDMlogo } from './logo.style';


import CommonIcon from 'Assets/icons/common';

export const Logo = () => {
  return (
    <>
      <BDMlogo>
        <div style={{ paddingBottom: '7px' }}>
          <Link to="/dashboard/home">
            <CommonIcon width="177" height="58" viewBox="0 0 150 30" fill="none" name={'logo'} />
          </Link>
        </div>
      </BDMlogo>
    </>
  );
};
