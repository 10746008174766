import React from "react";
import { colors } from "Style/colors";

const SVGComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        viewBox="0 0 360.000000 360.000000"
        preserveAspectRatio="xMidYMid meet"
        {...props}
    >
        <g
            transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)"
            fill={colors.sideBarIconColor}
            stroke="none"
        >
            <path d="M1628 3370 c-280 -48 -468 -219 -523 -475 -33 -156 -29 -360 11 -534 14 -61 14 -66 -8 -110 -50 -101 -22 -259 63 -357 27 -30 49 -62 49 -70 0 -32 63 -174 105 -237 l45 -67 0 -123 c0 -141 -9 -165 -82 -215 -61 -42 -102 -61 -288 -137 -91 -37 -199 -84 -240 -106 -155 -80 -276 -194 -335 -314 -35 -73 -65 -196 -65 -272 l0 -63 830 0 830 0 -35 63 c-159 290 -146 644 35 938 16 27 28 49 25 49 -3 0 -58 -29 -122 -66 l-117 -65 -36 16 c-19 9 -84 44 -145 79 l-110 62 -3 118 -3 117 -32 28 c-31 28 -89 130 -109 195 -6 17 -13 60 -16 96 l-7 65 -37 3 c-43 4 -55 20 -73 97 -14 61 -5 103 31 131 l27 22 -16 68 c-37 158 -41 196 -42 359 0 148 3 179 22 240 66 209 223 315 493 332 150 9 269 -26 310 -91 16 -28 28 -34 66 -40 138 -19 222 -149 244 -378 15 -151 -10 -357 -55 -465 -13 -30 -12 -32 16 -44 16 -6 34 -20 39 -30 15 -27 11 -88 -8 -144 -16 -47 -19 -50 -57 -55 -30 -4 -40 -10 -43 -25 -18 -132 -80 -279 -137 -331 l-35 -31 0 -102 0 -102 93 94 c97 97 155 189 193 306 11 36 30 65 54 88 56 50 83 120 88 229 4 79 1 96 -18 134 -19 37 -21 49 -12 79 37 130 42 433 8 556 -43 155 -132 283 -231 329 -30 15 -61 26 -69 26 -9 0 -33 16 -55 35 -47 42 -129 81 -201 95 -61 11 -243 11 -312 0z m29 -2254 c10 -9 -4 -32 -63 -106 -42 -52 -79 -96 -82 -97 -4 -1 -35 34 -70 77 l-63 79 39 35 c21 19 47 51 57 70 l19 35 76 -40 c41 -23 81 -46 87 -53z m-290 -261 c59 -71 116 -131 126 -133 40 -8 66 11 133 94 38 48 72 92 76 98 5 7 17 2 33 -15 l27 -26 -32 -51 c-46 -76 -101 -206 -126 -302 l-22 -85 -532 -3 -533 -2 6 37 c22 132 158 276 342 363 101 47 370 158 382 157 6 -1 60 -60 120 -132z" />
            <path d="M2651 1513 c-32 -166 -29 -158 -67 -172 -40 -14 -37 -15 -158 72 l-69 49 -109 -109 -110 -110 65 -88 66 -89 -15 -35 c-8 -19 -20 -38 -26 -42 -6 -4 -48 -12 -92 -19 -129 -18 -117 1 -114 -173 l3 -152 55 -8 c151 -22 159 -25 171 -67 12 -38 11 -40 -51 -131 l-62 -92 108 -108 109 -109 97 65 96 64 40 -16 40 -15 18 -114 18 -114 147 0 c132 0 148 2 152 18 3 9 13 59 22 110 17 91 18 93 53 108 l36 15 96 -65 97 -64 108 108 108 109 -37 53 c-20 29 -50 71 -67 93 -28 39 -29 42 -16 80 l14 39 109 21 109 21 3 151 c3 174 18 152 -123 174 -96 14 -104 18 -120 57 -13 29 -11 34 52 127 l65 98 -103 103 c-57 57 -106 104 -109 104 -3 0 -44 -30 -92 -66 -87 -65 -88 -65 -127 -53 l-39 12 -17 113 -17 114 -152 0 -152 0 -13 -67z m206 -162 c6 -46 14 -89 17 -96 2 -7 33 -22 68 -34 35 -12 81 -32 102 -45 l39 -23 80 58 c43 33 82 59 87 59 4 0 13 -7 20 -15 10 -12 2 -29 -44 -98 l-57 -83 24 -50 c14 -27 33 -74 42 -105 l18 -57 101 -13 c98 -14 101 -15 104 -41 4 -30 -4 -33 -134 -59 l-61 -12 -23 -66 c-12 -35 -33 -82 -46 -103 -13 -21 -24 -40 -24 -43 0 -4 27 -42 61 -86 l61 -81 -22 -20 -21 -20 -83 57 -83 57 -49 -25 c-27 -13 -75 -33 -106 -42 l-57 -18 -11 -71 c-20 -122 -23 -131 -53 -134 -28 -3 -26 -7 -42 106 -13 90 -12 88 -78 112 -34 12 -81 33 -105 47 l-42 24 -87 -56 -86 -56 -20 20 -21 20 59 85 c45 66 56 88 47 99 -6 7 -26 53 -45 102 l-34 89 -59 11 c-32 7 -76 15 -96 19 -33 6 -38 10 -38 35 0 28 1 28 99 45 l99 18 15 64 c9 35 27 80 41 101 14 20 22 41 19 47 -4 6 -29 44 -56 84 l-50 73 21 21 20 21 85 -57 84 -58 82 36 c45 20 88 36 96 36 28 0 43 31 55 112 6 46 14 86 17 91 3 4 17 7 31 5 26 -3 28 -7 39 -87z" />
            <path d="M2703 1059 c-105 -40 -183 -155 -183 -268 0 -207 226 -349 413 -258 184 91 219 334 66 471 -84 75 -191 95 -296 55z m185 -148 c59 -31 81 -135 42 -198 -52 -87 -198 -83 -244 6 -70 135 66 265 202 192z" />
        </g>
    </svg>
);
export default SVGComponent;
