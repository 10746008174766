import React from 'react';
import {StyledFlexListForLanding} from "./style";
import {useTranslation} from "react-i18next";

export default (props) => {
  const {t} = useTranslation();
  const handleInputKeyDown = (e) => {
    const isNumberKey = /^[0-9]$/i.test(e.key);

    if (e.key === 'Backspace') {
      return;
    }

    if (!isNumberKey) {
      e.preventDefault();
    }
  };
  return (
    <StyledFlexListForLanding
      showSearch
      placeholder={props.placeholder}
      style={{width: '100%'}}
      suffixIcon={null}
      onInputKeyDown={handleInputKeyDown}
      onKeyDown={handleInputKeyDown}
      onSearch={props.handleSearch}
      onChange={props.onChange}
      notFoundContent={t('noData')}
      options={(props.data || []).map((d) => ({
        value: d.pinfl ? d.pinfl : d.tin,
        label: (d.pinfl ? d.pinfl : d.tin) + '-' + d.name,
      }))}
    />
  );
};