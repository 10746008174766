import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash.get';

// ANCHOR style

import BaseStyledVirtualHeader from 'Common/Style/styledVirtualHeader';

export const VirtualizedTableHeaderComponent = (props) => {
  const isNewUi = useSelector((state) => state?.ui?.isNewUi)
  const { columns = [], style } = props;

  return (
    <BaseStyledVirtualHeader isNewUi={isNewUi} style={{ display: 'flex', ...style }} border={props.border} padding="0px" height={get(style, 'height')}>
      {columns.map((column, key) => (
        <div style={get(column, 'props.style')} className={get(column, 'props.className')} key={key}>
          <span className={get(column, 'props.children[0].props.className')}>{get(column, 'props.children[0].props.title')}</span>
        </div>
      ))}
    </BaseStyledVirtualHeader>
  );
};
