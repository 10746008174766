import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectZoom } from 'Store/selectors/ui';

export default (extraHeight = 0) => {
  const zoom = useSelector(selectZoom);

  const calculateHeigthOfTable = useMemo(() => {
    let percentZoom = 100 / zoom,
      windowHeight = window.innerHeight * percentZoom;
    let value = windowHeight - (windowHeight - 85) * 0.04 - 95;
    return value - 80;
  }, [zoom]);

  return calculateHeigthOfTable + extraHeight;
};
