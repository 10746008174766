import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// ANCHOR  import
import NewSideBarComponent from './new.sidebar';

// ANCHOR import services function
import { onTranslatehandler } from 'Services/translate/translate';

// ANCHOR import links

import { parentLinks as mainLinks, keysLanguage } from 'Components/organisms/links/side.bar';

function NewSideBarContainer(props) {
  const { onToggle, isOpen } = props;
  const { t } = useTranslation(['layout']);
  const parentLinks = useMemo(() => onTranslatehandler(mainLinks, keysLanguage, 'text', t, 'layout:sidebar.'), [t]);

  return <NewSideBarComponent onClick={props.onClick} parentLinks={parentLinks} onToggle={onToggle} isOpen={isOpen} />;
}

export default NewSideBarContainer;
