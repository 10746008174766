import React from 'react';

const SVG = ({ style = {}, fill = '#000', width = '100%', onClick, className = '', viewBox = '0 0 40 26' }) => (
  <svg
    width={width}
    style={style}
    onClick={onClick}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M29.7563 7.4847H28.8518V3.1425C28.8508 2.30935 28.5194 1.5106 27.9303 0.921469C27.3412 0.332342 26.5424 0.000952808 25.7093 0H3.42375C2.5906 0.000952808 1.79185 0.332342 1.20272 0.921469C0.613592 1.5106 0.282203 2.30935 0.28125 3.1425V18.8271C0.282203 19.6602 0.613592 20.459 1.20272 21.0481C1.79185 21.6373 2.5906 21.9686 3.42375 21.9696H25.7093C26.5424 21.9686 27.3412 21.6373 27.9303 21.0481C28.5194 20.459 28.8508 19.6602 28.8518 18.8271V14.4849H29.7563C29.8955 14.4848 30.029 14.4295 30.1274 14.331C30.2258 14.2326 30.2812 14.0991 30.2813 13.9599V8.0097C30.2812 7.87049 30.2258 7.737 30.1274 7.63856C30.029 7.54012 29.8955 7.48478 29.7563 7.4847ZM27.8018 18.8271C27.8011 19.3819 27.5805 19.9137 27.1882 20.306C26.7959 20.6983 26.264 20.919 25.7093 20.9196H3.42375C2.86898 20.919 2.33711 20.6983 1.94483 20.306C1.55255 19.9137 1.33189 19.3819 1.33125 18.8271V3.1425C1.33189 2.58773 1.55255 2.05586 1.94483 1.66358C2.33711 1.2713 2.86898 1.05064 3.42375 1.05H25.7093C26.1723 1.05088 26.622 1.20519 26.9881 1.4888C27.3542 1.77241 27.6159 2.16933 27.7325 2.6175H4.48125C4.34201 2.6175 4.20848 2.67281 4.11002 2.77127C4.01156 2.86973 3.95625 3.00326 3.95625 3.1425C3.95625 3.28174 4.01156 3.41527 4.11002 3.51373C4.20848 3.61219 4.34201 3.6675 4.48125 3.6675H27.8018V7.4847H23.1686C22.2403 7.4847 21.35 7.85346 20.6936 8.50986C20.0372 9.16625 19.6685 10.0565 19.6685 10.9848C19.6685 11.9131 20.0372 12.8033 20.6936 13.4597C21.35 14.1161 22.2403 14.4849 23.1686 14.4849H27.8018V18.8271ZM29.2313 13.4349H23.1686C22.5187 13.4349 21.8956 13.1768 21.4361 12.7173C20.9766 12.2578 20.7185 11.6346 20.7185 10.9848C20.7185 10.335 20.9766 9.7118 21.4361 9.25232C21.8956 8.79284 22.5187 8.5347 23.1686 8.5347H29.2313V13.4349Z"
      fill="white"
    />
    <path
      d="M22.0173 10.9847C22.0173 11.2468 22.095 11.503 22.2407 11.7209C22.3863 11.9388 22.5932 12.1087 22.8353 12.209C23.0775 12.3093 23.3439 12.3355 23.6009 12.2844C23.858 12.2333 24.0941 12.107 24.2794 11.9217C24.4647 11.7364 24.5909 11.5003 24.6421 11.2433C24.6932 10.9862 24.667 10.7198 24.5667 10.4776C24.4664 10.2355 24.2965 10.0286 24.0786 9.88296C23.8607 9.73735 23.6045 9.65964 23.3424 9.65964C22.9911 9.66003 22.6543 9.79977 22.4059 10.0482C22.1575 10.2966 22.0177 10.6334 22.0173 10.9847Z"
      fill="white"
    />
  </svg>
);

export default SVG;
