import styled, { css } from 'styled-components';
import get from 'lodash.get';
import Select from 'react-select';
import { colors } from 'Style/colors';
import shadows from 'Style/shadows';

export const StyledOption = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  background: ${({ theme }) => get(theme, 'colors.primary')};
  border-radius: 4px;
  margin: 2px;
  color: white;
  padding-left: 2px;
  & > div {
    &:first-child {
      padding: 1px 8px;
      background-color: ${({ theme }) => get(theme, 'colors.primary')};
      margin-right: 0.5rem;
      background-color: rgb(230, 230, 230);
      border-radius: 5px;
      color: #777;
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        font-size: 1.2rem;
        padding-bottom: 5px;
        margin: 0 5px;
        height: 25px;
        border-radius: 5px;
        &:hover {
          cursor: pointer;
          background-color: rgb(255, 189, 173);
          color: rgb(222, 53, 11);
          border-radius: 4px;
          transform: scale(1.05, 1.05);
          transition: transform 100ms;
        }
      }
    }
  }
`;

function hideIndicatorSelectedItem() {
  return window.location.pathname.includes('create');
}

export const customStyles = {
  // control: (styles) => ({ ...styles, background: 'red', textAlign: 'center' }),
  valueContainer: (styles, state) => {
    return {
      ...styles,
      border: 0,
      cursor: !state.isDisabled ? 'initial' : 'not-allowed',
      '&:focus': {
        outline: 0,
        boxShadow: shadows.elFocus
      }
    }
  },
  container: (styles, state) => ({
    ...styles,
    border: 0,
    backgroundColor: state.isDisabled ? 'black' : 'initial',
    '&:focus': {
      border: 'none',
      outline: 'none',
      boxShadow: shadows.elFocus,

    }
  }),
  option: (provided, state) => ({
    ...provided,
    padding: 0,
    paddingLeft: 5,
    color: 'black',
    opacity: 0.8,
    height: '34px',
    display: 'flex',
    alignItems: 'center'
  }),
  input: (styles, state) => ({
    ...styles,
    border: 0,
    '&:hover': { cursor: 'initial' },
    // opacity:state.isDisabled?0.5:1,
    backgroudColor: state.isDisabled ? colors.disableColor : '#fff'
  }),
  placeholder: (styled) => ({
    ...styled,
    transform: 'translateY(-50%)',
    color: colors.placeHolderColor
  }),
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? 0 : 0,
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    width: '100%',
    minWidth: 0,
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #d9d9d9',
    borderRadius: '2px',
    transition: 'all 0.3s',
    opacity: state.isDisabled ? 0.9 : 1
    // height: '44px'
  }),
  dropdownIndicator: (styles, state) => {
    return {
      ...styles,
      opacity: state?.hasValue && hideIndicatorSelectedItem() ? '0' : '1'
    };
  },
  menu: (styles, state) => {
    return {
      ...styles,
      zIndex: 3
    }
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.8 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      '&:focus': {
        border: 'none',
        boxShadow: shadows.elFocus,
        outLine: 'none'
      },
      // fontFamily: 'Roboto',
      fontWeight: '400',
      opacity,
      transition,
      borderRadius: '3px',
      padding: '5px',
      transform: 'translateY(-46%)'
    };
  },
  noOptionsMessage: (styles, state) => {
    return styles;
  },
  multiValueRemove: (styles) => {
    return {
      ...styles,
      '&:hover': {
        backgroundColor: '#FFBDAD',
        color: '#DE350B'
      }
    };
  }
};

export function getStyledSelect(Component) {
  return styled(Component)`
    & > div {
      &[class*='control'] {
        /* NOTE react select control style */
        display: flex !important;
        border-radius: 8px;

        ${(props) =>
      props.autoHeightControl
        ? css`height:auto; min-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};`
        : css`height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
        min-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};`}

        &:focus,
        &:hover {
          ${(props) =>
      props.autoHeightControl
        ? css`height:auto; min-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};`
        : css`height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
        min-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};`}
          display: flex;
        }
        & > div {
          /* NOTE react slect value container style */
          &:first-child {
            ${(props) =>
      props.autoHeigthControl
        ? css`height:auto; min-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};`
        : css`height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
        min-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};`}
          }
          &:focus,
          &:hover {
            ${(props) =>
      props.autoHeigthControl
        ? css`height:auto; min-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};`
        : css`height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
        min-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};`}
          }
        }
      }

      [class*='indicatorSeparator'] {
        ${(props) =>
      props.autoHeightControl ? `` : css`height: calc(${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')} - 15px);`}
      }
      [class*='indicatorContainer'] {
        padding-top: 4px;
      }

      [class*='Input'] {
        box-shadow: none;
        margin: 0px;
      }
      [class*='noOptionsMessage'],
      [class*='menu'] {
        height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
        line-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
        z-index:3;
      }
      [class*='option'] {
        ${(props) =>
      props.autoHeightOption
        ? css`height:auto;min-height:34px;`
        : css`height: calc(${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')} - 10px);
        line-height: calc(${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')} - 10px);`}
      }
      [class*='placeholder'] {
        font-size: ${({ theme }) => get(theme, 'screens.laptop.sizes.base')};
        line-height: ${({ theme }) => get(theme, 'screens.laptop.sizes.base')};
        top: 45%;
      }
      input:focus {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }
  `;
}

export let selectAutoHeightOption = {
  ...customStyles,
  option: (provided, state) => ({
    ...provided,
    padding: 0,
    paddingLeft: 5,
    color: 'black',
    opacity: 0.8,
    height: 'auto',
    display: 'flex',
    alignItems: 'center'
  })
};

export const StyledSelect = getStyledSelect(Select);
