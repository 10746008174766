export const INBOX = 1;
export const OUTBOX = 2;

export const DOUBLE_SIDE_TAB_INDEX = 1;
export const THREE_SIDE_TAB_INDEX = 2;
export const CONTRACT_TAB_INDEX = 3;

export const THREE_SIDE_PART = 3;
export const DOUBLE_SIDE_PART = 2;
export const CONTRACT_PART = 4
