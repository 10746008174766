import React from 'react';
import { useTranslation } from 'react-i18next';
import MySelect from 'Modules/auth/components/select/select';
import Modal from 'Components/atoms/modal';
import requestState from 'Services/api/status';
import BaseFlex from 'Common/Style/styledFlex';
import Button from 'Components/atoms/button';
import BaseFormItem from 'Common/Style/styledFormItemWrapper';
import KeyOption from '../keyOption/keyOption';

export default function AcceptConfirmationModal({ visible, status, ...props }) {
  const { t } = useTranslation(['common']);

  const onClose = () => {
    if (status !== requestState.loading) {
      props.onClose();
    }
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps}>
        <KeyOption data={data} />
      </div>
    );
  };

  return (
    <Modal title={t('common:sign')} visible={visible} onClose={onClose}>
      <BaseFormItem>
        <MySelect
          data={props.keyOptions}
          handleChange={props.onKeyChange}
        />
        {/*<ReactSelect*/}
        {/*  components={{ Option: CustomOption }}*/}
        {/*  isClearable*/}
        {/*  onChange={props.onKeyChange}*/}
        {/*  options={props.keyOptions}*/}
        {/*  value={props.selectedKey}*/}
        {/*  placeholder="Выберите ключ"*/}
        {/*/>*/}
      </BaseFormItem>
      <BaseFlex className="justify-content-center mt" marginTop="0.9rem">
        <Button style={{ marginRight: '0.625rem' }} color="red" disabled={status === requestState.loading} onClick={onClose}>
          {t('cancel')}
        </Button>{' '}
        <Button onClick={props.onConfirm} loading={status === requestState.loading} disabled={!props.selectedKey}>
          {t('sign')}
        </Button>
      </BaseFlex>
    </Modal>
  );
}
