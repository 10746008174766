import { Resizable } from 're-resizable';
import get from 'lodash.get';
import styled from 'styled-components';
import BaseStyledFormItemWrapper from 'Common/Style/styledFormItemWrapper';
import { colors } from 'Style/colors';

export const StyledFilterCard = styled.div`
  /* NOTE style filter card  */

  /* visibility: none; */
  //box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-shadow:${colors.filterCardShadow};
  position: absolute !important;
  top: 50px !important;
  border-radius: 12px;
  left: ${(props) => props.left + 'px'};

  & > div:nth-child(2) {
    display: none;
  }

  & > div {
    position: fixed;
    background-color: ${colors.white};
    border-radius: 12px;
    width: ${(props) => `${props.width}px`};
    min-height: ${(props) => `${props.height}px`};
    height: auto;
    min-width: 280px;
    padding: 10px;
    z-index: 100;
    /* NOTE style header */

    /* NOTE style loading container */
    & .status_loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: ${(props) => '220px'};
      & > div {
        border-color: ${colors.primary} !important;
      }
    }

    & > div > header {
      margin-bottom: 10px;
      min-height: 70px;
      border-bottom: 2px solid ${colors.borderColor};
      /* NOTE select all chechbox style */
      & > div.select_all {
        /* padding: 3px; */
        margin-top: 10px;
      }
    }
    /* NOTE Style item */
    && > div > .list_item {
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 3px;
      border-bottom: ${(props) => props.theme.borders.tableBorder};
      /* line-height: 30px; */

      /* NOTE Styled item hover effect */
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
      /* NOTE Styled  checkbox label */
      & label {
        font-size: 0.7rem;
        & span {
          display: block;
          width: 80%;
          line-height: 20px;
        }
      }
    }

    /* NOTE Styled footer */

    & > div > footer {
      text-align: end;
      margin-top: 5;
      height: 60px;
      line-height: 60px;
      border-top: 2px solid ${colors.borderColor};

      & button{
        line-height:10px;
        height:28px;
      }
    }
  }
`;

export const StyledRowText = styled.span`
  width: ${(props) => `${props.width}px`};
  min-width: 220px !important;
  display: flex;
  justify-content: space-beetwen;
  align-items: center;
  & > span:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 2;
  }
`;

export const StyledCountBadge = styled.span`
  flex: 1;
  text-align: center;
  border-radius: 5px;
  background: ${({ theme }) => get(theme, 'colors.primary')};
  color: white;
`;

export const StyledFilterItemWrapper = styled(BaseStyledFormItemWrapper)`
  &.loading {
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid black;
      top: 0;
      left: 0;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;
