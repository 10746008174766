import * as con from 'Store/constant';
import omit from 'lodash.omit';
import requestState from 'Services/api/status';
const initialState = {
  formValues: {
    formatedFormData: {},
    file: {},
    objKeys: {}
  },
  fail: {},
  status: 'initial',
  additionalData: {
    data: {},
    type: null,
    status: 'initial'
  },
  allSavedFormValues: {}
};

const formValueReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case con.GET_DOC_CONTENT_HTML:
      return {
        ...state,
        status: requestState.loading
      };
    case con.GET_DOC_CONTENT_HTML_SUCCESS:
      return {
        ...state,
        formValues: { ...state.formValues, ...payload },
        status: requestState.success
      };
    case con.GET_ADDITIONAL_DOC_CONTENT_HTML_SUCCESS:
      return {
        ...state,
        additionalData: {
          data: payload.data,
          type: payload.type,
          status: 'success'
        }
      };
    case con.GET_DOC_CONTENT_HTML_FAIL:
      return {
        ...state,
        fail: { message: payload['message'], id: payload.id },
        status: requestState.error
      };
    case con.CLEAR_FORM_DATA_REDUCER:
      return initialState;
    case con.OUT_LOADING_SITUTION:
      return {
        ...state,
        status: requestState.loading
      };
      
      
    case 'SET_SAVED_FORM_VALUES':
      return {
        ...state,
        allSavedFormValues: { ...state.allSavedFormValues, [payload.name]: payload.value }
      };
    default:
      return state;
  }
};

export default formValueReducer;
