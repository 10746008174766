import React from 'react';
import { StyledAntDatePicker } from "./datePicker.style";
import BaseStyledLabel from "Common/Style/styledLabel";
import ru_Ru from 'antd/es/date-picker/locale/ru_RU';
import uz_UZ from 'antd/es/date-picker/locale/uz_UZ';
import 'moment/locale/ru';
import 'moment/locale/uz';
import 'moment/locale/uz-latn';
import moment from "moment";
import { formatDate } from "Utils/formatDate";
import { addDays } from 'date-fns';
import browserStorage from "Services/browserStorage";

const AntDatePicker = (props) => {
  const { name, value, onChange, label, maxDate, minDate, ...rest } = props;
  const el = document.getElementsByName(name)
  const currentLang = browserStorage.get('i18nextLng') || 'ru';
  let locale = currentLang === 'ru' ? ru_Ru : uz_UZ;

  let max = maxDate ? addDays(maxDate, 1) : null;
  let min = minDate ? minDate : null;
  moment.locale("", {
    week: {
      dow: 1
    }
  });

  const onChangeHandler = (date) => {
    onChange(date?._d, name)
  }

  const onKeyDownHandler = (e) => {
    let currentVal = e?.target?.value.split('');
    let temp = []
    currentVal.map((char, i) => {
      if ((i === 2 || i === 5) && char !== '.') {
        temp[i] = '.'
      }
      // if (i === 0 && Number(char) > 3) {
      //   temp.push('0');
      // }

      // if (i === 3 && Number(char) > 1) {
      //   temp.push('0');
      // }
      if (i < 9) {
        temp?.push(char);
      }
    })

    if (el[0]) {
      el[0].value = temp?.join('');
    }
  }


  if (props?.noLabel) {
    return (
      <StyledAntDatePicker
        name={name}
        locale={locale}
        showToday={false}
        format={'DD.MM.YYYY'}
        placeholder="__.__.____"
        renderExtraFooter={false}
        onChange={(date) => onChange(date?._d, name)}
        disabledDate={d => !d || d.isAfter(max) || d.isBefore(min)}
        value={(value && value > new Date('02/01/1970')) && moment(formatDate(value), 'YYYY-MM-DD')}
        {...rest}
      />
    );
  }

  return (
    <>
      <BaseStyledLabel style={{ ...props.style, height: '20px' }} className={props.className}>
        {label}
      </BaseStyledLabel>

      <StyledAntDatePicker
        name={name}
        locale={locale}
        showToday={false}
        format={'DD.MM.YYYY'}
        placeholder="__.__.____"
        renderExtraFooter={false}
        disabledDate={d => !d || d.isAfter(max) || d.isBefore(min)}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}

        value={(value && value > new Date('02/01/1970')) && moment(formatDate(value), 'YYYY-MM-DD')}
        {...rest}
      />
    </>
  );
};

export default AntDatePicker;