import React from 'react';

import Create from './create';
import Income from './income';
import OutGoing from './outgoing';
import Discover from './discover';
import Settings from './settings';
import Finance from './finance';
import AgreementsIcon from './agreement';
import Parameters from './parameters';
import Admin from './admin';
import Forum from './forum';
import Helpful from './helpful';
import Home from './home'

const SideBarIcon = (props) => {
  switch (props.name) {
    case 'home':
      return <Home {...props} />;
    case 'create':
      return <Create {...props} />;
    case 'incoming':
      return <Income {...props} />;
    case 'outgoing':
      return <OutGoing {...props} />;
    case 'discover':
      return <Discover {...props} />;
    case 'settings':
      return <Settings {...props} />;
    case 'helpful':
      return <Helpful {...props} />;
    case 'finance':
      return <Finance {...props} />;
    case 'agreements':
      return <AgreementsIcon {...props} />;
    case 'parameters':
      return <Parameters {...props} />;
    case 'admin':
      return <Admin {...props} />;
    case 'forum':
      return <Forum {...props} />;
    default:
      return;
  }
};

export default SideBarIcon;
