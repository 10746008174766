import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { StyledModal } from './modal.style';

const CustomModal = ({ color = null, width = 600, height, ...props }) => {
  const { onClose, visible, title = '' } = props;
  const isNewUi = useSelector((state) => state?.ui?.isNewUi)

  const onCloseHandler = () => {
    onClose(false);
  };

  return (
    <StyledModal
      title={title}
      color={color}
      width={width}
      height={height}
      visible={visible}
      onCancel={onCloseHandler}
      footer={null}
      isNewUi={isNewUi}
      {...props}>
      {props.children}
    </StyledModal>
  );
};


export default memo(CustomModal);
