import React from 'react';
import logo from "../../images/BDM-logo.png";
import policy from "../../policy.pdf";

const Footer = () => {
  const year = new Date()

  return (
    <footer className={'site-footer'}>
      <div className={'container site-footer__container'}>
        <a className={'site-footer__logo-link'} href={'#'}>
          <img src={logo} alt={'Business documents management logo 24m.uz Цифровой Обмен Документами'} />
        </a>
        <div className={'site-footer__content'}>
          <div className={'site-footer__left'}>
            <ul className={'site-footer__nav-list'}>
              <li className={'site-footer__nav-item'}>
                <a href={'#secondSection'} className={'site-footer__nav-link'}>
                  Что такое BDM
                </a>
              </li>
              <li className="site-footer__nav-item">
                <a href={'#thirdSection'} className={'site-footer__nav-link'}>
                  Возможности
                </a>
              </li>
              <li className="site-footer__nav-item">
                <a href={'#seventhSextion'} className={'site-footer__nav-link'}>
                  Интеграция
                </a>
              </li>
              <li className="site-footer__nav-item">
                <a href={'#pricingSection'} className={'site-footer__nav-link'}>
                  Тарифы
                </a>
              </li>
            </ul>
            <ul className={'site-footer__contact-list'}>
              <li className={'site-footer__contact-item'}>
                <a href={'mailto:info@24m.uz'} className={'site-footer__contact-link'}>
                  info@24m.uz
                </a>
              </li>
              <li className={'site-footer__contact-item'}>
                <a href={'tel:+998951942424'} className={'site-footer__contact-link'}>
                  +998 95 194 24 24
                </a>
              </li>
            </ul>
          </div>
          <div className={'site-footer__right'}>
            <h3 className={'site-footer__social-title'}>Присоединяйтесь к нам</h3>
            <ul className={'site-footer__social-list'}>
              <li className={'site-footer__social-item'}>
                <a href={'https://t.me/bdm_24m_uz'} className={'site-footer__social-link'} target={'_blank'}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100px" height="100px">
                    <path d="M25,2c12.703,0,23,10.297,23,23S37.703,48,25,48S2,37.703,2,25S12.297,2,25,2z M32.934,34.375 c0.423-1.298,2.405-14.234,2.65-16.783c0.074-0.772-0.17-1.285-0.648-1.514c-0.578-0.278-1.434-0.139-2.427,0.219 c-1.362,0.491-18.774,7.884-19.78,8.312c-0.954,0.405-1.856,0.847-1.856,1.487c0,0.45,0.267,0.703,1.003,0.966 c0.766,0.273,2.695,0.858,3.834,1.172c1.097,0.303,2.346,0.04,3.046-0.395c0.742-0.461,9.305-6.191,9.92-6.693 c0.614-0.502,1.104,0.141,0.602,0.644c-0.502,0.502-6.38,6.207-7.155,6.997c-0.941,0.959-0.273,1.953,0.358,2.351 c0.721,0.454,5.906,3.932,6.687,4.49c0.781,0.558,1.573,0.811,2.298,0.811C32.191,36.439,32.573,35.484,32.934,34.375z" />
                  </svg>
                </a>
              </li>
              <li className={'site-footer__social-item'}>
                <a
                  href={'https://www.linkedin.com/company/everbestlab/mycompany/'}
                  className={'site-footer__social-link'}
                  target={'_blank'}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                  </svg>
                </a>
              </li>
              <li className={'site-footer__social-item'}>
                <a href={'https://www.youtube.com/@bdm24m2'} className={'site-footer__social-link'} target={'_blank'}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                  </svg>
                </a>
              </li>
              <li className={'site-footer__social-item'}>
                <a href="https://www.instagram.com/everbestlab/" className={'site-footer__social-link'} target={'_blank'}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={'site-footer__docs'}>
          <div className={'site-footer__docs-top'}>
            <a href={policy} target="_blank">
              Публичная оферта
            </a>
            <a href="https://esi.uz/index/help/eimzo" target={'_blank'}>
              Скачать модуль E-IMZO
            </a>
          </div>
          <p className={'site-footer__copyright'}>
            © Copyright THE MIND x{' '}
            <a href={'https://everbestlab.com'} target={'_blank'}>
              EverbestLab.
            </a>
            {` ${year.getFullYear() ?? '2024'} All rights reserved.`}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;