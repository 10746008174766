import styled from 'styled-components';
import { Button, Table, Form, Input, Select, DatePicker } from 'antd';
import { colors } from 'Style/colors';
import { Drawer } from 'antd';

export const StyledDrawer = styled(Drawer)`

`;

export const Wrapper = styled.div`
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Header = styled.div`
  display: flex;
  justify-content:space-between;
  align-items: center;
  flex-direction: row;
  width:99.5%;
`;

export const StyledDrawerFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
  padding-bottom: 20px;
  padding-left: 10px;
`;

export const StyledDrawerTab = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  padding-bottom: 20px;
`;

export const DetailsBtn = styled.a`
  background: #115cef;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;

  &:hover {
    background: #053ba5;
    color: #fff;
  }
`;

export const StyledTable = styled(Table)`
  & .ant-table-thead > tr > th {
    color: ${(props) => props?.isNewUi ? colors.settingTableHeaderColor : '#FFFFFF'}; 
    background-color: ${(props) => props?.isNewUi ? colors.white : '#64c5e1'};
   ${(props) => props?.isNewUi ? `border-top:1.5px solid ${colors.settingTableBorderColor}` : ''};
    text-transform: capitalize;
    padding: 10px 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.9px;
  }

  & .ant-table-thead > tr:first-child > th:nth-last-child(2) {
    ${(props) => props?.isNewUi ? `border-top-right-radius: 8px; border-right: 1.8px solid ${colors.settingTableBorderColor};` : ''};
  }
  & .ant-table-thead > tr:first-child > th:first-child {
    ${(props) => props?.isNewUi ? `border-top-left-radius: 8px; border-left: 1.5px solid ${colors.settingTableBorderColor};` : ''};
  }

  & .ant-table-tbody .ant-table-row {
    ${(props) => props?.isNewUi ? `border-left: 1.5px solid ${colors.settingTableBorderColor}; border-right: 1.5px solid ${colors.settingTableBorderColor};` : ''};
  }

  .ant-table-tbody > tr > td:first-child {
    ${(props) => props?.isNewUi ? `border-left: 1.5px solid ${colors.settingTableBorderColor};` : ''};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${colors?.antdTableHover};
  }

  &
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: ${(props) => props?.isNewUi ? colors?.settingTableBorderColor : '#ffffff'};
    width: 2px;
  }
`;

export const StyledButton = styled(Button)`
  margin: 10px 10px 10px 0px;
  background-color: #64c5e1;
  border: 1px solid #64c5e1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  &:hover {
    background-color: #ffffff;
    color: #64c5e1;
    ${(props) => props.delete && 'color:#FFFFFF; background:red;  border: 1px solid red;'}
  }

  ${(props) => props.edit && 'color:#FFFFFF; margin:0px;'}
  ${(props) => props.delete && 'color:red; background:#FFFFFF;  border: 1px solid red; margin:0px;'}
`;

export const StyledTab = styled(Button)`
  margin: 0px;
  background-color: ${colors?.disableColor};
  border: 1px solid ${colors?.disableColor};
  border-raduis:0px;
  border-top-right-radius:8px;
  border-top-left-radius:8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  color:#FFFFFF;

  &:hover {
    background-color: #ffffff;
    color: ${colors?.disableColor};
    border: 1px solid ${colors?.disableColor};
    ${(props) => props.isActive && `
      background-color: #ffffff;
      color: ${colors?.primary};
      border: 1px solid ${colors?.primary};
    `}
  }

  ${(props) => props.isActive && `
    background-color: ${colors?.primary};
    border: 1px solid ${colors?.primary};
  `}
`;

export const StyledForm = styled(Form)``;

export const StyledFormItem = styled(Form.Item)`
  & .ant-form-item-label {
    font-weight: 500;

    & > label {
      ${(props) => props.error && `color: red !important;`}
    }
   
   
  }
`;

export const StyledInfoDiv = styled.div`
  margin:30px 0px 0px 0px;
  border:1.5px solid ${colors?.disableColor};
  border-radius:8px;
  padding:8px;
 
  & > div {
    width:90%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top:10px;
  }
`;

export const StyledInfo = styled.span`
  font-size:15px;
  font-weight:400;
  margin:0px 5px;
`;

export const StyledInfoLabel = styled.div`
   font-size:16px;
   font-weight:500;
`;

export const StyledInput = styled(Input)``;

export const StyledSelect = styled(Select)``;

export const StyledTag = styled.div`
  background: #1890ff;
  color: white;
  border-radius: 8px;
  max-width: 100px;
  margin: auto;
  background: ${(props) => props.bgColor};
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin:10px 5px;
`;

export const SearchDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const ColumnDiv = styled.div`
  height:95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledDatePicker = styled(DatePicker)``;
