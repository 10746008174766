import React from 'react';
import PropTypes from 'prop-types';
import { BiErrorCircle } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import Button from 'Components/atoms/button';
import { useSelector } from 'react-redux';
import { selectZoom } from 'Store/selectors/ui';
import { StyledErrorFallback } from './style';
import { cAlert } from '../toast';


const MyFallbackComponent = ({ componentStack, error }) => {
  const { t, i18n } = useTranslation('errorMsg');
  const zoom = useSelector(selectZoom);
  const contWidth = `${100 / (zoom / 100)}vw`;
  const contHeight = `${100 / (zoom / 100)}vh`;
  const lng = i18n.language;

  const reloadPage = () => {
    window.location.reload();
  }

  let reason = '';
  switch (lng) {
    case 'ru': {
      reason = <>Пожалуйста, попробуйте еще раз в противном случае обратитесь в службу <a href="tel:998951942424">{t('errorMsg:support')}</a></>
      break;
    }
    case 'latin': {
      reason = <>Iltimos qayta urinib ko'ring, aks holda <a href="tel:998951942424">{t('errorMsg:support')}</a> bilan bog'laning.</>
      break;
    }
    case 'cyrillic': {
      reason = <>Илтимос қайта уриниб кўринг, акс ҳолда <a href="tel:998951942424">{t('errorMsg:support')}</a> билан боғланинг.</>
      break;
    }
    default: { }
  }

  let chunkError = false;
  try {
    if ((error.toString()).includes('ChunkLoadError')) {
      chunkError = true;
      cAlert('error', t('errorMsg:errorList.chunkError'))
      return;
    }
  } catch (e) { };


  return (
    <StyledErrorFallback style={{ height: contHeight, width: contWidth }}>
      <div className="main-content">
        <div className='main-content__inner'>
          <div className='main-content__left'>
            <BiErrorCircle color='#FF8A00' />
          </div>

          <div className='main-content__right'>
            <h1 className='_fz32'>{chunkError ? t('errorMsg:errorList.chunkError') : t('errorMsg:unexpected')}</h1>
            {!chunkError && <><strong className='_fz16'>{t('errorMsg:errorList.syntaxError')}</strong><br /></>}
            <strong className='_fz16'>{reason}</strong>

            <br />
            <br />
            <h2 className='_fz24'>{t('errorMsg:errorDetails')}</h2>
            <p>
              <strong>{t('errorMsg:error')}:</strong> {error.toString()}
            </p>
            <p>{componentStack}</p>

            <div className='main-content__btns'>
              <Button onClick={reloadPage} style={{ borderColor: '#fff' }}>
                {t('errorMsg:reloadPage')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </StyledErrorFallback>
  );
};

MyFallbackComponent.prototype = {
  componentStack: PropTypes.string,
  error: PropTypes.string
};

export { MyFallbackComponent };
