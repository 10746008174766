/* ---------------------------------- LOGIN --------------------------------- */

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const FIRST_LOGIN = 'FIRST_LOGIN';

/* ----------------------------- READ USER DATA ----------------------------- */

export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAIL = 'GET_USER_DATA_FAIL';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

/* ----------------------------- READ DOCUMENTS ----------------------------- */

export const GET_OUTGOING_DATA_LIST = 'GET_OUTGOING_DATA_LIST';
export const GET_OUTGOING_DATA_LIST_SUCCESS = 'GET_OUTGOING_DATA_LIST_SUCCESS';
export const GET_OUTGOING_DATA_LIST_FAIL = 'GET_OUTGOING_DATA_LIST_FAIL';
export const FILTERED_LIST = 'FILTERED_LIST';
export const EXCRACTED_DATA = 'EXCRACTED_DATA';
export const SET_CURRENT_DOCUMENT = 'SET_CURRENT_DOCUMENT';
export const CHANGE_LIMIT_OF_FETCH_DATA_COUNT = 'CHANGE_LIMIT_OF_FETCH_DATA_COUNT';
/* ------------------------------ DOCUMENT VIEW ----------------------------- */

export const VIEW_OUTGOING_DOCUMENT = 'VIEW_OUTGOING_DOCUMENT';
export const VIEW_OUTGOING_DOCUMENT_SUCCESS = 'VIEW_OUTGOING_DOCUMENT_SUCCESS';
export const VIEW_OUTGOING_DOCUMENT_FAIL = 'VIEW_OUTGOING_DOCUMENT_FAIL';
export const VIEW_DOCUMENT_BY_JSON = 'VIEW_DOCUMENT_BY_JSON';
export const RESET_VIEW_REDUCER = 'RESET_VIEW_REDUCER';
/* ----------------------------- DOCUMENT DELETE ---------------------------- */

export const DELETE_OUTGOING_DOCUMENT = 'DELETE_OUTGOING_DOCUMENT';
export const DELETE_OUTGOING_DOCUMENT_SUCCESS = 'DELETE_OUTGOING_DOCUMENT_SUCCESS';
export const DELETE_OUTGOING_DOCUMENT_FAIL = 'DELETE_OUTGOING_DOCUMENT_FAIL';
export const CHANGE_DELETE_MODE = 'CHANGE_DELETE_MODE';

// DOCUMENT SEND
export const SEND_DOCUMENT = 'SEND_DOCUMENT';
export const SEND_DOCUMENT_SUCCESS = 'SEND_DOCUMENT_SUCCESS';
export const SEND_DOCUMENT_FAIL = 'SEND_DOCUMENT_FAIL';
export const SEND_DOCUMENT_RESET = 'SEND_DOCUMENT_RESET';
export const SEND_DOCUMENT_REDUCER_CLEAR = 'SEND_DOCUMENT_REDUCER_CLEAR';
/* ----------------------------- DOCUMENT SEARCH ---------------------------- */

export const SEARCH_OUTGOING_DOCUMENT = 'SEARCH_OUTGOING_DOCUMENT';
export const SEARCH_OUTGOING_DOCUMENT_SUCCESS = 'SEARCH_OUTGOING_DOCUMENT_SUCCESS';
export const SEARCH_OUTGOING_DOCUMENT_FAIL = 'SEARCH_OUTGOING_DOCUMENT_FAIL';

/* ---------------------------- UPLOAD EXCEL FILE --------------------------- */

export const EXCEL_UPLOAD = 'EXCEL_UPLOAD';
export const EXCEL_UPLOAD_SUCCESS = 'EXCEL_UPLOAD_SUCCESS';
export const EXCEL_UPLOAD_FAIL = 'EXCEL_UPLOAD_FAIL';

/* ----------------------------- GET STATISTIKA ----------------------------- */

export const STATISTIKA = 'STATISTIKA';
export const STATISTIKA_SUCCESS = 'STATISTIKA_SUCCESS';
export const STATISTIKA_FAIL = 'STATISTIKA_FAIL';

/* ------------------------------ SIGN DOCUMENT ----------------------------- */

export const SIGN_DOCUMENT = 'SIGN_DOCUMENT';
export const SIGN_DOCUMENT_SUCCESS = 'SIGN_DOCUMENT_SUCCESS';
export const SIGN_DOCUMENT_FAIL = 'SIGN_DOCUMENT_FAIL';
export const SET_INITIAL_SITUATION_SIGN = 'SET_INITIAL_SITUATION_SIGN';
export const CREATED_DOC_ID = 'CREATED_DOC_ID';
/* ----------------------------- REJECT DOCUMENT ---------------------------- */

export const REJECT_DOCUMENT = 'REJECT_DOCUMENT';
export const REJECT_DOCUMENT_SUCCESS = 'REJECT_DOCUMENT_SUCCESS';
export const REJECT_DOCUMENT_FAIL = 'REJECT_DOCUMENT_FAIL';
export const SET_INITIAL_SITUATION_REJECT = 'SET_INITIAL_SITUATION_REJECT';

/* ------------------------------ DOCUMENT COPY ----------------------------- */

export const COPY_DOCUMENT = 'COPY_DOCUMENT';
export const COPY_DOCUMENT_SUCCESS = 'COPY_DOCUMENT_SUCCESS';
export const COPY_DOCUMENT_FAIL = 'COPY_DOCUMENT_FAIL';

/* ------------------------------ CANCEL ACTION ----------------------------- */

export const CANCEL_ACTION = 'CANCEL_ACTION';

/* ----------------------------- FETCH FLEXLIST ----------------------------- */

export const FETCH_FLEX_LIST = 'FETCH_LIST';
export const FETCH_FLEX_LIST_SUCCESS = 'FETCH_LIST_SUCCESS';
export const FETCH_FLEX_LIST_FAIL = 'FETCH_LIST_FAIL';

/* --------------------------------- BUYERS --------------------------------- */

export const BUYER_INFO = 'BUYER_INFO';

/* ----------------------------- SELECTED OPTION ---------------------------- */

export const SELECTED_OPTION = 'SELECTED_OPTION';
export const SELECTED_PHYSIC_PERSON = 'SELECTED_PHYSIC_PERSON';
export const CLEAR_SELECTED_USERS = 'CLEAR_SELECTED_USERS';

/* -------------------------- FETCH REGIONS VALUES; -------------------------- */

export const FETCH_REGION_VALUES = 'FETCH_REGION_VALUES';
export const FETCH_REGION_VALUES_SUCCESS = 'FETCH_REGION_VALUES_SUCCESS';
export const FETCH_REGION_VALUES_FAIL = 'FETCH_REGION_VALUES_FAIL';

/* ------------------------- FETCH DISTRICTS VALUES ------------------------- */

export const FETCH_DISTRICT_VALUES = 'FETCH_DISTRICT_VALUES';
export const FETCH_DISTRICT_VALUES_SUCCESS = 'FETCH_DISTRICT_VALUES_SUCCESS';
export const FETCH_DISTRICT_VALUES_FAIL = 'FETCH_DISTRICT_VALUES_FAIL';

// GET MEASURE LIST

export const GET_MESURE_LIST = 'GET_MEASURE_LIST';

// DOCUMENT PDF  CREATE
export const DOCUMENT_CREATE_PDF = 'DOCUMENT_PDF_CREATE';
export const DOCUMENT_CREATE_PDF_SUCCESS = 'DOCUMENT_PDF_CREATE_SUCCESS';
export const DOCUMENT_CREATE_PDF_FAIL = 'DOCUMENT__PDF_CREATE_FAIL';

// DOCUMENT FORM VALUE
export const DOCUMENT_FORM_VALUE = 'DOCUMENT_FORM_VALUE';

// DOCUMENT_SIGN_AFTER_CREATED

export const DOCUMENT_SIGN_AFTER_CREATED = 'DOCUMENT_SIGN_AFTER_CREATED';
export const DOCUMENT_SIGN_AFTER_CREATED_SUCCESS = 'DOCUMENT_SIGN_AFTER_CREATED_SUCCESS';
export const DOCUMENT_SIGN_AFTER_CREATED_FAIL = 'DOCUMENT_SIGN_AFTER_CREATED_FAIL';

// EDIT USER DATA

export const EDIT_USER_DATA = 'EDIT_USER_DATA';
export const EDIT_USER_DATA_SUCCESS = 'EDIT_USER_DATA_SUCCESS';
export const EDIT_USER_DATA_FAIL = 'EDIT_USER_DATA_FAIL';

// FETCH MENU ITEMS
export const FETCH_MENU_ITEMS = 'FETCH_MENU_ITEMS';
export const FETCH_MENU_ITEMS_SUCCESS = 'FETCH_MENU_ITEMS_SUCCESS';
export const FETCH_REPORTS_MODULE_ITEMS_SUCCESS = 'FETCH_REPORTS_MODULE_ITEMS_SUCCESS';
export const FETCH_MENU_ITEMS_FAIL = 'FETCH_MENU_ITEMS_FAIL';
export const CHANGE_ACTIVE_SIDEBAR = 'CHANGE_ACTIVE_SIDEBAR';
export const LAYOUT_WIDTH = 'LAYOUT_WIDTH';
export const SHOW_AND_HIDE_SUBSIDEBAR = 'SHOW_AND_HIDE_SUBSIDEBAR';
export const CHANGE_SELECT_MULTIPLE_TYPES = 'CHANGE_SELECT_MULTIPLE_TYPES';
export const CHANGE_DOUBLE_TYPES = 'CHANGE_DOUBLE_TYPES';
export const CHANGE_TRIPLE_TYPES = 'CHANGE_TRIPLE_TYPES';
export const SET_INITIAL_VALUES_FOR_MULTIPLE_TYPES = 'SET_INITIAL_VALUES_FOR_MULTIPLE_TYPES';
export const CLEAR_DOCUMENT_TYPES = 'CLEAR_DOCUMENT_TYPES';
export const MENU_SET_INITIAL_STATUS = 'MENU_SET_INITIAL_STATUS';
// CAHNGE LOGIN USER DATA
export const CHANGE_LOGIN_USER_DATA = 'CHANGE_LOGIN_USER_DATA';

// CHANGE PHYSIC PERSON DATA
export const CHANGE_PHYSIC_PERSON_DATA = 'CHANGE_PHYSIC_PERSON_DATA';

// SET EMPTY ARRAY FOR FLEX LIST
export const RESET_FLEXLIST = 'RESET_FLEXLIST';

//SET  OLDFACTURA DATA
export const SET_OLD_FACTURA_DATA = 'SET_OLD_FACTURA_DATA';

// CHANGE DOCTYPES
export const CHANGE_DOCTYPES = 'CHANGE_DOCTYPES';

// CHANGE SIDEBAR ACTIVE MODULE
export const CHANGE_SIDEBAR_ACTIVE_MODULE = 'CHANGE_SIDEBAR_ACTIVE_MODULE';

// SET_INITIAL_PART
export const SET_INITIAL_PART = 'SET_INITIAL_PART';

//SEARCH DOCUMENTS
export const SEARCH_DOCUMENTS = 'SEARCH_DOCUMENTS';

// CLEAR FORM DATA reducer
export const CLEAR_FORM_DATA_REDUCER = 'CLEAR_FORM_DATA_REDUCER';
export const GET_DOC_CONTENT_HTML = 'GET_DOC_CONTENT_HTML';
export const GET_DOC_CONTENT_HTML_SUCCESS = 'GET_DOC_CONTENT_HTML_SUCCESS';
export const GET_ADDITIONAL_DOC_CONTENT_HTML_SUCCESS = 'GET_ADDITIONAL_DOC_CONTENT_HTML_SUCCESS';
export const GET_DOC_CONTENT_HTML_FAIL = 'GET_DOC_CONTENT_HTML_FAIL';
export const OUT_LOADING_SITUTION = 'OUT_LOADING_SITUTION';

//GET SETTING > ROLES
export const FETCH_ROLES_VALUES = 'FETCH_ROLES_VALUES';
export const FETCH_ROLES_VALUES_FAIL = 'FETCH_ROLES_VALUES_FAIL';
export const FETCH_ROLES_VALUES_SUCCESS = 'FETCH_ROLES_VALUES_SUCCESS';

// NOTE DOC TYPE FOR Создать menu
export const DOC_TYPE = 'DOC_TYPE';
export const MENU_OPENKEYS = 'MENU_OPENKEYS';
export const EDIT_DOC_ID = 'EDIT_DOC_ID';
export const EDIT_TEMPLATE_ID = 'EDIT_TEMPLATE_ID';
export const FORMS_EXCEL_DATA = 'FORMS_EXCEL_DATA';
export const IS_MARKING = 'IS_MARKING';
export const CORRECTION_DOC = 'CORRECTION_DOC';
export const CLEAR_EDIT_DATA = 'CLEAR_EDIT_DATA';

// NOTE DOC TYPE FOR display document

export const DOC_TYPE_FOR_DISPLAY = 'DOC_TYPE_FOR_DISPLAY';

export const RESET_MENU_REDUCER = 'RESET_MENU_REDUCER';

// NOTE NOTIFICATION HANDLER

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// NOTE Contract  
export const OPEN_CREATE_CONTRACT_DRAWER = 'OPEN_CREATE_CONTRACT_DRAWER';
export const CLOSE_CREATE_CONTRACT_DRAWER = 'CLOSE_CREATE_CONTRACT_DRAWER';

// NOTE CLIENT PRODUCTS SETTINGS BO'LIMIDAGI FORMA UCHUN LIST KO'RSATISHGA KERAK

export const SET_CLIENT_PRODUCTS = 'SET_CLIENT_PRODUCTS';
export const FAIL_CLIENT_PRODUCTS = 'FAIL_CLIENT_PRODUCTS';
export const ADD_ONE_CLIENT_PRODUCT = 'ADD_ONE_CLIENT_PRODUCT';

export const OPEN_DRAWER_CLIENT_PRODUCTS = 'OPEN_DRAWER_CLIENT_PRODUCTS';
export const CLOSE_DRAWER_CLIENT_PRODUCTS = 'CLOSE_DRAWER_CLIENT_PRODUCTS';
export const START_DRAWER_CLIENT_PRODUCTS = 'START_DRAWER_CLIENT_PRODUCTS';
export const SUCCESS_DRAWER_CLIENT_PRODUCTS = 'SUCCESS_DRAWER_CLIENT_PRODUCTS';
export const FAIL_DRAWER_CLIENT_PRODUCTS = 'FAIL_DRAWER_CLIENT_PRODUCTS';

export const OPEN_CLIENT_PRODUCTS_MODAL = 'OPEN_CLIENT_PRODUCTS_MODAL';
export const CLOSE_CLIENT_PRODUCTS_MODAL = 'CLOSE_CLIENT_PRODUCTS_MODAL';
export const FORCE_SET_CLIENT_PRODUCT_LIST = 'FORCE_SET_CLIENT_PRODUCT_LIST';
export const SET_CLIENT_PRODUCT_FROM_TABLE = 'SET_CLIENT_PRODUCT_FROM_TABLE';

// NOTE DOCUMENT YARATILGANDA SUCCESSDAN KEYIN FORMADA PUBLIC VIEW UCHUN DOCNI KIMGADIR JO'NATISH MODALI
export const SAVE_DATA_PUBLIC_VIEW_MODAL = 'SAVE_DATA_PUBLIC_VIEW_MODAL';
export const CLEAR_SEND_PUBLIC_VIEW_MODAL = 'CLEAR_SEND_PUBLIC_VIEW_MODAL';
export const OPEN_SEND_PUBLIC_VIEW_MODAL = 'OPEN_SEND_PUBLIC_VIEW_MODAL';
export const CLOSE_SEND_PUBLIC_VIEW_MODAL = 'CLOSE_SEND_PUBLIC_VIEW_MODAL';

export const START_SENDING_PUBLIC_VIEW_MODAL = 'START_SENDING_PUBLIC_VIEW_MODAL';
export const FINISH_SENDING_PUBLIC_VIEW_MODAL = 'FINISH_SENDING_PUBLIC_VIEW_MODAL';

// Actual tilni olish uchun

export const SET_NEW_LANGUAGE = 'SET_CLIENT_PRODUCT_FROM_TABLE';

export const SET_AGREEMENTS_SUBMENU = 'SET_AGREEMENTS_SUBMENU';

//NOTE TARIFF'S DATA - USER TARIFF MA'LUMOTLARI
export const GET_TARIFF_DATA = 'GET_TARIFF_DATA';
export const GET_TARIFF_DATA_SUCCESS = 'GET_TARIFF_DATA_SUCCESS';
export const GET_TARIFF_DATA_FAIL = 'GET_TARIFF_DATA_FAIL';

export const SET_FILTER_DATA_FROM_DASHBOARD = 'SET_FILTER_DATA_FROM_DASHBOARD';