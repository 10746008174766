import styled from 'styled-components';
import { colors } from 'Style/colors';
import { Layout } from 'antd';

const { Header } = Layout;

export const AntdHeader = styled(Header)`
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  background: ${(props) => props?.isNewUi ? colors.primary : colors.headerBackground};
  line-height: 20px;
  width: 100%;
  height: 75px;
  font-weight: 400;
  top: 0;

  & + section {
    position: fixed;
    margin-top: 75px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items:center;
  flex-direction:row;
  gap:15px;
`;
