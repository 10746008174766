import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Button from 'Components/atoms/button';
import BaseInput from 'Common/Style/styledInput';
import ApiUser from 'Services/api/controller/user';
import { Checkbox } from 'Components/atoms/checkbox';
import { formatDate } from 'Utils/formatDate';
import { useTranslation } from "react-i18next";

import ApiConstructionObject from "Services/api/controller/constructionObject";
import ApiBranch from "Services/api/controller/branch";
import useFetch from "Hooks/useFetch";
import notification from 'Components/atoms/toast';
import FlexList from 'Components/molecules/flexList';
import requestState from "Services/api/status";

import { fetchFlexListData } from 'Store/middlewares/fetchUserDataByInn';
import * as _ from 'Modules/agreements/components/contracts/style'
import { DatePicker } from "Components/atoms/datePicker";
import BaseSelect from 'Common/Style/styledSelect';
import moment from "moment";

export const ContractDrawer = () => {
    const { t } = useTranslation(['agreements']);
    const dispatch = useDispatch()
    const contractDrawer = useSelector((state) => state?.ui?.contractDrawer)

    const [formData, setFormData] = useState({
        active: true,
        buyerName: '',
        buyerTin: '',
        contractDate: '',
        contractNum: '',
        sellerName: '',
        sellerTin: ''
    });
    const [buyerObj, setBuyerObj] = useState({});
    const [isSearchByTax, setIsSearchByTax] = useState(true)
    const [taxSearchResult, setTaxSearchResult] = useState(undefined)
    const [searchInputTax, setSearchInputTax] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchId, setBranchId] = useState('');
    const [branchsState, , , getBranchs] = useFetch();
    const [constructionObjectsState, , , getConstructionObjects] = useFetch();
    const [counterAgent, setCounterAgent] = useState('buyer')

    useEffect(() => {
        if (contractDrawer?.boxType === 2) {
            setBranchName('sellerBranchName');
            setBranchId('sellerBranchId');
            setCounterAgent('buyer')
        } else {
            setBranchName('buyerBranchName');
            setBranchId('buyerBranchId');
            setCounterAgent('seller')
        }
    }, [contractDrawer?.boxType]);

    // All Functions
    const onClose = () => {
        dispatch({ type: 'CONTRACT_DRAWER', payload: { visible: false, mode: 'add', editData: undefined, setValue: () => { } } })
        setBuyerObj({});
        setSearchInputTax('');
        setTaxSearchResult(undefined);
        setFormData({
            active: false,
            buyerName: '',
            buyerTin: '',
            contractDate: '',
            contractNum: '',
            sellerName: '',
            sellerTin: ''
        })
    };

    const handleSaveOrEdit = () => {
        let data = {
            ...formData,
            contractDate: formatDate(formData?.contractDate),
            buyerTin: contractDrawer?.boxType === 1 ? null : formData.buyerTin,
            sellerTin: contractDrawer?.boxType === 2 ? null : formData.sellerTin,
            docType: contractDrawer?.editData?.docType || 1
        };
        ApiUser.editOrCreateContractlist(data).then((e) => {
            if (!e?.data?.success) {
                notification.setType('error').setMessage(e?.message).alert();
                dispatch({ type: 'CONTRACT_DRAWER', payload: { visible: false, mode: 'add', editData: null, setValue: () => { } } })
            } else {
                notification.setType('success').setMessage('Success').alert();
                contractDrawer?.setValue('contractDate', new Date(e?.data?.data?.contractDate))
                contractDrawer?.setValue('contractNo', e?.data?.data?.contractNum)
            }
        });
        onClose();
    };

    const handleSetValue = () => {
        if (taxSearchResult?.data?.contractDate && taxSearchResult?.data?.contractNum) {
            contractDrawer?.setValue('contractDate', taxSearchResult?.data?.contractDate)
            contractDrawer?.setValue('contractNo', taxSearchResult?.data?.contractNum)
        }
        onClose();
    };

    useEffect(() => {
        if (buyerObj?.value?.tin) {
            setFormData({
                ...formData,
                [counterAgent + 'Name']: buyerObj?.value?.name,
                [counterAgent + 'Tin']: buyerObj?.value?.pinfl || buyerObj?.value?.tin
            });
        }
    }, [buyerObj]);


    useEffect(() => {
        if (contractDrawer?.mode === 'edit' && contractDrawer?.editData) {
            setFormData({
                id: contractDrawer?.editData?.id,
                active: contractDrawer?.editData?.status,
                buyerName: contractDrawer?.editData?.buyerName,
                buyerTin: contractDrawer?.editData?.buyerTin,
                contractDate: new Date(contractDrawer?.editData?.contractDate),
                contractNum: contractDrawer?.editData?.contractNum,
                constructionObjectId: +contractDrawer?.editData?.constructionObjectId,
                buyerBranchName: contractDrawer?.editData?.buyerBranchName,
                buyerBranchId: contractDrawer?.editData?.buyerBranchId,
                sellerName: contractDrawer?.editData?.sellerName,
                sellerTin: contractDrawer?.editData?.sellerTin,
                sellerBranchName: contractDrawer?.editData?.sellerBranchName,
                sellerBranchId: contractDrawer?.editData?.sellerBranchId
            });
            setIsSearchByTax(false);
        }
    }, [contractDrawer?.boxType])

    useEffect(() => {
        getBranchs(ApiBranch.getBrachs);
        getConstructionObjects(ApiConstructionObject.getConstructionObjects)
    }, []);

    const handleSearchTax = () => {
        ApiUser.getContractByTax(searchInputTax).then((res) => {
            if (res?.data?.success) {
                setTaxSearchResult({ data: res?.data?.data })
                notification.setType('success').setMessage('Success').alert();
            } else {
                setTaxSearchResult({ err: 'Договор не найден' })
                notification.setType('error').setMessage(res?.message).alert();
            }
        });
    };

    const handleSetBranchData = (e) => {
        setFormData({
            ...formData,
            [branchName]: branchsState?.data.find(obj => +obj?.id === +e.target.value)?.name,
            [branchId]: branchsState?.data.find(obj => +obj?.id === +e.target.value)?.id
        });
    }
    const drawerContent = () => {
        let content = (isSearchByTax ?
            <>
                <_.SearchDiv>
                    <BaseInput placeholder={t('searchByTaxId')} value={searchInputTax} onChange={(e) => setSearchInputTax(e.target.value)} />
                    <Button type="primary" onClick={handleSearchTax}>{t('translation:search')}</Button>
                </_.SearchDiv>
                {taxSearchResult?.err && <p>{taxSearchResult?.err}</p>}
                {taxSearchResult?.data &&
                    <>
                        <_.StyledInfoDiv>
                            <div>
                                <_.StyledInfoLabel>{t('translation:buyer2')}
                                    <_.StyledInfo>{taxSearchResult?.data?.buyerName + " " + taxSearchResult?.data?.buyerTin}</_.StyledInfo>
                                </_.StyledInfoLabel>

                            </div>
                            <div>
                                <_.StyledInfoLabel>{t('translation:seller')}:
                                    <_.StyledInfo>{taxSearchResult?.data?.sellerName + " " + taxSearchResult?.data?.sellerTin}</_.StyledInfo>
                                </_.StyledInfoLabel>
                            </div>
                            <div>
                                <_.StyledInfoLabel>{t('translation:contractNumber')}:  <_.StyledInfo>{taxSearchResult?.data?.contractNum}</_.StyledInfo></_.StyledInfoLabel>
                            </div>
                            <div>
                                <_.StyledInfoLabel>{t('translation:contractDate')}:  <_.StyledInfo>{taxSearchResult?.data?.contractDate}</_.StyledInfo></_.StyledInfoLabel>
                            </div>
                            <div>
                                <_.StyledInfoLabel>{t('translation:documentAmount')}:  <_.StyledInfo>{taxSearchResult?.data?.summa}</_.StyledInfo></_.StyledInfoLabel>
                            </div>
                            <div>
                                <_.StyledInfoLabel>{t('translation:status')}:  <_.StyledInfo>{taxSearchResult?.data?.active ? t('settings:constructionObject.table.active') : t('settings:constructionObject.table.notActive')}</_.StyledInfo></_.StyledInfoLabel>
                            </div>
                        </_.StyledInfoDiv>
                        <Button disabled={!taxSearchResult?.data?.active} style={{ marginTop: '20px' }} onClick={() => handleSetValue()}>
                            {t('translation:save')}
                        </Button>
                    </>
                }
            </> :
            <_.StyledForm layout="vertical">
                <_.StyledFormItem label={t('translation:searchByTin')}>
                    <FlexList
                        placeholder={t('translation:buyerTin')}
                        action={fetchFlexListData}
                        name={counterAgent}
                        onChange={(e) => setBuyerObj(e)}
                        value={buyerObj}></FlexList>
                </_.StyledFormItem>
                <_.StyledFormItem label={t('translation:nameOfEntrerprise')}>
                    <BaseInput
                        value={formData?.[counterAgent + 'Name']}
                        defaultValue={formData?.[counterAgent + 'Name']}
                        onChange={(e) => setFormData({ ...formData, [counterAgent + 'Name']: e.target.value })}
                    />
                </_.StyledFormItem>
                <_.StyledFormItem label={t('translation:TIN')}>
                    <BaseInput
                        value={formData?.[counterAgent + 'Tin']}
                        defaultValue={formData?.[counterAgent + 'Tin']}
                        onChange={(e) => setFormData({ ...formData, [counterAgent + 'Tin']: e.target.value })}
                    />
                </_.StyledFormItem>
                <_.StyledFormItem label={t('translation:contractNumber')}>
                    <BaseInput
                        value={formData?.contractNum}
                        defaultValue={formData?.contractNum}
                        onChange={(e) => setFormData({ ...formData, contractNum: e.target.value })}
                    />
                </_.StyledFormItem>
                <_.StyledFormItem label={t('translation:contractDate')}>
                    <DatePicker
                        format={"DD.MM.YYYY"}
                        // value={formData?.contractDate || moment()}
                        // defaultValue={formData?.contractDate ? formData?.contractDate : new Date()}

                        onChange={(e) => setFormData({ ...formData, contractDate: e })}
                        {...(formData?.contractDate ? { value: formData?.contractDate } : {})}
                    />
                </_.StyledFormItem>
                <_.StyledFormItem label={t('common:constructionObject')}>
                    <BaseSelect name="constructionObjectId" onChange={(e) => setFormData({ ...formData, [e.target.name]: +e.target.value })}>
                        {constructionObjectsState.status === requestState.success && constructionObjectsState?.data?.map(obj => <option value={obj.id} selected={+formData?.constructionObjectId === obj?.id}>{obj.name}</option>)}
                    </BaseSelect>
                </_.StyledFormItem>
                <_.StyledFormItem label={t('settings:enterSubdivision')} value={formData[branchId]}>
                    <BaseSelect name={branchName} onChange={handleSetBranchData}>
                        {branchsState.status === requestState.success && branchsState?.data?.map(obj => <option value={obj.id} selected={+formData[branchId] === +obj.id}>{obj.name}</option>)}
                    </BaseSelect>
                </_.StyledFormItem>
                <_.StyledFormItem label={t('translation:status')}>
                    <Checkbox checked={formData?.active} onChange={() => setFormData({ ...formData, active: !formData?.active })}>
                        {formData?.active ? t('settings:constructionObject.table.active') : t('settings:constructionObject.table.notActive')}
                    </Checkbox>
                </_.StyledFormItem>
            </_.StyledForm>
        )

        return content;
    }

    return (
        <_.StyledDrawer
            title={contractDrawer?.mode === 'edit' ? t('translation:edit') : t('addContractFromOtherOperators')}
            visible={contractDrawer?.visible}
            color={'black'}
            onClose={() => onClose()}
            bodyStyle={{ paddingTop: '8px' }}
            style={{ zIndex: '1000' }}
            placement={'right'}
            width={550}
            footer={
                (isSearchByTax) ?
                    <_.StyledDrawerFooter>

                    </_.StyledDrawerFooter> :
                    <_.StyledDrawerFooter>
                        <Button color="red" onClick={onClose}>
                            {t('common:cancel')}
                        </Button>
                        <Button onClick={() => handleSaveOrEdit()}>
                            {contractDrawer?.mode === 'edit' ? t('translation:edit') : t('settings:submit')}
                        </Button>
                    </_.StyledDrawerFooter>
            }>
            <>
                {contractDrawer.mode === 'add' && (<_.StyledDrawerTab>
                    <_.StyledTab isActive={isSearchByTax} onClick={() => setIsSearchByTax(true)}>
                        {t('searchByTax')}
                    </_.StyledTab>
                    <_.StyledTab isActive={!isSearchByTax} onClick={() => setIsSearchByTax(false)}>
                        {contractDrawer?.mode === 'edit' ? t('translation:edit') : t('settings:submit')}
                    </_.StyledTab>
                </_.StyledDrawerTab>)}
                {drawerContent()}
            </>
        </_.StyledDrawer>
    )
}