export function toCurrency(number) {
    const formatter = new Intl.NumberFormat("sv-SE", {
        style: "decimal",
        currency: "SEK"
    });

    return formatter.format(number);
}

export function priceRender(amount) {
    amount = amount?.toString();
    if (amount?.length > 3) {
        amount = amount?.replace(/[^0-9.]/g, "");
        if (amount?.indexOf(".") !== -1) {
            amount = amount?.substring(0, amount?.indexOf(".") + 3);
        }
        amount = amount?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return amount;
}