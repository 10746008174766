import React from "react";
import { useSelector } from "react-redux";
import { ContractDrawer } from './drawer'

const ContractDrawerController = () => {
    const contractDrawer = useSelector((state) => state?.ui?.contractDrawer)
    return (
        <>
            {contractDrawer?.visible && <ContractDrawer />}
        </>
    )
}

export default ContractDrawerController;

