import * as con from 'Store/constant';

const initialState = {
  pending: false,
  fail: false,
  flexList: []
};

const flexListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case con.FETCH_FLEX_LIST:
      return {
        ...state,
        pending: true,
        fail: false
      };
    case con.FETCH_FLEX_LIST_SUCCESS:
      return {
        ...initialState,
        flexList: payload
      };
    case con.FETCH_FLEX_LIST_FAIL:
      return {
        ...state,
        fail: true,
        pending: false
      };

    case con.RESET_FLEXLIST:
      return {
        ...state,
        flexList: []
      };
    default:
      return state;
  }
};

export default flexListReducer;
