import styled from 'styled-components';
import get from 'lodash.get';
import { colors } from 'Style/colors';

function getBaseStyleFormField(tag) {
  return styled(tag)`
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: ${colors.textColor};
    font-size: ${({ theme }) => theme.screens.laptop.sizes['sm']};
    background-color: ${({ theme }) => theme.screens.laptop.formElements['bgColor']};
    border: ${({ theme }) => theme.screens.laptop.formElements['border']};
    border-radius: ${({ theme }) => theme.screens.laptop.formElements['borderRadius']};
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    height: ${({ theme }) => get(theme, 'screens.laptop.formElements.height')};
    outline: none;
    &::placeholder {
      color: ${colors.placeHolderColor};
      font-size: ${({ theme }) => theme.screens.laptop.sizes['base']};
    }
  `;
}

export default getBaseStyleFormField;
