import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import omit from 'lodash.omit';

// ANCHOR style

import { StyledSelect, customStyles } from 'Common/Style/reactSelect';

export const ReactSelectComponent = ({ style, label = '', className = '', ...props }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <StyledSelect
        closeMenuOnSelect={true}
        styles={Object.assign({}, omit({ ...customStyles, ...style }, ['dropdownIndicator', 'base']))}
        noOptionsMessage={() => t('noData')}
        {...props}
      />
    </Fragment>
  );
};

export default ReactSelectComponent;
