import * as con from 'Store/constant';
import get from 'lodash.get';
import ApiUser from 'Services/api/controller/user';
import ApiDocument from 'Services/api/controller/document';
import browserStorage from 'Services/browserStorage';
import requestState from 'Services/api/status';

import { SET_CATALOG_CODES } from 'Components/organisms/layout/reducer/catalogCode';
import ApiLgota from 'Services/api/controller/lgota';
import { SET_LGOTA } from './reducer/lgotaReducer';

export const getUserData = () => {
  return async (dispatch) => {
    dispatch({ type: con.GET_USER_DATA });
    dispatch({ type: con.GET_TARIFF_DATA });

    try {
      const columnChecklist = await ApiUser.getTableColumnChecklist();
      dispatch({
        type: 'IN_TABLE_COLUMN',
        payload: columnChecklist?.data?.data || {
          dateAndNumber: true,
          contract: true,
          branchName: true,
          branchNum: true,
          filialNum: true,
          constructionObject: true,
          ageInDays: true,
          sum: true
        }
      });

      const userData = await ApiUser.getUserData();
      dispatch({ type: con.GET_USER_DATA_SUCCESS, payload: userData?.data?.data || {} });

      const userTariff = await ApiUser.getOnePlan(1);
      dispatch({ type: con.GET_TARIFF_DATA_SUCCESS, payload: userTariff?.data || {} })

      const [measureList, lgota] = await Promise.allSettled([
        ApiDocument.getMeasureList(),
        // ApiDocument.getTovarClassifications(),
        ApiLgota.getLgotas()
      ]);
      // if (catalogCodes['status'] === 'fulfilled') {
      //   dispatch({ type: SET_CATALOG_CODES, payload: { data: get(catalogCodes, 'value.data.data') } });
      // }

      if (lgota['status'] === 'fulfilled') {
        dispatch({ type: SET_LGOTA, payload: { data: get(lgota, 'value.data.data') } });
      }

      browserStorage.set('measureList', measureList['status'] !== 'fulfilled' ? [] : get(measureList, 'value.data'));
    } catch (error) {
      dispatch({ type: con.GET_USER_DATA_FAIL });
    }
  };
};
