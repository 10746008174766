import axios from 'axios';
import { EIMZOClient, dates } from './e-imzo-client';
import $ from 'jquery';
import Axios from 'Services/api';
import browserStorage from 'Services/browserStorage';
export var EIMZO_MAJOR = 3;
export var EIMZO_MINOR = 37;


var pkcs7Serviec = function () {
  EIMZOClient.API_KEYS = [
    'localhost',
    '96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B',
    '127.0.0.1',
    'A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F',
    'corp.24m.uz',
    '663C1DC9BC03B456BF17A82F2A09C51CDF96389EC250DB61CB8B79EF6675412AC6FB03F177A68556178F4184FD9DFD09AB174D47B1C369B2920BE0AAA4AB1771',
    '24m.uz',
    'F9A86C9DC221A0EE579CB18ECCD99246CC40A5A1338559B5131FEFCB5FF7A4D705E3F8E54CBF8B41E6EFF64CC46615E545786630F19FF0412EC883A391DC2C54'
  ];
};

var timestamper = (signature_hex, callback, fail) => {
  const clientIP = browserStorage.get('clientIP') ?? '';
  axios.post('/frontend/timestamp/pkcs7', signature_hex, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      'X-Real-IP': `${clientIP}`
    }
  })
    .then((res) => {
      const { data } = res;
      if (res?.data?.pkcs7b64) {

        callback(res?.data?.pkcs7b64);
      } else {
        fail(data?.reason);
      }
    })
    .catch((res) => {
      fail(res);
    });
};

export var uiLoadKeys = function (comboId, cb) {
  uiClearCombo(comboId);
  EIMZOClient.listAllUserKeys(
    function (o, i) {
      var itemId = 'itm-' + o.serialNumber + '-' + i;
      cb(o);
      return { itemId, o };
    },
    function ({ itemId, o }, v) {
      return uiCreateItem(itemId, v);
    },
    function (items, firstId) {
      uiFillCombo(items, comboId);
    },
    function (e, r) {

    }
  );
};

var uiClearCombo = function (selectId) {
  var combo = $('#' + selectId);
  combo.empty();
  combo.append('<option disabled selected>Сертификат ЭЦП</option>');
};

var uiFillCombo = function (items, comboId) {
  var combo = $('#' + comboId);
  for (var itm in items) {
    combo.append(items[itm]);
  }
};



var uiCreateItem = function (itmkey, vo) {
  var now = new Date();
  vo.expired = dates.compare(now, vo.validTo) > 0;
  var itm = document.createElement('option');
  itm.value = itmkey;
  itm.text = vo.TIN + ' - ' + vo.CN;
  if (!vo.expired) {
  } else {
    itm.style.color = 'gray';
    itm.text = itm.text + ' (срок истек)';
  }
  itm.setAttribute('vo', JSON.stringify(vo));
  itm.setAttribute('id', itmkey);
  return itm;
};

export function checkEimzoNotLaunched(err, cb) {
  if (typeof err === 'object' && err instanceof Event && err.type === 'error') {
    cb(null, 'EIMZO_IS_NOT_LAUNCHED');
    return false;
  }
  return true;
}

// NOTE: adashmasam eimzo run bo'lganda kar bir key uchun random id yaratadi,
//  agar user orada eimzoni reload qilsa localStoragedagi id bilan yangi random id mos kelmay qoladi
//  shuni o'ylagan holda personTin bo'yicha keyni idsini yangilab olish uchun kerak
export function checkEimzoKeyNotLoadedError(err = '', requestCb, retryRequest) {
  try {
    const isEimzoLaunched = checkEimzoNotLaunched(err, requestCb);
    if (!isEimzoLaunched) return;

    if (typeof err === 'string'
      && (err.includes('API-key для домена недействителен') || err.includes('Ключ по идентификатору не найден'))
    ) {
      console.log(`TRYING TO RELOAD EIMZO CLIENT ID AGAIN STARTED`);
      
      // NOTE eimzo reload qilganda qayta login so'ramasdan keyni load qilib olamiz
      const personTin = browserStorage.get('personTin');

      // TODO login qilgandan keyin local storageda person tin saqlanyotgan edi shuni oldingi commitlarga qarab qaytarib qo'yish kerak,
      //  bo'lmasa kompyuterda eimzo dasturi reload bo'lgandan keyin
      //  user imzo qo'yish uchun logout qilib qayta login qilishi kerak bo'lib qolyapti
      if (personTin) {
        EIMZOClient.installApiKeys(
          function () {
            uiLoadKeys('tin', function (data) {
              const findedPerson = `${data.TIN}` === `${personTin}` && data;
              if (findedPerson) {
                EIMZOClient.loadKey(findedPerson, function (id) {
                  browserStorage.set('id', id);
                  browserStorage.set('personTin', findedPerson.TIN);
                  retryRequest(id); // NOTE sign qilishga yana urinib ko'ramiz
                });
              }
            });
          },
          function (e, r) {
            requestCb(null, err);
            if (r) {
              console.warn('Eimzo keylarini qayta load qilishda xatolik', r);
            }
          }
        );
      } else {
        requestCb(null, err); // NOTE odatdagiday davom etaveradi
      }
    } else {
      requestCb(null, err); // NOTE odatdagiday davom etaveradi
    }
  } catch (err) {
    requestCb(null, err); // NOTE syntax error bo'lsa odatdagiday davom etaveradi
  };
}

export const createPkcs7 = (data, cb, isRetry) => {
  let serialNumber = browserStorage.get('id');

  const errorHandler = function (e, r) {
    if (e || r) {
      if(isRetry) {
        cb(null, r);
        return;
      }
      checkEimzoKeyNotLoadedError(r, cb, () => {
        if(!isRetry) {
          createPkcs7(data, cb, 'retry');
        }
      });
    }
  }

  EIMZOClient.createPkcs7(
    serialNumber,
    data,
    null,
    function (pkcs7) {
      timestamper(pkcs7, function (pkcs7wtst) {
        cb(pkcs7wtst, null);//shu 
      }, errorHandler)
    },
    errorHandler
  );
};

export const appendPkcs7 = (data, cb, isRetry) => {
  let serialNumber = browserStorage.get('id');

  const errorHandler = function (e, r) {
    if (isRetry) {
      cb(null, r);
      return;
    }
    checkEimzoKeyNotLoadedError(r, cb, () => {
      if (!isRetry) {
        appendPkcs7(data, cb, 'retry');
      }
    });
  }

  EIMZOClient.appendPkcs7Attached(
    serialNumber,
    data,
    null,
    function (pkcs7) {
      timestamper(pkcs7, function (pkcs7wtst) {
        cb(pkcs7wtst, null);
      }, errorHandler)
    },
    errorHandler
  );
};

window.onload = pkcs7Serviec;
