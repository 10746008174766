const isChromeHighVersion = () => {
  const ua = navigator.userAgent.toLowerCase();
  const chromeIndex = ua.indexOf('chrome');
  if (chromeIndex > -1) {
    const version = ua.substring(chromeIndex + 7);
    const majorVersion = parseInt(version.split('.')[0], 10);
    return majorVersion > 127;
  }
  return false;
};

// NOTE: css zoom stil ishlashi yangi chrome browserlarda o'zgaribti
//  shu sabab antd tooltiplari yangi browser versiyalarida aniq pozitsiyani hisoblay olmayapti
export function zoomPlugin() {
  const originalGetBoundingClientRect = Element.prototype.getBoundingClientRect;
  if (!isChromeHighVersion()) {
    return;
  }
  Element.prototype.getBoundingClientRect = function () {
    const rect = originalGetBoundingClientRect.call(this);
    const zoom = Number(document.body.style.zoom) || 1;
    const newRect = new DOMRect(rect.x / zoom, rect.y / zoom, rect.width / zoom, rect.height / zoom);
    return newRect;
  };
}
