import { EIMZOClient } from './e-imzo-client';
import { uiLoadKeys, uiUpdateApp } from './appLoad';
import axios from 'axios';
export var Login = (function () {
  function handleLogin(user, cb) {
    sendLoginData(user, cb);
  }
  var handlerKey = function (comboId, cb) {
    // startLoader();
    EIMZOClient.checkVersion(
      function (major, minor) {
        var newVersion = 37 * 100 + 3;
        var installedVersion = parseInt(major) * 100 + parseInt(minor);
        if (installedVersion > newVersion) {
          uiUpdateApp();
        } else {
          EIMZOClient.installApiKeys(
            function () {
              uiLoadKeys(comboId, function (data) {
                cb(data);
              });
            },
            function (e, r) {
              if (r) {
              } else {
              }
            }
          );
        }
      },
      function (e, r) {
        if (r) {
          //   uiShowMessage(r, "error");
        } else {
          //   uiNotLoaded(e);
        }
      }
    );
  };
  var sendLoginData = function (user, cb) {
    let person = JSON.stringify(user).replace(/\^/g, "'");
    person = JSON.parse(person);
    var tin = user.TIN;
    // var name = $('input[name="name"]', $('.login-form')).val();
    var name = user.CN;
    var loginData = new Object();
    loginData.tin = tin;
    loginData.fullname = name;
    // loginData.organization = name;
    var json = {
      tin: tin,
      fullName: name
    };

    // var requestData = JSON.stringify(json);
    // var requestData = '072034ce-76df-459d-862d-c2cd721a18a2'
    axios.get('/frontend/challenge').then(res => {

      EIMZOClient.loadKey(
        person,
        function (id) {
          EIMZOClient.createPkcs7(
            id,
            res?.data?.challenge,
            null,
            function (pkcs7) {
              cb(pkcs7, id);
            },
            function (e, r) {
              if (r) {
                if (r.indexOf('BadPaddingException') != -1) {
                  cb(null, null, 'error_password');
                } else {
                  cb(null, null, r);
                }
              } else {
                cb(null, null, 'error_browser');
              }
              // cb(null, null, 'error_browser'); // tepadagi elseda 'error_browser' ishlashi kerak edi
            }
          );
        },
        function (e, r) {
          if (r) {
            if (r.indexOf('BadPaddingException') != -1) {
              cb(null, null, 'error_password');
            } else {
              cb(null, null, r);
            }
          } else {
            cb(null, null, 'error_browser');
          }
        }
      );
    })

  };
  return {
    //main function to initiate the module
    init: function (errorCode, cb, user = {}) {
      if (errorCode === 4 && Object.keys(user).length > 0) {
        handleLogin(user, cb);
      }
      if (errorCode === 3) {
      } else {
        if (errorCode === 2) {
          handlerKey('tin', function (data) {
            cb(data);
          });
        }
      }
    }
  };
})();
