import client from "../client";

const Blogs = {
  async getBlogs() {
    return await client('GET','blog/get-all');
  },
  async getBlogById(articleId) {
    return await client('GET',`blog/get/${articleId}`)
  }
}

export default Blogs;