import React, { memo, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import last from 'lodash.last';
import { Menu } from 'antd';

// ANCHOR redux selectors
import { selectActiveSideBar } from './menuReducer';

// ANCHOR redux constant
import * as con from 'Store/constant';
import * as allDocTypes from 'Store/docTypes';
import browserStorage from 'Services/browserStorage';

// ANCHOR utils and services functions
import { onGenerateMenuItems } from 'Utils/generateMenuItems';
import { getDefaultOpenKeys } from 'Utils/getDefaultOpenKeys';

// ANCHOR custom components
import InOutBoxTitle from './inOutBoxMenuText';
import { StyledMenu } from './style';

// ANCHOR style
import { SkletonParag, StyledCounter } from './style';
import { financeSidebarData, agreementSidebarData } from './hardMenuItems';

const { SubMenu } = Menu;

const onInboxOrOutboxHandler = (active) => {
  return active === 2 || active === 3 || active === 5;
};

export const onPrepareMenuItems = (list, active, part, t) => {
  return onGenerateMenuItems(list, active, part);
};

export const defaultSelectedId = () => browserStorage.get('doctype') || allDocTypes.OTHER_FROM_OTHER_OPERATORS;
export const defaultAgreements = () => browserStorage.get('agreements') || 3;
export const defaultOpenKeys = () => getDefaultOpenKeys(defaultSelectedId());

const SideBarMenuComponent = (props) => {
  // NOTE language controller
  const { t } = useTranslation();
  const currentLang = browserStorage.get('lang') || 'ru';
  // NOTE redux data menues
  const active = useSelector(selectActiveSideBar);
  const part = useSelector((state) => state.outDocuments.part);
  const stateinfo = useSelector((state) => state);

  const docIsLoading = useSelector((state) => state.outDocuments.pending);

  const doubleSideTypes = useSelector((state) => state.menues.doubleSideTypes);

  const threeSideTypes = useSelector((state) => state.menues.threeSideTypes);
  const selectedDoubleTypes = useSelector((state) => state.menues.selectedDoubleTypes);
  const allDoubleTypes = useSelector((state) => state.menues.allDoubleTypes);
  const selectedTripleTypes = useSelector((state) => state.menues.selectedTripleTypes);
  const allTripleTypes = useSelector((state) => state.menues.allTripleTypes);

  const { isReport } = useSelector((state) => state.menues);

  let types = useMemo(() => {
    if (active === 1) return [...doubleSideTypes, ...threeSideTypes];

    if (active === 5) return [...selectedDoubleTypes, ...selectedTripleTypes];
    if (part === 2) return allDoubleTypes;
    if (part === 2) return allTripleTypes;
    if (part === 2) return doubleSideTypes;

    if (part === 3) return threeSideTypes;

    return [];
  }, [active, part, doubleSideTypes, threeSideTypes, selectedDoubleTypes, selectedTripleTypes, allDoubleTypes, allTripleTypes]);

  const { menuPending, menuFail, items: unpreparedMenuItems } = useSelector((state) => state.menues);

  const menuItems = useMemo(() => {
    if (active === 9) return financeSidebarData[`${currentLang}`];

    if (active === 10) return agreementSidebarData[`${currentLang}`];

    return onPrepareMenuItems(unpreparedMenuItems, active, part, t);
  }, [unpreparedMenuItems, active, part, t, currentLang]);

  const dispatch = useDispatch();

  const isInboxOrOutBox = useMemo(() => onInboxOrOutboxHandler(active), [active]);

  const fetchData = (type) => {
    let currentTypes = [...types];

    if (currentTypes.includes(type)) {
      currentTypes = currentTypes.filter((itm) => itm !== type);
    } else {
      currentTypes = [...currentTypes, type];
    }

    dispatch({ type: con.CHANGE_DOCTYPES, payload: { part, currentTypes } });
  };

  const handleClick = (e) => {
    let emptyArr = [];

    if (docIsLoading) return;
    switch (active) {
      case 1:
        setTimeout(() => dispatch({ type: 'FORMS_EXCEL_DATA', payload: [] }), 100);
        setTimeout(() => dispatch({ type: 'EDIT_DOC_ID', payload: null }), 100);
        setTimeout(() => dispatch({ type: 'DOC_TYPE', payload: parseInt(last(e.key.split('/'))) }), 100);
        setTimeout(() => {
          dispatch({ type: 'SET_CATALOG_CODE_FOR_LGOTA', payload: {} });
        }, 100);
        break;
      case 2:
      case 3:
        fetchData(parseInt(e.key));
        break;
      case 5:
        setTimeout(() => {
          if ('checkAll2Types' === e.key) {
            if (!selectedDoubleTypes?.length > 0) {
              dispatch({ type: con.CHANGE_DOUBLE_TYPES, payload: [...allDoubleTypes] });
            } else {
              dispatch({ type: con.CHANGE_DOUBLE_TYPES, payload: [] });
            }
          } else if ('checkAll3Types' === e.key) {
            if (!selectedTripleTypes?.length > 0) {
              dispatch({ type: con.CHANGE_TRIPLE_TYPES, payload: [...allTripleTypes] });
            } else {
              dispatch({ type: con.CHANGE_TRIPLE_TYPES, payload: [] });
            }
          } else {
            dispatch({ type: con.CHANGE_SELECT_MULTIPLE_TYPES, payload: parseInt(last(e?.key?.split('/'))) });
          }
        });
        break;
      case 10:
        setTimeout(() => {
          dispatch({ type: con.SET_AGREEMENTS_SUBMENU, payload: parseInt(last(e.key.split('-'))) })
        }, 100)
        break;
      default:
    }
  };

  // NOTE pending
  const _renderPending = () => {
    return (
      <SkletonParag>
        <Skeleton style={{ margin: '10px 0' }} count={1} height={20} />
        <Skeleton style={{ margin: '10px 0 10px 20px' }} count={4} width={185} height={20} />
        <Skeleton style={{ margin: '10px 0' }} count={1} height={20} />
        <Skeleton style={{ margin: '10px 0' }} count={1} height={20} />
        <Skeleton style={{ margin: '10px 0 10px 20px' }} count={8} width={185} height={20} />
      </SkletonParag>
    );
  };

  if (menuFail && active !== 10 && active !== 9) return <h1>Двусторонние</h1>;

  if (menuPending && active !== 10 && active !== 9) return _renderPending();

  // NOTE menu checkbox

  const _renderCheck = (link) => {
    return <StyledCounter type={'checkbox'} className="counter" checked={types.includes(link)}></StyledCounter>;
  };

  const handleCheckAll = (e, link) => {
    e.stopPropagation();
    e.key = `checkAll${link.part}Types`;
    handleClick(e);
  };

  return (
    <StyledMenu
      inlineIndent={7}
      onClick={handleClick}
      style={{ width: '100%', opacity: docIsLoading ? 0.6 : 1 }}
      mode="inline"
      defaultOpenKeys={getDefaultOpenKeys(defaultSelectedId())}
      defaultSelectedKeys={onInboxOrOutboxHandler(active) ? [] : [String(defaultSelectedId())]}>

      {menuItems?.map((link, id) => {
        if (Object.keys(link).includes('children')) {
          return (
            <SubMenu
              key={`sub${link.id}`}
              title={link.title}
              className={(isReport && active === 5) ? 'my-ant-isReport' : ''}
              icon={
                (isReport && active === 5) ? (
                  <Menu.Item key={`checkAll${link.part}Types`} className={(isReport && active === 5) ? 'isReport' : ''}>
                    <StyledCounter
                      className="check-all"
                      type={'checkbox'}
                      onClick={(e) => handleCheckAll(e, link)}
                      checked={(link.part === 2 ? selectedDoubleTypes : selectedTripleTypes).length > 0}></StyledCounter>
                  </Menu.Item>
                ) : null
              }
            >
              {link.children.map((subLink, index) => {
                if (Object.keys(subLink).includes('children')) {
                  return (
                    <SubMenu key={`sub${subLink.id}`} title={subLink.title} className={(isReport && active === 5) ? 'isReport' : ''}>
                      {subLink.children.map((lastSubLink) => {
                        return (
                          <Menu.Item key={lastSubLink.type} className={(isReport && active === 5) ? 'isReport' : ''}>
                            {isInboxOrOutBox && _renderCheck(lastSubLink.type, lastSubLink.count)}
                            <InOutBoxTitle
                              type={lastSubLink.type}
                              text={lastSubLink.name}
                              count={lastSubLink.count}
                              inout={isInboxOrOutBox}
                              isReport={!!(isReport && active === 5)}
                            />
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  );
                } else {
                  return (
                    <Menu.Item key={subLink.type} className={(isReport && active === 5) ? 'isReport' : ''}>
                      {isInboxOrOutBox && _renderCheck(subLink.type, subLink.count)}
                      <InOutBoxTitle type={subLink.type} text={subLink.name} count={subLink.count} inout={isInboxOrOutBox} isReport={!!(isReport && active === 5)} />
                    </Menu.Item>
                  );
                }
              })}
            </SubMenu>
          );
        } else {
          return (
            <Menu.Item key={link.type} className={(isReport && active === 5) ? 'isReport' : ''}>
              {isInboxOrOutBox && _renderCheck(link.type, link.count)}
              <InOutBoxTitle type={link.type} text={link.name} count={link.count} inout={isInboxOrOutBox} path={link.path} isReport={!!(isReport && active === 5)} />
            </Menu.Item>
          );
        }
      })}
    </StyledMenu>
  );
};

export default memo(SideBarMenuComponent);
