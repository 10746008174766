import React, { useState } from 'react';
import { Col, Row } from 'antd';
import * as _ from '../../container/auth.style';
const FinishPage = ({ register, onFinish, isEmail, errors }) => {
  const [passwordEye, setPasswordEye] = useState(true);
  const eye = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
        fill="rgba(167,167,167,1)"
      />
    </svg>
  );
  const openPassword = () => {
    setPasswordEye((prev) => !prev);
  };
  return (
    <div id="finish__page">
      <Row>
        <Col span={12}>
          {/*<_.Text esmw color={'#000017'}>*/}
          {/*  {!isEmail ? 'Эл. адрес' : 'Номер телефона'}*/}
          {/*</_.Text>*/}
          <_.FormItem>
            <_.StyledInput
              name={!isEmail ? 'email' : 'tel'}
              ref={register({ required: true })}
              placeholder={!isEmail ? 'Эл. адрес' : 'Номер телефона'}
              style={{ marginTop: '10px' }}
            />
            {!isEmail && errors.email && <_.StyledErrorText>Это поле не может быть пустым!</_.StyledErrorText>}
            {isEmail && errors.tel && <_.StyledErrorText>Это поле не может быть пустым!</_.StyledErrorText>}
          </_.FormItem>
        </Col>
        <Col span={11} offset={1}>
          {/*<_.Text esmw color={'#000017'}>*/}
          {/*  ИНН или ПИНФЛ*/}
          {/*</_.Text>*/}
          <_.FormItem>
            <_.StyledInput type={'number'} name="tin" ref={register} placeholder={'ИНН или ПИНФЛ'} style={{ marginTop: '10px' }} />
          </_.FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {/*<_.Text esmw color={'#000017'}>*/}
          {/*  Имя и Фамилия*/}
          {/*</_.Text>*/}
          <_.FormItem>
            <_.StyledInput name="name" ref={register({ required: true })} placeholder={'Имя и Фамилия'} style={{ marginTop: '10px' }} />
            {errors.name && <_.StyledErrorText>Это поле не может быть пустым!</_.StyledErrorText>}
          </_.FormItem>
        </Col>
        <Col span={11} offset={1}>
          {/*<_.Text esmw color={'#000017'}>*/}
          {/*  Пароль*/}
          {/*</_.Text>*/}
          <_.FormItem style={{ position: 'relative' }}>
            <_.StyledInput
              name="password"
              ref={register({ required: true })}
              type={passwordEye ? 'password' : 'text'}
              placeholder={'Пароль'}
              style={{ marginTop: '10px' }}
            />
            {errors.password && <_.StyledErrorText>Это поле не может быть пустым!</_.StyledErrorText>}
            <span onClick={openPassword} style={{ position: 'absolute', zIndex: 10, top: '26px', right: '24px' }}>
              {eye()}
            </span>
          </_.FormItem>
        </Col>
      </Row>
      <_.FormItem>
        <_.StyledButton htmlType="submit" onClick={onFinish}>
          {'Завершить'}
        </_.StyledButton>
      </_.FormItem>
    </div>
  );
};

export default FinishPage;
