import React, { Suspense, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import { AiFillEdit, AiFillDelete, AiOutlineSelect, AiFillEye, AiOutlineFilter } from 'react-icons/ai';
import useVirtualData from 'Modules/settings/hooks/common/useVirtualData';
import Tooltip from 'Components/atoms/tooltip';
import Button from 'Components/atoms/button';
import VirtualTable from 'Components/molecules/virtualTable';
import VirtualTableHeader from 'Components/atoms/virtualizTableHeader';
import BaseStyledFlex from 'Common/Style/styledFlex';
import useHeight from 'Modules/settings/hooks/common/useHeight';
import getPackageNameObjKey from 'Utils/getPackageNameObjKey';
import { toCurrency } from 'Utils/toCurrencyFormat';
import ApiClientProducts from 'Services/api/controller/clientProducts';
import { cAlert } from 'Components/atoms/toast';
import { StyledSelect, CenteredCellItem, StyledSelectWrapper } from './style';
import AntdPopover from 'Components/atoms/popover';
import { BiDotsHorizontal, BiDotsHorizontalRounded, BiDotsVertical, BiDotsVerticalRounded } from 'react-icons/bi';
import Filter from '../filter';
import useClick from 'Components/molecules/filterCart/useClick';
import { SET_SELECTED_DATA, SET_ACTIVE_ON_SUCCESS, SET_ACTIVE_ON_FAIL } from 'Hooks/useFilter';
import BaseFlex from 'Common/Style/styledFlex';
import BaseVirtualHeader from 'Common/Style/styledVirtualHeader';
import { colors } from 'Style/colors';
import { useSelector } from 'react-redux';
import { productOrigin } from 'Components/molecules/customSelect/data';

const Table = ({ status, isFilterColumnOpen, ...props }) => {
  const { t, i18n } = useTranslation(['settings', 'common', 'translation']);
  const lng = i18n.language;
  const filterRef = useRef();
  useClick(filterRef, props.filterDispatch, props.filterData.isActive, SET_SELECTED_DATA);
  const data = useVirtualData(props.data, status); // props.totalCount
  const calculateHeigthOfTable = useHeight();
  const isNewUi = useSelector((state) => state?.ui?.isNewUi);


  // 1-6-2-11-2-2-1.5
  const tableDataWidth = 25.5;
  const columns = useMemo(() => {
    return [
      {
        label: '№ ',
        width: 1 / tableDataWidth,
        dataKey: 'index',
        style: { textAlign: 'center' }
      },
      {
        label: t('settings:my_products.table.productName'),
        width: 4 / tableDataWidth,
        dataKey: 'productName',
        style: { textAlign: 'left' }
      },
      {
        label: t('settings:my_products.table.price'),
        width: 2 / tableDataWidth,
        dataKey: 'price',
        style: { textAlign: 'right', paddingRight: 15 }
      },
      {
        label: t('settings:my_products.table.catalogCode'),
        width: 7 / tableDataWidth,
        dataKey: 'catalogCodeAndName',
        style: { textAlign: 'left' }
      },
      {
        label: t('settings:my_products.table.origin'),
        width: 4.2 / tableDataWidth,
        dataKey: 'origin',
        style: { textAlign: 'center' }
      },
      {
        label: t('settings:my_products.table.packages'),
        width: 3.5 / tableDataWidth,
        dataKey: 'defaultPackage',
        style: { textAlign: 'center' }
      },
      {
        label: t('settings:my_products.table.internationalCode'),
        width: 2.5 / tableDataWidth,
        dataKey: 'defaultInternationalCode',
        style: { textAlign: 'center' }
      },
      {
        label: t('settings:my_products.table.action'),
        width: 1.2 / tableDataWidth,
        dataKey: 'action',
        style: { textAlign: 'center', display: 'flex', aligItems: 'center', justifyContent: 'center' }
      }
    ];
  }, [t]);

  const getColumnKey = (label) => columns.find((_column) => _column.label === label).dataKey;

  async function fetchDataForSelectedKey(columnKey) {
    let response;
    switch (columnKey) {
      case 'productName':
        response = await ApiClientProducts.getAllFilterProducts(1);
        break;
      case 'catalogCodeAndName':
        response = await ApiClientProducts.getAllFilterProducts(2);
        break;
      /*       case 'typeName':
        response = await ApiDocumentClassification.getTypeCodes({
          groupIds: get(props.filterData, 'checkedBoxes.groupName')
        });
        break; */
      /* case 'positionName':
        response = await ApiEmployee.getEmployees({ page: 0, limit: 50 });
        break; */
      default:
        break;
    }
    if (!response?.fail) {
      props.filterDispatch({
        type: SET_ACTIVE_ON_SUCCESS,
        list: response?.data?.data
      });
    } else {
      props.filterDispatch({
        type: SET_ACTIVE_ON_FAIL,
        message: response?.message
      });
    }
  }

  const onFinalActionFiltirIcon = () => {
    props.refetch(props.filterData);
    props.filterDispatch({ type: SET_SELECTED_DATA });
  };

  function _renderHeader(params) {
    const { style, ...rest } = params;
    return (
      <BaseVirtualHeader isNewUi={isNewUi} border style={{ display: 'flex', ...style }} padding="0px">
        {columns.map((column, index) => {
          let dataKey = columns.find((_column) => _column.label === column?.label)?.dataKey;

          let className = props.filterData.activeProperties.includes(dataKey) && 'active';
          return (
            <BaseFlex
              style={get(params, `columns[${index}].props.style`)}
              className={`${get(params, `columns[${index}].props.className`)}  align-items-center ${
                /* index === 1 || */ index === 3 ? 'justify-content-space-between' : ' justify-content-space-around'
                }`}
              key={index}>
              <span
                style={{ marginLeft: index === 1 || index === 3 ? '10px' : '0' }}
                className={get(params, `columns[${index}]props.children[0].props.className`)}>
                {get(params, `columns[${index}].props.children[0].props.title`)}
              </span>
              {
                /* index === 1 || */ index === 3 && (
                  <span style={{ color: className == 'active' ? 'red' : '', marginRight: index === 1 || index === 3 ? '10px' : '0' }}>
                    <AiOutlineFilter
                      onClick={(e) =>
                        props.onFilterIconHandler({
                          e,
                          columns: params.columns,
                          column: params.columns[index],
                          getColumnKey,
                          cb: fetchDataForSelectedKey
                        })
                      }
                    />
                  </span>
                )
              }
            </BaseFlex>
          );
        })}
      </BaseVirtualHeader>
    );
  }

  const onChangePackage = async (rowData, value) => {
    const formdata = new FormData();
    // Object.keys(rowData).forEach(function(key, index) {
    //   formdata[key] = rowData[key];
    // });
    const pckg = rowData?.packagesList?.find((i) => `${i.code}` === `${value}`);
    formdata.append('defaultPackage', JSON.stringify(pckg));
    formdata.id = rowData.id;
    formdata.origin = rowData.origin;


    const res = await ApiClientProducts.updateProduct(formdata);
    if (!res.fail && pckg) {
      props.setData((prev) => {
        const item = prev.find((item) => item.catalogCode === rowData.catalogCode);
        if (!item) return prev;
        item.defaultPackageDTO = pckg;
        return [...prev];
      });
    } else {
      cAlert('error', res.message);
    }
  };

  const onChangeOrigin = async (rowData, value) => {

    const formdata = new FormData();
    formdata.append('origin', Number(value));
    formdata.id = rowData.id;

    const res = await ApiClientProducts.updateProduct(formdata);

    if (!res.fail) {
      props.setData((prev) => {
        const item = prev.find((item) => item.catalogCode === rowData.catalogCode);
        if (!item) return prev;
        item.origin = Number(value);
        return [...prev];
      });
    } else {
      cAlert('error', res.message);
    }
  };

  const onChangeInternationalCode = async (rowData, value) => {
    const formdata = new FormData();
    const intCode = rowData?.internationalCodeList?.find((int_code) => `${int_code}` === `${value}`);
    formdata.append('defaultInternationalCode', intCode);
    formdata.id = rowData.catalogCode;

    const res = await ApiClientProducts.updateProduct(formdata);
    if (!res.fail && intCode) {
      props.setData((prev) => {
        const item = prev.find((item) => item.catalogCode === rowData.catalogCode);
        if (!item) return prev;
        item.defaultInternationalCode = `${value}`;
        return [...prev];
      });
    } else {
      cAlert('error', res.message);
    }
  };

  const onDoubleClickRow = useCallback(
    (rowProps) => {
      try {
        if (props.isModalMode && props.onSelectForTableInput) {
          props.onSelectForTableInput(rowProps.rowData);
        } else {
          props.onOpenEditDrawer(rowProps.index);
        }
      } catch (err) { }
    },
    [props.onOpenEditDrawer]
  );

  const stopCLickBubble = useCallback((e) => {
    e.stopPropagation();
  }, []);

  function _renderCell(index, column, rowData) {
    const cellContent = get(column, 'props.title');
    const textAlignment = get(column, 'props.style.textAlign');

    switch (get(column, 'props.aria-colindex')) {
      case 1: {
        return (
          <CenteredCellItem maxLineClamp={3} style={{ textAlign: textAlignment }}>
            {index + 1}
          </CenteredCellItem>
        );
      }
      case 2: {
        return (
          <CenteredCellItem maxLineClamp={3} style={{ textAlign: textAlignment }}>
            {cellContent}
          </CenteredCellItem>
        );
      }
      case 3: {
        return (
          <CenteredCellItem maxLineClamp={3} style={{ textAlign: textAlignment }}>
            {toCurrency(cellContent)}
          </CenteredCellItem>
        );
      }
      case 4: {
        let text = `${rowData.catalogCode} - ${rowData.catalogName}`;
        return (
          <CenteredCellItem
            title={`${rowData.catalogCode} - ${rowData.catalogName}`}
            maxLineClamp={3}
            style={{ textAlign: textAlignment, paddingRight: 10 }}>
            {text}
          </CenteredCellItem>
        );
      }

      case 5: {
        if (productOrigin[cellContent]) {
          let selectedObj = productOrigin[cellContent]
          return (
            <StyledSelectWrapper>
              <div className="textContent">
                <span>{selectedObj?.name && t('translation:' + selectedObj?.name)}</span>
              </div>
              <StyledSelect
                onDoubleClick={stopCLickBubble}
                id={`origin${rowData.id}`}
                optionsCount={productOrigin?.length}
                onChange={(e) => onChangeOrigin(rowData, e.target.value)}
                value={selectedObj?.id}
                title={selectedObj?.name && t('translation:' + selectedObj?.name)}>
                {productOrigin?.map((obj) => (
                  <option value={obj.id}>{obj?.name && t('translation:' + obj?.name)}</option>
                ))}
              </StyledSelect>
            </StyledSelectWrapper>
          );
        } else {
          return (
            <CenteredCellItem title={t('translation:' + productOrigin[cellContent])} maxLineClamp={3}>
              {''}
            </CenteredCellItem>
          );
        }
      }

      case 6: {
        let defaultPackage = rowData.defaultPackageDTO;
        if (rowData.packagesList?.length > 1) {
          return (
            <StyledSelectWrapper>
              <div className="textContent">
                <span>{defaultPackage?.[getPackageNameObjKey(lng)]}</span>
              </div>
              <StyledSelect
                onDoubleClick={stopCLickBubble}
                id={`packageSelect${rowData.id}`}
                optionsCount={rowData.packagesList?.length}
                onChange={(e) => onChangePackage(rowData, e.target.value)}
                value={defaultPackage?.code}
                title={defaultPackage[getPackageNameObjKey(lng)]}>
                {rowData.packagesList?.map((pckg) => (
                  <option value={pckg.code}>{pckg[getPackageNameObjKey(lng)]}</option>
                ))}
              </StyledSelect>
            </StyledSelectWrapper>
          );
        } else {
          return (
            <CenteredCellItem title={defaultPackage?.[getPackageNameObjKey(lng)]} maxLineClamp={3}>
              {defaultPackage?.[getPackageNameObjKey(lng)]}
            </CenteredCellItem>
          );
        }
      }
      case 7: {
        if (!rowData.internationalCodeList) {
          return '';
        }
        let defaultInternationalCode = rowData.defaultInternationalCode;

        if (rowData.internationalCodeList?.length > 1) {
          return (
            <StyledSelectWrapper>
              <div className="textContent">
                <span>{defaultInternationalCode}</span>
              </div>
              <StyledSelect
                onDoubleClick={stopCLickBubble}
                optionsCount={rowData.internationalCodeList?.length}
                onChange={(e) => onChangeInternationalCode(rowData, e.target.value)}
                value={defaultInternationalCode}
                title={defaultInternationalCode}>
                {rowData.internationalCodeList?.map((code) => (
                  <option value={code}>{code}</option>
                ))}
              </StyledSelect>
            </StyledSelectWrapper>
          );
        } else {
          return (
            <CenteredCellItem title={defaultInternationalCode} maxLineClamp={3}>
              {defaultInternationalCode}
            </CenteredCellItem>
          );
        }
      }
      case columns.length:
        const renderBtns = (ref) => {
          return (
            <div>
              {props.isModalMode && (
                <Tooltip title={t('common:select')} placement={'bottomRight'}>
                  <Button
                    responsivePad
                    className="mr-1"
                    icon={<AiOutlineSelect fill={'white'} />}
                    onClick={() => {
                      props.onSelectForTableInput?.(rowData);
                      ref.current?.onClick?.();
                    }}></Button>
                </Tooltip>
              )}
              {rowData.imageLinks && (
                <Tooltip title={t('settings:seeImages')} placement={'bottomRight'}>
                  <Button
                    responsivePad
                    className="mr-1"
                    icon={<AiFillEye fill={'white'} />}
                    onClick={() => {
                      props.onOpenImagesModal(index);
                      ref.current?.onClick?.();
                    }}></Button>
                </Tooltip>
              )}
              <Tooltip title={t('settings:edit')} placement={'bottomRight'}>
                <Button
                  responsivePad
                  className="mr-1"
                  icon={<AiFillEdit fill={colors.primary} />}
                  onClick={() => {
                    props.onOpenEditDrawer(index);
                    ref.current?.onClick?.();
                  }}></Button>
              </Tooltip>
              <Tooltip title={t('common:delete')} placement={'bottomRight'}>
                <Button
                  responsivePad
                  color="red"
                  icon={<AiFillDelete fill={'red'} />}
                  onClick={() => {
                    props.onOpenDeleteModal(index);
                    ref.current?.onClick?.();
                  }}></Button>
              </Tooltip>
            </div>
          );
        };

        return (
          <BaseStyledFlex className="justify-content-center align-items-center height-100">
            <AntdPopover placement={'left'} renderBtns={renderBtns} trigger="hover">
              <BiDotsVerticalRounded size={24} color={colors.primary} />
            </AntdPopover>
          </BaseStyledFlex>
        );
      default:
        return (
          <CenteredCellItem maxLineClamp={3} style={{ textAlign: textAlignment, padding: '2px 8px 0px' }}>
            {cellContent}
          </CenteredCellItem>
        );
    }
  }

  return (
    <>
      <VirtualTable
        onScroll={props.onScrollHandler}
        height={calculateHeigthOfTable}
        rowHeight={54}
        headerHeight={40}
        data={data}
        renderCell={_renderCell}
        columns={columns}
        errorMessage="Error Message"
        renderHeader={_renderHeader}
        endMessage={t('common:end')}
        onDoubleClickRow={onDoubleClickRow}
      />
      {props.filterData.isActive && (
        <Suspense fallback={<div />}>
          <Filter
            handleResize={props.handleResize}
            filterData={props.filterData}
            width={props.width}
            ref={filterRef}
            height={props.height}
            filterDispatch={props.filterDispatch}
            onFinalActionFiltirIcon={onFinalActionFiltirIcon}
            footer={
              <BaseFlex className="justify-content-end mt" marginTop="10px">
                <Button onClick={props.hideFilter} color="red" className="mr-1">
                  {t('common:cancel')}
                </Button>
                <Button onClick={onFinalActionFiltirIcon}>{t('translation:filter.filter')}</Button>
              </BaseFlex>
            }
          />
        </Suspense>
      )}
    </>
  );
};

export default React.memo(Table);
