import React, { useCallback, useEffect } from 'react';
import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import useFetch from "Hooks/useFetch";
import ApiDocument from "Services/api/controller/document";
import requestState from "Services/api/status";
import { selectZoom } from "Store/selectors/ui";
import getSizeByZoom from "Utils/getSizeByZoom";
import { cAlert } from "Components/atoms/toast";
import StyledModal from "Components/atoms/modal";
import { colors } from "Style/colors";
import Form from "../../../../_commonFormComponents/SendDocPublicView/Form";


const SendToPublicForLanding = ({ isVisible, func, documentId }) => {
  const zoom = useSelector(selectZoom);
  const { t } = useTranslation();
  const [sendPublicModalFormState, , , sendPublicModalForm] = useFetch();

  const modalWidth = getSizeByZoom(zoom, window.innerWidth * 0.45);
  const modalHeight = getSizeByZoom(zoom, window.innerHeight * 0.65);

  const onClose = useCallback(() => {
    func();
  }, []);

  const onSubmit = useCallback((values) => {
    let body = {
      publicDocumentId: documentId,
      email: String(values?.email),
      phone: String(values?.phone),
      telegram: String(values?.telegram),
      receiverName: values?.receiver
    }

    sendPublicModalForm(ApiDocument.sendToPublicPersonOnLanding, body);
  }, [onClose, documentId]);

  useEffect(() => {
    if (sendPublicModalFormState.status === requestState.success) {
      cAlert(sendPublicModalFormState.status, t('createDocumentSuccess'))
      func()
    } else if (sendPublicModalFormState.status === requestState.error) {
      cAlert(sendPublicModalFormState.status, sendPublicModalFormState?.error?.message);
    }
  }, [sendPublicModalFormState]);

  if (!isVisible) return null;
  return (
    <StyledModal
      width={modalWidth}
      height={modalHeight}
      onClose={onClose}
      bodyStyle={{}}
      title={null}
      header={null}
      color={null}
      visible={isVisible}
    >
      <React.Suspense fallback={<div><MoonLoader color={colors.primary} css={{ margin: '0 auto' }} /></div>}>
        <Form
          onSubmit={onSubmit}
          status={sendPublicModalFormState.status}
          onCancel={onClose}
          documentUrl={documentId ? 'https://24m.uz/public/document/' + documentId : ''}
        />
      </React.Suspense>
    </StyledModal>
  )
};

export default SendToPublicForLanding;