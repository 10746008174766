import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import { AUTH_PAGE_DATE_FORMAT, formatTime } from 'Services/time/formatTime';
import styled from 'styled-components';
import { colors } from 'Style/colors';
import customDate from 'Services/time';

const StyledKeyOption = styled.article`
  transition: all 0.3s;
  margin: 5px 0px;
  padding: 0.3rem;
  &:hover {
    background-color: #e5e5e5;
  }

  & .key__option__row {
    & > b {
      margin-right: 0.3rem;
    }
  }
`;

export default function KeyOption({ data }) {
  const { t } = useTranslation(['login']);

  const from = formatTime(data.value.validFrom, AUTH_PAGE_DATE_FORMAT),
    to = formatTime(data.value.validTo, AUTH_PAGE_DATE_FORMAT), disabled = customDate.compareWithCurrentDate(to, AUTH_PAGE_DATE_FORMAT);

  return (
    <StyledKeyOption>
      <div className="key__option__row">
        <b> ИНН </b>
        <span> {data.value.getProp('tin')} </span>

        <span>
          <span
            style={{
              background: colors.primary,
              borderRadius: '4px',
              padding: '3px',
              color: 'white',
              fontSize: '10px'
            }}>
            {data.value.getProp('tin').startsWith(4) || data.value.getProp('tin').startsWith(5) || data.value.getProp('tin').startsWith(6)
              ? 'ФИЗИЧЕСКОЕ ЛИЦО'
              : 'ЮРИДИЧЕСКОЕ ЛИЦО'}
          </span>{' '}
        </span>
      </div>

      <div className="key__option__row">
        <b>СЕРТИФИКАТА</b>
        <span>{data.value.getProp('serialNumber')}</span>
      </div>

      <div className="key__option__row">
        <b>Ф.И.О</b>
        <span> {data.value.getProp('cn')} </span>
      </div>

      <div className="key__option__row">
        <b>ОРГАНИЗАЦИЯ</b>

        <span>{data.value.getProp('o')}</span>
      </div>

      <div className="key__option__row">
        <b>СРОК ДЕЙСТВИЯ СЕРТИФИКАТА</b>
        <span>
          {from} - {to}
        </span>
      </div>

      {disabled && (
        <div>
          <b style={{ color: 'red' }}>
            {get(t('warning')?.split('-'), '[0]', '')} {from} - {to} {get(t('warning')?.split('-'), '[1]')}
          </b>
        </div>
      )}
    </StyledKeyOption>
  );
}
