import React, { forwardRef } from 'react';
import FilterCart from 'Components/molecules/filterCart';
import { StyledFilterContainer } from './filter.style';

const Filter = forwardRef((props, ref) => {
  return (
    <StyledFilterContainer>
      <FilterCart {...props} ref={ref} />
    </StyledFilterContainer>
  );
});

export default Filter;
