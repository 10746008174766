import React from 'react';
import { useSelector } from 'react-redux';
import { colors } from 'Style/colors';
import { StyledTooltip } from './style';

const Tooltip = ({ children, title, placement = "left", color = colors.primary }) => {
    const isNewUi = useSelector((state) => state?.ui?.isNewUi)
    return (
        <StyledTooltip title={title} placement={placement} color={isNewUi ? color : '#001529'}>
            {children}
        </StyledTooltip>
    );
}

export default Tooltip;
