import styled from 'styled-components';
import { colors } from "Style/colors";

export const HintsContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 10px;
  padding: 9px 12px;
  cursor:pointer;
  transition: background-color 0.3s ease;

  &:hover {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.12);
  }

  & > a {
    display: flex;
    align-items: center;
    height: 25px;
    width: 20px;

    svg:hover {
      // path {
      //   fill: ${colors.iconHoverColor};
      //   cursor: pointer;
      // }
    }
  }
`;
