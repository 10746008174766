import styled from 'styled-components';
import get from 'lodash.get';
import { colors } from 'Style/colors';
export const StyledAside = styled.aside`
  background: ${(props) => props.isNewUi ? colors.sideBarColor : 'rgb(114, 114, 114)'};
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  // border-right:1.5px solid ${colors.borderColor};
 ${(props) => props.isNewUi ? `border-right: 2px solid ${colors.inputBorderColor}` : ''};  

  .sidebar {
    direction: rtl;
    margin-bottom: auto;
    padding-top:10px;
    height:100%;
    overflow-y:auto;
    overflow-x:hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .sidebar::-webkit-scrollbar {
    display: none;
  }

  // NOTE - create page faqat inbox orqali kirsa kommentdan chiqadi
  /* .sidebar > div:nth-child(1) {
    display: none;
  } */
`;

export const StyledSideBarBtn = styled.div`
  width: 100%;
  height: 40px;
  background: ${colors.primary};
  align-self: flex-end;
  /* position: absolute; */
  /* bottom: 74px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #fff;
  &:hover {
    cursor: pointer;
  }

  svg {
    color: #fff !important;
  }
`;

export const StyledSideBarBox = styled.div`
  padding: 10px 0px 6px 0px;
  width: 100%;
  height:72px;
  max-height:72px;
  cursor: pointer;
  margin:2px 0%;

  // border-radius: 12px;

  ${(props) => props.isActive && { backgroundColor: props?.isNewUi ? colors.primary : 'rgb(100, 197, 225)' }};
 

   ${(props) => props.isDisable && { opacity: 0.6 }}

   & > div svg path{
    ${(props) => !props.isNewUi && { fill: colors.white }} !important;
   }

   & > div svg path{
    ${(props) => props.isActive && { fill: props.isNewUi ? colors.white : 'white' }} !important;
   }

  & > div {
    text-align: center;
    color: ${(props) => props.isNewUi ? colors.sideBarIconColor : colors.white};
    font-weight: ${(props) => props.isNewUi ? '500' : '450'};
    font-size: 14px;
    line-height: 16px;
    ${(props) => props.isActive && { color: props.isNewUi ? colors.white : 'white' }};

    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      font-size: 8px;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1360px) {
      font-size: 10px;
    }
  }

  & > div svg {
     margin-bottom:4px;
  }
`;

export const StyledLogoutBtn = styled.div`
  padding: 10px 0;
  width: 100%;
  height: auto;
  cursor: pointer;
  background-color: ${(props) => props.isNewUi ? colors.primary : 'rgb(100, 197, 225)'};
  color: #fff;

  &>div {
    text-align: center;
    color: white;
    font-size: ${({ theme }) => get(theme, 'screens.laptop.sizes.xs')};
    font-weight: ${({ theme }) => get(theme, 'fontWeights.bold')};
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      font-size: 8px;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1360px) {
      font-size: 10px;
    }
  }
`;