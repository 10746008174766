// ANCHOR npm packages
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Iframe from 'Components/atoms/iframe';


// ANCHOR redux selectors
import { selectFileUrl } from 'Store/selectors/view.js';
// ANCHOR style
import { StyledModal } from './pdf.style';


const DisplayPdf = memo(({ visible, change, ...props }) => {
  const { t } = useTranslation();

  const url = useSelector(selectFileUrl);

  return (
    <StyledModal
      visible={visible}
      style={{ top: 20 }}
      onCancel={() => change('close')}
      title={props.header}
      className="pdf"
      width={window.innerWidth * 0.9}
      footer={props.footer}
      bodyStyle={{ padding: '10px 0px', textAlign: 'end' }}>
      <Iframe src={url} width="100%" title="pdf" height={window.innerHeight * 0.8} />
    </StyledModal>
  );
});

export default DisplayPdf;
