import React from 'react';

const SVG = ({ style = {}, fill = '#727272', width = '100%', height = '100%', onClick, className = '', viewBox = '0 0 40 26' }) => (
  <svg
    width={width}
    style={style}
    onClick={onClick}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M10.8336 5.00027C10.8336 5.46048 10.4605 5.83356 10.0003 5.83356C9.54007 5.83356 9.16699 5.46048 9.16699 5.00027C9.16699 4.54007 9.54007 4.16699 10.0003 4.16699C10.4605 4.16699 10.8336 4.54007 10.8336 5.00027Z"
      fill={fill}
    />
    <path
      d="M10.0833 16.3333C9.73828 16.3333 9.45828 16.0533 9.45828 15.7083V9.25H8.625C8.28 9.25 8 8.97 8 8.625C8 8.28 8.28 8 8.625 8H10.0833C10.4283 8 10.7083 8.28 10.7083 8.625V15.7083C10.7083 16.0533 10.4283 16.3333 10.0833 16.3333Z"
      fill={fill}
    />
    <path
      d="M10 20C4.48578 20 0 15.5142 0 10C0 4.48578 4.48578 0 10 0C15.5142 0 20 4.48578 20 10C20 15.5142 15.5142 20 10 20ZM10 1.25C5.17502 1.25 1.25 5.17502 1.25 10C1.25 14.825 5.17502 18.75 10 18.75C14.825 18.75 18.75 14.825 18.75 10C18.75 5.17502 14.825 1.25 10 1.25Z"
      fill={fill}
    />
    <path
      d="M11.875 16.25H8.125C7.78 16.25 7.5 15.97 7.5 15.625C7.5 15.28 7.78 15 8.125 15H11.875C12.22 15 12.5 15.28 12.5 15.625C12.5 15.97 12.22 16.25 11.875 16.25Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
