import { Modal as AntdModal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(AntdModal)`

  ${(props) => props?.isNewUi ? `
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-radius: 8px 8px 0px 0px;
  }
  ` : ''}

  .ant-modal-body {
    height: ${(props) => (props.height ? props.height : 'initial')};
    ${(props) => props.bodyStyle};

    &>div{
      height:100%;
    }
  }

  .ant-modal-title {
    color: ${(props) => props.color || 'black'};
    display: flex;
    align-items: center;
  }

  ${(props) => props?.isSignedModal ? `
  .ant-modal-footer {
    padding: 0px;
    border:none;
  }
  ` : ''}
`;
