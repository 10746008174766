import client from 'Services/api/client';

const ApiInfo = {
  async getRoutesByDocId(docId) {
    return await client(undefined, `/documents/get/routes/${docId}`);
  },
  async getRelatedDocs(docId) {
    return await client(undefined, `/documents/get/linkedDocuments/${docId}`)
  },
  async getFromDidox() {
    return await client(undefined, '/info/get/synchronization/details?isFromDidox=true');
  },
  async getCommisioner(tin) {
    return client(undefined, `/user/commissioner/${tin}`)
  },
  async getDocLimitInfo() {
    return client(undefined, '/user/get-doc-limit-count');
  }

}


export default ApiInfo;