import React, { useEffect, useState } from 'react';
import * as _ from '../../container/auth.style';
const ApprovePage = ({ register, getSMSagain, isEmail, loadingApprove }) => {
  const [seconds, setSeconds] = useState(60);

  const handleClick = () => {
    getSMSagain();
    setSeconds(60);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    if (seconds < 1) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [seconds]);

  return (
    <div id="approve_page">
      <_.Text esmw color={isEmail ? '#ff0000': '#000017'}>
        {isEmail ? 'На указанную почту отправлен код подтверждения' : 'На указанный номер отправлен код подтверждения'}
      </_.Text>
      <_.FormItem>
        <_.StyledInput
          type={'number'}
          name="approve"
          ref={register}
          placeholder={'Введите код подтверждения'}
          style={{ marginTop: '10px' }}
        />
      </_.FormItem>
      <_.AgainButton onClick={handleClick} disabled={seconds === 0 ? false : true}>
        {'Отправить ещё раз?'} {`(${seconds})`}
      </_.AgainButton>
      <_.FormItem>
        <_.StyledButton htmlType="submit" disabled={loadingApprove}>
          {'Подтвердить'}
        </_.StyledButton>
      </_.FormItem>
    </div>
  );
};

export default ApprovePage;
