import * as con from 'Store/constant';
import requestState from 'Services/api/status';
export const initialState = {
  status: 'initil',
  loading: {},
  success: {},
  error: {},
  massive: {
    error: [],
    success: [],
    loading: null
  },
  mode: 'one'
};

const sendReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case con.SEND_DOCUMENT:
      return {
        ...state,
        status: requestState.loading,
        massive: {
          ...state.massive,
          loading: payload.id
        },
        mode: payload.mode
      };
    case con.SEND_DOCUMENT_SUCCESS:
      return {
        ...state,
        success: payload,
        status: requestState.success,
        ...(
          state.mode === 'multiple' ? {
            massive: {
              ...state.massive,
              loading: null,
              success: [...state.massive.success, payload]
            }
          } : {}
        )
      };
    case con.SEND_DOCUMENT_FAIL:
      return {
        ...state,
        error: payload,
        status: requestState.error,
        ...(
          state.mode === 'multiple' ? {
            massive: {
              ...state.massive,
              loading: null,
              error: [...state.massive.error, payload]
            }
          } : {}
        )
      };
    case con.SEND_DOCUMENT_RESET:
      return {
        ...state,
        status: 'initial'
      };
    case con.SEND_DOCUMENT_REDUCER_CLEAR:
      return initialState
    default:
      return state;
  }
};

export default sendReducer;
