import React from 'react';

const TelegramSvg2 = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="currentColor" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8552 15.2561L19.9793 1.08072C20.132 0.387914 19.4119 -0.190979 18.7155 0.0592526L18.3845 0.180633L0.42221 6.8267C0.295298 6.86778 0.185159 6.95078 0.108918 7.06277C0.0326757 7.17477 -0.00539688 7.3095 0.000617197 7.44601C0.00663127 7.58252 0.0563952 7.71317 0.142174 7.81764C0.227952 7.92211 0.344938 7.99455 0.474944 8.0237L5.12464 9.33088L6.85941 14.5596C6.90943 14.6876 6.98911 14.8012 7.09154 14.8904C7.19397 14.9796 7.31605 15.0418 7.4472 15.0716C7.57834 15.1014 7.71459 15.0979 7.84412 15.0614C7.97364 15.0249 8.09254 14.9565 8.19049 14.8621L10.5926 12.5297L15.3096 15.8163C15.886 16.2216 16.708 15.9209 16.8552 15.2561ZM8.30697 10.0237L16.4226 3.21142C16.6262 3.03962 16.3898 2.74083 16.1589 2.87902L6.12487 8.78373C6.04067 8.8298 5.97489 8.90476 5.939 8.99554C5.90311 9.08632 5.89939 9.18715 5.92848 9.28046L6.61039 11.3626L7.27047 13.3981C7.27264 13.4228 7.2843 13.4457 7.30289 13.4616C7.32148 13.4776 7.34546 13.4853 7.36958 13.483C7.39369 13.4808 7.41596 13.4688 7.43147 13.4497C7.44699 13.4307 7.45449 13.406 7.45232 13.3813L7.68689 11.3458L7.77236 10.6212C7.78645 10.5123 7.84122 10.4132 7.9251 10.3449L8.30697 10.0237Z" fill="currentColor"/>
    </svg>
  )
}

export default TelegramSvg2;