import styled from 'styled-components';
import { colors } from 'Style/colors';
import BaseStyledFlex from 'Common/Style/styledFlex';
import BaseStyledFormItemWrapper from 'Common/Style/styledFormItemWrapper';

const StyledSearchContainer = styled(BaseStyledFlex)`
  position: relative;
  width: 5%;
  height: 100%;
  & > ${BaseStyledFlex} {
    height: 60%;
    background-color: white;
    border-radius: 4px;
    width: 100%;

    & > ${BaseStyledFlex} {
      &.field-container {
        flex: 8;
      }
      &.arrow-container {
        flex: 1;
        &:hover {
          cursor: pointer;
        }
      }

      &.zoom-container {
        flex: 1;
      
        background-color: ${colors.headerBackground};
        opacity: 0.8;
        &:hover {
          opacity: 0.4;
        }
        & > svg {
          font-size: 1.6rem;
          color: white;
        }
      }
    }
  }
`;

const StyledDropDownContainer = styled.div`
  position: absolute;
  /* bottom: 100%; */
  top: 70px;
  left: 0;
  width: 90%;
  overflow: auto;
  height: 0px;
  transition: height 1s;
  /* z-index:5; */
  &.visible {
    height: 400px;
  }
  background-color: white;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
`;

const StyledForm = styled.form`
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 10px;
`;

const StyledFormItemWrapper = styled(BaseStyledFormItemWrapper)`
  align-items: center;
  & > label {
    flex: 1;
    overflow:auto;
  }

  & > input,&>select {
    flex: 3;
  }

  &.date-container,
  &.sum-container {
    & > div {
      flex-basis: 48%;
    }
  }
`;

export { StyledDropDownContainer, StyledForm, StyledFormItemWrapper };

export default StyledSearchContainer;
