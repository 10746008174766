import styled from 'styled-components';
import BaseInput from 'Common/Style/styledInput';
import BaseTextarea from 'Common/Style/styledTextarea';
import BaseFlex from 'Common/Style/styledFlex';
import { Button } from 'antd';

export const margin = {
  marginRight: '10px'
};

export const StyledButton = styled(Button)`
  background: ${(props) => props.color};
  color: white;
`;

export const StyledBadge = styled.div`
  border-radius: 5px;
  color: white;
  padding: 0.2rem 1rem;
`;





export const StyledSigns = styled.div`
  text-align: left;
  .title {
    margin: 0.3rem 0;
    font-weight: 700;
  }

  .tooltip {
    position: relative;
    display: flex;
  }
  .tool {
    border-radius: 5px;
    content: 'Title some tile';
    position: absolute;
    background: #000c17;
    color: white;
    padding: 0.4rem 1rem;
    height: 2rem;
    display: block;
    right: 0;
    z-index: 12;
    opacity: 0;
    bottom: -32px;
    transition: 0.3s;
  }
  .tooltip:hover .tool {
    opacity: 1;
  }
  .ellipses {
    width: 100px;
    text-align: end;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .m-auto {
    margin: auto;
  }
  .d-flex {
    zoom: 0.8;
    flex-wrap: wrap;
    display: flex;
  }
  .sign-card > h3 {
    margin: 0;
    font-weight: 700;
    opacity: 0.7;
  }
  .reject {
    border-color: #f5222d !important;
    color: #f5222d !important;
    background: #f5222d26 !important;
  }
  .f-33 {
    width: 33%;
    margin-top: 1rem;
    padding-right: 1rem;
  }
  .sign-card {
    height: 100%;
    border: 6px solid #5b8c00;
    width: 100%;
    color: #5b8c00;
    text-align: center;
    border-radius: 17px;
    padding: 0.2rem 0;
    background: #5b8c0026;
  }
`;



