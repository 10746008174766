import 'Assets/css/app.css';
import 'react-virtualized/styles.css';
import ReactGA from 'react-ga';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import store, { persistor } from 'Store/store';
import Routes from './routes';
import i18n from './i18n';
import { BASE_URL } from 'Services/api';
import browserStorage from 'Services/browserStorage';
import axios from 'axios';
import { zoomPlugin } from 'Utils/zoom-plugin';

const getClientIpUrl = `${BASE_URL}/public/client-ip`;

const App = () => {

  useEffect(() => {
    // override getBoundingClientRect if current browser is chrome high version
    zoomPlugin();

    if (process.env.NODE_ENV === 'production') {
      window.ym(92324200, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
      });//Yandex metrica initilizer

      ReactGA.initialize('UA-166055219-1');//Google Analytics initilizer
    }

    axios.get(getClientIpUrl).then(res => {
      if (res?.data) {
        browserStorage.set('clientIP', res?.data)//save clientIp for e-imzo
      }
    })
  }, [])

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<h1>Loading...</h1>}>
          <Routes />
        </PersistGate>
      </Provider>
    </I18nextProvider>
  );
};

export default App;
