import styled from 'styled-components';
import get from 'lodash.get';
import Select from 'react-select';
import { colors } from 'Style/colors';
import shadows from 'Style/shadows';

function hideIndicatorSelectedItem() {
  return window.location.pathname.includes('create');
}

export const customStyles = {
  // control: (styles) => ({ ...styles, background: 'red', textAlign: 'center' }),
  valueContainer: (styles, state) => ({
    ...styles,
    border: 0,
    '&:focus': {
      outline: 0,
      boxShadow: shadows.elFocus
    }
  }),
  container: (styles, state) => ({
    ...styles,
    border: 0,
    '&:focus': {
      border: 'none',
      outline: 'none',
      boxShadow: shadows.elFocus
    }
  }),
  option: (provided, state) => ({
    ...provided,
    padding: 0,
    paddingLeft: 5,
    color: 'black',
    opacity: 0.8,
    height: '34px',
    display: 'flex',
    alignItems: 'center'
  }),
  input: (styles, state) => ({
    ...styles,
    border: 0,
    '&:hover': { cursor: 'initial' },
    // opacity:state.isDisabled?0.5:1,
    backgroudColor: state.isDisabled ? colors.disableColor : '#fff'
  }),
  placeholder: (styled) => ({
    ...styled,
    transform: 'translateY(-50%)',
    color: colors.placeHolderColor
  }),
  control: (styles, state) => ({
    ...styles,

    boxShadow: state.isFocused ? 0 : 0,
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    width: '100%',
    minWidth: 0,
    backgroundColor: colors.inputBgColor,
    backgroundImage: 'none',
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    transition: 'all 0.3s',
    opacity: state.isDisabled ? 0.5 : 1
    // height: '44px'
  }),
  dropdownIndicator: (styles, state) => {
    return {
      ...styles,
      opacity: state?.hasValue && hideIndicatorSelectedItem() ? '0' : '1'
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.2 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      '&:focus': {
        border: 'none',
        boxShadow: shadows.elFocus,
        outLine: 'none'
      },
      // fontFamily: 'Roboto',
      fontWeight: '400',
      opacity,
      transition,
      borderRadius: '3px',
      padding: '5px',
      transform: 'translateY(-46%)'
    };
  },
  noOptionsMessage: (styles, state) => {
    return styles;
  },
  multiValueRemove: (styles) => {
    return {
      ...styles,
      '&:hover': {
        backgroundColor: '#FFBDAD',
        color: '#DE350B'
      }
    };
  }
};

export const StyledLabel = styled.label`
  display: block;
  width: 100%;
  font-weight: 600;
  /* margin-bottom: 0.3125rem; */
  color: ${({ theme }) => get(theme, 'colors.textColor')};
  &.table-out-error-field {
    color: ${colors.warning};
  }
`;

export const StyledSelect = styled(Select)`
  && > div {
    &[class*='control'] {
      /* NOTE react select control style */
      display: flex !important;
      height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
      min-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};

      &:focus,
      &:hover {
        height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
        min-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
        display: flex;
      }
      & > div {
        /* NOTE react slect value container style */
        &:first-child {
          height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
          min-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
        }
        &:focus,
        &:hover {
          height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
          min-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
        }
      }
    }

    [class*='indicatorSeparator'] {
      height: calc(${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')} - 15px);
    }
    [class*='indicatorContainer'] {
      padding-top: 4px;
    }

    [class*='Input'] {
      box-shadow: none;
      margin: 0px;
    }
    [class*='noOptionsMessage'],
    [class*='menu'] {
      height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
      line-height: ${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')};
    }
    [class*='option'] {
      height: calc(${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')} - 10px);
      line-height: calc(${({ theme }) => get(theme, 'screens.laptop.formElements.mainHeight')} - 10px);
    }
    [class*='placeholder'] {
      font-size: ${({ theme }) => get(theme, 'screens.laptop.sizes.base')};
      line-height: ${({ theme }) => get(theme, 'screens.laptop.sizes.base')};
      top: 45%;
    }
    input:focus {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;
