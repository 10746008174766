import React from 'react';
import { StyledChoosingTariffsDiscount } from "./style";
import { BaseRadio } from "Common/Style/styledFormItemWrapper";
import BaseInput from 'Common/Style/styledInput';
import BaseLabel from 'Common/Style/styledLabel';

//TODO refactor
const ChoosingTariffsDiscount = ({ discountNum, setDiscountNum, name }) => {
  return (
    <StyledChoosingTariffsDiscount>
      <BaseRadio onClick={(e) => setDiscountNum(0)}>
        <BaseLabel><BaseInput type="radio" name={name} defaultChecked /> 1 месяц</BaseLabel>
        <div style={{ display: "flex", flexDirection: 'row', height: '20px' }}><hr /></div>
      </BaseRadio>
      <BaseRadio onClick={(e) => setDiscountNum(10)}>
        <BaseLabel><BaseInput type="radio" name={name} /> 6 месяц</BaseLabel>
        <div style={{ display: "flex", flexDirection: 'row', height: '20px' }}><hr /></div>
      </BaseRadio>
      <BaseRadio onClick={(e) => setDiscountNum(20)}>
        <BaseLabel><BaseInput type="radio" name={name} />12 месяц</BaseLabel>
      </BaseRadio>
    </StyledChoosingTariffsDiscount>
  );
};

export default ChoosingTariffsDiscount;