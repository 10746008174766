import { colors } from "Style/colors";
import React from "react";

export const CopyClipboard = (props) => (
    <svg
        id="Group_27733"
        data-name="Group 27733"
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={13}
        viewBox="0 0 230.451 235.429"
        {...props}
    >
        <path
            id="Path_25"
            data-name="Path 25"
            d="M1101.887,252.759h115.407a28.247,28.247,0,0,1,28.212,28.216v115.4a28.247,28.247,0,0,1-28.212,28.216H1101.887a28.247,28.247,0,0,1-28.211-28.216v-115.4A28.247,28.247,0,0,1,1101.887,252.759ZM1217.294,410.19a13.826,13.826,0,0,0,13.808-13.812v-115.4a13.826,13.826,0,0,0-13.808-13.812H1101.887a13.826,13.826,0,0,0-13.807,13.813v115.4a13.826,13.826,0,0,0,13.807,13.813Z"
            transform="translate(-1015.055 -189.165)"
            fill={props.fill ? props.fill : "#001529"}
        />
        <path
            id="Path_26"
            data-name="Path 26"
            d="M1133.962,266.005h89.5a34.289,34.289,0,0,1,34.255,34.251v3.072a7.2,7.2,0,1,1-14.4,0v-3.072a19.875,19.875,0,0,0-19.853-19.848h-89.5a33.716,33.716,0,0,0-33.674,33.674v89.506a19.873,19.873,0,0,0,19.848,19.848,7.2,7.2,0,0,1,0,14.4,34.291,34.291,0,0,1-34.252-34.251V314.083A48.131,48.131,0,0,1,1133.962,266.005Z"
            transform="translate(-1085.885 -266.005)"
            fill={props.fill ? props.fill : "#001529"}
        />
    </svg>
);

export const CopyClipboardFill = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1000 1000"
        style={{
            enableBackground: "new 0 0 1000 1000",
        }}
        xmlSpace="preserve"
        {...props}
        width={17}
        height={17}
    >
        <style type="text/css">{"\n\t.st0{fill:white;}\n"}</style>
        <path
            className="st0"
            d="M388.6,830.7c-56.8,0-113.7,0.1-170.5,0c-35.2-0.1-62.4-18.4-72.5-49.2c-2.4-7.5-3.8-15.6-3.8-23.4 c-0.2-107.2-0.3-214.4-0.1-321.6c0.1-40.1,30-71,70.1-73c5.5-0.3,11-0.1,16.4-0.1c110.7,0,221.3,0,332,0c37.4,0,65.9,21.5,74,56.1 c1.6,6.7,2.1,13.8,2.1,20.8c0.1,104.5,0.1,208.9,0.1,313.4c0,46.8-30.4,77.1-77.3,77.2C502.3,830.7,445.5,830.7,388.6,830.7 L388.6,830.7z"
        />
        <path
            className="st0"
            d="M365.1,315.3c0-17.1-0.2-33.2,0.1-49.4c0.2-11.4-0.3-23.1,1.9-34.2c7.9-39.2,40.1-63.9,82.3-64 c100-0.1,199.9-0.1,299.9,0c11.5,0,23-0.3,34.4,0.4c43.8,2.7,75.8,36.6,76,80.6c0.2,57.9,0,115.7,0,173.5c0,43.1,0,86.3,0,129.4 c-0.1,48.4-34.3,83.1-82.6,83.4c-28.7,0.2-57.3,0-86,0c-1.9,0-3.9-0.2-6.7-0.4c0-3.3,0-6.2,0-9.1c0-62.8,0.2-125.7,0-188.5 c-0.2-56.8-36.8-104.5-91-117.9c-10.5-2.6-21.7-3.7-32.5-3.7c-61.8-0.3-123.6-0.2-185.5-0.2C372.3,315.3,369.3,315.3,365.1,315.3 L365.1,315.3z"
        />
    </svg>
);


// export const CopyClipboardBlack = (props) => (
//     <svg
//         fill="#c4c4c4"
//         xmlns="http://www.w3.org/2000/svg"
//         width={props.width ? props.width : "18px"}
//         height={props.height ? props.height : "18px"}
//         viewBox="0 0 52 52"
//         enableBackground="new 0 0 52 52"
//         xmlSpace="preserve"
//         {...props}
//     >
//         <g>
//             <path d="M17.4,11.6h17.3c0.9,0,1.6-0.7,1.6-1.6V6.8c0-2.6-2.1-4.8-4.7-4.8h-11c-2.6,0-4.7,2.2-4.7,4.8V10 C15.8,10.9,16.5,11.6,17.4,11.6z" />
//             <path d="M43.3,6h-1.6c-0.5,0-0.8,0.3-0.8,0.8V10c0,3.5-2.8,6.4-6.3,6.4H17.4c-3.5,0-6.3-2.9-6.3-6.4V6.8 c0-0.5-0.3-0.8-0.8-0.8H8.7C6.1,6,4,8.2,4,10.8v34.4C4,47.8,6.1,50,8.7,50h34.6c2.6,0,4.7-2.2,4.7-4.8V10.8C48,8.2,45.9,6,43.3,6z" />
//         </g>
//     </svg>
// );


export const CopyClipboardBlack = (props) => (
    <svg
        fill="#515151"
        width="18px"
        height="18px"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>{"ionicons-v5-e"}</title>
        <path d="M456,480H136a24,24,0,0,1-24-24V128a16,16,0,0,1,16-16H456a24,24,0,0,1,24,24V456A24,24,0,0,1,456,480Z" />
        <path d="M112,80H400V56a24,24,0,0,0-24-24H60A28,28,0,0,0,32,60V376a24,24,0,0,0,24,24H80V112A32,32,0,0,1,112,80Z" />
    </svg>
);


export const SuccessfullBlack = (props) => (
    <svg
        fill="#515151"
        xmlns="http://www.w3.org/2000/svg"
        width="18px"
        height="18px"
        viewBox="0 0 52 52"
        enableBackground="new 0 0 52 52"
        xmlSpace="preserve"
        {...props}
    >
        <g>
            <path d="M19.1,42.5L2.6,25.9c-0.6-0.6-0.6-1.6,0-2.2l2.2-2.2c0.6-0.6,1.6-0.6,2.2,0L19.4,34c0.4,0.4,1.1,0.4,1.5,0 L45.2,9.5c0.6-0.6,1.6-0.6,2.2,0l2.2,2.2c0.6,0.6,0.6,1.6,0,2.2L21.3,42.5C20.7,43.2,19.7,43.2,19.1,42.5z" />
        </g>
    </svg>
);

export const Successfull = (props) => (
    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fill={props.fill ? props.fill : "#001529"}
            d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z"
        />
    </svg>
);

export const SuccessfullWhite = (props) => (
    <svg
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        width={17}
        height={17}
    >
        <path
            fill="#FFFFFF"
            d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z"
        />
    </svg>
);