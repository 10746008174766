import React, { useState } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { IconContext } from 'react-icons';

//
import { StyledAdvertisment, StyledToggle } from './advertisment.style';

const AdvertismentComponent = (props) => {
  const { onHide, width } = props;

  const [statusMouse, setStatusMouse] = useState('out');

  const onAdvertisingBlockIconEnterHandler = () => {
    setStatusMouse('in');
  };

  const onAdvertisingBlockIconLeaveHandler = () => {
    setStatusMouse('out');
  };

  return (
    <IconContext.Provider value={{ style: { color: '#1d2228', fill: '#1d2228' } }}>
      <StyledAdvertisment className={`advertisment ${statusMouse}`} width={width}>
        advertiesms
        {parseInt(width) > 0 ? (
          <StyledToggle
            className={statusMouse}
            onClick={onHide}
            id="toggle"
            right={width}
            onMouseEnter={onAdvertisingBlockIconEnterHandler}
            onMouseLeave={onAdvertisingBlockIconLeaveHandler}>
            <BsChevronRight />
          </StyledToggle>
        ) : (
          <></>
        )}
      </StyledAdvertisment>
    </IconContext.Provider>
  );
};

export default AdvertismentComponent;
