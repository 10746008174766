import PropTypes from 'prop-types';

export const TypeLogin={
    CN:PropTypes.string,
    O:PropTypes.string,
    T:PropTypes.string,
    TIN:PropTypes.string,
    UID:PropTypes.string,
    alias:PropTypes.string,
    disk:PropTypes.string,
    expired:PropTypes.bool,
    name:PropTypes.string,
    path:PropTypes.string,
    serialNumber:PropTypes.string,
    type:PropTypes.string,
    validFrom:PropTypes.instanceOf(Date),
    validTo:PropTypes.instanceOf(Date)
}