import styled, { css } from 'styled-components';
import get from 'lodash.get';
import { Button } from 'antd';
import { colors } from 'Style/colors';



export const StyledButton = styled(Button)`
  font-weight: 500;
  display: inline-block;
  font-size: ${({ theme }) => get(theme, 'screens.laptop.sizes.base')};
  height: ${({ theme }) => get(theme, 'screens.laptop.formElements.height')};
  background: ${(props) => ((props.isNewUi) ? colors.evenRowBgColor : props.color === 'red' ? props.color : 'rgb(100, 197, 225)')};
  color: ${(props) => ((props.color && props.isNewUi) ? props.color : colors.white)};

  border: ${(props) => ((props.color && props?.isNewUi) ? `1.5px solid ${props.color}` : props.color === 'red' ? `1.5px solid red` : `1.5px solid rgb(100, 197, 225)`)};

  border-radius: ${(props) => props?.isNewUi ? '8px' : '0px'};
  transition: box-shadow 0.3s;

  & > span {
    & > svg {
      color: ${(props) => ((props.isNewUi && props.color) ? props.color : 'white')} !important;
      font-size: 1rem !important;
       
      & > path {
        color: ${(props) => ((props.isNewUi && props.color) ? props.color : 'white')} !important;
        fill: ${(props) => ((props.isNewUi && props.color) ? props.color : 'white')}  !important;
      }
    }
  }
  &:hover, &:active, &:focus {
    background-color: ${(props) => ((props.isNewUi && props.color) ? props.color : 'white')};
    box-shadow: ${(props) => props.shadow};
    color: ${props => (props.isNewUi && props.color) ? colors.white : (props.color === 'red') ? 'red' : 'rgb(100, 197, 225)'};
    border-color: ${(props) => (props.isNewUi && props.color) ? props.color : (props.color === 'red') ? 'red' : 'rgb(100, 197, 225)'};

    & > span {
      & > svg {
        color: white !important;
        fill: white  !important;
        & > path {
          color: white !important;
          fill: white  !important;
        }

        & > path {
          color: ${(props) => ((props.isNewUi && props.color) ? 'white' : 'rgb(100, 197, 225)')} !important;
          fill: ${(props) => ((props.isNewUi && props.color) ? 'white' : 'rgb(100, 197, 225)')}  !important;
        }
      }
    }
  }

  ${props => props.responsivePad && css`
    @media (max-width: 1350.98px) {
      padding: 2px 8px;
    }
  `}
`;
