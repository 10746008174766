// ANCHOR npm packages
import React from 'react';
import { useSelector } from 'react-redux';
import omit from 'lodash.omit';
// import {Button} from 'antd';

// ANCHOR style
import { colors } from 'Style/colors';
import shadows from 'Style/shadows';
import { StyledButton } from './button.style';

export const ButtonComponent = ({ disabled, color = colors.primary, shadow = shadows.boxShadow, loading = false, ...props }) => {
  const { children, ...rest } = props;

  const isNewUi = useSelector((state) => state?.ui?.isNewUi)

  return (
    <StyledButton isNewUi={isNewUi} disabled={disabled} {...omit(rest, ['icon'])} color={color} shadow={shadow} loading={loading}>
      {rest.icon && <span style={{ verticalAlign: 'middle' }}>{rest.icon}</span>}
      {children}
    </StyledButton>
  );
};
