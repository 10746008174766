import * as con from 'Store/constant';
import requestState from 'Services/api/status';
const initialState = {
  status: 'initial',
  signFail: {},
  signData: { message: '', id: null },
  massive: {
    success: [],
    error: [],
    loading: null
  },
  mode: 'one',
  createdDocId: null
};

const signReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case con.SIGN_DOCUMENT:
      return {
        ...state,
        status: requestState.loading,
        massive: {
          ...state.massive,
          loading: payload?.id
        },
        mode: payload?.mode || 'one'
      };
    case con.SIGN_DOCUMENT_SUCCESS:
      const { id, message } = payload;
      return {
        ...state,
        signData: { message, id },
        status: requestState.success,
        ...(
          state.mode === 'multiple' ? {
            massive: {
              ...state.massive,
              loading:null,
              success: [...state.massive.success, payload]
            }
          } : {}
        )
      };
    case con.SIGN_DOCUMENT_FAIL:
      return {
        ...state,
        signFail: payload,
        status: requestState.error,
        ...(
          state.mode === 'multiple' ? {
            massive: {
              ...state.massive,
              loading:null,
              error: [...state.massive.error, payload]
            }
          } : {}
        )
      };
    case con.CANCEL_ACTION:
      return {
        ...state,
        status: 'initial'
      };
    case con.CREATED_DOC_ID:
      return {
        ...state,
        createdDocId: payload.id
      }
    case con.SET_INITIAL_SITUATION_SIGN:
      return initialState;
    default:
      return state;
  }
};
export default signReducer;
