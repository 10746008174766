//All colors must be saved here!!!

export const colors = {
  primary: '#2977FF',
  primaryBlurred: '#ccf1ff',
  error: '#ff7875',
  warning: '#FF8A00',
  success: '#5b8c00',
  white: '#FFF',
  blocked: '#495057',

  headerBackground: '#001529',
  layoutBackground: '#FFFFFF',
  menuItemBackground: '#E7F8E7',
  settingTableHeaderColor: '#7A7B8F',
  settingTableBorderColor: '#d9d9d9',

  badgeColor: '#fafafa',
  disableColor: '#C4C4C4',
  stampColor: 'blue',
  evenRowBgColor: '#f7f7f7',
  oddRowBgColor: '#FFFFFF',
  textColor: '#000017',

  borderColor: '#d9d9d9',
  borderFilterColor: '#d9d9d9',

  dropDownBgColor: '#FFFFFF',
  dropDownHoverBg: '#E7F8E7',
  dropDownShadow: '0px 6px 12px rgba(0, 0, 0, 0.24), 0px 0px 12px rgba(0, 0, 0, 0.12)',
  backward: '#F84E4E',
  urgent: '#932525',
  backwardHoverBg: '#fceded',

  cardBgColor: '#fdfdfd',
  cardShadow: '0px 2px 2px rgba(0, 0, 0, 0.1), 0px 0px 5px rgba(0, 0, 0, 0.1)',
  filterCardShadow: '0px 24px 24px rgba(0, 0, 0, 0.24), 0px 0px 24px rgba(0, 0, 0, 0.12)',

  iconColor: '#7A7B8F',
  iconHoverColor: '#A7ECEE',

  sideBarIconColor: '#7A7B8F',
  sideBarColor: '#fdfdfd',
  sideBarActiveItemBg: '#D9F4D9',

  inputBgColor: '#fdfdfd',
  inputTextColor: '#000017',
  inputBorderColor: '#d9d9d9',
  labelColor: '#7A7B8F',
  placeHolderColor: '#9D9DB2',
  tableBgColor: '#F2F3F4',//minor 
  antdTableHover: '#e5e5e5',


  deadlineState: {
    warn: 'yellow',
    danger: 'red'
  }
};

