import { v4 as uuidv4 } from 'uuid';
import * as con from 'Store/constant';

const InterfaceToast = {
  'title?': '',
  
  id: 'g87hiu87ghohf65f7yu',
  text: 'message',
  type: 'error/info/success',
  extraState: null
};

const initialState = {
  list: [
    // {id: 1, title: 'POSSIBLE MODAL TYPES', type: 'success', text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi, ab?'},
    // {id: 2, type: 'error', text: 'Lorem ipsumte odit eveniet voluptates eius qui! Enim aliquam reprehenderit tenetur dolorum harum. Totam facere repellat sapiente quo voluptates provident aliquid laborum laudantium expedita debitis? Atque cum eaque, nostrum sequi voluptates iste eos placeat natus nesciunt nobis, ea doloremque animi sint consequatur mollitia. Saepe ipsam, tempora aut atque placeat dolorem.'},
    // {id: 7, type: 'error', text: 'my.soliq.uz {"ErrorMessage":"custom soliq error message asdasdasdasdas asd ad asd as"}'},
    // {id: 3, type: '', text: 'Lorem ipsum facere repellat sapiente quo voluptates provident aliquid laborum laudantium expedita debitis? Atque cum eaque, nostrum sequi voluptates iste eos placeat natus nesciunt nobis, ea doloremque animi sint consequatur mollitia. Saepe ipsam, tempora aut atque placeat dolorem.'},
    // {id: 4, type: 'secondary', text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum iusto, vitae in corrupti facilis labore dolore cupiditate odit eveniet voluptates eius qui! Enim aliquam reprehenderit tenetur dolorum harum. Totam facere repellat sapiente quo voluptates provident aliquid laborum laudantium expedita debitis? Atque cum eaque, nostrum sequi voluptates iste eos placeat natus nesciunt nobis, ea doloremque animi sint consequatur mollitia. Saepe ipsam, tempora aut atque placeat dolorem.'},
    // {id: 5, type: 'error', text: 'Lorem ipsum dolor sates iste eos placeat natus nesciunt nobis, ea doloremque animi sint consequatur mollitia. Saepe ipsam, tempora aut atque placeat dolorem.'},
  ]
};


const notificationReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case con.ADD_NOTIFICATION: {
      const obj = {id: uuidv4(), ...payload};
      return { ...state, list: [...state.list, obj] };
    }
    case con.REMOVE_NOTIFICATION: {
      return { ...state, list: state.list.filter(i => i.id !== payload) };
    }
    default: {
      return state;
    }
  }
}

export default notificationReducer;