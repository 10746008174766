import client from 'Services/api/client';

const ApiLogin = {
  async getSMS(number, changePass = false) {
    return await client('GET', `/auth/verify?emailOrPhone=${number}&changePass=${changePass}`);
  },
  async getApprove(code) {
    return await client('GET', `/auth/convert/${code}`);
  },
  async createUser(body) {
    return await client('POST', '/auth/register', body);
  },
  async changeUser(body) {
    return await client('POST', '/auth/change/password', body);
  }
};

export default ApiLogin;
