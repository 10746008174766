const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const convertFileToBase64 = async (filePath) => {
  try {
    return await toBase64(filePath);
  } catch (err) {
    console.error(err);
  }
};


export default convertFileToBase64;