import { isTaxHandler } from './docTypes';
import sort from './sort';

export const onGenerateMenuItems = (list, active = 2, part) => {
  if (active === 1) return list;

  if (active === 5) {
    let newList = [];

    list = list.sort((a,b)=>sort(a,b,'name'));

    newList = list.filter((_item) => isTaxHandler(_item.type)) || [];

    list= list.filter(_item=> !isTaxHandler(_item.type) ) || [];

    list=[...newList,...list];

    return list;
  }

  if (active === 1 || active === 5) return list;

  return list.filter((item) => item.part === part) || [];
};
