const { default: styled } = require("styled-components");

export function getBaseLineClampElement (element) {
  return styled[element]`
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.maxLineClamp || 99999};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}