import store from 'Store/store';
import ApiClientProducts from 'Services/api/controller/clientProducts';
import * as con from 'Store/constant';
import { getRequestBodyForClientProduct } from '..';

export function onFetchClientProducts() {
  return async (dispatch) => {
    // TODO - tovar soni ko'payib ketganda poisk limit qo'yib poiskdan tortib keladigan bo'ladi,
    //   hozir selectda default limit qo'yaman, yozib poisk qilganda umumiydan qidirib default list yangilanaveradi
    const { data, fail, message } = await ApiClientProducts.getAllProducts({ page: 1, limit: 99999 });
    if (!fail) {
      dispatch({ type: con.SET_CLIENT_PRODUCTS, payload: data.data.clientProductsDTOS });
      return;
    }

    dispatch({ type: con.FAIL_CLIENT_PRODUCTS, payload: message });
  };
}

export function addOneProductToList(product) {
  store.dispatch({
    type: con.ADD_ONE_CLIENT_PRODUCT,
    payload: product
  });
}

export function openAddClientProductDrawer(uniqueTrId) {
  return {
    type: con.OPEN_DRAWER_CLIENT_PRODUCTS,
    payload: { uniqueTrId }
  };
}

export function openCreateContractDrawer() {
  return {
    type: con.OPEN_CREATE_CONTRACT_DRAWER
  };
}

export function closeCreateContractDrawer() {
  return {
    type: con.CLOSE_CREATE_CONTRACT_DRAWER
  };
}

export function openEditClientProductDrawer(product) {
  return {
    type: con.OPEN_DRAWER_CLIENT_PRODUCTS,
    payload: product
  };
}

export function onSubmitAddProduct(values) {
  return async (dispatch) => {
    dispatch({ type: con.START_DRAWER_CLIENT_PRODUCTS });
    const formdata = getRequestBodyForClientProduct(values);

    const { data, fail, message } = await ApiClientProducts.addProduct(formdata);
    if (!fail) {
      dispatch({ type: con.SUCCESS_DRAWER_CLIENT_PRODUCTS, payload: { data: data.data, message: message } });
      return;
    }

    dispatch({ type: con.FAIL_DRAWER_CLIENT_PRODUCTS, payload: message });
  };
}

export function onSubmitEditProduct(values, id) {
  return async (dispatch) => {
    dispatch({ type: con.START_DRAWER_CLIENT_PRODUCTS });
    const formdata = getRequestBodyForClientProduct(values);
    formdata.id = id;

    const { data, fail, message } = await ApiClientProducts.updateProduct(formdata);
    if (!fail) {
      dispatch({ type: con.SUCCESS_DRAWER_CLIENT_PRODUCTS, payload: { data: data.data, message: message } });
      return;
    }

    dispatch({ type: con.FAIL_DRAWER_CLIENT_PRODUCTS, payload: message });
  };
}

// ------------------------- TABLE MODAL -----------------------------
export function openClientProductModal(uniqueTrId) {
  return {
    type: con.OPEN_CLIENT_PRODUCTS_MODAL,
    payload: { uniqueTrId }
  };
}

export function closeClientProductModal() {
  return { type: con.CLOSE_CLIENT_PRODUCTS_MODAL };
}

export function forceSetClientProducts(type, data) {
  return { type: con.FORCE_SET_CLIENT_PRODUCT_LIST, payload: { type, data } };
}

export function selectClientProductFromTable(data) {
  return { type: con.SET_CLIENT_PRODUCT_FROM_TABLE, payload: data };
}
