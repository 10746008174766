import React from 'react';

const SVG = ({ style = {}, fill = '#000', width = '100%', onClick, className = '', viewBox = '0 0 40 26' }) => (
  <svg
    width={width}
    style={style}
    onClick={onClick}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M47.8215 10.7576L46.6094 10.3788C46.3063 10.303 46.0033 10.2273 45.6246 10.2273C44.2609 10.2273 43.0488 11.1363 42.5942 12.4242L41.9125 14.4696V12.9545V12.8788C41.9125 12.6515 41.7609 12.4242 41.6094 12.2727L29.1852 0.227291C29.0337 0.0757635 28.8064 0 28.6549 0H4.33667C3.88209 0 3.57914 0.303054 3.57914 0.757535V3.106H0.776089C0.321508 3.106 0.0185547 3.40906 0.0185547 3.86354V49.2424C0.0185547 49.6969 0.321609 49.9999 0.776089 49.9999H37.5942C38.0488 49.9999 38.3518 49.6968 38.3518 49.2424V46.8939H41.1548C41.6094 46.8939 41.9124 46.5908 41.9124 46.1364V35.9091L44.0335 33.0303L44.1093 32.803L49.7912 14.6969C50.3973 13.0303 49.4882 11.2879 47.8215 10.7576ZM29.4124 2.57576L39.3366 12.1213H29.4124V2.57576ZM36.8367 48.4848H1.53362V4.62117H3.57904V46.1364C3.57904 46.5909 3.88209 46.8939 4.33657 46.8939H36.8366V48.4848H36.8367ZM40.4731 45.3788H40.3973H5.09421V1.51517H27.8972V12.8788C27.8972 13.3334 28.2003 13.6363 28.6547 13.6363H40.473V19.3182L36.9124 30.5303V30.7576L37.0639 40.3788C37.0639 40.6819 37.2912 40.9849 37.5942 41.0606C37.8973 41.1364 38.276 41.0606 38.4275 40.7576L40.473 38.0303V45.3788H40.4731ZM38.5033 38.0303L38.3518 31.7424L42.1396 32.9545L38.5033 38.0303ZM48.4276 14.2424L42.973 31.591L38.5791 30.1516L44.0336 12.8788C44.3367 12.0455 45.2458 11.5151 46.079 11.8182L47.2912 12.197C48.2003 12.4242 48.7306 13.4091 48.4276 14.2424Z"
      fill="#62A7DC"
    />
    <path
      d="M10.2458 13.6364H21.155C21.6095 13.6364 21.9125 13.3333 21.9125 12.8789V5.15158C21.9125 4.697 21.6094 4.39404 21.155 4.39404H10.2458C9.79123 4.39404 9.48828 4.6971 9.48828 5.15158V12.8789C9.48828 13.2577 9.8671 13.6364 10.2458 13.6364ZM11.0035 5.90911H20.3974V12.1212H11.0035V5.90911Z"
      fill="#62A7DC"
    />
    <path
      d="M8.73214 20.7573H31.7625C32.217 20.7573 32.52 20.4542 32.52 19.9997C32.52 19.5451 32.2169 19.2422 31.7625 19.2422H8.73214C8.27756 19.2422 7.97461 19.5452 7.97461 19.9997C7.97451 20.4542 8.27756 20.7573 8.73214 20.7573Z"
      fill="#62A7DC"
    />
    <path
      d="M8.73214 26.288H31.7625C32.217 26.288 32.52 25.985 32.52 25.5305C32.52 25.076 32.2169 24.7729 31.7625 24.7729H8.73214C8.27756 24.7729 7.97461 25.076 7.97461 25.5305C7.97461 25.985 8.27756 26.288 8.73214 26.288Z"
      fill="#62A7DC"
    />
    <path
      d="M8.73214 31.8183H31.7625C32.217 31.8183 32.52 31.5152 32.52 31.0608C32.52 30.6063 32.2169 30.3032 31.7625 30.3032H8.73214C8.27756 30.3032 7.97461 30.6063 7.97461 31.0608C7.97461 31.5152 8.27756 31.8183 8.73214 31.8183Z"
      fill="#62A7DC"
    />
    <path
      d="M32.4442 36.591C32.4442 36.1365 32.1412 35.8335 31.6867 35.8335H8.73214C8.27756 35.8335 7.97461 36.1366 7.97461 36.591C7.97461 37.0455 8.27766 37.3486 8.73214 37.3486H31.7624C32.1412 37.3486 32.4442 36.9698 32.4442 36.591Z"
      fill="#62A7DC"
    />
    <path
      d="M34.943 41.3638H23.5036C23.049 41.3638 22.7461 41.6668 22.7461 42.1213C22.7461 42.5758 23.0491 42.8788 23.5036 42.8788H34.8672C35.3218 42.8788 35.6248 42.5758 35.6248 42.1213C35.6248 41.6668 35.3218 41.3638 34.943 41.3638Z"
      fill="#62A7DC"
    />
  </svg>
);

export default SVG;
