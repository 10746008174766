import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AutoSizer, Column, Table } from 'react-virtualized';
import styled from 'styled-components';

import BeatLoader from 'react-spinners/BeatLoader';

// ANCHOR custom components;
import Loader from 'Components/atoms/spinner/loading';

import VirtualizeTableRow from 'Components/atoms/virtualizeTableRow';

import BaseStyledFlex from 'Common/Style/styledFlex';
import { colors } from 'Style/colors';

const StyledStatusContainer = styled(BaseStyledFlex)`
  background-color: white;
  text-align: center;
`;

const StyledTable = styled(Table)`
  &.loading{
    opacity: 0.6;
  }

  .table-img-item {
    width: 100%;
    overflow-x: auto !important;
    overflow-y: hidden !important;

    display: flex;
    justify-content: center;
    gap: 5px;

    &>* {
      flex-shrink: 0;
    }

    img {
      object-fit: cover;
      object-position: center;
    }
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    border:1.5px solid ${colors.settingTableBorderColor}
  }
`;

const VirtualTableCom = (props) => {
  const { t } = useTranslation(['common']);
  const isNewUi = useSelector((state) => state?.ui?.isNewUi)

  const { height, rowHeight = 43, headerHeight = 40, data, columns, onDoubleClickRow = () => { }, ...rest } = props;

  function _renderLoader() {
    return <Loader Component={BeatLoader} size={15} style={{ width: '100%' }} height="40px" />;
  }

  function _renderInitialRow(params) {

    const { rowData } = params;
    const statusContainerClassName = `${params.className} justify-content-center`;

    const statusText = rowData?.isEnd ? t('end') : rowData?.isLoading ? _renderLoader() : rowData?.isError ? 'Error' : '';

    if (!(rowData.hasOwnProperty('isEnd') || rowData.hasOwnProperty('isLoading') || rowData.hasOwnProperty('isError'))) {
      return <VirtualizeTableRow isEven={isNewUi && (params?.index % 2) !== 1} onDoubleClick={onDoubleClickRow} cellValue={props.renderCell} {...params} />;
    } else {
      return (
        <StyledStatusContainer className={statusContainerClassName} style={params.style}>
          {statusText}
        </StyledStatusContainer>
      );
    }
  }

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <StyledTable
          {...rest}
          rowHeight={rowHeight}
          height={height}
          width={width}
          headerHeight={headerHeight}
          rowCount={data.length}
          rowGetter={({ index }) => data[index]}
          rowRenderer={props.renderRow || _renderInitialRow}
          headerRowRenderer={props.renderHeader}>
          {columns.map((column, index) => {
            return <Column {...column} key={index} width={width * column.width} />;
          })}
        </StyledTable>
      )}
    </AutoSizer>
  );
};

export default VirtualTableCom;
