import { colors } from 'Style/colors';
import styled from 'styled-components';

export const StyledSubSideBar = styled.div`

 ${(props) => props?.isNewUi ? ' border-right: 2px solid ' + colors.inputBorderColor : ''};  

  &::-webkit-scrollbar {
    display: none;
  }

  .zoom-09{
    zoom: 0.9;
  }

  // &.visible {
  //   width: ${(props) => (props.active === 0 ? 0 : '15%')};
  //   visibility: ${(props) => (props.active === 0 ? 'hidden' : 'visible')};
  //   opacity: 1;
  // }
  // &.hide {
  //   width: 0;
  //   visibility: hidden;
  //   opacity: 0;
  // }

  position:absolute;
  top:0px;
  bottom:0px;
  left:5.8%;
  z-index: 200;
  background-color:#FFFFFF;
  
  &.visible {
    width:17%;
    visibility: visible;
    opacity: 1;
  }

  &.hide {
    width: 0;
    visibility: hidden;
    opacity: 0;
  }

 
 



  transition: all 450ms;
  will-change: width, visibility;
  -webkit-transition: all 450ms;
  overflow: auto;
  padding-bottom:30px;
`;
