import React from 'react';

const SVG = ({ style = {}, fill = '#fff', width = '100%', onClick, className = '', viewBox = '0 0 40 26' }) => (
  <svg
    width={width}
    style={style}
    onClick={onClick}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M14.5714 27H9.42857C8.22857 27 6.85714 26.1474 6.85714 24.4421V21.4579C6.85714 20.0368 6 18.7579 4.62857 17.7632C1.54286 15.7737 0 12.9316 0 9.80526C0.171429 4.40526 5.48571 0.142105 11.8286 0C15.0857 0 18.1714 0.994737 20.4 2.84211C22.6286 4.68947 24 7.24737 24 9.94737C24 12.9316 22.4571 15.7737 19.5429 17.6211C18 18.6158 17.1429 20.1789 17.1429 21.6V24.8684C17.1429 26.0053 15.9429 27 14.5714 27ZM12 1.42105C6.51429 1.42105 1.88571 5.25789 1.71429 9.80526C1.71429 12.5053 3.08571 15.0632 5.65714 16.6263C7.54286 17.9053 8.57143 19.6105 8.57143 21.4579V24.4421C8.57143 24.7263 8.57143 25.5789 9.42857 25.5789H14.5714C15.0857 25.5789 15.4286 25.2947 15.4286 24.8684V21.6C15.4286 19.7526 16.6286 17.7632 18.5143 16.4842C20.9143 14.9211 22.2857 12.5053 22.2857 9.94737C22.2857 7.67368 21.2571 5.5421 19.2 3.83684C17.3143 2.27368 14.7429 1.42105 12 1.42105Z"
      fill="white"
    />
    <path
      d="M16.1949 22.9501H8.05208C7.56351 22.9501 7.23779 22.6801 7.23779 22.2751C7.23779 21.8701 7.56351 21.6001 8.05208 21.6001H16.1949C16.6835 21.6001 17.0092 21.8701 17.0092 22.2751C17.0092 22.6801 16.6835 22.9501 16.1949 22.9501Z"
      fill="white"
    />
    <path
      d="M18.571 10.658C18.0567 10.658 17.7138 10.3738 17.7138 9.94746C17.7138 7.24747 14.971 4.97379 11.7138 4.97379C11.1995 4.97379 10.8567 4.68958 10.8567 4.26326C10.8567 3.83694 11.1995 3.55273 11.7138 3.55273C15.9995 3.55273 19.4281 6.39484 19.4281 9.94746C19.4281 10.3738 19.0852 10.658 18.571 10.658Z"
      fill="white"
    />
  </svg>
);

export default SVG;
