import React from 'react';

const getStep = (activeIndex, doctype, t) => {

  // activeIndex = 0
  const home = [
    // { content: 'Хотите ознакомиться с сайтом?', target: 'body', placement: 'center' },
    {
      target: '.hints-hint',
      content: t('home.hint'),
      placement: 'auto',
      disableBeacon: true
    },
    {
      target: '.branch-hint',
      // title: t('menuTitle'),
      content: t('home.branch'),
      placement: 'auto',
      disableBeacon: true,
    },
    {
      target: '.support-hint',
      title: t('home.supportTitle'),
      content: t('home.support'),
      placement: 'auto',
      disableBeacon: true
    },
    {
      target: '.wallet-hint',
      title: t('home.walletTitle'),
      content: t('home.wallet'),
      placement: 'auto',
      disableBeacon: true
    },
    {
      target: '.profile-hint',
      content: t('home.profile'),
      placement: 'auto',
      disableBeacon: true
    },
    {
      target: '.isNewUi-hint',
      content: t('home.isNewUi'),
      placement: 'auto',
      disableBeacon: true
    },
    {
      target: '.sidebar',
      title: t('home.sidebarTitle'),
      content: t('home.sidebar'),
      // <div>
      //   <h4>Подключение сервиса Monitary доступно в тарифе Premium.</h4>
      //   <div style={{display:"flex", margin: 'auto', gap: '30px'}}>
      //   <img width="200px" height="100%" src={clickImg} alt="clickde bu"/>
      //   <img width="200px" height="100%" src={clickImg} alt="clickde bu"/>
      //   </div>
      //   <br /> <a href="#">Смотреть видео</a>
      // </div>
      // ),
      placement: 'auto',
      disableBeacon: true
    },
    // {
    //   target: '.save-hint',
    //   title: t('menuTitle'),
    //   content: t('menuPr'),
    //   // offset: '10'
    //   placement: 'auto',
    //   disableBeacon: true
    // },
    // {
    //   target: '.howToList',
    //   content: 'Блок "помощь", где вы можете найти ответы на свои вопросы',
    //   placementBeacon: 'top'
    //   // disableBeacon: true
    // },
    // {
    //   target: '.useMonetary ',
    //   placementBeacon: 'top',
    //   content: (
    //     <div>
    //       <h4>Подключение сервиса Monitary доступно в тарифе Premium.</h4>
    //       <br /> <a href="#">Смотреть видео</a>
    //     </div>
    //   ),
    //   styles: {
    //     options: {
    //       a: { background: 'red' }
    //     }
    //   }
    // },
  ];

  const kanban = [

    {
      target: '.board-hint',
      title: 'Меню',
      content: 'Здесь вы можете переключаться между вкладками для быстрого доступа и для создания новых документов',
      // offset: '10'
      placement: 'auto'
    }
  ];
  const inside = [

    {
      target: '.hint-pdf',
      title: 'Меню',
      content: 'Здесь вы можете переключаться между вкладками для быстрого доступа и для создания новых документов',
      // offset: '10'
      placement: 'auto'
    },
    {
      target: '.tab-hint-two-side',
      title: 'Меню',
      content: 'Здесь вы можете переключаться между вкладками для быстрого доступа и для создания новых документов',
      // offset: '10'
      placement: 'auto'
    },
    {
      target: '.tab-hint-three-side',
      title: 'Меню',
      content: 'Здесь вы можете переключаться между вкладками для быстрого доступа и для создания новых документов',
      // offset: '10'
      placement: 'auto'
    },
    {
      target: '.table-hint',
      title: 'Меню',
      content: 'Здесь вы можете переключаться между вкладками для быстрого доступа и для создания новых документов',
      // offset: '10'
      placement: 'auto'
    }
  ];


  const inboxInvoice = [
    {
      target: '.documentProperty',
      title: t('invoiceTitle'),
      content: t('invoicePr'),
      // offset: '10'
      placement: 'auto'
    },
    {
      target: '.requisiteForm',
      title: 'Меню',
      content: 'Здесь вы можете переключаться между вкладками для быстрого доступа и для создания новых документов',
      // offset: '10'
      placement: 'auto'
    },
    {
      target: '.invoiceFilter',
      title: 'Меню',
      content: 'Здесь вы можете переключаться между вкладками для быстрого доступа и для создания новых документов',
      // offset: '10'
      placement: 'auto'
    },
    {
      target: '.document-create',
      title: 'Меню',
      content: 'Здесь вы можете переключаться между вкладками для быстрого доступа и для создания новых документов',
      // offset: '10'
      placement: 'auto'
    },
    {
      target: '.submit-reset-btn-container',
      title: 'Меню',
      content: 'Здесь вы можете переключаться между вкладками для быстрого доступа и для создания новых документов',
      // offset: '10'
      placement: 'auto'
    },
    {
      target: '.table-buttons',
      title: 'Меню',
      content: 'Здесь вы можете переключаться между вкладками для быстрого доступа и для создания новых документов',
      // offset: '10'
      placement: 'auto'
    }
  ];
  const inboxContract = [
    {
      target: '.contract-hint',
      title: 'Меню',
      content: 'Здесь вы можете переключаться между вкладками для быстрого доступа и для создания новых документов',
      // offset: '10'
      placement: 'auto'
    }
  ];


  const getWithDockType = (doctype) => {
    switch (doctype) {
      case 21:
      case 2:
      case 24:
      case 13:
      case 22:
      case 29:
      case 19:
      case 18:
      case 27:
        return inboxInvoice;
      case 1:
      case 4:
      case 7:
      case 5:
      case 11:
      case 3:
      case 6:
      case 37:
      case 9:
      case 38:
      case 8:
      case 99:
      case 10:
      case 28:
      case 33:
        return inboxContract;
    }

    return [];
  };


  const settingsUpdateUser = [

    {
      target: '.react-tabs__tab-list',
      title: t('menuTitle'),
      content: t('menuPr'),
      // offset: '10'
      placement: 'auto'
    },
    {
      target: '.update_user_hint',
      title: t('menuTitle'),
      content: t('menuPr'),
      placementBeacon: 'left'
    }
  ];

  const settingsProductService = [

    {
      target: '.select',
      title: t('menuTitle'),
      content: t('menuPr'),
      // offset: '10'
      placement: 'auto'
    },
    {
      target: '.add-hint',
      title: t('menuTitle'),
      content: t('menuPr'),
      placementBeacon: 'left'
    },
    {
      target: '.synch-hint',
      title: t('menuTitle'),
      content: t('menuPr'),
      placementBeacon: 'left'
    },
    {
      target: '.table-hint',
      title: t('menuTitle'),
      content: t('menuPr'),
      placementBeacon: 'left'
    }
  ];
  const settingsOthers = [

    {
      target: '.search-hint',
      title: t('menuTitle'),
      content: t('menuPr'),
      // offset: '10'
      placement: 'auto'
    }, {
      target: '.pulse-button',
      title: t('menuTitle'),
      content: t('menuPr'),
      // offset: '10'
      placement: 'auto'
    },
    {
      target: '.table-hint',
      title: t('menuTitle'),
      content: t('menuPr'),
      placementBeacon: 'left'
    }
  ];

  const getInbox = (doctype) => {
    if (doctype === 1) {
      return [
        {
          target: '.chevron-hint',
          title: t('sidebarTitle'),
          content: t('sidebarPr'),
          // offset: '10',
          placement: 'top-end'
        },
        {
          target: '.sub-sidebar',
          title: t('sidebarTitle'),
          content: t('sidebarPr'),
          // offset: '10',
          placement: 'auto'
        },
        ...getWithDockType(doctype)
      ];
    } else {
      return [
        ...getWithDockType(doctype)
      ];
    }
  };


  const getSettings = () => {
    const tabIndex = localStorage.getItem('settingsTabIndex');
    if (tabIndex === '0' || !tabIndex) {
      return settingsUpdateUser;
    } else if (tabIndex === '1') {
      return settingsProductService;
    } else if (tabIndex === '3' || tabIndex === '4' || tabIndex === '5' || tabIndex === '6') {
      return settingsOthers;
    }
  };

  switch (activeIndex) {
    case 0:
      return home;
    case 1:
      return getInbox(doctype);
    case 2:
      return inside;
    case 3:
      return inside;
    case 4:
      return kanban;
    case 5:
      return getInbox(99);
    case 6:
      return getSettings();
    default:
      return [];
  }
}


export { getStep };
