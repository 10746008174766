/**
 * Created with IntelliJ IDEA.
 * User: FN214
 * Date: 06.04.15
 * Time: 10:20
 * To change this template use File | settings | File Templates.
 */
import { EIMZOClient, dates } from './e-imzo-client';
import $ from 'jquery';
export var EIMZO_MAJOR = 3;
export var EIMZO_MINOR = 37;

var AppLoad = function () {
  EIMZOClient.API_KEYS = [
    'localhost',
    '96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B',
    '127.0.0.1',
    'A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F',
    'corp.24m.uz',
    '663C1DC9BC03B456BF17A82F2A09C51CDF96389EC250DB61CB8B79EF6675412AC6FB03F177A68556178F4184FD9DFD09AB174D47B1C369B2920BE0AAA4AB1771',
    '24m.uz', 
    'F9A86C9DC221A0EE579CB18ECCD99246CC40A5A1338559B5131FEFCB5FF7A4D705E3F8E54CBF8B41E6EFF64CC46615E545786630F19FF0412EC883A391DC2C54'
  ];
};




export var uiUpdateApp = function () {
  // TODO eimzoni yangilang! => modal
};

export var uiLoadKeys = function (comboId, cb) {
  uiClearCombo(comboId);
  EIMZOClient.listAllUserKeys(
    function (o, i) {
      var itemId = 'itm-' + o.serialNumber + '-' + i;
      cb(o);
      return { itemId, o };
    },
    function ({ itemId, o }, v) {
      return uiCreateItem(itemId, v);
    },
    function (items, firstId) {
      uiFillCombo(items, comboId);
      // uiLoaded();
      uiComboSelect(firstId, comboId);
    },
    function (e, r) {
      
    }
  );
};

var uiComboSelect = function (itm, comboId) {
  if (itm) {
  }
};
var uiClearCombo = function (selectId) {
  var combo = $('#' + selectId);
  combo.empty();
  combo.append('<option disabled selected>Сертификат ЭЦП</option>');
};

var uiFillCombo = function (items, comboId) {
  var combo = $('#' + comboId);
  for (var itm in items) {
    combo.append(items[itm]);
  }
};

var uiCreateItem = function (itmkey, vo) {
  var now = new Date();
  vo.expired = dates.compare(now, vo.validTo) > 0;
  var itm = document.createElement('option');
  itm.value = itmkey;
  itm.text = vo.TIN + ' - ' + vo.CN;
  if (!vo.expired) {
  } else {
    itm.style.color = 'gray';
    itm.text = itm.text + ' (срок истек)';
  }
  itm.setAttribute('vo', JSON.stringify(vo));
  itm.setAttribute('id', itmkey);
  return itm;
};

window.onload = AppLoad;
