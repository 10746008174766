const initialState = {
  zoom: 90,
  inTableColm: {
    checkbox: true,
    state: true,
    tin: true,
    typeName: true,
    dateAndNumber: true,
    contract: true,
    branchName: true,
    filialNum: true,
    constructionObject: true,
    ageInDays: true,
    sum: true
  },
  outTableColm: {
    checkbox: true,
    state: true,
    tin: true,
    typeName: true,
    dateAndNumber: true,
    contract: true,
    branchName: true,
    filialNum: true,
    constructionObject: true,
    ageInDays: true,
    sum: true
  },
  tinAndNameWidth: 278,
  isNewUi: true,
  contractDrawer: { visible: false, mode: 'add', boxType : '', setValue: () => { } },
};

export const zoomStep = 10;

export const INCREMENT_ZOOM = 'INCREMENT_ZOOM';
export const DECREMENT_ZOOM = 'DECREMENT_ZOOM';
export const IN_TABLE_COLUMN = 'IN_TABLE_COLUMN';
export const OUT_TABLE_COLUMN = 'OUT_TABLE_COLUMN';
export const TIN_AND_NAME_WIDTH = 'TIN_AND_NAME_WIDTH';
export const IS_NEW_UI = 'IS_NEW_UI';
export const CONTRACT_DRAWER = 'CONTRACT_DRAWER'

const uiReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INCREMENT_ZOOM:
      return {
        ...state,
        zoom: state.zoom + payload.value
      };
    case DECREMENT_ZOOM:
      return {
        ...state,
        zoom: state.zoom - payload.value
      };
    case IN_TABLE_COLUMN:
      return {
        ...state,
        inTableColm: { ...state.inTableColm, ...payload }
      };
    case OUT_TABLE_COLUMN:
      return {
        ...state,
        outTableColm: { ...state.outTableColm, ...payload }
      };
    case TIN_AND_NAME_WIDTH:
      return {
        ...state,
        tinAndNameWidth: payload
      };
    case IS_NEW_UI:
      return {
        ...state,
        isNewUi: payload
      };
    case CONTRACT_DRAWER:
      return {
        ...state,
        contractDrawer: payload
      };
    default:
      return state;
  }
};

export default uiReducer;
