import React, { memo } from 'react';
import { registerLocale } from 'react-datepicker';
import moment from 'moment';

import ru from 'date-fns/locale/ru';

// ANCHOR style
import 'react-datepicker/dist/react-datepicker.css';
import 'Assets/css/datePicker.css';
import BaseStyledLabel from 'Common/Style/styledLabel';
import { StyledDatePicker, StyledNumberFormat } from './datePicker.style';

registerLocale('ru', ru);

const CustomDatePicker = (props) => {
    const { name, value, onChange, label, ...rest } = props;

    const ExampleCustomInput = ({ value, onClick, onChange }) => (
      <StyledNumberFormat
        name={name}
        value={value}
        onChange={(value) => onChange(value, name)}
        onClick={onClick}
        format="##.##.####"
        style={props.style}
        mask="_"
        allowLeadingZero={true}
        placeholder="__.__.____"
        autoComplete='off'
      />
    );

    if (props?.noLabel)
      return (
        <StyledDatePicker
          locale="ru"
          id="dp"
          name={name}
          selected={value}
          onChange={(date) => onChange(date, name)}
          dateFormat="dd.MM.yyyy"
          customInput={<ExampleCustomInput />}
          {...rest}
        />
      );

    return (
      <>
        <BaseStyledLabel style={{ ...props.style, height: '20px' }} className={props.className}>
          {label}
        </BaseStyledLabel>

        <StyledDatePicker
          locale="ru"
          id="dp"
          name={name}
          selected={value}
          onChange={(date) => onChange(date, name)}
          dateFormat="dd.MM.yyyy"
          customInput={<ExampleCustomInput />}
          {...rest}
        />
      </>
    );
  }

export { CustomDatePicker };
