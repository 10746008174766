import styled, { keyframes } from 'styled-components';
import { Switch } from 'antd';

const pulse = keyframes`
  0% {transform: scale(.85);}
  100% {transform: scale(1.15);}
 `

export const StyledSwitch = styled(Switch)`

   &.ant-switch {
    ${(props) => props?.withWhiteBorder && 'box-shadow: 0 0 0 2px #e6f7ff'};

    animation: ${pulse} 1.5s alternate infinite ease-in !important;
      .ant-switch-handle {
      }
   }
   
   &.ant-switch:focus:hover {
      ${(props) => props?.withWhiteBorder && 'box-shadow: 0 0 0 2px #e6f7ff'};
   }

  

`;

export const Container = styled.div`
   margin:5px 0px 0px 5px;
`