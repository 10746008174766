import styled from 'styled-components';
import BaseStyledFlex from './styledFlex';
import { colors } from 'Style/colors';

const DEFAULT_VIRTAUL_TABLE_HEADER = 40;


const StyledHeader = styled(BaseStyledFlex)`
  background-color: ${(props) => props?.isNewUi ? colors.white : 'rgb(100,197,225)'};
  line-height: ${({ height }) => `${height || DEFAULT_VIRTAUL_TABLE_HEADER}px`};
  border:1.5px solid ${(props) => props?.isNewUi ? colors.settingTableBorderColor : 'rgb(100,197,225)'};
  //border-bottom:0px solid ${colors.settingTableBorderColor};
  border-top-right-radius: ${(props) => props?.isNewUi ? '8px' : '0px'};
  border-top-left-radius:${(props) => props?.isNewUi ? '8px' : '0px'};
  color: ${(props) => props?.isNewUi ? '#7A7B8F' : 'white'};
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;

  /* ANCHOR */
  & > div {
    position: relative;
    text-align: center;
    margin:0px;
    &:first-child {
      margin:0px;
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      content: '';
      height: ${({ height }) => `${(height || DEFAULT_VIRTAUL_TABLE_HEADER) / 2}px`};
      border: 0.5px solid ${(props) => props?.isNewUi ? colors.borderColor : 'white'};
    }

    &:last-child::after {
      border: none;
    }
  }
`;

export default StyledHeader;
