import styled, { css } from 'styled-components';
import { colors } from 'Style/colors';

const StyledTextWrapper = styled.div`
  position: relative;
  cursor: pointer;

  .copied_status {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${colors.primary};
    color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    padding: 5px 10px;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    z-index: 99;

    &:after {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-25%) rotate(45deg);

      display: block;
      width: 10px;
      height: 10px;
      background-color: inherit;
      content: '';
    }
  }

  .copied_status {
    display: ${props => props.copied ? 'inline-block' : 'none'};
  }
  &:hover {
    .copied_status {
      display: inline-block;
    }
    ${props => props.copied ? css`
    .text_copy {
      display: none;
    }
    .text_copied {
      display: block;
    }
  ` : css`
    .text_copy {
      display: block;
    }
    .text_copied {
      display: none;
    }
  `}
  }
`;

export {
  StyledTextWrapper
};
