import client from 'Services/api/client';

const ApiConstructionObject = {
  async getConstructionObjects() {
    return await client(undefined, '/constructionObjects');
  },
  async getConstructionObjectOne(constructionId) {
    return await client(undefined, `/constructionObjects/${constructionId}`);
  },
  async getActiveConstructionObjects(){
   return await client(undefined, '/constructionObjects/active')
  },
  async createConstructionObject(body) {
    return await client('POST', '/constructionObjects', body);
  },
  async deleteConstructionObject(constructionId) {
    return await client('DELETE', `/constructionObjects/${constructionId}`);
  },
  async updateConstructionObject(body) {
    const constructionId = body.id;

    return await client('PUT', `/constructionObjects/${constructionId}`, body);
  },
  async getConstructionObjectsSearch(body) {
    return await client(undefined, `constructionObjects?keyword=${body?.keyword || ''}`)
  }
};

export default ApiConstructionObject;
