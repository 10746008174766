import { useReducer, useState, useCallback } from 'react';
import get from 'lodash.get';
import { useSelector } from "react-redux";
import {
  filterList,
  checkedValuesHandler,
  controlSelectAllAction,
  headerInputChangeHandler,
  onFormatNumberForSearch
} from 'Components/molecules/documentList/table/functions';
import { getHeight, getWidth } from 'Components/molecules/filterCart/helpers';
import { getIdentifyProperty } from 'Components/molecules/filterCart/helpers';
import * as allState from 'Store/state';

import requestState from 'Services/api/status';
export const SET_INITIAL_VALUE = 'SET_INITIAL_VALUE';
export const ONE_SELECT = 'ONE_SELCT';
export const ALL_SELECT = 'ALL_SELECT';
export const SET_ACTIVE_ON = 'SET_ACTIVE_ON';
export const SET_ACTIVE_ON_LOADING = 'SET_ACTIVE_ON_LOADING';
export const SET_ACTIVE_ON_FAIL = 'SET_ACTIVE_ON_FAIL';
export const SET_ACTIVE_ON_SUCCESS = 'SET_ACTIVE_SUCCESS';
export const SET_ACTIVE_OFF = 'SET_ACTIVE_OFF';
export const SET_INPUT_TIN_NAME = 'SET_INPUT_TIN_NAME';
export const SET_SELECTED_DATA = 'SET_SELECTED_DATA';
export const SET_EMPTY_VALUE_INPUT_TIN_NAME = 'SET_EMPTY_VALUE_INPUT_TIN_NAME';
export const HIDE_FILTER_CARD = 'HIDE_FILTER_CARD';
export const SET_FROM_TO_INPUT_FIELDS = 'SET_FROM_TO_INPUT_FIELDS';
export const SET_CONTRACT_FIELDS = 'SET_CONTRACT_FIELDS';
export const SET_COUNTER_AGENT = 'SET_COUNTER_AGENT';
export const SET_INITIAL_SITUATION = 'SET_INITIAL_SITUATION';
export const SET_SAVED_DEFAULT_VALUES = 'SET_SAVED_DEFAULT_VALUES';
export const SET_INITIAL_SITUATION_ITEM = 'SET_INITIAL_SITUATION_ITEM';
export const SET_DATE_FIELDS = 'SET_DATE_FIELDS';
export const SET_NUMBER_FIELDS = 'SET_NUMBER_FIELDS';
export const CHANGE_LEFT_OF_FILTER_CARD = 'CHANGE_LEFT_OF_FILTER_CARD';
export const REACT_SELECT_CHANGE_HANDLER = 'REACT_SELECT_CHANGE_HANDLER';
export const SET_NEW_VALUES_TO_CHECKEDS = 'SET_NEW_VALUES_TO_CHECKEDS';
export const BLOCK_CHECKBOX = 'BLOCK_CHECKBOX';
export const SET_WITHOUT_OBJECT = 'SET_WITHOUT_OBJECT';
export const SET_AGE_IN_DAYS_FROM = 'SET_AGE_IN_DAYS_FROM';
export const SET_AGE_IN_DAYS_TO = 'SET_AGE_IN_DAYS_TO';
export const SET_SELECTED_FILIAL = 'SET_SELECTED_FILIAL';
export const SET_SORT_TYPE = 'SET_SORT_TYPE';
export const TURN_OFF_SORT_TYPE = 'TURN_OFF_SORT_TYPE';
export const RETURN_TO_PREV_STATE = 'RETURN_TO_PREV_STATE'; // NOTE - apply qilmasdan filterni yopib ketsa ishlaydi

export const filterSortTypes = {
  ascend: 'asc',
  descend: 'desc'
};
export const extraDefaultFilterData = {
  activeProperties: ['state'],
  checkedBoxes: { state: [allState.SEND_TO_RECEIVER, allState.SAVED, allState?.SENDING] },
  status: 'initial_request',
  isFilter: true
  // activeSortProperty: {
  //   'dateAndNumber': filterSortTypes.descend
  // },
};

export const normilizeSavedDefaults = (data) => {
  let checkedBoxes = {};
  if (data?.tin) checkedBoxes.tin = data?.tin;
  if (data?.state && data?.state?.length > 0) checkedBoxes.state = data?.state;
  if (data?.typeName) checkedBoxes.typeName = data?.type;
  if (data?.constructionObjects && data?.constructionObjects?.length > 0) checkedBoxes.constructionObject = data?.constructionObjects;
  let activeProperties = Object.keys(checkedBoxes);

  if (data?.type && data?.type?.length > 0) { activeProperties.push("typeName") }
  if ((!!data?.ageInDaysFrom) || (!!data?.ageInDaysTo)) { activeProperties.push("ageInDays") }
  if ((!!data?.fromSum) || (!!data?.toSum)) { activeProperties.push("sum") }

  if ((!!data?.contractDate) || (!!data?.contractNumber)) { activeProperties.push("contract") }
  if ((!!data?.dateFrom) || (!!data?.dateTo)) { activeProperties.push("dateAndNumber") }
  if ((!!data?.keyword) || (!!data?.tin)) { activeProperties.push("tin") }

  let body = {
    isActive: true,
    status: 'initial',
    identify: '',
    isFilter: true,
    selectedProperty: '',
    left: 0,
    width: 0,
    checkedBoxes: checkedBoxes,
    inputNameTin: '',
    onePropertyValueList: {},
    searchedPropertyValueLIst: {},
    sum: {
      inputFrom: '',
      inputTo: ''
    },
    date: {
      dateFrom: '',
      dateTo: '',
      status: 'date_fields_initial'
    },
    number: {
      function: 'numberStartWith',
      number: '',
      id: ''
    },
    contract: {
      contractNumber: '',
      contractDate: ''
    },
    activeProperties: activeProperties,
    activeSortProperty: {},
    branchName: {
      currentBranchId: [],
      currentEmployeeId: []
    },
    counterAgent: {},
    filialNum: [],
    ageInDaysFrom: data?.ageInDaysFrom ?? null,
    ageInDaysTo: data?.ageInDaysTo ?? null,
    blocked: data?.blocked ?? false,
    withoutObject: false
  }

  return body
}

const initialState = {
  isActive: false,
  status: 'initial',
  identify: '',
  isFilter: false,
  selectedProperty: '',
  left: 0,
  width: 0,
  checkedBoxes: {},
  inputNameTin: '',
  onePropertyValueList: {},
  searchedPropertyValueLIst: {},
  sum: {
    inputFrom: '',
    inputTo: ''
  },
  date: {
    dateFrom: '',
    dateTo: '',
    status: 'date_fields_initial'
  },
  number: {
    function: 'numberStartWith',
    number: '',
    id: ''
  },
  contract: {
    contractNumber: '',
    contractDate: ''
  },
  activeProperties: [],
  activeSortProperty: {},
  branchName: {
    currentBranchId: [],
    currentEmployeeId: []
  },
  counterAgent: {},
  filialNum: [],
  ageInDaysFrom: null,
  ageInDaysTo: null,
  blocked: false,
  withoutObject: false
};

export const WITHOUT_OBJECT_VALUE = 0;

const filterReducer = (state, action) => {
  let {
    ageInDays,
    onePropertyValueList,
    identify,
    activeProperties,
    searchedPropertyValueLIst,
    selectedProperty,
    selectedList,
    isFilter,
    number,
    sum,
    date,
    checkedBoxes,
    status,
    left,
    counterAgent
  } = state;
  switch (action.type) {
    case SET_ACTIVE_ON_LOADING:
      return {
        ...state,
        status: requestState.loading
      };
    case SET_ACTIVE_ON_SUCCESS:
      let checkeds = isFilter
        ? checkedBoxes[selectedProperty] ||
        checkedValuesHandler({ list: action.list, property: getIdentifyProperty(selectedProperty), selectedProperty })
        : checkedValuesHandler({ list: action.list, property: getIdentifyProperty(selectedProperty), selectedProperty });

      return {
        ...state,
        isFilter: true,
        status: requestState.success,
        identify: getIdentifyProperty(selectedProperty),
        checkedBoxes: { ...checkedBoxes, [selectedProperty]: checkeds },
        onePropertyValueList: { ...onePropertyValueList, [selectedProperty]: action.list }
      };
    case SET_ACTIVE_ON:
      return {
        ...state,
        isActive: true,
        inputNameTin: '',
        inputFrom: '',
        inputTo: '',
        left: action.left,
        selectedProperty: action.selectedProperty,
        status: requestState.loading
      };
    case CHANGE_LEFT_OF_FILTER_CARD:
      return {
        ...state,
        left: left - action.left,
        width: action.width
      };
    case ONE_SELECT:
      const filteredPartOfList = filterList(state.checkedBoxes[selectedProperty], action.value, selectedProperty);
      return {
        ...state,
        checkedBoxes: {
          ...checkedBoxes,
          [selectedProperty]: filteredPartOfList
        }
      };
    case ALL_SELECT:
      const currentValuesOfActiveProperty = controlSelectAllAction(
        checkedBoxes[selectedProperty],
        status !== 'header_input_search' ? onePropertyValueList[selectedProperty] : searchedPropertyValueLIst[selectedProperty],
        identify,
        selectedProperty
      );
      return {
        ...state,
        onePropertyValueList,
        checkedBoxes: {
          ...checkedBoxes,
          [selectedProperty]: currentValuesOfActiveProperty
        },
        blocked: false
      };
    case SET_NEW_VALUES_TO_CHECKEDS:
      return {
        ...state,
        checkedBoxes: {
          ...checkedBoxes,
          [selectedProperty]: action.newValue
        }
      };
    case SET_INPUT_TIN_NAME:
      const { value } = action.e.target || {};

      const searchedValues = headerInputChangeHandler(onePropertyValueList[selectedProperty], value);

      checkedBoxes = [...checkedValuesHandler({ list: searchedValues, property: identify, selectedProperty })];

      return {
        ...state,
        inputNameTin: value,
        onePropertyValueList,
        checkedBoxes: { ...state.checkedBoxes, [selectedProperty]: checkedBoxes },
        status: 'header_input_search',
        searchedPropertyValueLIst: {
          ...searchedPropertyValueLIst,
          [selectedProperty]: searchedValues
        }
      };
    case SET_AGE_IN_DAYS_FROM:
      return { ...state, ageInDaysFrom: action.payload };
    case SET_AGE_IN_DAYS_TO:
      return { ...state, ageInDaysTo: action.payload };
    case SET_SORT_TYPE:
      return { ...state, activeSortProperty: action.payload };
    case TURN_OFF_SORT_TYPE:
      return { ...state, activeSortProperty: {} };
    case SET_NUMBER_FIELDS:
      return {
        ...state,
        number: {
          ...number,
          [action.name]: action.value
        }
      };
    case SET_FROM_TO_INPUT_FIELDS:
      const { name: fromToName, value: fromToValue } = action.e.target;
      return {
        ...state,
        sum: {
          ...sum,
          [fromToName]: onFormatNumberForSearch(fromToValue)
        }
      };
    case SET_EMPTY_VALUE_INPUT_TIN_NAME:
      return {
        ...state,
        inputNameTin: ''
      };
    case SET_DATE_FIELDS:
      return {
        ...state,
        date: {
          ...date,
          [action.dateName]: action.e
        }
      };

    case SET_SELECTED_DATA:
      return {
        ...state,
        activeProperties: [...state.activeProperties, state.selectedProperty],
        status: 'filter',
        isActive: false,
        selectedProperty: ''
      };
    case SET_SELECTED_FILIAL:
      return {
        ...state,
        filialNum: action.payload
      };
    case SET_CONTRACT_FIELDS:
      return {
        ...state,
        contract: {
          ...state.contract,
          [action.name]: action.value
        }
      };
    case SET_COUNTER_AGENT:
      return {
        ...state,
        counterAgent: {
          ...counterAgent,
          [action.name]: action.value
        }
      }
    case SET_INITIAL_SITUATION:
      return { ...initialState, status: 'disable_filter', isActive: false };
    case SET_SAVED_DEFAULT_VALUES:
      return {
        ...state,
        ...(action?.payload ? action?.payload : initialState),
        status: 'disable_filter', isActive: false
      };
    case SET_INITIAL_SITUATION_ITEM:
      return {
        ...initialState,
        ...state,
        date: {
          dateFrom: action.dataKey === 'dateAndNumber' ? '' : state.date.dateFrom,
          dateTo: action.dataKey === 'dateAndNumber' ? '' : state.date.dateTo
        },
        number: {
          number: action.dataKey === 'dateAndNumber' ? '' : state.number.number,
          id: action.dataKey === 'dateAndNumber' ? '' : state.number.id
        },
        contract: {
          contractNumber: action.dataKey === 'contract' ? '' : state.contract.contractNumber,
          contractDate: action.dataKey === 'contract' ? '' : state.contract.contractDate
        },
        ageInDaysFrom: action.dataKey === 'ageInDays' ? null : state.ageInDaysFrom,
        ageInDaysTo: action.dataKey === 'ageInDays' ? null : state.ageInDaysTo,
        sum: {
          inputFrom: action.dataKey === 'sum' ? '' : state.sum.inputFrom,
          inputTo: action.dataKey === 'sum' ? '' : state.sum.inputTo
        },
        status: 'one_filter',
        isActive: false,
        activeProperties: state.activeProperties.filter((e) => e !== action.dataKey),
        checkedBoxes: Object.fromEntries(
          Object.entries(state.checkedBoxes).filter(([key, value], index) => {
            if (key === action.dataKey) {
              return false;
            }
            return true;
          })
        )
      };
    case HIDE_FILTER_CARD:
      return { ...state, selectedProperty: '', isActive: false };
    case REACT_SELECT_CHANGE_HANDLER:
      return {
        ...state,
        [action.parentProperty]: {
          ...state[action.parentProperty],
          [action.property]: action.value
        }
      };
    case BLOCK_CHECKBOX:
    case SET_WITHOUT_OBJECT:
      let newCheckboxes;

      if (action.payload.value) {
        newCheckboxes = [];
      } else {
        newCheckboxes = onePropertyValueList[selectedProperty].map((_item) => _item[identify]);
      }

      return {
        ...state,
        checkedBoxes: {
          ...checkedBoxes,
          [selectedProperty]: newCheckboxes
        },
        [action.payload.key]: action.payload.value
      };
    case RETURN_TO_PREV_STATE: {
      return { ...action.payload, selectedProperty: '', isActive: false };
    }
    default:
      return state;
  }
};

const useFilter = ({ layoutWidth, zoomValue }) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const filterDataFromDashboard = useSelector(state => state.outDocuments.filterDataFromDashboard);

  if (filterDataFromDashboard) extraDefaultFilterData.checkedBoxes = filterDataFromDashboard;

  const [filterData, filterDispatch] = useReducer(filterReducer, { ...initialState, ...extraDefaultFilterData });

  const getPositionFromLeft = ({ e, column, columns, getWidth, getColumnKey, label }) => {
    let prevColumns = [];
    for (let item of columns) {
      if (get(item, 'props.children[0].props.children') === label) {
        prevColumns = [get(item, 'props.style.flex')];
      }
    }

    let mainWidth = layoutWidth === 80 || layoutWidth === 95 ? layoutWidth : layoutWidth + 18;

    label = getColumnKey(label);

    let positionLeft = e.clientX * (100 / zoomValue) - window.innerWidth * (100 / zoomValue) * (1 - mainWidth / 100) - getWidth(label);

    if (label === 'contractState' || label === 'partnerTin') {
      positionLeft = positionLeft + 150
    }
    return positionLeft;
  };

  const onFilterIconHandler = async ({ e, column, columns, getColumnKey, cb }) => {
    let label = get(column, 'props.children[0].props.children');
    const columnKey = getColumnKey(label);
    const positionLeftpx = getPositionFromLeft({ e, column, columns, getWidth, getColumnKey, label });
    setWidth(getWidth(columnKey));
    setHeight(getHeight(columnKey));

    if (filterData.selectedProperty === columnKey) {
      return filterDispatch({ type: SET_ACTIVE_OFF });
    }

    filterDispatch({ type: SET_ACTIVE_ON, isActive: true, selectedProperty: columnKey, left: positionLeftpx });
    await cb(columnKey);
  };

  const hideFilter = useCallback(() => filterDispatch({ type: HIDE_FILTER_CARD }));

  const handleResize = useCallback(
    (d, key) => {
      if (key === 'height') return setHeight((prevHeight) => (prevHeight += d.height));
      setWidth((prevWidth) => (prevWidth += d.width));
    },
    [height, width]
  );

  return {
    filterData,
    filterDispatch,
    getPositionFromLeft,
    width,
    height,
    setHeight,
    setWidth,
    hideFilter,
    onFilterIconHandler,
    handleResize,
    filterInitialState: initialState
  };
};

export default useFilter;
