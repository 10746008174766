import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import accountReducer from 'Components/organisms/layout/reducer/reducer';

// outcome and income documents

import documentList from 'Modules/box/reducers/documents';
import readReducer from 'Modules/box/reducers/read';
import signReducer from 'Modules/box/reducers/sign';
import rejectReducer from 'Modules/box/reducers/reject';
import copyReducer from 'Modules/box/reducers/copy';
import sendReducer from 'Modules/box/reducers/send';
// document create

import flexListReducer from 'Modules/document/reducers/flexList';
import formValueReducer from 'Modules/document/reducers/formValues';
import sendDocToPublicViewReducer from 'Modules/document/reducers/sendToPublicView';

//layout
import menuReducer from 'Components/molecules/menu/menuReducer';
import uiReducer from 'Components/organisms/layout/reducer/ui';
import catalogCodeReducer from 'Components/organisms/layout/reducer/catalogCode';
import lgotaReducer from 'Components/organisms/layout/reducer/lgotaReducer';
import notificationReducer from 'Components/atoms/toast/reducer';
import clientProductsReducer from 'Pages/_Parameters_section/Products/reducer/reducer';
import tariffReducer from "Components/organisms/layout/reducer/tariffReducer";

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['ui']
};

export const rootReducer = combineReducers({
  accountData: accountReducer,
  outDocuments: documentList,
  views: readReducer,
  signs: signReducer,
  rejects: rejectReducer,
  copies: copyReducer,
  flexList: flexListReducer,
  formValue: formValueReducer,
  menues: menuReducer,
  sends: sendReducer,
  catalogCodes: catalogCodeReducer,
  clientProducts: clientProductsReducer,
  ui: uiReducer,
  lgota: lgotaReducer,
  notification: notificationReducer,
  sendDocToPublicView: sendDocToPublicViewReducer,
  tariff: tariffReducer
});

export default persistReducer(rootPersistConfig, rootReducer);
