import React from "react";
import * as _ from './style'

const Switch = (props) => {

    return (
        <_.Container>
            <_.StyledSwitch {...props} />
        </_.Container>
    )
}

export default Switch;