import React from "react";
import { colors } from "Style/colors";

const SVGComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        viewBox="0 0 752.000000 752.000000"
        preserveAspectRatio="xMidYMid meet"
        {...props}
    >
        <g
            transform="translate(0.000000,752.000000) scale(0.100000,-0.100000)"
            fill={colors.sideBarIconColor}
            stroke="none"
        >
            <path d="M1621 5726 c-53 -19 -111 -74 -137 -130 l-24 -51 0 -1785 0 -1785 24 -51 c27 -59 90 -116 145 -132 55 -17 4207 -17 4262 0 55 16 118 73 145 132 l24 51 0 1785 0 1785 -24 51 c-27 59 -90 116 -145 132 -57 18 -4222 16 -4270 -2z m4239 -141 c55 -28 60 -53 60 -275 l0 -200 -2160 0 -2160 0 0 200 c0 160 3 207 15 230 33 64 -131 59 2143 60 1834 0 2076 -2 2102 -15z m58 -2098 c2 -1625 6 -1519 -58 -1552 -26 -13 -268 -15 -2100 -15 -1832 0 -2074 2 -2100 15 -64 33 -60 -71 -60 1548 0 810 3 1477 7 1480 3 4 974 6 2157 5 l2151 -3 3 -1478z" />
            <path d="M1949 5435 c-83 -45 -47 -175 48 -175 94 0 127 132 44 175 -37 18 -58 19 -92 0z" />
            <path d="M2354 5440 c-59 -24 -72 -104 -25 -151 49 -49 129 -30 150 34 26 78 -49 148 -125 117z" />
            <path d="M2731 5424 c-68 -61 -27 -164 65 -164 15 0 38 12 55 29 56 56 27 145 -52 157 -30 5 -42 1 -68 -22z" />
            <path d="M3762 5414 c-51 -35 -49 -98 4 -123 27 -12 1797 -17 1842 -5 12 4 30 20 38 36 19 37 5 80 -32 97 -18 8 -278 11 -927 11 -799 0 -905 -2 -925 -16z" />
            <path d="M2135 4558 c-32 -11 -92 -52 -113 -78 -7 -8 -22 -33 -35 -55 -21 -39 -22 -49 -22 -330 l0 -290 29 -53 c33 -59 76 -96 137 -117 62 -22 3196 -22 3258 0 61 21 104 58 137 117 l29 53 0 290 c0 281 -1 291 -22 332 -13 23 -39 58 -59 77 -73 71 47 66 -1718 65 -1082 0 -1601 -4 -1621 -11z m705 -463 l0 -336 -336 3 -336 3 -29 33 -29 32 0 263 c0 226 2 266 16 286 33 47 57 50 397 51 l317 0 0 -335z m2504 321 c65 -27 66 -32 66 -323 l0 -263 -29 -32 -29 -33 -1181 -3 -1181 -2 0 335 0 335 1160 0 c970 0 1166 -2 1194 -14z" />
            <path d="M3200 4270 c-27 -27 -26 -74 2 -100 21 -20 32 -20 609 -20 l588 0 20 26 c27 35 27 65 -2 92 l-23 22 -587 0 c-574 0 -587 0 -607 -20z" />
            <path d="M3202 4017 c-27 -29 -28 -71 -2 -97 20 -20 33 -20 965 -20 932 0 945 0 965 20 27 27 26 71 -3 98 l-23 22 -940 0 -941 0 -21 -23z" />
            <path d="M2130 3250 c-72 -25 -133 -88 -154 -160 -22 -75 -23 -522 -1 -596 19 -66 72 -126 134 -153 l51 -21 1601 0 1601 0 49 21 c28 13 65 39 85 62 57 68 64 109 64 387 0 295 -7 330 -79 401 -84 85 51 79 -1725 79 -1515 -1 -1573 -1 -1626 -20z m710 -460 l0 -330 -326 0 c-354 0 -368 2 -393 57 -7 15 -11 118 -11 284 l0 261 29 29 29 29 336 0 336 0 0 -330z m2541 301 l29 -29 0 -261 c0 -180 -4 -268 -12 -286 -26 -58 34 -55 -1238 -55 l-1170 0 0 330 0 330 1181 0 1181 0 29 -29z" />
            <path d="M3206 2969 c-32 -25 -35 -70 -6 -99 20 -20 33 -20 610 -20 577 0 590 0 610 20 27 27 25 66 -5 95 l-24 25 -579 0 c-571 0 -580 0 -606 -21z" />
            <path d="M3206 2719 c-36 -28 -36 -80 0 -108 27 -21 31 -21 961 -21 l934 0 24 25 c34 33 33 77 -1 104 -27 21 -31 21 -959 21 -928 0 -932 0 -959 -21z" />
        </g>
    </svg>
);
export default SVGComponent;
