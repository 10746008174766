import styled from "styled-components";
import { colors } from 'Style/colors';
import { Menu } from "antd";

const MoneyPopupWrapper = styled.div`
  svg {
    color: ${colors.white};
  }

  &:hover {
    svg {
      color: ${colors.iconHoverColor} !important;
    }
  }
`;

const MoneyPopupInner = styled(Menu)`
  display: flex;
  flex-direction: column;
  top: 0.7rem;
  background-color: ${(props) => props?.isNewUi ? colors.dropDownBgColor : colors.headerBackground};
  border-radius: 0px 0px 8px 8px;
  box-shadow: ${colors.dropDownShadow};
  color:${(props) => props?.isNewUi ? colors.textColor : colors.white} !important;

  .balanceStatusSpan {
    font-weight: bold;
  }

  .support-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 9px 16px;
    color:${(props) => props?.isNewUi ? colors.textColor : colors.white} !important;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.33); // NOTE - DARK_BLUE_BG
    }

    &__img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      path {
        fill: inherit !important;
        color: inherit !important;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
    &__content-label {
      letter-spacing: 0.4px;
    }

    &__content-text {
      font-weight: 500;
      letter-spacing: 0.15px;
      font-size: 16px;
      line-height: 20px;
      color: ${colors.textColor};
    }
  }

  .support-item:hover {
    color:${colors.primary} !important;
  }

`;

const DropdownLink = styled.p`
  display: flex;
  align-self: center;
  margin-bottom: 0;
  margin: 10px;
  padding: 10px;

  & > .ant-dropdown-link {
    margin: 0;
    padding: 0;
    color: white !important;
    &:active > img,
    &:focus > img,
    &:visited > img {
      color: red !important;
    }
  }

  & > img {
    height: 22px;
    width: 35px;
    color: white !important;
    cursor: pointer;
    &:active,
    &:visited,
    &:focus {
      color: dodgerblue !important;
    }
  }

  & > .support-image {
    margin: 0;
    padding: 0;
    width: auto;
    height: 25px;
  }

  & > .support-image:hover {
    path {
      fill: ${colors.iconHoverColor};
      cursor: pointer;
    }
  }

  & > .support-image:active,
  & > .support-image:visited,
  & > .support-image:focus {
    color:  ${colors.iconHoverColor} !important;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  padding: 0;
  &:hover {
    background-color: inherit;
    background: inherit;
    color: ${colors.primary};
    opacity: 1;
    transition: none;
  }
  .balanceStatusSpan {
    font-weight: bold !important;
  }
`

export const FlexCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: ${(props) => props.isActive === 0 ? '#ff0000' : '#fff'};
  padding: 3px 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  background-color: ${(props) => props?.isActive === 0 ? 'white' : ''};

  &:hover {
    ${({isActive}) => isActive === 0 ? 'box-shadow: 0px 7px 14px -2px rgba(34, 60, 80, 0.2);' : 'background: rgba(255, 255, 255, 0.12)'}
  }
`;

export const FlexRow = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:row;
  gap:5px;
`;

export {
  MoneyPopupWrapper,
  MoneyPopupInner,
  DropdownLink,
  StyledMenuItem
};