import React, { memo } from 'react';
import PropTypes from 'prop-types';

// style
import { StyledCheckBoxLabel, StyledInput } from './checkbox.style';

const MyCheckbox = memo(({
  children,
  onKeyDown = (e) => console.log('StyledInput checkbox', e.key),
  ...props
}) => {

  return (
    <>
      <StyledCheckBoxLabel style={props?.labelStyle} className={`'checkbox-label'  ${children || 'flex-box'}`}>
        <StyledInput type="checkbox"  {...props} onKeyDown={onKeyDown} className="create-form-checkbox" />
        <span>{children}</span>
      </StyledCheckBoxLabel>
    </>
  );
});

MyCheckbox.whyDidYouRender = true;

MyCheckbox.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string.isRequired,
  onKeyDown: PropTypes.any,
};

export { MyCheckbox };
