import styled from "styled-components";
import { Select } from 'antd';

export const StyledFlexListForLanding = styled(Select)`
    border: 2px solid #d9d9d9;
    border-radius: 8px;

    [class*='selector'] {
        background-color: transparent !important;
        border: none !important;
    }
`;