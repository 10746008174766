import { createGlobalStyle } from 'styled-components';
import { colors } from 'Style/colors';
import shadows from './shadows';

//Removed colors property because it was confusing as there is a colors object already in a seperate file!!!
export const theme = {
  fonts: {
    sans: 'sans-serif'
  },
  screens: {
    laptop: {
      formElements: {
        height: '36px',
        mainHeight: '44px',
        borderRadius: '8px',
        bgColor: colors.inputBgColor,
        border: `2.2px solid ${colors.inputBorderColor}`
      },
      sizes: {
        xs: '0.75rem',
        sm: '0.875rem',
        base: '1rem',
        lg: '1.25rem'
      }
    }
  },
  fontWeights: {
    light: 100,
    bold: 600
  },
  shadows: {
    focus: '0 0 0 2px rgba(24, 144, 255, 0.2)',
    row: 'inset 0 -1px 0 0 rgb(100 121 143 / 12%)',
    ...shadows
  },
  borders: {
    primary: `1px solid ${colors.primary}`,
    tableBorder: `0.5px solid ${colors.disableColor}`,
    verticalBorder: `0.5px solid ${colors.white}`
  },
  status: {
    received: '#ffa940',
    signed: '#5b8c00',
    rejected: '#f5222d'
  },
  opacity: {
    button: {
      hover: 0.8
    }
  },
  heights: {
    rowHeight: '40px'
  }
};

export const GlobalStyle = createGlobalStyle`
html{
  scroll-behavior:smooth;
  font-size:${({ theme }) => theme.screens.laptop.sizes['base']};

}
  body{
      margin:0;
      padding:0;
      box-sizing:border-box;
      zoom:1;
      &>#root{
        background: ${colors.layoutBackground};
        width: 100%;
        height: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
  }

//.carousel-initialized{
//  top:50%;
//  //transform: translateY(-50%);
//}

`;
