import React from 'react';
import Main from "Pages/Info/pages/main/Main";
import Header from "Pages/Info/components/header/Header";
import Footer from "Pages/Info//components/footer/Footer";
import './style.css';

const Info = () => {
  return (
    <div className="infoContainer">
      <Header />
      <Main />
      <Footer />
    </div>
  );
};

export default Info;
