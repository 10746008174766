import { createSelector } from 'reselect';
import * as con from 'Store/constant';

const initialState = {
  pending: false,
  fail: false,
  first_login: false,
  user: { name: '', tin: '', balance: '', offerId: null }
};

const accountReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case con.GET_USER_DATA_FAIL:
      return {
        ...initialState,
        first_login: state.first_login,
        fail: true
      };
    case con.GET_USER_DATA_SUCCESS:
      return {
        ...initialState,
        first_login: state.first_login,
        user: payload
      };
    case con.UPDATE_USER_DATA:
      return {
        ...initialState,
        first_login: state.first_login,
        user: payload
      };
    case con.GET_USER_DATA:
      return {
        ...initialState,
        first_login: state.first_login,
        pending: true
      };
    case con.CHANGE_LOGIN_USER_DATA:
      return {
        ...state,
        first_login: state.first_login,
        user: {
          ...state.user,
          ...payload
        }
      };
    case 'FIRST_LOGIN': {
      return { ...state, first_login: payload };
    }
    default:
      return state;
  }
};

export const selectAccount = (state) => state.accountData;

export const selectAccountTin = createSelector(selectAccount, (account) => account.user.tin);

export default accountReducer;
