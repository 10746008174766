import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import Button from 'Components/atoms/button';
import { IoMdCloseCircleOutline, IoMdCheckmarkCircleOutline, IoMdInformationCircleOutline } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaPhone, FaTelegram } from 'react-icons/fa';
import { SoliqErrorHandler } from 'Utils/SoliqErrorUtils';
import { getNormalizedErrorText } from 'Utils/getNormalizedErrorText';
import { colors } from 'Style/colors';


export function getColorsByType(type) {
  let color;
  let textColor;
  let bg;

  switch (type) {
    case "success": {
      color = '#2FB676';
      textColor = '#222';
      bg = '#fff';
      break;
    }
    case "error": {
      color = '#FE5E61';
      textColor = '#222';
      bg = '#fff';
      break;
    }
    case "secondary": {
      color = '#222';
      textColor = '#222';
      bg = '#ededed';
      break;
    }
    default: {
      color = '#2977FF';
      textColor = '#222';
      bg = '#fff';
      break;
    }
  }

  return { color, textColor, bg };
}

export function getIconByType(type) {
  let Icon = null;
  switch (type) {
    case "success": {
      Icon = <IoMdCheckmarkCircleOutline color={getColorsByType(type).color} />;
      break;
    }
    case "error": {
      Icon = <IoMdCloseCircleOutline color={getColorsByType(type).color} />;
      break;
    }
    case "secondary": {
      Icon = <IoMdInformationCircleOutline color={getColorsByType(type).color} />;
      break;
    }
    default: {
      Icon = <IoMdInformationCircleOutline color={getColorsByType(type).color} />;
      break;
    }
  }

  return Icon;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => getColorsByType(props.type).bg};
  color: ${props => getColorsByType(props.type).textColor};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 30px;
  font-size: 1rem;
  line-height: 1.5;


  .title {
    color: ${props => getColorsByType(props.type).color};
    font-size: 1.5rem;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .text {
    font-size: 1rem;
    line-height: 1.5;
  }
  p {
    text-align: center;
  }
  
  .actions {
    width: 100%;
    text-align: center;

    button {
      width: 100%;
      max-width: 300px;
    }
  }

  svg {
    width: 70px;
    height: 70px;
  }
`;

const StyledSpan = styled.span`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${colors.primary};

  & > span {
    display: inline-block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(calc(-50% + 10px));
    background-color: ${colors.primary};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    padding: 5px 10px;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
  }
  &:hover {
    text-decoration: underline;
  }
  svg {
    height: 16px;
    width: auto;
  }
`;
const StyledLink = styled.a`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  svg {
    height: 16px;
    width: auto;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const SupportContactBtns = () => {
  const { t } = useTranslation(['translation']);
  const [copied, setCopied] = useState(0);

  useLayoutEffect(() => {
    const id = setTimeout(() => {
      setCopied(0);
    }, 1000);
    return () => clearTimeout(id);
  }, [copied]);

  return (
    <div style={{ display: 'inline-flex', gap: 15 }}>
      <CopyToClipboard text={998951942424} onCopy={() => setCopied(p => ++p)}>
        <StyledSpan>
          <FaPhone /> +998951942424
          {!!copied && <span>
            {t('translation:copySuccess')}
          </span>}
        </StyledSpan>
      </CopyToClipboard>
      <StyledLink target='_blank' href='https://t.me/bdm_24m_admin'>
        <FaTelegram /> Telegram
      </StyledLink>
    </div>
  )
}

const NotificationPopup = ({ data = {}, onOk }) => {
  const { t, i18n } = useTranslation(['errorMsg']);
  const lng = i18n.language;
  const type = data.type;

  let reason = '';
  switch (lng) {
    case 'ru': {
      reason = <p>Если столкнулись с необъяснимой ошибкой, обратитесь в службу {t('errorMsg:support')}: <SupportContactBtns /> </p>
      break;
    }
    case 'latin': {
      reason = <p>Tushunarsiz xatolik bilan to'qnash keldingizmi, iltimos {t('errorMsg:support')} bilan bog'laning: <SupportContactBtns /> </p>
      break;
    }
    case 'cyrillic': {
      reason = <p>Тушунарсиз хатолик билан тўқнаш келдингизми, илтимос {t('errorMsg:support')} билан боғланинг: <SupportContactBtns /> </p>
      break;
    }
    default: {
      reason = <p>Если столкнулись с необъяснимой ошибкой, обратитесь в службу {t('errorMsg:support')}: <SupportContactBtns /> </p>
      break;
    }
  }

  const onClose = (e) => {
    onOk(data.id);
  };

  let isSoliqError = SoliqErrorHandler?.checkIsSoliqError(data?.text);
  let message = getNormalizedErrorText(data?.text) || t('errorMsg:unexpected');
  let isTariffError = message?.includes('Вы должны изменить тариф план') || message?.includes("tarif rejangizni o'zgartirishingiz kerak") || message?.includes('тариф режангизни ўзгартиришингиз керак')

  return (
    <StyledWrapper type={type}>
      {getIconByType(type)}

      <h2 className='title'>
        {data?.title ?? (isSoliqError ? 'My.soliq.uz' : t('errorMsg:smtWrong'))}
      </h2>

      {(!isSoliqError && !message) &&
        <p className='text'>
          {'Сервер не отвечает. Попробуйте перезагрузить страницу позднее.'}
        </p>
      }

      <p className='text'>{message}</p>
      {isTariffError && (<div className='actions'>
        <Button onClick={() => { window.open(`/dashboard/payment`); }} style={{ marginBottom: '10px' }}>{t('errorMsg:changeTariffPlan')}</Button>
      </div>)}
      <p>{reason}</p>


      <div className='actions'>
        <Button color={getColorsByType(type).color} onClick={onClose}>
          OK
        </Button>
      </div>
    </StyledWrapper>
  )
};

export default NotificationPopup;