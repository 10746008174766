import 'react-toastify/dist/ReactToastify.css';
import React, { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { GlobalStyle, theme } from 'Style/globalStyle';
import { PrivateRoute } from 'Components/atoms/privateRoute/privateRoute';
import Loader from 'Components/atoms/spinner/loading';
import { MyFallbackComponent } from 'Components/atoms/errorBoundry/ErrorBoundry';
import { routes } from './routesPath';
import RouteChangeTracker from './Components/atoms/_routeChangeTracker'
import GlobalNotificationModal from 'Components/atoms/toast/GlobalNotificationModal';

const Routes = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <RouteChangeTracker />
          <ToastContainer />
          <ErrorBoundary FallbackComponent={MyFallbackComponent}>
            <Suspense fallback={<Loader />}>
              <Switch>
                {routes.map((route, id) => {
                  if (route.private) {
                    return <PrivateRoute {...route} key={id} />;
                  }
                  return <Route {...route} key={id} />;
                })}
              </Switch>
              <GlobalNotificationModal />
            </Suspense>
          </ErrorBoundary>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default Routes;
