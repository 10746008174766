import React from 'react';
import { colors } from 'Style/colors';

const SVGComponent = ({
  style = { marginBottom: '10px' },
  className = '',
  onClick,
  viewBox = '-3.5 0 22 17.5'
}) => (
  <svg
    style={style}
    width={26}
    height={26}
    viewBox={viewBox}
    onClick={onClick}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 9L12.6 7.6L10 10.2L10 -3.49691e-07L8 -4.37114e-07L8 10.2L5.4 7.6L4 9L9 14L14 9ZM2 18L2 10L-4.37114e-07 10L-7.86805e-07 18C-8.34888e-07 19.1 0.899999 20 2 20L16 20C17.1 20 18 19.1 18 18L18 10L16 10L16 18L2 18Z"
      fill={colors.sideBarIconColor}
    />
  </svg>
);
export default SVGComponent;