import React, { useEffect, useState } from 'react';
import Joyride, { EVENTS } from 'react-joyride';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getStep } from './steps';
import ApiUser from "Services/api/controller/user";
import { selectAccountData, selectDocType } from 'Store/selectors/selectors';
import CommonIcon from 'Assets/icons/common';

/* --------------------------------- styles --------------------------------- */
import { HintsContainer } from './hints.style';
import { getStyles } from './styles';

const Hints = () => {
  const [run, setRun] = useState(false);
  const doctype = useSelector(selectDocType);
  const user = useSelector(selectAccountData);
  const { t } = useTranslation(['hint']);

  const handleClick = (e) => {
    setRun(true);
  };
  // NOTE Joyrideni auto run qilish
  useEffect(() => {
    if (user?.guideline === false) {
      setTimeout(() => setRun(true), 1000);
      setTimeout(() => ApiUser.setGuideline(), 1000);
    }
  }, [user])

  const defaultOptions = {
    overflow: 'scroll',
    arrowColor: '#fff',
    backgroundColor: '#fff',
    beaconSize: 36,
    overlayColor: 'rgba(75, 0, 0, 0.5)',
    primaryColor: '#f04',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: '#333',
    outline: 'none',
    zIndex: 100,
    height: '100%',
    header: {
      showProgress: true
    }
  };

  const handleJoyrideCallback = (data) => {
    const { type } = data;

    if (type === EVENTS.TOUR_END && run) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false);
    }
  };

  const activeIndex = useSelector((state) => state.menues.activeSideBar);
  return (
    <HintsContainer className={'hints-hint'}>
      <a href="#" onClick={handleClick}>
        <CommonIcon width={'24'} height={'35px'} name={'hints'} viewBox={'0 0 24 27'} fill={'white'} />
        <Joyride
          steps={getStep(activeIndex, doctype, t)}
          run={run}
          disableScrollParentFix={true}
          disableScrolling={true}
          disableOverlay={false}
          continuous
          showProgress={true}
          spotlightPadding={0}
          scrollOffset={10}
          showSkipButton
          styles={getStyles(defaultOptions)}
          locale={{
            next: t('next'),
            back: t('back'),
            close: t('close'),
            last: t('last'),
            skip: t('close')
          }}
          callback={handleJoyrideCallback}
        />
      </a>
    </HintsContainer>
  );
};

export default Hints;
