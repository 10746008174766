import styled from "styled-components";

export const Container = styled.div`
  .react-pdf__Document {

    ${(props) => props.isLoaded && ` background:#A9A9A9 !important;`}

    .react-pdf__Page {
      .react-pdf__Page__canvas {
        width: 100% !important;
        height:auto !important;
      }

      .react-pdf__Page__annotations {
        height:15px !important;
        ${(props) => props.isLoaded && ` background:#A9A9A9 !important;`}
      }
    }
  }
`;

export const LoaderWrapper = styled.div`
margin:200px auto;
max-width:100px;
`;