import React from 'react';
import * as _ from '../style';
import { useTranslation } from 'react-i18next';
import {CardHeaderH5} from "../style";

const Basic = ({ setSelectedPlan, showSelectModal, isActive, isLanding }) => {
  const { t } = useTranslation(['login']);

  return (
    <>
      <_.Card isActive={isActive} isLanding={isLanding}>
        <_.CardInline>
          <_.CardHeader>
            <_.CardHeaderH2>Individual</_.CardHeaderH2>
            <_.CardHeaderH1 isLanding={isLanding}>Бесплатно</_.CardHeaderH1>
            <CardHeaderH5>Отправляйте <br/> 50 документов в месяц БЕСПЛАТНО</CardHeaderH5>
          </_.CardHeader>
          <_.CardBottom>
            <_.CardBottomButton
              isLanding={isLanding}
              onClick={() => {
                setSelectedPlan(1);
                showSelectModal();
              }}>
              Выбрать
            </_.CardBottomButton>
          </_.CardBottom>
        </_.CardInline>
      </_.Card>
    </>
    /*     <_.CardItem isActive={isActive}>
      <_.IconHolder>
        <BasicIcon fill={isActive ? '#FFFFFF' : '#64c5e1'} />
      </_.IconHolder>
      <_.Cardtitle isActive={isActive}>Basic</_.Cardtitle>
      <_.SubscriptionPrice>
        <_.Currency isActive={isActive}>UZS</_.Currency>
        <_.StyledPriceLabel isActive={isActive}>
          25 000 <_.StyledText isActive={isActive}>/ {t('payment.planSection.month')}</_.StyledText>
        </_.StyledPriceLabel>
      </_.SubscriptionPrice>
      <_.SelectBtn
        onClick={() => {
          setSelectedPlan(1);
          showSelectModal();
        }}>
        {t('payment.planSection.select')}
      </_.SelectBtn>
      <_.CardOptions>
        <_.OptionLabel isActive={isActive} style={{ marginTop: '35px' }}>
          {<TrueIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive}>{t('payment.planSection.withoutPayment')}</_.OptionLabel>
        <_.OptionLabel style={{ borderBottom: 'none' }} isActive={isActive}>
          <span style={{ height: '21px' }}></span>
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive}>{t('payment.planSection.freeUpTo1000Documents400SumPerDocument')}</_.OptionLabel>
        <_.OptionLabel isActive={isActive}>500 {t('payment.planSection.sum') + ' ' + t('payment.planSection.perDocument')}</_.OptionLabel>
        <_.OptionLabel isActive={isActive}>{'PDF'}</_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '22.85px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive}>{'10'}</_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
        <_.OptionLabel isActive={isActive} style={{ padding: '12px 0' }}>
          {<FalseIcon isActive={isActive} />}
        </_.OptionLabel>
      </_.CardOptions>
    </_.CardItem> */
  );
};

export default Basic;
