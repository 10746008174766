// ANCHOR npm packages
import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
// ANCHOR custom  components
import PublicDropZone from '../publicDropZone';
import Button from 'Components/atoms/button';
// ANCHOR style
import { ExcelUploadContainer } from './excelPublicUpload.style';

const ExcelPublicUpload = forwardRef(({ change, filePath, onSave, isLoading, ...props }, span) => {
  const { t } = useTranslation(['common']);

  const changed = (dz, isSuccess) => {
    if (!isSuccess) return;

    change(dz);
  };
  return (
    <ExcelUploadContainer>
      <PublicDropZone changed={changed} onClear={props.onClear} />
      {/* <div style={{ textAlign: 'end', marginTop: '1.25rem' }}>
        {' '}
        <Button loading={isLoading} onClick={onSave}>{t('save')}</Button>
      </div> */}
    </ExcelUploadContainer>
  );
});

export default memo(ExcelPublicUpload);
