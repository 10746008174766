import React, { useState, useEffect, useRef } from 'react';
import SinglePage from './SinglePage';

export default function PdfViewer({ src, }) {
    const [result, setResult] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [redoStack, setRedoStack] = useState([]);
    const [flag, setFlag] = useState("");
    const [bounds, setBounds] = useState({});
    const [isText, setIsText] = useState(false);
    const [buttonType, setButtonType] = useState("");
    const tempRef = useRef(null);

    useEffect(() => {
        if (isText) {
            setIsText(false);
        }
    }, [result])

    //Keep track of current page number
    const pageChange = (num) => {
        setPageNumber(num);
    }

    //Function to add text in PDF
    const addText = () => {
        //Flag to change cursor if text
        setIsText(true);
        document.getElementById("drawArea").addEventListener("click", (e) => {
            e.preventDefault();
            setResult(result => [...result, { id: generateKey(e.pageX), x: e.pageX, y: e.pageY - 10, text: "", page: pageNumber, type: "text", ref: tempRef }]);
        }, { once: true });
    }

    //Undo function for both line and text
    const undo = () => {
        let temp = result.pop();
        if (temp) {
            if (temp.type === "freehand") {
                //Flag for DrawArea reference
                setFlag("undo");
            }
            setRedoStack(stack => [...stack, temp]);
            setResult(result);
        }
    }

    //Flag for DrawArea reference
    const changeFlag = () => {
        setFlag("");
    }

    //Redo functionality
    const redo = () => {
        let top = redoStack.pop();
        if (top) {
            if (top.type === "freehand") {
                //Flag for DrawArea reference
                setFlag("redo");
            }
            setResult(res => [...res, top]);
        }
    }

    const getPaths = (el) => {
        setResult(res => [...res, el]);
    }

    const getBounds = (obj) => {
        setBounds(obj);
    }

    const generateKey = (pre) => {
        return `${pre}_${new Date().getTime()}`;
    }

    const onTextChange = (id, txt, ref) => {
        let indx = result.findIndex(x => x.id === id);
        let item = { ...result[indx] };
        item.text = txt;
        item.ref = ref;
        result[indx] = item;
        setResult(result);
    }

    const changeButtonType = (type) => {
        setButtonType(type);
    }

    const resetButtonType = () => {
        setButtonType("");
    }

    return (
        <div >
            {/* <div className="navbar">
                <button onClick={() => changeButtonType("download")} style={{ marginTop: "1%", marginBottom: "1%" }}><i style={{ fontSize: 25 }} className="fa fa-fw fa-download"></i></button>
            </div> */}
            <SinglePage
                resetButtonType={resetButtonType}
                buttonType={buttonType}
                cursor={isText ? "text" : "default"}
                pdf={src}
                pageChange={pageChange}
                getPaths={getPaths}
                flag={flag}
                getBounds={getBounds}
                changeFlag={changeFlag}
            />
        </div>
    );
}
