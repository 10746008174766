import styled from 'styled-components';
import { colors } from 'Style/colors';
import get from 'lodash.get';
import getBaseStyleFormField from './baseStyleFormField';

const rent = getBaseStyleFormField('select');

const BaseStyledSelect = styled(rent)`
    ${props => props?.name === "singleSidedType" ?
    `height: ${get(props?.theme, 'screens.laptop.formElements.mainHeight')};
       min-height: ${get(props?.theme, 'screens.laptop.formElements.mainHeight')};
       padding: 0 8px;
       background-image: linear-gradient(45deg, transparent 50%, hsl(0, 0%, 80%) 50%),
       linear-gradient(135deg, hsl(0, 0%, 80%) 50%, transparent 50%),
       linear-gradient(to right, #ccc, #ccc);
       background-position: calc(100% - 20px) calc(1em + 4px),
       calc(100% - 15px) calc(1em + 4px),
       calc(100% - 2.55em) 0.55em;
       background-size: 5px 5px,
       5px 5px,
       1px 2.1em;
       background-repeat: no-repeat;
       appearance: none;
       ` : ''
  };


    &.error-field {
    border: 1px solid ${colors.warning};
  }
  
`;

export default BaseStyledSelect;
