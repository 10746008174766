import findlastindex from 'lodash.findlastindex';
import toSearch from 'Utils/search';
import { WITHOUT_OBJECT_VALUE } from 'Hooks/useFilter';
import { generateRequestBodyBoxModule } from 'Store/middlewares/getDocuments.js';

export const onGenerateUrl = ({ documentTypes, boxType, part, argParams, tin, start, limit, isSearch, moduleType }) => {
  let requestBody = generateRequestBodyBoxModule({
    params: argParams,
    boxType,
    part,
    start,
    limit,
    isSearch,
    requestBody: documentTypes,
    isFetch: false,
    moduleType
  });

  return requestBody;
};

export const isCheckedHandler = (list, property, value) => {
  let searchedItem = list?.filter((item) => item[property] === value) || [];

  if (searchedItem) return true;
  return false;
};

export const filterList = (list, value, selectedProperty) => {
  const isInclude = list.includes(value);

  if (list.includes(value)) return list?.filter((item) => item !== value) || [];

  if (selectedProperty === 'constructionObject' && !isInclude && value === WITHOUT_OBJECT_VALUE) {
    return [value];
  }

  return [...list, value];
};

export const checkedValuesHandler = ({ list, property, selectedProperty }) => {
  let arr = [];

  (Array.isArray(list) ? list : [1, 2])?.forEach((item, index) => (arr = [...arr, item[property]]));

  if (selectedProperty === 'constructionObject') return getSelectedCheckBoxesConstructionObjects(arr);

  return arr;
};

export const controlSelectAllAction = (checkedList, activePropertyValues, identify, selectedProperty) => {

  if (selectedProperty === 'constructionObject' && checkedList.length === activePropertyValues.length - 1) return [];

  if (checkedList.length === activePropertyValues.length) return [];
  return checkedValuesHandler({ list: activePropertyValues, property: identify });
};

export const headerInputChangeHandler = (list, value) => {
  return (
    list?.filter((item) => {
      return toSearch(item['name'] + item['tin'], value);
    }) || []
  );
};

export const getIdentifyProperty = (selectedProperty) => {
  if (selectedProperty.startsWith('tin')) return 'tin';
  if (selectedProperty === 'constructionObject') return 'id';
  if (selectedProperty === 'name') return selectedProperty;
  if (selectedProperty === 'mainSignerName') return 'tin';
  if (selectedProperty === 'registererName') return 'tin';
  return 'code';
};

export const getHeight = (property) => {
  switch (property) {
    case 'tin':
    case 'name':
    case 'name2':
    case 'tin2':
    case 'typeName':
    case 'registererName':
      return 370;
    case 'number':
    case 'sum':
    case 'letterType':
      return 170;
    case 'date':
    case 'contract':
    case 'letterDeadline':
      return 180;
    case 'dateAndNumber':
    case 'parent':
      return 280;
    case 'branchName':
      return 188;
    case 'filialNum':
    case 'ageInDays':
      return 100
    default:
      return 370;
  }
};

export const getWidth = (property) => {
  switch (property) {
    case 'tin':
    case 'tin2':
      return 300;
    default:
      return 280;
  }
};

export const onFormatNumberForSearch = (value) => {
  if (!value) return value;
  let lastIndexOfDot = 0,
    characters = [];

  characters = value?.split('');

  lastIndexOfDot = findlastindex(characters, function (item) {
    return item === '.';
  });

  characters = (
    characters?.filter((character, index) => {
      if (character === '.' && index === lastIndexOfDot) return true;
      if (character !== '.' && character !== ',') return true;

      return false;
    }) || []
  ).join('');

  return characters;
};

export function getSelectedCheckBoxesConstructionObjects(list) {
  return list?.filter((_item) => _item !== WITHOUT_OBJECT_VALUE) || [];
}

