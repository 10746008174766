import React from 'react';

const SVG = ({ style = {}, fill = '#000', width = '100%', onClick, className = '', viewBox = '0 0 40 26' }) => (
  <svg
    width={width}
    style={style}
    onClick={onClick}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M28.5 6.57912H22V4.57912C21.9983 3.19899 20.8801 2.08083 19.5 2.07912H10.5C9.11987 2.08083 8.00171 3.19899 8 4.57912V6.57912H1.5C0.671631 6.57912 0 7.25075 0 8.07912V19.209C0.00317383 19.8433 0.402832 20.4075 1 20.6206V27.5791C1 28.4075 1.67163 29.0791 2.5 29.0791H27.5C28.3284 29.0791 29 28.4075 29 27.5791V20.6211C29.5972 20.4077 29.9971 19.8433 30 19.209V8.07912C30 7.25075 29.3284 6.57912 28.5 6.57912ZM9 4.57912C9 3.75075 9.67163 3.07912 10.5 3.07912H19.5C20.3284 3.07912 21 3.75075 21 4.57912V6.57912H20V4.57912C20 4.30299 19.7761 4.07912 19.5 4.07912H10.5C10.2239 4.07912 10 4.30299 10 4.57912V6.57912H9V4.57912ZM19 6.57912H11V5.07912H19V6.57912ZM28 27.5791C28 27.8552 27.7761 28.0791 27.5 28.0791H2.5C2.22388 28.0791 2 27.8552 2 27.5791V20.7925L13 22.3096V23.5791C13 24.4075 13.6716 25.0791 14.5 25.0791H15.5C16.3284 25.0791 17 24.4075 17 23.5791V22.3096L28 20.7925V27.5791ZM16 23.5791C16 23.8552 15.7761 24.0791 15.5 24.0791H14.5C14.2239 24.0791 14 23.8552 14 23.5791V20.5791C14 20.303 14.2239 20.0791 14.5 20.0791H15.5C15.7761 20.0791 16 20.303 16 20.5791V23.5791ZM29 19.209C29.0002 19.4583 28.8169 19.6697 28.5701 19.7046L28.4314 19.7236L17 21.3001V20.5791C17 19.7507 16.3284 19.0791 15.5 19.0791H14.5C13.6716 19.0791 13 19.7507 13 20.5791V21.3001L1.43042 19.7046C1.18359 19.6699 0.999756 19.4585 1 19.209V8.07912C1 7.80299 1.22388 7.57912 1.5 7.57912H28.5C28.7761 7.57912 29 7.80299 29 8.07912V19.209Z"
      fill="white"
    />
  </svg>
);

export default SVG;
