import { toast } from 'react-toastify';
import { config } from 'Components/atoms/toast';

export function soliqErrorParseAndToast(message) {
  try {
    const jsonString = message.replace('my.soliq.uz ', '');
    const errSoliqObj = JSON.parse(jsonString) || {};
    const configError = { ...config, autoClose: 8000 };
    toast.error(`my.soliq.uz: ${errSoliqObj.getProp('ErrorMessage', jsonString)}`, configError);
  } catch (err) {
    toast.error(message, config);
  }
}