import BaseStyledLabel from "Common/Style/styledLabel";
import styled, { css } from "styled-components";
import { theme } from 'Style/globalStyle';
import { colors } from 'Style/colors';

const changedTheme = {
  ...theme, screens: {
    ...theme.screens,
    laptop: {
      ...theme.screens.laptop,
      formElements: {
        ...theme.screens.laptop.formElements,
        mainHeight: '38px',
      },
    }
  }
}

const StyledForm = styled.form`
  
`;

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 0 15px 0;
  list-style: none;
`;

const StyledTab = styled.li`
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  color: ${colors.primary};
  margin-top:10px;

  display: flex;
  /* flex-direction: row-reverse; */
  align-items: center;
  gap: 20px;

  
  &>label:nth-child(1) {
    min-width: 200px !important;
    width: auto !important;
    cursor: pointer;

    flex-direction: row-reverse;
    justify-content: space-between;
  }
  &>div:nth-child(2) {
    flex-grow: 1;
  }

  &.selected_tab {
    text-decoration: underline;
    font-weight: 700;
  }
`;
const BaseLabelWrapper = styled.div`
  position: relative;

`;

const ReStyledBaseLabel = styled(BaseStyledLabel)`
  ${({ absolute }) => absolute ? css`
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
  ` : ''}

  ${props => props.requiredLabel ? css`
    &::after {
      color: red;
      content: ' *';
    }
  ` : ''}

  font-size:16px;
`;

const FlexListStyle = {
  option: (providedStyles, state) => (
    {
      ...providedStyles,
      padding: 0,
      paddingLeft: 5,
      paddingTop: 3,
      paddingBottom: 3,
      color: 'black',
      opacity: 0.8,
      minHeight: '34px',
      display: 'flex',
      alignItems: 'center',
      height: 'auto !important',
      lineHeight: '1.2 !important'
    }
  )
}

const TagItem = styled.div`
    background-color: #d4d5d6;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0.3rem 0 0 0.3rem;
    color:#565656;
`

const TagBtn = styled.button`
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    margin-bottom:2px;
    font-size:16px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color:#565656;
`

const ErrorStyle = {
  margin: '0px',
  fontSize: '13px',
  color: '#FF8A00'
}

const HasError = {
  borderColor: '#FF8A00'
}

const InputStyle = {
  border: 'none',
  boxShadow: 'none'
}

const MultipleInputContainer = styled.div`
border: 2px solid #d9d9d9;
`;

export {
  changedTheme,
  StyledForm,
  StyledTab,
  StyledList,
  ReStyledBaseLabel,
  FlexListStyle,
  BaseLabelWrapper,
  TagItem,
  TagBtn,
  ErrorStyle,
  HasError,
  InputStyle,
  MultipleInputContainer
};
