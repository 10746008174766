import get from 'lodash.get';
import ApiDocument from 'Services/api/controller/document';
import incomeBoxModule from 'Context/box/global/models/incomeBox';

// ANCHOR Utils and Services functions

import * as con from 'Store/constant';
import browserStorage from 'Services/browserStorage';

const generateRequestBodyBoxModule = ({ params, boxType, part, start, limit, isSearch, requestBody, isFetch, moduleType }) => {
  requestBody = {
    boxType,
    part,
    start,
    limit
  };

  if (!isSearch) return requestBody;

  try {
    requestBody = {
      ...requestBody,
      ...incomeBoxModule.generateBody(params, isFetch)
    };
    return requestBody;
  } catch (e) {
    return e.message;
  }
};

const getOutGoingData = ({
  boxType = null,
  start = 0,
  ageInDays = 12,
  limit = 50,
  scroll = false,
  argParams = {},
  isSearch = false,
  moduleType = incomeBoxModule.moduleType,
  func = ApiDocument.getDocumentList,
  dataKey,
  extraParams // NOTE - default ojidayushiy docni olish uchun
}) => {
  return async (dispatch, getState) => {
    const part = getState().outDocuments.part;
    // NOTE get document list @@@@@@@ loading @@@@@@@@@
    dispatch({ type: con.GET_OUTGOING_DATA_LIST });

    let requestBody = {};
    let resetProperty;
    if (dataKey === 'tin') {
      resetProperty = { tin: [] };
    } else if (dataKey === 'typeName') {
      resetProperty = { type: [] };
    } else if (dataKey === 'dateAndNumber') {
      resetProperty = { dateFrom: '', dateTo: '', id: '' };
    } else if (dataKey === 'contract') {
      resetProperty = { contractDate: '', contractNumber: '' };
    } else if (dataKey === 'ageInDays') {
      resetProperty = { ageInDaysFrom: null, ageInDaysTo: null };
    } else if (dataKey === 'sum') {
      resetProperty = { fromSum: '', toSum: '' };
    } else if (dataKey === 'state') {
      resetProperty = { state: [] }
    }

    // NOTE generation url for request --------------
    requestBody = generateRequestBodyBoxModule({
      params: argParams,

      boxType,
      part,
      start,
      ageInDays,
      limit,
      isSearch,
      requestBody,
      isFetch: true,
      moduleType
    });
    requestBody = { ...requestBody, ...extraParams, ...resetProperty };

    // NOTE request get documentlist ------------
    let { data = [], fail } = await func(requestBody);
    // NOTE request which is get document list ********* fail ***********
    if (fail) return dispatch({ type: con.GET_OUTGOING_DATA_LIST_FAIL });
    // NOTE request which is get document list ========= success ========
    dispatch({
      type: con.GET_OUTGOING_DATA_LIST_SUCCESS,
      payload: { ...data, documentTypes: [], scroll }
    });
  };
};

const fetchList = ({ dispatch, getState, boxType, getList, moduleType }) => {
  const limit = get(getState(), 'outDocuments.limit');

  const filterData = browserStorage.get('filterData');
  dispatch(
    getOutGoingData({
      boxType,
      start: 0,
      limit,
      scroll: false,
      argParams: filterData,
      isSearch: filterData.status !== 'initial' || filterData.status !== 'initial_request',
      func: getList,
      moduleType
    })
  );
};

export { fetchList, getOutGoingData, generateRequestBodyBoxModule };
