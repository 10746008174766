import React from 'react';
import { useSelector } from 'react-redux';

import GlobalSearch from 'Components/organisms/globalSearch';
import { Navbar } from 'Components/molecules/navbar/navbar';
import { Logo } from 'Components/molecules/logo/logo';

// ANCHOR style
import { AntdHeader, FlexRow } from './header.style';
import MoneyPopup from 'Components/molecules/navbar/MoneyPopup';

const Header = (props) => {
  const isNewUi = useSelector((state) => state.ui.isNewUi);

  return (
    <AntdHeader isNewUi={isNewUi}>
      <FlexRow>
        <Logo />
        <MoneyPopup />
      </FlexRow>
      {/* <GlobalSearch/> */}
      <Navbar {...props} />
    </AntdHeader>
  );
};

Header.whyDidYouRender = true;

export { Header };
