import React, { useState, useRef } from "react";
import { Popover } from 'antd';
import Tooltip from 'Components/atoms/tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyClipboard, Successfull } from 'Assets/icons/common/copyClipboard';
import * as _ from './style';

export const CustomPopover = ({ children: content, withIcon }) => {
    const [contentCopied, setContentCopied] = useState(false);

    const handleContentCopy = () => {
        setContentCopied(true)
        setTimeout(() => setContentCopied(false), 2000)
    }

    return (
        <CopyToClipboard text={content} onCopy={() => handleContentCopy()}>
            <_.StyledTin>
                {contentCopied ?
                    <Tooltip placement="top" title={'Copied'} >
                        {content}
                    </Tooltip>
                    :
                    <Tooltip placement="top" title={'Copy'}>
                        {content}
                    </Tooltip>
                }

                {withIcon &&
                    <_.IconHolder>
                        {contentCopied ?
                            <Tooltip placement="top" title={'Copied'} >
                                <Successfull />
                            </Tooltip>
                            :
                            <Tooltip placement="top" title={'Copy'}>
                                <CopyClipboard />
                            </Tooltip>
                        }
                    </_.IconHolder>}
            </_.StyledTin>
        </CopyToClipboard>
    );
}



export default function AntdPopover({ children, renderBtns, ...props }) {
    const ref = useRef();

    return <Popover ref={ref} content={renderBtns(ref)} {...props}>
        {children}
    </Popover>
}