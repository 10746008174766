import React from 'react';

const SupportSvg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="currentColor" {...props}>
      <path d="M0 9C0 4.03 4.03 0 9 0C13.97 0 18 4.03 18 9V14C18 15.1 17.1 16 16 16C16 17.933 14.433 19.5 12.5 19.5H10.8662C10.6934 19.799 10.3701 20 10 20H9C8.44775 20 8 19.5522 8 19V18.5C8 17.9478 8.44775 17.5 9 17.5H10C10.3701 17.5 10.6934 17.701 10.8662 18H12.5C13.6046 18 14.5 17.1046 14.5 16H13V9H16C16 5.13 12.87 2 9 2C5.13 2 2 5.13 2 9H5V16H2C0.9 16 0 15.1 0 14V9Z" fill="currentColor"/>
    </svg>
  )
}

export default SupportSvg;