import React from 'react';
import { colors } from 'Style/colors';

const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -960 960 960"
    height={26}
    width={26}
    fill={colors.sideBarIconColor}
    {...props}
  >
    <path d="M666-440 440-666l226-226 226 226-226 226Zm-546-80v-320h320v320H120Zm400 400v-320h320v320H520Zm-400 0v-320h320v320H120Z" />
  </svg>
);
export default SVGComponent;




// const Discover = (props) => {
//   return (
//     <svg
//       version="1.1"
//       id="Слой_1"
//       xmlns="http://www.w3.org/2000/svg"
//       x="0px"
//       y="0px"
//       viewBox="0 0 29 29"
//       {...props}
//       >
//       <style type="text/css"></style>
//       <g>
//         <path class="st0" d="M12.9,18.2L12.9,18.2L12.9,18.2l0.1,0.1L12.9,18.2z M12.9,18.2L12.9,18.2L12.9,18.2l0.1,0.1L12.9,18.2z" />
//         <polygon class="st0" points="13,18.3 12.9,18.3 12.9,18.2 12.9,18.2 	" />
//         <path
//           class="st0"
//           d="M12.9,0v3.8H3.5V29h14.9V3.8h-4.7V0.9h9.5v17.4h-3.5v0.9h4.4V0H12.9z M17.3,27.8H5.7l7.9-8.6h3.7V27.8z
// 		 M17.3,5v13.3h-3.5v-12h-0.9v11.9l0,0l0.1,0.1h-0.1v-0.1L4.7,27V5H17.3z"
//         />
//       </g>
//     </svg>
//   );
// };

// export default Discover;
