import styled from 'styled-components';
import get from 'lodash.get';
import BaseFlex from 'Common/Style/styledFlex';
import { Menu } from 'antd';

import { colors } from 'Style/colors';

export const StyledMenu = styled(Menu)`
  width: 100%;
  height:50px !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color:#7A7B8F;
  background: ${colors.white}!important;

  .ant-menu-sub.ant-menu-inline {
    background: ${colors.white}!important;
  }


  .ant-menu-submenu-title {
    margin:0px 0px !important;
    padding:8px 8px !important;
    background: ${colors.white}!important;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color:#5c5d6b !important;
    border-top:1px solid #E7E8FE;
    border-bottom:1px solid #E7E8FE;
    height:52px !important;
  }

  .ant-menu-item-only-child {
    margin:0px 0px !important;
    padding:8px 8px !important;
    padding-left:18px !important;
    background: ${colors.white}!important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color:#7A7B8F;
    border-top:1px solid #E7E8FE;
    border-bottom:1px solid #E7E8FE;
    height:52px !important;
  }

  .ant-menu-title-content {
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0px 4px;
    height:40px;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    .ant-menu-title-content {
      color:${colors.white};
      border-radius:6px;
    }

    background: ${colors.primary} !important;
    
    ::after {
        opacity: 0;
        display:none;
    }
  }

  .ant-menu-item-only-child.isReport { //report sectionning subsidebari uchun
    padding:0px !important;
    width:40px !important;
  }

  .my-ant-isReport { //report sectionning subsidebari uchun
    .ant-menu-submenu-title {
        .ant-menu-title-content {
            margin:0px 0px !important;
        }
    }
  }

  .isReport {
    .ant-menu-title-content{
      justify-content:flex-start;
    }
  }

 
  .ant-menu-item {

    &:hover {
      color:${colors.white} !important;
      background: ${colors.primary} !important;
    }

    ::after {
      display:none !important;
    }
  }
  
`;

export const StyledBadge = styled.div`
  height: 20px;
  margin-left: auto;
  span {
    background: ${colors.badgeColor};
    font-size: ${({ theme }) => get(theme, 'screens.laptop.sizes.xs')};
    border-radius: 4px;
    padding: 3px 10px;
  }
`;

export const SoliqIcon = styled.div`
  position: relative;
  & > img {
    height: 24px;
    position: relative;
    margin-right: 5px;
    top: 0;
    border-radius:4px;
  }
`;
export const SkletonParag = styled.p`
  margin: 20px 25px;
`;

export const StyledCounter = styled.input`
  width: 20px;
  height: 20px;
  padding: 0 3px;
  border-radius: 3px;
  background: ${({ disable, theme }) => (disable ? get(theme, 'colors.disableColor') : get(theme, 'colors.primary'))};
  margin-right: 10px;
  text-align: center;
  line-height: 20px;
  color: white;
`;

// export const StyledMenuItem = styled()
