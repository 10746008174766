import client from 'Services/api/client';
import ApiSettings from './settings';
import { apiService } from '../client';
// const base_url_controller = 'client-products';
const base_url_controller = 'classifications';

const ApiClientProducts = {
  async getAllFilterProducts(params) {
    return await apiService
      .setMethod('GET')
      .setUrl(`/classifications/get/commodity/data?selectType=${params}&pageNumber=1&limit=100000`)
      .setBody()
      .request();
  },
  async getFilteredProducts(body) {
    return await client('POST', 'classifications/get/filter/client-product?pageNumber=1&limit=10000', body);
  },
  async getAllProducts(body) {
    return await client(
      undefined,
      `${base_url_controller}/get-client-products?pageNumber=${body.page}${body.limit ? `&limit=${body.limit}` : ''}`
    );
  },
  async getOneProduct(id) {
    return await client(undefined, `${base_url_controller}/get-one/${id}`);
  },
  async getFullDataMxik(classCode) {
    return await client(undefined, `info/get/package/${classCode}`);
  },
  async searchProducts({ keyword = '' } = {}) {
    return await client('POST', `${base_url_controller}/search/${keyword}`);
  },
  async addProduct(body) {
    return await client(
      'POST',
      `${base_url_controller}/add-product`,
      body,
      { 'Content-Type': 'multipart/form-data' },
      undefined,
      'multipart/form-data'
    );
  },
  async updateProduct(body) {
    return await client(
      'PUT',
      `${base_url_controller}/update-product/${body.id}`,
      body,
      { 'Content-Type': 'multipart/form-data' },
      undefined,
      'multipart/form-data'
    );
  },
  async deleteProduct(classcode) {
    return await ApiSettings.deleteClassification(classcode);
  },
  async downloadImage(imageUrl) {
    return await client('GET', imageUrl, undefined, { responseType: 'blob' }, { responseType: 'blob' });
  }
};

export default ApiClientProducts;
