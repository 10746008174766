import styled from "styled-components";

const Wrapper = styled.div`
  display:flex;
  gap:20px;
  margin-top:10px;
`;

const TypeCard = styled.div`
   display:flex;
   flex-direction:column;
   align-items:center;
   text-align:center;
   margin:20px 30px 10px 10px; 
   cursor:pointer;
`;

const ClickCard = styled.div`
  padding: 10px;
  width: 203px;
  height: 258px;
  display: inline-block;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  color: rgb(44, 44, 44);
  font-weight: 700;
`

const ClickForm = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  flex-direction:column;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.5px;
`;



export { Wrapper, ClickCard, TypeCard, ClickForm, Label }
