import styled from 'styled-components';
import get from 'lodash.get';
import { Menu } from 'antd';

import { colors } from 'Style/colors';

export const ProfileMenuContainer = styled(Menu)`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 0.8rem;
  background-color:${(props) => props?.isNewUi ? colors.dropDownBgColor : colors.headerBackground};
  border-radius: 0px 0px 8px 8px;
  box-shadow: ${colors.dropDownShadow};

  & > .menu__title {
    display: flex;
    justify-content: center;
    margin: 5px auto 0 auto;
    font-style: normal;
    font-size: ${({ theme }) => get(theme, 'screens.laptop.sizes.lg')};
    line-height: 28px;
    text-align: center;
    font-weight:500;
    color:${(props) => props?.isNewUi ? colors.textColor : colors.white} !important;
  }

  & > span:nth-child(2) {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    color:${(props) => props?.isNewUi ? colors.textColor : colors.white} !important;
    font-size: ${({ theme }) => get(theme, 'screens.laptop.sizes.sm')};
  }
`;

export const MenuButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary};
  color: white !important;
  height: 2.75rem;
  margin-top: 1rem;

  transition: box-shadow 0.3s;
  &:hover {
    color: #fff;
    -webkit-box-shadow: 0px 0px 13px 2px rgba(171, 219, 255, 0.96);
    -moz-box-shadow: 0px 0px 13px 2px rgba(171, 219, 255, 0.96);
    box-shadow: 0px 0px 13px 2px rgba(171, 219, 255, 0.96);
    cursor: pointer;
  }
`;

export const MenuItemContainer = styled(Menu.Item)`
  /* height: 100px; */
  &:nth-child(9) {
    height: 50px;

    & > img {
      margin-top: 20px;
      line-height: 30px;
    }
  }

  &:hover {
    background-color: ${(props) => props?.isNewUi ? colors.dropDownHoverBg : 'rgb(114, 114, 114)'};
    background: ${(props) => props?.isNewUi ? colors.dropDownHoverBg : 'rgb(114, 114, 114)'};
  }

  &:last-child > span > div > a > p  {
    color: ${(props) => props?.isNewUi ? colors.backward : colors.white} !important;
  }

  // &:last-child:hover {
  //   background-color: ${(props) => props?.isNewUi ? colors.backwardHoverBg : 'rgb(114, 114, 114)'};
  //   background: ${(props) => props?.isNewUi ? colors.backwardHoverBg : 'rgb(114, 114, 114)'};
  // }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => props.height};
  line-height: ${(props) => props.LHeight};
  margin-bottom: ${(props) => props.MBottom};
  & > span .img {
    width: 25px;
  }

  & > p,
  .item-link {
    margin: 0;
    padding: 0;
    margin-left: 30px;
    text-align: center;
    color: white;
  }
`;

export const SettingsComponentImage = styled.img`
  width: 25px;
`;

export const SettingsRightContainer = styled.span`
  margin-left: 20px;

  & > p {
    color:${(props) => props?.isNewUi ? colors.textColor : colors.white} !important;
  }

  & > a {
    margin-top: 0;
    color: ${colors.primary};
  }

  &.dropdown-text {
    color:${(props) => props?.isNewUi ? colors.textColor : colors.white} !important;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
  }

`;

export const TarifClass = styled.span`
  margin: 20px;
  & > span:first-child {
    display: flex;
    font-family: Roboto;
    font-style: normal;
    text-decoration-line: underline;
    color: ${({ theme }) => get(theme, 'colors.primary')};
    font-weight: normal;
    font-size: ${({ theme }) => get(theme, 'screens.sizes.sm')};
    justify-content: center;
  }
`;




export const QuitComponentImage = styled.img`
  width: 25px;
`;

export const QuitComponentText = styled.a`
  margin-left: 20px;
  & > p {
    margin: 0;
    color:${(props) => props?.isNewUi ? colors.textColor : colors.white} !important;
  }
`;
export const DropdownLink = styled.p`
  display: flex;
  align-self: center;
  margin-bottom: 0;
  margin: 10px;
  padding: 9px 12px;
  transition: background-color 0.3s ease;

  & > .ant-dropdown-link {
    margin: -10px;
    padding: 0;
    color: white !important;
    &:active > img,
    &:focus > img,
    &:visited > img {
      color: red !important;
    }
  }

  & > img {
    height: 22px;
    width: 35px;
    color: white !important;
    cursor: pointer;
    &:active,
    &:visited,
    &:focus {
      color: dodgerblue !important;
    }
  }

  & > .user-image {
    margin: 0;
    padding: 0;
    width: 20px;
    height: 25px;
  }

  &:hover {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.12);
    cursor:pointer;
  }

  & > .user-image:active,
  & > .user-image:visited,
  & > .user-image:focus {
    color: dodgerblue !important;
  }
`;


export const StyledZoomIconsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  width:200px;
  & > div {
    height: 60%;
    width:70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.primary};
    border-radius: 1rem;
    & > svg {
      font-size: 2rem;
      color: white;
    }

    &>span{
       color:white;
       font-size:1.2rem;
    }
  }
`;
