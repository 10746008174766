import React from 'react';

import Login from 'Modules/auth/container/auth';

const Auth = () => {
  return <Login />;
};

// Auth.whyDidYouRender = true;

export default Auth;
