import styled, { keyframes } from 'styled-components';
import BaseFlex from 'Common/Style/styledFlex';
import { colors } from 'Style/colors';

const StyledCartItem = styled(BaseFlex)`
  padding: 0.5rem 0.8rem;
  background-color: white;
  min-width: 140px;
  max-width: 280px;
  min-height: 140px;
  max-height: 200px;
  /* text-align: center; */
  margin: 0.625rem;
  padding-bottom: 30px;
  border-radius: 0.5rem;
  border: 0.5px solid ${colors.primary};
  & svg {
    font-size: 40px;
  }
`;

const StyledGoButton = styled(BaseFlex)`
  width: 40px;
  height: 40px;
  border: 2px solid ${colors.primary};
  border-radius: 4px;
  transition: all 0.1s ease-in-out;

  & > svg {
    color: ${colors.primary};
    font-size: 20px;
  }

  &:hover {
    background-color: ${colors.primary};
    cursor: pointer;
    & > svg {
      color: white;
    }
  }
`;

export { StyledCartItem, BaseFlex, StyledGoButton };
