import * as React from "react";

const CardIcon = (props) => (
    <svg
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style={{
            enableBackground: "new 0 0 512 512",
        }}
        xmlSpace="preserve"
        role="img"
        {...props}
    >
        <g>
            <g>
                <path
                    style={{
                        fill: "#5D647F",
                    }}
                    d="M472,72H40C17.945,72,0,89.945,0,112v288c0,22.055,17.945,40,40,40h432c22.055,0,40-17.945,40-40 V112C512,89.945,494.055,72,472,72z"
                />
            </g>
            <g>
                <path
                    style={{
                        fill: "#FFD100",
                    }}
                    d="M176,232H80c-8.837,0-16-7.163-16-16v-64c0-8.837,7.163-16,16-16h96c8.837,0,16,7.163,16,16v64 C192,224.837,184.837,232,176,232z"
                />
            </g>
            <g>
                <g>
                    <path
                        style={{
                            fill: "#B8BAC0",
                        }}
                        d="M120,336H80c-8.837,0-16-7.163-16-16v-8c0-8.837,7.163-16,16-16h40c8.837,0,16,7.163,16,16v8 C136,328.837,128.837,336,120,336z"
                    />
                </g>
                <g>
                    <path
                        style={{
                            fill: "#B8BAC0",
                        }}
                        d="M224,336h-40c-8.837,0-16-7.163-16-16v-8c0-8.837,7.163-16,16-16h40c8.837,0,16,7.163,16,16v8 C240,328.837,232.837,336,224,336z"
                    />
                </g>
                <g>
                    <path
                        style={{
                            fill: "#B8BAC0",
                        }}
                        d="M328,336h-40c-8.837,0-16-7.163-16-16v-8c0-8.837,7.163-16,16-16h40c8.837,0,16,7.163,16,16v8 C344,328.837,336.837,336,328,336z"
                    />
                </g>
                <g>
                    <path
                        style={{
                            fill: "#B8BAC0",
                        }}
                        d="M432,336h-40c-8.837,0-16-7.163-16-16v-8c0-8.837,7.163-16,16-16h40c8.837,0,16,7.163,16,16v8 C448,328.837,440.837,336,432,336z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        style={{
                            fill: "#8A8895",
                        }}
                        d="M232,384H72c-4.422,0-8-3.582-8-8s3.578-8,8-8h160c4.422,0,8,3.582,8,8S236.422,384,232,384z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        style={{
                            fill: "#8A8895",
                        }}
                        d="M336,384h-72c-4.422,0-8-3.582-8-8s3.578-8,8-8h72c4.422,0,8,3.582,8,8S340.422,384,336,384z"
                    />
                </g>
            </g>
            <g>
                <path
                    style={{
                        fill: "#FF4F19",
                    }}
                    d="M368,216.002C359.211,225.821,346.439,232,332.224,232c-26.51,0-48-21.49-48-48s21.49-48,48-48 c14.213,0,26.983,6.177,35.772,15.993"
                />
            </g>
            <g>
                <polygon
                    style={{
                        fill: "#FF9500",
                    }}
                    points="192,192 112,192 112,176 192,176 192,160 112,160 112,136 96,136 96,232 112,232 112,208  192,208  "
                />
            </g>
            <g>
                <circle
                    style={{
                        fill: "#FFD100",
                    }}
                    cx={400}
                    cy={184}
                    r={48}
                />
            </g>
        </g>
    </svg>
);

const BankIcon = (props) => (
    <svg
        width="128px"
        height="128px"
        viewBox="0 0 128 128"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        className="iconify iconify--noto"
        preserveAspectRatio="xMidYMid meet"
        {...props}
    >
        <path
            d="M64.64 3.84c-1.67.2-53.72 35.1-54.02 36.16c-.3 1.07-.42 12.19-.42 12.19l6.71 2.13l.03 8.84l3.59 7.35v31.07l-2.68 5.85l-7.35 2.17v5.26l-5.76 1.75s.08 4.51.08 5.26s.25 2.34 3.26 2.34s112.87-.51 113.98-.51s2.26-.22 2.32-2.21s-.06-4.8-.06-4.8l-5.58-2.76l-.02-4.41l-9.08-2.8l-2.19-6.2l.03-29.21l3.48-7.34l1.05-1.99v-7.9l5.85-2.21s.11-11.43-.06-11.76c-.15-.32-51.78-36.43-53.16-36.27z"
            fill="#cddfee"
        />
        <path
            d="M15.33 42.32c-.31.77-.39 6.45-.11 6.73c.28.28 96.94.13 97.27-.14c.33-.27.38-6.44.22-6.82S85.3 23.1 85.3 23.1l-42.13.21c-.01 0-27.66 18.55-27.84 19.01z"
            fill="#94adbc"
        />
        <path
            d="M4.74 116.63c-.09-.22 0-1.26 0-1.68s.4-.9 1.3-.9s115.14-.16 115.7-.14c1.41.05 2.36.35 2.5 1.25c.14.9.09 1.55.09 1.55l-119.59-.08z"
            fill="#94adbc"
        />
        <path
            d="M118.73 109.53l-108.22.08s.04-1.11.04-1.49c0-.38.2-1 1.49-1s103.92-.39 104.8-.39s1.84.41 1.96 1.02s-.07 1.78-.07 1.78z"
            fill="#94adbc"
        />
        <path fill="#94adbc" d="M108.26 102.83l-.8-2.34l-86.95-.02l-.65 2.61z" />
        <path fill="#94adbc" d="M19.51 68.38l89.26.31l-1.28 2.62l-86.95.33z" />
        <path
            d="M17.56 64.7s-.79-.57-.92-1.32c-.13-.75.22-1.19.22-1.19l95.16-.21s.36.74.05 1.26c-.31.53-1.19 1.01-1.19 1.01l-93.32.45z"
            fill="#94adbc"
        />
        <path
            d="M10.2 52.19l107.67-.32s.07 1.03 0 1.46c-.06.43-.62.81-2.05.81h-1.43s-100.79.5-101.91.5s-1.92-.62-2.11-1.12c-.11-.33-.17-1.33-.17-1.33z"
            fill="#94adbc"
        />
        <path fill="#657582" d="M18.01 45.03l22.65-15.29l1.52 15.59z" />
        <path fill="#657582" d="M87.55 29.23l22.68 16H86.94z" />
        <path
            fill="#54636e"
            d="M33.41 64.63l3.73-.01l2.92 7.01l-.27 28.86l-2.43 6.54l-4.34.01l-2.39-6.51l-.11-28.96z"
        />
        <path
            fill="#54636e"
            d="M53.27 64.53l-3.71 6.94l.28 29.02l2.2 6.49l24.23-.1l2.18-6.39l.06-29.08l-2.83-6.99z"
        />
        <path
            fill="#54636e"
            d="M91.37 64.35l3.29-.02l2.91 7.01l-.01 29.16l-1.69 6.32l-5.49.01l-1.68-6.33l-.13-29.13z"
        />
        <path fill="#6ba4ae" d="M54.62 106.97V84.72l19.52.19l.04 22z" />
        <path fill="#a5d0d7" d="M63.26 83.71l-.01 23.23l2.02-.01V84.05z" />
        <path d="M54.62 85.78V80.2h19.52v5.58s-19.35-.17-19.52 0z" fill="#95acbc" />
        <path
            d="M54.62 80.2h19.52s.08-1.74-1.73-1.91s-15.11-.03-16.23 0c-1.67.05-1.56 1.91-1.56 1.91z"
            fill="#ccdeed"
        />
        <path
            d="M64.39 11.11c-14.49.09-25.96 11.55-25.47 26.04S52.7 61.83 64.39 61.74c12.27-.09 25.19-8.87 25.77-24.67c.59-15.79-12.71-26.05-25.77-25.96z"
            fill="#cddfee"
        />
        <path
            d="M64.48 14.58c-12.13.08-21.84 10.03-21.43 22.65c.4 12.62 11.08 20.62 20.87 20.71c11.29.1 21.68-7.28 22.16-21.03s-10.67-22.4-21.6-22.33z"
            fill="#94adbc"
        />
        <path
            d="M60.98 21.63s-.22-3.86.05-4.19c.27-.33 1.37-.33 3.5-.33c2.14 0 3.01 0 3.34.27s.16 4.49.16 4.49s2.9 1.09 4.71 2.49c1.92 1.48 3.29 3.45 3.18 3.78c-.11.33-3.94 3.67-4.49 3.56c-.55-.11-3.67-3.76-7.39-4c-5.91-.38-5.86 4.22-2.19 5.48c3.67 1.26 15.24 2.26 14.79 11.47c-.38 7.75-8.21 8.3-8.21 8.3s.22 2.9 0 3.07s-7.12.27-7.34-.05s-.05-3.29-.05-3.29s-3.07-.6-5.59-2.3c-2.52-1.7-4.33-3.94-4.38-4.22c-.05-.27 4.44-4.05 4.76-4.16s4.89 4.39 9.2 4.76c4.38.38 6.24-3.94.16-5.8c-6.08-1.86-13.25-3.45-12.92-11.06c.33-7.73 8.71-8.27 8.71-8.27z"
            fill="#024fac"
        />
    </svg>
);

export { CardIcon, BankIcon };
