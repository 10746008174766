import React from "react";

const SVGComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={20}
        viewBox="0 -960 960 960"
        width={20}
        {...props}
    >
        <path d="M80 0v-119.997h800V0H80Zm100.001-219.999v-122.305l357.385-356.615 121.536 121.536-356.615 357.384H180.001ZM240-279.997h36l298.616-297.386-37.23-37.231L240-315.997v36Zm459.306-339.078L577.77-739.842l58.922-58.922q11-11.616 27.615-11.308 16.616.308 27.616 11.308l65.536 65.536q11 11 11.385 27.308.385 16.308-10.615 27.923l-58.923 58.922ZM240-279.997Z" />
    </svg>
);
export default SVGComponent;
