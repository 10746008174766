import React, { memo } from 'react';
import DotLoader from 'react-spinners/DotLoader';
// ANCHOR style
import { override, StyledSweetLoading } from './loading.style';
import { colors } from 'Style/colors';
// Can be a string as well. Need to ensure each key-value pair ends with ;

const Loader = memo(({ size = 150, color = colors.primary, Component = DotLoader, isMain = true, ...props }) => {
  return (
    <StyledSweetLoading isMain className="flex-center" style={props.style} {...props}>
      <Component css={override} size={size} color={color} loading={true} />
    </StyledSweetLoading>
  );
});

export default Loader;
