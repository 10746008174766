import React from 'react';

const SVG = ({ style = {}, fill = '#000', width = '100%', onClick, className = '', viewBox = '0 0 40 26' }) => (
  <svg
    width={width}
    style={style}
    onClick={onClick}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className}`}
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M22.3711 30C21.7239 30 21.1992 29.4754 21.1992 28.8281C21.1992 24.2403 17.4667 20.5078 12.8789 20.5078H11.1211C6.53326 20.5078 2.80078 24.2403 2.80078 28.8281C2.80078 29.4754 2.27613 30 1.62891 30C0.98168 30 0.457031 29.4754 0.457031 28.8281C0.457031 22.9479 5.24092 18.1641 11.1211 18.1641H12.8789C18.7591 18.1641 23.543 22.9479 23.543 28.8281C23.543 29.4754 23.0183 30 22.3711 30Z"
      fill="white"
    />
    <path
      d="M11.8828 15.8203C7.52115 15.8203 3.97266 12.2718 3.97266 7.91016C3.97266 3.5485 7.52115 0 11.8828 0C16.2445 0 19.793 3.5485 19.793 7.91016C19.793 12.2718 16.2445 15.8203 11.8828 15.8203ZM11.8828 2.34375C8.8135 2.34375 6.31641 4.84084 6.31641 7.91016C6.31641 10.9795 8.8135 13.4766 11.8828 13.4766C14.9521 13.4766 17.4492 10.9795 17.4492 7.91016C17.4492 4.84084 14.9521 2.34375 11.8828 2.34375Z"
      fill="white"
    />
  </svg>
);

export default SVG;
