import Dashboard from 'Pages/Dashboard';
import Auth from 'Pages/Login';
import Info from 'Pages/Info';
import Public from 'Pages/Public';
import { NoMatch } from 'Pages/NotFoundPage';
import Blogs from 'Pages/Info/pages/blogs/Blogs';
import Blogpost from "Pages/Info/pages/blogpost/Blogpost";

export const routes = [
  {
    private: true,
    component: Dashboard,
    path: '/dashboard'
  },
  {
    private: false,
    component: Public,
    path: '/public',
    exact: false
  },
  {
    private: false,
    component: Auth,
    path: '/login',
    exact: true
  },
  {
    private: false,
    component: Info,
    path: '/',
    exact: true
  },
  {
    private: false,
    component: Blogs,
    path: '/blogs',
    exact: true
  },
  {
    private: false,
    component: Blogpost,
    path: '/blogpost/*',
    exact: true
  },
  {
    private: false,
    component: NoMatch,
    path: '/*',
    exact: true
  }
];
