import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import notification, { alertTypes, cAlert } from '../../atoms/toast';
import ApiUser from "Services/api/controller/user";
import { eimzoService } from 'Services/e-imzo';
import Button from 'Components/atoms/button';
import { useTranslation } from "react-i18next";
import synchronizeSeveralDocumentsFromTax from "Utils/synchronizeSeveralDocumentsFromTax";

export const operators = {
    '202530465': 'soliqservis.uz',
    '302936161': 'Didox.uz',
    '310529901': 'Didox.uz',
    '302563857': 'Faktura.uz',
    '302502322': 'docs.1uz.uz',
    '305858074': '24m.uz',
    '202042939': 'edo.uzcardtrade.uz',
    '302738051': 'micros24.uz',
    '306773252': 'E-invoice.uz',
    '300711863': 'edocs.uz',
    '305620546': 'ETulov.uz',
    '306851622': 'L-Factura.uz',
    '306717486': 'OnlineFactura.Uz',
    '306865819': 'e365.uz',
    '201059101': 'invoice.uzasbo.uz',
    '205916449': 'agent.paynet.uz',
    '303465075': 'hujjat.uz',
    '300674317': 'netdoc.uz',
    '202540002': 'mexm.uz',
    '306156394': 'ExDoc.uz',
    '307308543': 'KiberUz',
    '306344019': 'XFile',
    '205464363': 'factura.smartbase.uz',
    '301551793': 'my.contracts.uz',
    '308011454': 'app.hippo.uz',
    '309944528': 'digitalbiznes.uz'
}

const OperatorList = ({ callback = () => { } }) => {
    const { t } = useTranslation();
    const [operatorList, setOperatorList] = useState([]);
    const [isFetched, setIsFetched] = useState(false);
    const user = useSelector((state) => state.accountData.user);
    const { tin = '', pinfl = '' } = user;
    const ClientTin = pinfl ? pinfl : tin;

    const handleSetOperator = () => {
        let json = { ClientTin, ProviderTins: [...operatorList, '305858074'] };

        eimzoService.createSign(JSON.stringify(json)).then((sign) => {
            if (sign) {
                ApiUser.setBDMOperator(sign).then((r) => {

                    if (r?.data?.success) {
                        notification.setType('success').setMessage(r?.data?.reason).alert()
                        callback(false);
                        synchronizeSeveralDocumentsFromTax()
                    } else {
                        notification.setType('error').setMessage(r?.data?.reason).alert();
                    }
                });
            }
        });
    }

    useEffect(() => {
        if (ClientTin) {
            ApiUser.getBDMOperator(ClientTin).then((res) => {
                if (!res.fail) {
                    setOperatorList([...res.data?.data])
                    setIsFetched(true)
                } else {
                    cAlert(alertTypes.success, res.data?.message);
                    setIsFetched(true)
                }
            });
        }
    }, [ClientTin]);

    return (
        <>
            <div style={{ marginBottom: 'auto' }}>{operatorList?.length > 0 &&
                operatorList?.map((key, index) => <div style={{ fontWeight: '500', fontSize: '16px' }}>{`${index + 1} - ${operators[key]}`}</div>)}
            </div>
            {isFetched && !operatorList.includes('305858074') &&
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px' }}>
                    <div style={{ fontWeight: '500', fontSize: '20px', marginRight: '20px' }}>{t('settings:areYouWantToSetUsAsOperator')}</div>
                    <Button onClick={handleSetOperator}>{t('yes')}</Button>
                </div>
            }
        </>
    )
}

export default OperatorList;