import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import browserStorage from 'Services/browserStorage';
// import en from '../public/locales/ru/translation';
// const ru =

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(XHR)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: browserStorage.get('lang') || 'ru',
    ns: ['login', 'settings', 'translation', 'sidebar', 'hint','box', 'hilton', 'modalMessages', 'errorMsg', 'codePart', 'agreements'],
    defaultNS: 'translation',
    order: ['htmlTag'],
    fallbackLng: 'ru',
    debug: false,
    // cache: [],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      // path to post missing resources
      addPath: '/locales/add/{{lng}}/{{ns}}',
      allowMultiLoading: false
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },

    react: {
      wait: true,
      useSuspense: true
    },
    saveMissing: true,
    saveMissingTo: 'all',
    appendNamespaceToMissingKey: true,
    preload: ['ru', 'latin', 'cyrillic']
  });

export default i18n;
