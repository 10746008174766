import * as React from "react";

const StatusInfo = (props) => (
    <svg
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 494.572 494.572"
        style={{
            enableBackground: "new 0 0 494.572 494.572",
            fill: "#FFFFFF",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="XMLID_140_"
            d="M257.063,0C127.136,0,21.808,105.33,21.808,235.266c0,41.012,10.535,79.541,28.973,113.104L3.825,464.586 c-3.285,8.118-1.391,17.402,4.799,23.593c6.176,6.19,15.47,8.085,23.586,4.802l118.707-47.953 c31.933,16.186,67.912,25.504,106.146,25.504c129.945,0,235.275-105.33,235.275-235.266S387.009,0,257.063,0z M256.804,94.852 c22.865,0,41.403,18.538,41.403,41.404s-18.538,41.403-41.403,41.403c-22.865,0-41.406-18.537-41.406-41.403 S233.938,94.852,256.804,94.852z M298.616,356.496c0,10.6-8.592,19.191-19.191,19.191h-45.243c-10.6,0-19.19-8.591-19.19-19.191 V198.312c11.939,8.077,26.345,12.797,41.813,12.797c15.467,0,29.872-4.721,41.813-12.797V356.496z"
        />
    </svg>
);
export default StatusInfo;
