import { formatTime } from './formatTime';

class CustomDate {
  replaceDashToDot(value) {
    try {
      return value.split('-').reverse().join('.');
    } catch (err) {
      return value;
    }
  }

  replaceDotToDash(value) {
    try {
      return value.split('.').reverse().join('-');
    } catch (err) {
      return value;
    }
  }

  compareWithCurrentDate(date, format = 'YYYY-MM-DD') {
    const dateArray = date.split('.');
    const dateObject = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);

    return dateObject < new Date();
  }
}

const customDate = new CustomDate();

export default customDate;
