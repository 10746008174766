import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'antd';
import ApiUser from 'Services/api/controller/user';
import * as _ from './style';
import { useTranslation } from 'react-i18next';

const Expense = () => {
  const [expenseData, setExpenseData] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [detailsModal, setDetailsModal] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const { t } = useTranslation(['login']);
  const tabledata = [];

  const columns = [
    {
      title: t('payment.paymentExpenseSection.date'),
      width: 50,
      dataIndex: 'createdAt',
      key: 'createdAt',
      fixed: 'left'
    },
    {
      title: t('payment.paymentExpenseSection.amount'),
      dataIndex: 'amount',
      key: 'amount',
      width: 50
    },
    {
      title: t('payment.paymentExpenseSection.aboutPayment'),
      dataIndex: 'aboutPayment',
      key: 'aboutPayment',
      width: 50
    }
  ];

  for (let i = 0; i < expenseData?.length; i++) {
    tabledata.push({
      key: i,
      createdAt: expenseData[i]?.createdAt,
      amount: expenseData[i]?.amount.toLocaleString('en-US').replace(',', ' '),
      aboutPayment:
        localStorage.getItem('i18nextLng') === 'cyrillic' && expenseData[i]?.amount < 25000
          ? `'${expenseData[i]?.docDate}' санада яратилган '${expenseData[i]?.docNum}' рақамли ${expenseData[i]?.docTypeNameCyr}`
          : localStorage.getItem('i18nextLng') === 'cyrillic'
          ? 'Тариф учун ечилган'
          : localStorage.getItem('i18nextLng') === 'ru' && expenseData[i]?.amount < 25000
          ? `${expenseData[i]?.docTypeNameRu} под номером '${expenseData[i]?.docNum}' созданная в '${expenseData[i]?.docDate}' `
          : localStorage.getItem('i18nextLng') === 'ru'
          ? 'Списан за тариф'
          : localStorage.getItem('i18nextLng') === 'latin' && expenseData[i]?.amount < 25000
          ? `'${expenseData[i]?.docDate}' sanada yaratilgan '${expenseData[i]?.docNum}' raqamli ${expenseData[i]?.docTypeNameUzLat}`
          : 'Tarif uchun yechilgan'
    });
  }

  useEffect(() => {
    ApiUser.getUserSpending(pageNum).then((res) => {
      setExpenseData(res?.data?.data?.expensesResults);
    });

    ApiUser.getUserSpending(pageNum).then((res) => {
      setTotalElements(res?.data?.data?.totalCount);
    });
  }, [pageNum]);

  // Select MODAL
  const showDetailsModal = () => {
    setDetailsModal(true);
  };
  const hideDetailsModal = () => {
    setDetailsModal(false);
  };

  return (
    <_.Wrapper>
      <Table
        columns={columns}
        dataSource={tabledata}
        pagination={{
          total: totalElements,
          current: pageNum,
          defaultPageSize: 50,
          showSizeChanger: false,
          onChange: (e) => setPageNum(e)
        }}
        locale={{ emptyText: t('payment.paymentHistorySection.noData') }}
        scroll={{
          x: 0,
          y: 550
        }}
      />
      <Modal title="On the process" visible={detailsModal} color={'black'} onClose={hideDetailsModal} onCancel={hideDetailsModal}>
        <p>On the process</p>
      </Modal>
    </_.Wrapper>
  );
};

export default Expense;
