import React, { useState, memo, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { BsUpload } from 'react-icons/bs';
import convertFileToBase64 from 'Utils/convertFileToBase64';
import { isEmpty } from 'Utils/isEmptyObject';

import Btn from 'Components/atoms/button';

// style
import { DivWrapper } from './style';
import PdfViewer from 'Modules/publicDocumentView/components/view/react-pdf';
import Form from './form'
import notification from 'Components/atoms/toast';

const fileSize = (size) => {
  if (size === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const fileType = (fileName) => {
  return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
};

const generatePositionClassName = (position) => {
  switch (position) {
    case 'over':
      return 'dragged_item_over';
    case 'leave':
      return 'dragged_item_leave';
    default:
      return 'initial';
  }
};

const PublicDropZone = ({ changed, onClear }) => {
  const { t } = useTranslation();

  const [selectedFiles, setSelectedFiles] = useState('');

  const [positionDraggedItem, setPositionDraggedItem] = useState('initial');
  const [base64, setBase64] = useState(undefined)
  const [pdfBase64, setPdfBase64] = useState(undefined)

  const validateFile = (file) => {
    const validTypes = ["application/pdf"];
    if (validTypes.indexOf(file?.type) === -1) {
      notification.setType('error').setMessage('Загрузить PDF-файл').alert()
      return false;
    }
    return true;
  };

  const handleFiles = async (files) => {
    let base64Format;
    let uploadedFile = {},
      uploadFileMessageObj = '',
      isSuccess = false;
    if (validateFile(files[0])) {
      uploadFileMessageObj = { ...uploadedFile, name: files[0]?.name, size: files[0]?.size, invalid: false };
      uploadedFile = files[0];
      isSuccess = true;
    } else {
      uploadFileMessageObj = { ...uploadedFile, name: files[0]?.name, size: files[0]?.size, invalid: true };

      uploadedFile = {};
    }

    setSelectedFiles(uploadFileMessageObj);

    changed(uploadedFile, !uploadFileMessageObj.invalid);

    if (uploadedFile) {
      base64Format = await convertFileToBase64(uploadedFile);
      setPdfBase64(base64Format)
      base64Format = base64Format?.split(',')[1];
      setBase64(base64Format)
    } else {
      setBase64(undefined)
      setPdfBase64(undefined)
    }
  };

  const dragLeave = (e) => {
    e.stopPropagation();

    setPositionDraggedItem('leave');
  };
  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
      setPositionDraggedItem('initial');
    }
  };

  const finalCalb = () => {
    setPdfBase64(undefined)
    setBase64(undefined);
    setSelectedFiles('');
    document.getElementById('theFile').value = '';

  };

  function performClick(elemId) {
    var elem = document.getElementById(elemId);
    if (elem && document.createEvent) {
      var evt = document.createEvent('MouseEvents');
      evt.initEvent('click', true, false);
      elem.dispatchEvent(evt);
    }
  }

  const dragOver = (e) => {
    e.preventDefault();
    if (positionDraggedItem !== 'over') {
      setPositionDraggedItem('over');
    }
  };

  const dragEnter = (e) => { };

  const hasFile = (Object.keys(selectedFiles).length > 0 && base64 && pdfBase64) ? true : false;

  return (
    <DivWrapper>
      <div className={`content 
    ${generatePositionClassName(positionDraggedItem)}
    ${hasFile ? 'hasFile' : ''}
    `}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onDragOver={dragOver}
      >
        {!hasFile &&
          <>
            <h4 className='title'>Отправьте документ за 30 секунд</h4>
            <div className='instruction'>
              <div className='note'>Загрузите свой документ</div>
              <div className='note2'>Отправьте 1000 бесплатных документов</div>
            </div>
          </>
        }

        <input type="file" id="theFile" onChange={(e) => handleFiles(e.target.files)} />
        <div
          className={`
         ${generatePositionClassName(positionDraggedItem)} 
         ${hasFile ? 'hasFile' : 'drop-container'}
        `}
          onClick={() => !hasFile && performClick('theFile')}
          style={{ maxWidth: 'calc(100% - 0px' }}
        >
          {hasFile ?
            <Suspense fallback={<div />}>
              <Form fileName={selectedFiles?.name} filePath={base64} finalCalb={finalCalb}>
                <div className='preview-container' onClick={() => hasFile && performClick('theFile')}>
                  <PdfViewer src={pdfBase64} width={'100%'} height={200} />
                </div>
              </Form>
            </Suspense>

            :
            <div className="drop-message">
              <IconContext.Provider value={{ style: { color: '#0F5CEE', display: 'block', margin: 'auto', fontSize: '3.5rem' } }}>
                <BsUpload />
              </IconContext.Provider>
              {'Загрузить PDF-файл...'}
            </div>
          }
        </div>

        <div className="file-display-container">
          {hasFile ? (
            <div className="file-status-bar">
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </DivWrapper>
  );
};


export default PublicDropZone;