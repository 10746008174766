// NOTE Module auth
export const AUTH_LOGIN_BY_EMAIL = '/auth/login?type=0';
export const AUTH_LOGIN_BY_KEY = '/auth/login?type=1';

// NOTE Module document ======================================
export const DOCUMENT_MENU_ITEMS = '/user/get/document/types';
export const DOCUMENT_SAVE_DOCUMENT = '/document/save'; // /document/save/:docType
export const DOCUMENT_GET_USER_PASSPORT_DATA = '/user/requisite/from/tax'; // /:buyer tin

// NOTE Module box ======================================
export const BOX_PRODUCT_INFO = '/documents/get/info/'; // /info/document/:docId
export const BOX_PRODUCT_COPY = '/document/create/copy';
//NOTE Module Setting
export const GET_SUB_DIVISION_LIST = '/branches';
export const GET_ROUTE_DATA = '/routes/documentType/';
export const GET_SUB_STAFF_LIST = '/employees';
export const GET_SUB_ROLE_LIST = '/roles';
export const GET_SUB_DOCK_TYPE = '/user/documentTypes';
export const GET_PRODUCT_SERVICE = '/classifications';
export const DELETE_PRODUCT_SERVICE = '/classifications/delete?classCode=';

export const BOX_MENU_ITEMS = '/documents/get/counts/by/status?boxType='; // /document/get/counts/by/status?boxType=${id}`

// NOTE Module Reports ==========================================
export const REPORTS_MENU_ITEMS = '/report/document/types';
export const REPORT_GET_EXCEL_FILE = '/report/document?'; // /report/document/ ? ----params

// NOTE Module sidebar;
export const SCROLL_FETCH = '/edocuments?'; //'?query'

//NOTE get public document's URL
export const GET_DOCUMENT_URL = 'documents/get/url';
