function isJson(value) {
  try {
    let parsedJson = JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
}

class BrowserStorage {
  static keys = Object.keys(localStorage);

  get(key) {
    // NOTE read from localstorage
    if (BrowserStorage.keys.includes(key)) {
      let askedData = localStorage.getItem(key);
      if (isJson(askedData)) {
        return JSON.parse(askedData);
      } else {
        return askedData;
      }
    } else {
      return '';
    }
  }

  set(key, value) {
    // NOTE set localstrage

    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
    BrowserStorage.keys = [...BrowserStorage.keys, key];
  }

  clear() {
    //NOTE CLEAR broser storage ----
    localStorage.clear();
    BrowserStorage.keys = [];
  }

  remove(key) {
    // NOTE remome from browser storage ------
    if (BrowserStorage.keys.includes(key)) {
      localStorage.removeItem(key);
      BrowserStorage.keys = BrowserStorage.keys.filter((oldKey) => oldKey !== key) || [];
    }
  }
}

const browserStorage = new BrowserStorage();



export default browserStorage;
