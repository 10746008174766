import styled from 'styled-components';
import { colors } from 'Style/colors';

import BaseStyledLabel from 'Common/Style/styledLabel';

export const StyledCheckBoxLabel = styled(BaseStyledLabel)`
  color: ${colors.textColor};
  display: flex !important;
  &.flex-box {
    align-items: center;
    justify-content: center;
  }

  & {
    input {
      width: 20px;
      height: 20px;
      border-radius: 0px !important;
    }
  }
`;

export const StyledInput = styled.input`
  margin-right: 10px;
`;
