import styled from 'styled-components';

const StyledFlex = styled.div`
  display: flex;
  
  &.wrap{
    flex-wrap:wrap;
  }

  &.wrap-reverse{
   flex-wrap:wrap-reverse;
  }

  &.nowrap{
    flex-wrap:nowrap;
  }

  &.justify-content-center {
    justify-content: center;
  }

  &.align-items-center {
    align-items: center;
  }

  &.align-items-end{
    align-items: flex-end;
  }

  &.align-items-start {
    align-items:flex-start;
  }

  &.flex-direction-column {
    flex-direction: column;
  }

  &.justify-content-space-between{
    justify-content:space-between;
  }

  &.justify-content-space-around{
    justify-content:space-around;
  }

  &.justify-content-flex-end{
    justify-content:flex-end;
  }

  
  &.justify-content-end{
    justify-content:flex-end;
  }

  &.justify-content-start{
    justify-content:flex-start;
  }

  &.mt{
    margin-top:${props => props.marginTop};
  }
  &.mb{
    margin-bottom:${props => props.marginBottom};
  }
  &.ml{
    margin-left:${props => props.marginLeft};
  }
   &.ml-10{
    margin-left:10px;
  }
  &.mr{
    margin-right:${props => props.marginRight};
  }

  &.height-100{
    height:100%;
  }
  &.w-100 {
    width: 100%;
  }

  &.gap-5 {
    gap: 5px;
  }

  &.gap-10 {
    gap: 10px;
  }

  &.gap-20 {
    gap: 20px;
  }

  &.gap-30 {
    gap: 30px;
  }

  &.gap-5per {
    gap: 5%;
  }

  &.waybill {
    justify-content:space-between;
    align-items: center;

    label {
      width:auto;
    }
  }
`;

export default StyledFlex;
