import { isEmpty } from 'Utils/isEmptyObject';

export const autoComplitedDataGenerate = (value = {}, key = 'default') => {
  if (Array.isArray(value)) {
    let list = [...value];

    for (let i = 0; i < list.length; i++) {
      let item = list[i];

      switch (key) {
        case 'worker':
          item = {
            label: `${list[i].employeeName} - ${list[i].employeeTin}`,
            value: list[i],
            id: i + 1
          };
          break;
        case 'constructionObject':
          item = {
            label: item.name,
            value: item,
            id: i + 1
          };
          break;
        case 'classification':
          item = {
            label: `${item['classCode']} ${item['className']}`,
            value: item
          };
          break;
        case 'branch':
          item = {
            label: item.name,
            value: item
          };
          break;
        case 'documentType':
          item = {
            label: item.documentTypeName,
            value: item
          };
          break;
        default:
          break;
      }

      list[i] = item;
    }

    return list;
  }
  if (isEmpty(value)) return '';

  switch (key) {
    // NOTE ---------------------
    case 'oldFactura':
      return {
        value,
        label: `Счет фактура ID ${value.id ? value.id : ''} № ${value.oldFacturaNo ? value.oldFacturaNo + ' от ' : ''}  ${value.oldFacturaDate ? value.oldFacturaDate : ''}`
      };
    case 'receiver': {
      return {
        value,
        label: `${value?.name} - ${value?.tin || value?.pinfl}`
      }
    }
    // NOTE ---------------------
    default:
      return {
        value,
        label: `${value?.name?.length > 25 ?
          (value?.name.substring(0, 25) || '') :
          (value?.name || '')}...  
          ${value?.pinfl && value?.tin !== value?.pinfl ?
            `${value?.tin}/${value?.pinfl}`
            : (value?.tin || '')}`
      };
  }
};
