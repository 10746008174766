import React, { memo } from 'react';
import SideBarMenu from 'Components/molecules/menu';
// style
import { StyledSubSideBar } from './subSideBar.style';
import { useSelector } from 'react-redux';

const SubSideBarComponent = (props) => {
  const isNewUi = useSelector((state) => state?.ui?.isNewUi)

  const onMouseLeaveAside = () => {
    if ((window?.location?.pathname === "/dashboard/create" ||
      (window?.location?.pathname === "/dashboard/reports" && window?.location?.search === "?tab=1") ||
      window?.location?.pathname === '/dashboard/agreements' ||
      window?.location?.pathname === '/dashboard/agreements/counter-agents' ||
      window?.location?.pathname === '/dashboard/finance')) {
      props.onToggle(false)
    }
  }

  return (
    <StyledSubSideBar ref={props?.subSidebarRef} isNewUi={isNewUi} className={`${props.className} `} active={props.active} onMouseLeave={onMouseLeaveAside}>
      <SideBarMenu />
    </StyledSubSideBar>
  );
};

export default memo(SubSideBarComponent);
