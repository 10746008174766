import styled from 'styled-components';
import { css } from '@emotion/core';

export const override = css`
  display: block;
  margin: 0 auto;
`;

export const StyledSweetLoading = styled.div`
  width: 100%;
  height: ${(props) => props.height || '100%'};
`;
