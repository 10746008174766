import { useEffect, useReducer, useState } from 'react';
import useFetch from 'Hooks/useFetch';
import { useSelector } from 'react-redux';
import browserStorage from 'Services/browserStorage';

import requestState from 'Services/api/status';

export const POST_COMPANY_LOADING = 'POST_COMPANY_LOADING';
export const POST_COMPANY_SUCCESS = 'POST_COMPANY_SUCCESS';
export const POST_COMPANY_FAIL = 'POST_COMPANY_FAIL';

const initialState = {
  data: [],
  status: 'initial',
  error: '',
  loading: ''
};

const companyReducer = (state, action) => {
  let { data } = state;
  let { newData, delData } = action;
  switch (action.type) {
    case POST_COMPANY_LOADING:
      return {
        ...state,
        status: requestState.loading
      };
    case POST_COMPANY_SUCCESS:
      return {
        ...state,
        status: requestState.success
      };
    case POST_COMPANY_FAIL:
      return {
        ...state,
        status: 'fail',
        error: action.message
      };
    default:
      return state;
  }
};

const useFunc = () => {
  // const [list, dispatch] = useReducer( companyReducer, initialState);
  const [companies, getCompanies] = useFetch();
  const [company, setCompany] = useState([]);
  const [inn, setInn] = useState('');
  const [currComp, setCurrComp] = useState([]);
  const [list, postData] = useFetch();
  const user = useSelector((state) => state.accountData.user);
  const { name = '', tin = '', pinfl = '', mainDirector = '' } = user;
  const getCompUrl = 'companies';
  useEffect(() => {
    getCompanies('GET', getCompUrl);
  }, [user]);

  useEffect(() => {
    if (company.length) {
      let currentCompany = company.filter((item) => item.employerTin === inn)[0];
      browserStorage.set('currentCompany', currentCompany);
      if (currentCompany) setCurrComp(currentCompany?.employerTin);
    }
  }, [tin, pinfl, company]);

  useEffect(() => {
    if (pinfl) {
      if (pinfl) setInn(pinfl);
    } else {
      if (tin) setInn(tin);
    }
  }, [tin, pinfl]);

  useEffect(() => {
    if (companies.data?.length) setCompany(companies.data);
  }, [companies.data]);

  useEffect(() => {
    if (list.data.accessToken) {
      browserStorage.set('token', list.data);
      window.location.reload();
    }
  }, [list.data]);

  const onSubmit = async (id) => {
    postData('POST', `/companies/${id}/switch`);
  };
  user.branchName = company?.filter((item) => item.employerTin === currComp)[0]?.branchName;
  return [company, currComp, onSubmit];
};

export default useFunc;
