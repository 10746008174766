import { setTimeout } from 'core-js';
import { toast } from 'react-toastify';
import { soliqErrorParseAndToast } from 'Utils/soliqErrorParseAndToast';
import { DispatchNotification } from './actions';

export const config = {
  position: 'top-right',
  autoClose: 10000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

class ApiNotification {
  type = 'success';
  delayTime = 100;
  message = '';

  setType(type) {
    this.type = type;
    return this;
  }

  setDelayTime(time) {
    this.delayTime = time;
    return this;
  }

  setMessage(message) {
    this.message = message;
    return this;
  }
  activate() {
    switch (this.type) {
      case 'success':
        toast.success(this.message, {...config, autoClose: 2000});
        break;
      case 'error':
        DispatchNotification.error({text: this.message});
        // if (this.message?.includes('my.soliq.uz ')) {
        //   soliqErrorParseAndToast(this.message);
        //   return;
        // }
        // toast.error(this.message, { ...config, autoClose: 7000 });
        break;
      case 'warning':
        toast.warn(this.message, {...config, autoClose: 2000});
      default:
        break;
    }
  }
  activateDemo() {
    switch (this.type) {
      case 'success':
        toast.success(this.message, {...config, autoClose: 2000});
        break;
      case 'error':
        // DispatchNotification.error({text: this.message});
        if (this.message?.includes('my.soliq.uz ')) {
          soliqErrorParseAndToast(this.message);
          return;
        }
        toast.error(this.message, { ...config, autoClose: 7000 });
        break;
      case 'warning':
        toast.warn(this.message, {...config, autoClose: 2000});
      default:
        break;
    }
  }
  alert() {
    if (this.delayTime > 0) {
      setTimeout(() => {
        this.activate();
      }, this.delayTime);
    } else {
      this.delayTime();
    }
  }
  alertDemo() {
    if (this.delayTime > 0) {
      setTimeout(() => {
        this.activateDemo();
      }, this.delayTime);
    } else {
      this.delayTime();
    }
  }
}

const notification = new ApiNotification();


export function cAlert(type, message, todoRefactor) {
  if(todoRefactor === 'todoRefactor') {
    notification.setMessage(message).setType(type).alertDemo();
  } else {
    notification.setMessage(message).setType(type).alert();
  }
}


export const alertTypes = {
  success: 'success',
  error: 'error',
  warning: 'warning'
}

export default notification;
