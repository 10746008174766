import styled from "styled-components";

const StyledBlogpost = styled.div`
  margin-top: 30px;
  font-family: 'Montserrat', 'Arial', sans-serif;

  .title {
    text-align: center;
    font-weight: 700;
    border-bottom: 1px solid gray;
    padding-bottom: 15px;
    margin-bottom: 50px;
  }

  .postImg {
    width: 50%;
  }

  p {
    font-size: 18px;
  }
`;

export default StyledBlogpost;