import browserStorage from 'Services/browserStorage';
const logout = () => {
  // history.push('/');

  browserStorage.clear();

  window.location.href = '/login';
};

export default logout;
