import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'antd';
import ApiUser from 'Services/api/controller/user';
import * as _ from './style';
import { useTranslation } from 'react-i18next';

const PaymentHistory = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [detailsModal, setDetailsModal] = useState(false);
  const { t } = useTranslation(['login']);
  const tabledata = [];

  const columns = [
    {
      title: t('payment.paymentHistorySection.date'),
      width: 50,
      dataIndex: 'createdAt',
      key: 'createdAt',
      fixed: 'left'
    },
    {
      title: t('payment.paymentHistorySection.paymentSource'),
      width: 50,
      dataIndex: 'billingPlanId',
      key: 'billingPlanId'
    },
    {
      title: t('payment.paymentHistorySection.amount'),
      dataIndex: 'amount',
      key: '2',
      width: 50
    },
    {
      title: t('payment.paymentHistorySection.details'),
      width: 50,
      dataIndex: 'sourceName',
      key: 'sourceName'
    }
  ];

  for (let i = 0; i < paymentData?.length; i++) {
    tabledata.push({
      key: i,
      createdAt: paymentData[i]?.createdAt,
      billingPlanId: paymentData[i]?.receiverTin,
      amount: paymentData[i]?.amount.toLocaleString('en-US').replace(',', ' '),
      sourceName: paymentData[i]?.sourceName
    });
  }

  useEffect(() => {
    ApiUser.getPaymentHistory().then((res) => {
      setPaymentData(res?.data?.data?.incomePaymentsDTOList);
    });
  }, []);

  // Select MODAL
  const showDetailsModal = () => {
    setDetailsModal(true);
  };
  const hideDetailsModal = () => {
    setDetailsModal(false);
  };

  return (
    <_.Wrapper>
      <Table
        columns={columns}
        dataSource={tabledata}
        pagination={false}
        locale={{ emptyText: t('payment.paymentHistorySection.noData') }}
        scroll={{
          x: 0,
          y: 450
        }}
      />
    </_.Wrapper>
  );
};

export default PaymentHistory;
