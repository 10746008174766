import * as React from "react";

const Balance = ({ style = {}, fill = '#000', width = '100%', onClick, className = '', viewBox = '0 0 40 26' }) => (
    <svg
        fill={fill}
        width={width}
        style={style}
        onClick={onClick}
        height={width}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
    >
        <g>
            <g>
                <path d="M398.044,29.719C359.712,10.555,309.267,0,256.001,0c-53.271,0-103.719,10.555-142.05,29.721
           c-43.457,21.73-67.39,52.497-67.39,86.635v279.288c0,34.138,23.933,64.906,67.392,86.636C152.285,501.446,202.732,512,256.002,512
           c53.266,0,103.711-10.555,142.043-29.721c43.46-21.732,67.393-52.498,67.393-86.636V116.355
           C465.439,82.217,441.504,51.451,398.044,29.719z M256.001,46.548c93.229,0,162.889,36.855,162.889,69.808
           c0,32.953-69.66,69.809-162.889,69.809c-93.23,0-162.892-36.857-162.892-69.809C93.109,83.403,162.77,46.548,256.001,46.548z
            M418.891,395.643c0,32.953-69.662,69.809-162.891,69.809c-93.23,0-162.892-36.856-162.892-69.809v-18.58
           c6.348,4.292,13.294,8.346,20.844,12.121c38.332,19.167,88.779,29.721,142.05,29.721c53.266,0,103.711-10.555,142.043-29.721
           c7.552-3.777,14.498-7.829,20.846-12.121V395.643z M418.893,302.548h-0.002c0,32.953-69.66,69.809-162.889,69.809
           c-93.23,0-162.892-36.857-162.892-69.809v-18.58c6.348,4.292,13.294,8.343,20.844,12.119
           c38.332,19.168,88.779,29.722,142.05,29.722c53.266,0,103.711-10.555,142.043-29.722c7.552-3.777,14.498-7.829,20.846-12.123
           V302.548z M418.893,209.451h-0.002c0,32.954-69.66,69.811-162.889,69.811c-93.23,0-162.892-36.856-162.892-69.811v-18.579
           c6.348,4.292,13.294,8.343,20.844,12.118c38.331,19.167,88.779,29.721,142.05,29.721c53.266,0,103.711-10.555,142.043-29.721
           c7.552-3.777,14.498-7.829,20.846-12.121V209.451z"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
);

export default Balance;
