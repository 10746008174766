import styled from "styled-components";
import {colors} from "Style/colors";

const StyledBlogs = styled.div`
  padding-bottom: 30px;
`;

const StyledCards = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  padding-bottom: 10px;
  overflow: hidden;

  img {
    height: 230px;
    width: 100%;
    object-fit: cover;
    border: none;
  }

  .texts {
    padding: 10px;
    height: 205px;
    overflow: hidden;
  }

  h4 {
    color: ${colors.primary};
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 5px 0;

    &:hover {
      color: #722ed1;
      text-decoration: underline;
    }
  }

  p {
    font-size: medium;
  }
`;

export {StyledCards};
export default StyledBlogs