import React from 'react';

const SVG = ({ style = {}, fill = '#000', width = '100%', onClick, className = '', viewBox = '0 0 40 26' }) => (
  <svg
    fill={fill}
    width={width}
    style={style}
    onClick={onClick}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g>
      <path
        d="M14.9502 28.0375H3.73752C3.0498 28.0375 2.4917 27.4794 2.4917 26.7917V4.36641C2.4917 3.67869 3.04986 3.12059 3.73752 3.12059H14.9502C15.6391 3.12059 16.196 2.56371 16.196 1.87477C16.196 1.18582 15.6391 0.62883 14.9502 0.62883H3.73752C1.67689 0.62883 0 2.30578 0 4.36641V26.7916C0 28.8523 1.67689 30.5292 3.73752 30.5292H14.9502C15.6391 30.5292 16.196 29.9723 16.196 29.2833C16.196 28.5944 15.6391 28.0375 14.9502 28.0375Z"
        fill={fill}
      />
      <path
        d="M29.629 14.6922L22.0542 7.21709C21.5658 6.73369 20.776 6.73996 20.2926 7.22957C19.8092 7.71918 19.8142 8.50779 20.3051 8.99119L25.7183 14.3334H11.2129C10.5239 14.3334 9.96704 14.8902 9.96704 15.5792C9.96704 16.2681 10.5239 16.8251 11.2129 16.8251H25.7183L20.3051 22.1672C19.8142 22.6506 19.8105 23.4392 20.2926 23.9288C20.5368 24.1755 20.8582 24.3001 21.1797 24.3001C21.4961 24.3001 21.8125 24.1805 22.0542 23.9413L29.629 16.4662C29.8657 16.232 30.0002 15.913 30.0002 15.5791C30.0002 15.2454 29.8669 14.9277 29.629 14.6922Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect y="0.579102" width="30" height="30" fill={fill} />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
