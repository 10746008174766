export let allStateOptions = {
  10: {
    id: 1,
    tag: 'tag',
    color: '#ffa940',
    label: 'yourSignature',
    value: 10,
    boxType: [1, 2],
    count: 0
  },
  31: {
    id: 2,
    tag: 'tag',
    color: '#ffa940',
    label: 'yourSignature',
    value: 31,
    boxType: [1, 2],
    count: 0
  },
  12: {
    id: 3,
    tag: 'tag',
    color: '#ffa940',
    label: 'yourSignature',
    value: 12,
    boxType: [1, 2],
    count: 0
  },
  30: {
    id: 4,
    tag: 'tag',
    color: '#5b8c00',
    label: 'approved',
    value: 30,
    boxType: [1, 2],
    count: 0
  },
  60: {
    id: 5,
    tag: 'tag',
    color: '#f5222d',
    label: 'refused1',
    value: 60,
    boxType: [1, 2],
    count: 0
  },
  71: {
    id: 6,
    tag: 'tag',
    color: '#f5222d',
    label: 'canceled',
    value: 71,
    boxType: [1, 2],
    count: 0
  },
  11: {
    id: 7,
    tag: 'tag',
    color: '#1890ff',
    label: 'noSent',
    value: 11,
    boxType: [2],
    count: 0
  },
  5: {
    id: 8,
    tag: 'tag',
    color: '#ffeb3a',
    label: 'sending',
    value: 5,
    boxType: [1, 2],
    count: 0
  },
  65: {
    id: 9,
    tag: 'tag',
    color: '#ff4f00e3',
    label: 'waiting rejected',
    value: 65,
    boxType: [1, 2],
    count: 0
  },
  33: {
    id: 10,
    tag: 'tag',
    color: '#8fd50d',
    label: 'waitSigned',
    value: 33,
    boxType: [1, 2],
    count: 0
  },
  13: {
    id: 11,
    tag: 'tag',
    color: '#cb7777',
    label: 'waitingNotApproved',
    value: 13,
    boxType: [1, 2],
    count: 0
  },
  14: {
    id: 12,
    tag: 'tag',
    color: '#f5222d',
    label: 'refused1',
    value: 60,
    boxType: [1, 2],
    count: 0
  },
  16: {
    id: 13,
    tag: 'tag',
    color: '#b184ff',
    label: 'skipped',
    value: 16,
    boxType: [1, 2],
    count: 0
  },
  17: {
    id: 14,
    tag: 'tag',
    color: '#3090ff',
    label: 'yourSignature',
    value: 17,
    boxType: [1, 2],
    count: 0
  },
  40: {
    id: 15,
    tag: 'tag',
    color: '#d6c6c6',
    label: 'useless',
    value: 40,
    boxType: [1, 2],
    count: 0
  },
  130: {
    id: 16,
    tag: 'tag',
    color: '#BB8493',
    label: 'refusedResponsiblePerson',
    value: 130,
    boxType: [1, 2],
    count: 0
  },
  140: {
    id: 17,
    tag: 'tag',
    color: '#918D98',
    label: 'acceptedResponsiblePerson',
    value: 140,
    boxType: [1, 2],
    count: 0
  },
  150: {
    id: 18,
    tag: 'tag',
    color: '#49243E',
    label: 'cargoReturnedResponsiblePerson',
    value: 150,
    boxType: [1, 2],
    count: 0
  },
  160: {
    id: 19,
    tag: 'tag',
    color: '#918998',
    label: 'deliveredToRecipient',
    value: 160,
    boxType: [1, 2],
    count: 0
  },
  190: {
    id: 20,
    tag: 'tag',
    color: '#824D74',
    label: 'cargoReturnedTillResponsiblePerson',
    value: 190,
    boxType: [1, 2],
    count: 0
  },
  200: {
    id: 21,
    tag: 'tag',
    color: '#A0153E',
    label: 'returned',
    value: 200,
    boxType: [1, 2],
    count: 0
  },
  999: {
    id: 22,
    tag: 'tag',
    color: '#A34343',
    label: 'canceledNC',
    value: 999,
    boxType: [1, 2],
    count: 0
  },

  // ~~~~~~~~~ inBox va outBox da waitingSignature ni dynamic qilish uchun qo'shildi ~~~~~~~~
  0.1: {
    label: 'yourSignatureIsWaiting'
  },
  0.2: {
    label: 'partnerSignatureIsWaiting'
  }
};
