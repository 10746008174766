import React from 'react';

import AntDatePicker from "./antDatePicker";
import { CustomDatePicker } from './datePicker';

export const DatePicker = (props) => {

  return <AntDatePicker {...props} />

  // return <CustomDatePicker {...props} />;
};
