import styled, { keyframes } from 'styled-components';
import get from 'lodash.get';
export const rotateAnimation = keyframes`
 from{
     -webkit-transform:rotate(0deg);
 }
 to{
     -webkit-transform:rotate(360deg);
 }
`;

export const StyledAdvertisment = styled.aside`
  &.advertisment {
    &.in {
      border-left: 2px solid ${({ theme }) => get(theme, 'colors.primary')};
    }
    &.out {
      border-left: 2px solid white;
    }
    width: ${(props) => `${props.width}%`};
    height: calc(100% - 85px);
    position: relative;
    transition: width 300ms;
    will-change: width;
  }
`;

export const StyledToggle = styled.span`
  width: 20px;
  height: 20px;
  &.in {
    background-color: ${({ theme }) => get(theme, 'colors.primary')};
    box-shadow: 0px 0px 8px ${({ theme }) => get(theme, 'colors.primary')};
    && > svg {
      color: white !important;
      fill: white !important;
    }
  }
  &.out {
    background-color: white;
  }

  position: fixed;
  top: 50%;
  right: ${(props) => `${props.right}%`};
  border-radius: 50% 0 0 50%;
  /* opacity:.3; */
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.24);
  svg {
    animation-name: ${rotateAnimation};
    animation-duration: 2s;
    animation-delay: 500ms;
    animation-iteration-count: 1;
  }
  &:hover {
    cursor: pointer;
  }
`;
