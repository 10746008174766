import requestState from 'Services/api/status';

const initialState = {
  data: [],
  companiesLgota: [],
  lgotaByCatalogCode: [],
  catalogCodeForLgota: {},
  status: requestState.initial
};

export const SET_COMPANIES_LGOTA = 'SET_COMPANIES_LGOTA'
export const SET_LGOTA_BY_CATALOG_CODE = 'SET_LGOTA_BY_CATALOG_CODE'
export const SET_LGOTA = 'SET_LGOTA';
export const SET_CATALOG_CODE_FOR_LGOTA = 'SET_CATALOG_CODE_FOR_LGOTA'

const lgotaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LGOTA:
      return {
        ...state,
        data: payload.data,
        status: requestState.success
      };
    case SET_COMPANIES_LGOTA:
      return {
        ...state,
        companiesLgota: payload,
        status: requestState.success
      };
    case SET_LGOTA_BY_CATALOG_CODE:
      return {
        ...state,
        lgotaByCatalogCode: payload,
        status: requestState.success
      };
    case SET_CATALOG_CODE_FOR_LGOTA:
      return {
        ...state,
        catalogCodeForLgota: payload,
        status: requestState.success
      };
    default:
      return state;
  }
};

export default lgotaReducer;
