import React from 'react'
import StyledModal from 'Components/atoms/modal';
import { Image } from 'antd';
import { ONLY_BASE_URL } from 'Services/api';
import { useSelector } from 'react-redux';
import { selectZoom } from 'Store/selectors/ui';

const ImagesModal = ({ selectedProduct, onClose }) => {
  const zoom = useSelector(selectZoom);
  const width = (window.innerWidth * 65) / zoom;
  const height = (window.innerHeight * 40) / zoom;

  return (
    <StyledModal
      visible={!!selectedProduct?.imageLinks}
      title={<h3>{selectedProduct.productName}</h3>}
      footer={null}
      onClose={onClose}
      width={width}
      height={height}
    >
      <div
        style={{
          display: 'flex',
          gap: '3.3333%',
          flexWrap: 'wrap',
          alignItems: 'flex-start'
        }}
      >
        {
          selectedProduct?.imageLinks?.map(url => (
            <Image
              src={ONLY_BASE_URL + url}
              width={'30%'} 
              height={'auto'}
              alt={selectedProduct.productName}
              style={{ border: '1px solid #bbbbbb', borderRadius: '4px' }} 
            />
          ))
        }
      </div>
    </StyledModal>
  )
}

export default ImagesModal;