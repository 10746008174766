import styled from 'styled-components';
import get from 'lodash.get';
import { Select } from 'antd';
import { colors } from 'Style/colors';



import { FaWallet, FaBalanceScale, FaPiggyBank } from 'react-icons/fa';
import { BiDollarCircle, BiWallet } from 'react-icons/bi';
import { AiFillBank, AiOutlineBank } from 'react-icons/ai';
let WALLET_ICON = BiWallet


const { Option } = Select;

export const NavbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CompanyName = styled.p`
  font-size: ${({ theme }) => get(theme, 'screens.laptop.sizes.lg')};
`;

export const StyledZoomIconsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  width:200px;
  & > div {
    height: 60%;
    width:70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.primary};
    border-radius: 1rem;
    & > svg {
      font-size: 2rem;
      color: white;
    }

    &>span{
       color:white;
       font-size:1.2rem;
    }
  }
`;

export const Data = styled.div`
  width: 100%;
  margin: auto;   
  & > span {
    display:block;
    width:100%;
    font-style: normal;
    line-height: 15px;
    color: ${(props) => props?.isNewUi ? colors.textColor : colors?.white};
    ${(props) => props.isValue && `color: ${colors.white};`}
    font-weight: normal;
    font-size: ${({ theme }) => get(theme, 'screens.sizes.sm')};
    
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
   & > span:first-child {
    min-width: 5rem;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const SectStyle = styled.span`
  display: flex;  
  
  & > .ant-select{
    margin: auto;
  }
  & > .ant-select > .ant-select-selector{
    margin: auto;
    width: auto;
    height: auto;
  }
  & > .ant-select > .ant-select-selector > .ant-select-selection-item{
    display: flex;
    padding-right: 2rem;
  } 
  & > .ant-select > .ant-select-selector{
    background: transparent!important;
    color: ${colors.textColor};
    border: 0;
  }
  & > .ant-select > .ant-select-arrow{
      color: white;
  }
  .ant-select-item {
      height: 75px;
      display: flex;
      align-items: center; 
  }
  .branch-hint {
      padding: 8px 10px;
  }
`;

export const StyledAiOutlineDollar = styled(WALLET_ICON)`
  width: 28px;
  height: 28px;
  color: #fff;
  &:hover {
    color: ${colors.primary};
  }
`

export const LangDiv = styled.div`
    padding: 10px;
    div {
      color: #ffffff!important;
      text-transform: uppercase;
      border:1px solid #d9d9d9;
      padding: 2px 10px;
    }
`;

export const CopySpan = styled.span`
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    flex-direction: row !important;
    gap: 5px !important;
`;