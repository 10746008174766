import { createSelector } from 'reselect';
import flexList from 'Components/molecules/flexList/flexList';

const active = (state) => state.menues.active;
const part = (state) => state.outDocuments.part;

export const selectPart = createSelector(active, part, (active, part) => {
  if (active === 1) return 1;

  return part;
});

// NOTE select sign message -------------------------------------->

export const selectSignMessage = (state) => state.signs.signData.message;

// NOTE select pending -------------------------------------->

export const selectShapeOfHtmlLoading = (state) => state.formValue.loading;

// NOTE select fail

export const selectShapeOafHtmlFail = (state) => state.formValue.fail;

// NOTE select doctype ---------------------------------------->

export const selectDocType = createSelector(
  (state) => state.menues,
  (menues) => {
    return menues.doctype;
  }
);

//NOTE select editDocId ----------------------------------------->

export const selectEditDocId = createSelector(
  (state) => state.menues,
  (menues) => {
    return menues.editDocId;
  }
)

//NOTE select editTemplateId ----------------------------------------->

export const selectEditTemplateId = createSelector(
  (state) => state?.menues,
  (menues) => {
    return menues?.editTemplateId;
  }
)

//NOTE select isMarking
export const selectIsMarking = createSelector(
  (state) => state.menues,
  (menues) => {
    return menues.isMarking;
  }
)

//NOTE correction
export const selectCorrectionValue = createSelector(
  (state) => state.menues,
  (menues) => {
    return menues.correctionValue
  }
)

//NOTE select formsExcelData

export const selectFormsExcelData = createSelector(
  (state) => state.menues,
  (menues) => {
    return menues.formsExcelData;
  }
)

// NOTE select account data ------------------------------------------>

export const selectAccountData = (state) => state.accountData.user;

// NOTE select sign  ---------------------------------------------->

export const selectSign = (state) => state.signs;

// NOTE select current document  ----------------------------------------->

const selectDocs = (state) => state.outDocuments.documentList;

const selectCurrentDocId = (state) => state.outDocuments.docId;

const selectFilteredList = (state) => state.outDocuments.filteredList;

export const selectCurrentDoc = createSelector(selectDocs, selectFilteredList, selectCurrentDocId, (docs, filteredList, id) => {
  try {
    let doc = docs.find((doc) => doc.id === id);

    if (!doc) return filteredList.find((doc) => doc.id === id);

    return doc;
  } catch (e) {
    return {};
  }
});

// NOTE select current document state ------------------------------>

export const selectCurrentDocState = createSelector(selectCurrentDoc, (doc) => {
  return doc?.state;
});


// NOTE client products settingsda qo'shiladi
export const selectClientProducts = (store) => store.clientProducts.data;

const _utils = {
  getClassificationFromProductList(data = []) {
    return data.map(this.getOneClassificationFromProduct);
  },
  getOneClassificationFromProduct(product = {}, index, list = []) {
    return {
      id: product.id,
      classCode: product.catalogCode,
      className: product.catalogName,
      packageNames: product.packagesList,
      packages: product.packagesList,
      internationalCode: product.defaultInternationalCode,
      internationalCodeList: product.internationalCodeList,
      defaultPackageDTO: product.defaultPackageDTO,
      // groupCode - not exist in product object
    }
  }
}

export const selectCatalogCodesFromClientProducts = createSelector(selectClientProducts, (products) => {
  return _utils.getClassificationFromProductList(products);
});
// export const selectCatalogCodes = (store) => store.catalogCodes.data;
export const selectCatalogCodes = selectCatalogCodesFromClientProducts;

export const selectNamedClientProducts = createSelector((store) => store.clientProducts.data, (products) => {
  return products.filter(product => product.productName);
})

// ----------- drawer
export const selectDrawerClientProductsState = (store) => store.clientProducts.drawer;
export const selectVisibleDrawerClientProducts = (store) => store.clientProducts.drawer.visible;
export const selectEditProductDrawerClientProducts = (store) => store.clientProducts.drawer.editProduct;

// ----------- modal
export const selectClientProductVisible = (store) => store.clientProducts.tableModal.visible;
export const selectSelectedProductFromTable = (store) => store.clientProducts.tableModal.lastSelectedProduct;


// ------------------------------ SEND DOC TO PUBLIC VIEW ON CREATE ---------------------------------

export const selectSendDocToPublicView = (store) => store.sendDocToPublicView;

