import React from 'react';
import requestState from 'Services/api/status';
import Loading from './loading';
import Error from './error';

const Render = ({ children, status, loading = '', error = '', initial='' }) => {
  switch (status) {
    case requestState.loading:
      return loading || <Loading/>;
    case requestState.success:
      return children;
    case requestState.error:
      return error || <Error/>;
    case requestState.initial:
      return initial || <div/>
    default:
      return null;
  }
};

export default Render;
