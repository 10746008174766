import React, {memo} from 'react';
import Header from "Pages/Info/components/header/Header";
import Footer from "Pages/Info//components/footer/Footer";
import Helmet from "Components/organisms/head/Helmet";
import StyledBlogs from "./style";
import Cards from "./Cards";

const Blogs = () => {
  return (
    <div className="infoContainer">
      <Helmet title="24m.uz blog" content="Интересные публикации о процесс документооборота вы можете прочитать на странице блога 24m.uz. Не пропустите новости, подписывайтесь"/>
      <Header/>
      <StyledBlogs className="container">
        <Cards/>
      </StyledBlogs>
      <Footer/>
    </div>
  );
};

Blogs.whyDidYouRender = true;

export default memo(Blogs);