import client from 'Services/api/client';

const ApiLgota={
  async getLgotas(){
     return await  client('GET','/benefit')
  },
  async removeLgota(id){
      return await client('DELETE',`/benefit/${id}`)
  },
  async getLgotaWithTin(date, tin) {
      return await client('GET', `/benefit/check/benefit/${tin}?factureDate=${date}&companyStatusCode=10`)
  }
}


export default ApiLgota;