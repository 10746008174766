import styled from 'styled-components';
import get from 'lodash.get';
import getBaseStyleFormField from './baseStyleFormField';

const BaseStyledInput = getBaseStyleFormField('input');

export const BaseStyledCheckbox = styled(BaseStyledInput).attrs({
  type: 'checkbox'
})`
  height: 20px;
  width: 20px;
`;

export default BaseStyledInput;
