import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'Components/atoms/button';
import * as con from 'Store/constant';
import { selectAccount } from 'Components/organisms/layout/reducer/reducer';
import { alertTypes, cAlert } from 'Components/atoms/toast';
import { eimzoService } from 'Services/e-imzo';
import ApiUser from 'Services/api/controller/user';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import synchronizeSeveralDocumentsFromTax from "Utils/synchronizeSeveralDocumentsFromTax";

const SetBdmAsOperatorModal = ({ visible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accountData = useSelector(selectAccount);
  const ClientTin = accountData.user.pinfl ? accountData.user?.pinfl : accountData.user?.tin;
  const [loading, setLoading] = useState(false);

  const onOkAsync = async () => {
    setLoading(true);
    let isWrongPassword = false;

    const { fail, data } = await ApiUser.getBDMOperator(ClientTin);
    if (!fail) {
      const operators = data.data;
      if (!operators.includes('305858074')) {
        const body = { ClientTin, ProviderTins: [...operators, '305858074'] };

        // TODO email bilan login qilganda error beradi!!!
        await createSignOperator(body);
      }
    } else {
      cAlert(alertTypes.error, data.message);
    }
    setLoading(false);
    // !isWrongPassword && dispatch({type: con.FIRST_LOGIN, payload: false});

    async function createSignOperator(body) {
      try {
        const signText = await eimzoService.createSign(JSON.stringify(body));
        const { data } = await ApiUser.setBDMOperator(signText);
        if (data.success) {
          cAlert(alertTypes.success, data.reason);
          onCancel();
          synchronizeSeveralDocumentsFromTax();
        } else {
          cAlert(alertTypes.error, data.reason);
        }
      } catch (err) {
        cAlert(alertTypes.error, err);
        if (err.includes('javax.crypto.BadPaddingException')) isWrongPassword = true;
      }
    }
  };

  const onCancel = () => {
    dispatch({ type: con.FIRST_LOGIN, payload: false });
  }

  return (
    <Modal
      visible={visible}
      style={{
        width: 400, 
        border: '8px solid #2977FF',
        height: 'auto',
        paddingBottom: '0',
      }}
      footer={null}
      title={<h2 style={{ fontSize: 24 }}>{t('modalMessages:title.warning')}</h2>}
      closeIcon={<div onClick={onCancel} ><AiOutlineClose size={20} /></div>}
    >
      <h2 style={{ fontSize: 18, marginBottom: 15 }}>
        {t('modalMessages:bdmNotAsOperator')}
      </h2>
      <div style={{ display: 'flex', justifyContent: 'center', gap: 15 }}>
        <Button onClick={onOkAsync} loading={loading} disabled={loading} style={{ minWidth: 100 }}>
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default SetBdmAsOperatorModal;
