export function numberWithCommas(x = 0) {
  if (isNaN(x) || !x) {
    x = 0;
  }

  x = parseFloat(x);

  return x
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function numberWithSpace(x = 0, property) {
  if (isNaN(x) || !x) {
    x = 0;
  }

  x = parseFloat(x);

  if (property === "count") {

    let decimalPart = x.toFixed(4).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').split(".")[0]
    let fractionPart = x.toFixed(5).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').split(".")[1].replaceAll(" ", '')
    x = decimalPart + "." + fractionPart;
    return x;
  } else {
    return x
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}

function insert(str, index, value) {
  return str.substr(0, index) + value + str.substr(index);
}

export function generateNumberWithWhiteSpace(num, count) {
  let returnedData = '';

  let part_1 = num.split('.')[0];

  part_1 = part_1.split('').reverse().join('');

  for (let i = 0; i < part_1.length; i++) {
    returnedData += part_1[i];

    if ((i + 1) % count === 0) {
      let t = Math.trunc(i / count);
      returnedData = insert(returnedData, i + t + 1, ' ');
    }
  }

  return returnedData;
}

export function numberWithWhiteSpace({ num, count = 3 }) {
  if (num && !isNaN(num)) {
    let isMin = num.toString().length <= count;
    num = parseFloat(num).toFixed(2);
    const returnedData = generateNumberWithWhiteSpace(num, count);

    if (isMin) {
      return parseFloat(num).toFixed(2);
    } else {
      let part_2 = num.split('.')[1];

      return returnedData.split('').reverse().join('') + '.' + part_2;
    }
  } else {
    return num;
  }
}
