import requestState from 'Services/api/status';

const initialState = {
  data: [],
  status: requestState.initial
};

export const SET_CATALOG_CODES = 'SET_CATALOG_CODES';
export const ADD_CATALOG_CODE = 'ADD_CATALOG_CODE';

const catalogCodeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CATALOG_CODES:
      payload.data?.forEach?.(ctgData => {
        try {
          ctgData.internationalCodeList = ctgData.internationalCode.split(',').map(i => (i + '')?.trim());
        } catch (err) {
          ctgData.internationalCodeList = ctgData.internationalCode ? [ctgData.internationalCode?.trim?.()] : [];
        }
      })
      return {
        data: payload.data,
        status: requestState.success
      };
    case ADD_CATALOG_CODE:
      try {
        payload.internationalCodeList = payload.internationalCode.split(',').map(i => (i + '')?.trim());
      } catch (err) {
        payload.internationalCodeList = payload.internationalCode ? [payload.internationalCode?.trim?.()] : [];
      }
      payload.internationalCodeList = payload.internationalCode ? [payload.internationalCode?.trim()] : [];
      return {
        data: [...state.data, payload]
      };
    default:
      return state;
  }
};

export default catalogCodeReducer;
