import React from 'react';
import { useTranslation } from 'react-i18next';
import browserStorage from 'Services/browserStorage';

export const reportsMenuItems = [
  {
    type: 1,
    name: ''
  },
  {
    type: 2,
    name: ''
  },
  {
    type: 3,
    name: ''
  },
  {
    type: 4,
    name: ''
  },
  {
    type: 5,
    name: ''
  }
];

export const reportMenuTypes = [1, 2, 3, 4, 5];

export const reportsModuleMenuTranslateKeys = ['invoice', 'contract', 'message', 'reconciliationAct', 'acceptanceAct'];

export const financeSidebarData = {
  ru: [
    {
      title: 'Банк',
      id: 0,
      part: 2,
      children: [
        {
          id: 1,
          name: 'Платёжное поручение'
        },
        {
          id: 2,
          name: 'Платёжное требования'
        },
        {
          id: 3,
          name: 'Банковские выписки'
        }
      ]
    },
    {
      title: 'Денежные документы',
      id: 4,
      children: [
        {
          id: 5,
          name: 'Денежные документы'
        },
        {
          id: 6,
          name: 'Поступление денежных документов'
        },
        {
          id: 7,
          name: 'Выдача денежных документов'
        },
        {
          id: 8,
          name: 'Начисление процентов По графику кредитам'
        },
        {
          id: 9,
          name: 'Учет начисленных процентов По выданным кредитам'
        }
      ]
    },
    {
      title: 'Касса',
      id: 10,
      children: [
        {
          id: 11,
          name: 'Кассовые документы'
        },
        {
          id: 12,
          name: 'Операция пo платежным картам'
        },
        {
          id: 13,
          name: 'Авансовые отчеты'
        },
        {
          id: 14,
          name: 'Кассовые смены'
        },
        {
          id: 15,
          name: 'Кассовые чеки коррекции'
        },
        {
          id: 16,
          name: 'Управление фискальным устройством'
        },
        {
          id: 17,
          name: 'Управление эквайринговым терминалом'
        }
      ]
    },
    {
      title: 'Сервис',
      id: 19,
      children: [
        {
          id: 20,
          name: 'Дополнительные обработки'
        },
        {
          id: 21,
          name: 'Обмен с банком'
        },
        {
          id: 22,
          name: 'Авансовые отчеты'
        },
        {
          id: 23,
          name: 'Кассовые смены'
        },
        {
          id: 24,
          name: 'Кассовые чеки коррекции'
        },
        {
          id: 25,
          name: 'Управление фискальным устройством'
        },
        {
          id: 26,
          name: 'Управление эквайринговым терминалом'
        }
      ]
    },
    {
      name: 'Настройки',
      id: 27
    }
  ],
  latin: [
    {
      title: 'Bank',
      id: 0,
      part: 2,
      children: [
        {
          id: 1,
          name: "To'lov topshirig'i"
        },
        {
          id: 2,
          name: "To'lov talabi"
        },
        {
          id: 3,
          name: 'Bank bayonotlari'
        }
      ]
    },
    {
      title: 'Naqd pul hujjatlari',
      id: 4,
      children: [
        {
          id: 5,
          name: 'Naqd pul hujjatlari'
        },
        {
          id: 6,
          name: 'Pul hujjatlarini qabul qilish'
        },
        {
          id: 7,
          name: 'Pul hujjatlarini berish'
        },
        {
          id: 8,
          name: 'Foizlarni hisoblash Kreditlar jadvaliga muvofiq'
        },
        {
          id: 9,
          name: "Berilgan kreditlar bo'yicha hisoblangan foizlarni hisobga olish"
        }
      ]
    },
    {
      title: 'Kassa',
      id: 10,
      children: [
        {
          id: 11,
          name: 'Naqd pul hujjatlari'
        },
        {
          id: 12,
          name: "To'lov kartalari bilan operatsiyalar"
        },
        {
          id: 13,
          name: 'Xarajatlar hisoboti'
        },
        {
          id: 14,
          name: 'Hisob-kitoblarni almashtirish'
        },
        {
          id: 15,
          name: 'Naqd pul tushumlarini tuzatish'
        },
        {
          id: 16,
          name: 'Fiskal qurilmalarni boshqarish'
        },
        {
          id: 17,
          name: 'Terminal boshqaruvini olish'
        }
      ]
    },
    {
      title: "Qo'shimcha hisobotlar",
      id: 18
    },
    {
      title: 'Xizmatlar',
      id: 19,
      children: [
        {
          id: 20,
          name: "Qo'shimcha ishlov berish"
        },
        {
          id: 21,
          name: 'Bank almashinuvi'
        },
        {
          id: 22,
          name: 'Xarajatlar hisoboti'
        },
        {
          id: 23,
          name: 'Hisob-kitoblarni almashtirish'
        },
        {
          id: 24,
          name: 'Naqd pul tushumlarini tuzatish'
        },
        {
          id: 25,
          name: 'Fiskal qurilmalarni boshqarish'
        },
        {
          id: 26,
          name: 'Terminal boshqaruvini olish'
        }
      ]
    },
    {
      name: 'Sozlamalar',
      id: 27
    }
  ],
  cyrillic: [
    {
      title: 'Банк',
      id: 0,
      part: 2,
      children: [
        {
          id: 1,
          name: 'Тўлов топшириғи'
        },
        {
          id: 2,
          name: 'Тўлов талаби'
        },
        {
          id: 3,
          name: 'Банк баёнотлари'
        }
      ]
    },
    {
      title: 'Нақд пул ҳужжатлари',
      id: 4,
      children: [
        {
          id: 5,
          name: 'Нақд пул ҳужжатлари'
        },
        {
          id: 6,
          name: 'Пул ҳужжатларини қабул қилиш'
        },
        {
          id: 7,
          name: 'Пул ҳужжатларини бериш'
        },
        {
          id: 8,
          name: 'Фоизларни ҳисоблаш Кредитлар жадвалига мувофиқ'
        },
        {
          id: 9,
          name: 'Берилган кредитлар бўйича ҳисобланган фоизларни ҳисобга олиш'
        }
      ]
    },
    {
      title: 'Касса',
      id: 10,
      children: [
        {
          id: 11,
          name: 'Нақд пул ҳужжатлари'
        },
        {
          id: 12,
          name: 'Тўлов карталари билан операциялар'
        },
        {
          id: 13,
          name: 'Харажатлар ҳисоботи'
        },
        {
          id: 14,
          name: 'Ҳисоб-китобларни алмаштириш'
        },
        {
          id: 15,
          name: 'Нақд пул тушумларини тузатиш'
        },
        {
          id: 16,
          name: 'Фискал қурилмаларни бошқариш'
        },
        {
          id: 17,
          name: 'Терминал бошқарувини олиш'
        }
      ]
    },
    {
      title: 'Қўшимча ҳисоботлар',
      id: 18
    },
    {
      title: 'Хизматлар',
      id: 19,
      children: [
        {
          id: 20,
          name: 'Қўшимча ишлов бериш'
        },
        {
          id: 21,
          name: 'Банк алмашинуви'
        },
        {
          id: 22,
          name: 'Харажатлар ҳисоботи'
        },
        {
          id: 23,
          name: 'Ҳисоб-китобларни алмаштириш'
        },
        {
          id: 24,
          name: 'Нақд пул тушумларини тузатиш'
        },
        {
          id: 25,
          name: 'Фискал қурилмаларни бошқариш'
        },
        {
          id: 26,
          name: 'Терминал бошқарувини олиш'
        }
      ]
    },
    {
      name: 'Созламалар',
      id: 27
    }
  ]
};

export const agreementSidebarData = {
  latin: [
    {
      name: 'Kelishuvlarim',
      id: 0
    },
    {
      name: 'Mehnat shartnomalari',
      id: 4
    },
    {
      name: 'Kontragentlar',
      id: 10
    },
    {
      name: 'Shartnomalar',
      id: 11
    }
  ],
  cyrillic: [
    {
      name: 'Келишувларим',
      id: 0
    },
    {
      name: 'Меҳнат шартномалари',
      id: 4
    },
    {
      name: 'Контрагентлар',
      id: 10
    },
    {
      name: 'Шартномалар',
      id: 11
    }
  ],
  ru: [
    {
      name: 'Мои сделки',
      id: 0
    },
    {
      name: 'Трудовые договора',
      id: 4
    },
    {
      name: 'Контрагенты',
      id: 10,
    },
    {
      name: 'Договора',
      id: 11,
      // path: '/dashboard/agreements'
    }
  ]
};
