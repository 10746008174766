import React, { useState } from "react";
import { Container, LoaderWrapper } from './style'
import { Document, Page, pdfjs } from 'react-pdf';
import Loader from "Components/atoms/spinner/loading";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SinglePage(props) {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false)
    const screenWidth = window.innerWidth;

    function onDocumentLoadSuccess({ numPages, ...rest }) {
        setNumPages(numPages);
        setPageNumber(1);
        setIsLoaded(true)
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    //Load PDF
    const { pdf } = props;

    return (
        <>
            <Container isLoaded={isLoaded}>
                <Document
                    noData={<LoaderWrapper><Loader /></LoaderWrapper>}
                    loading={<LoaderWrapper><Loader /></LoaderWrapper>}
                    file={pdf}
                    options={{ workerSrc: "/pdf.worker.js" }}
                    onSourceError={(err) => console.log(err)}
                    onSourceSuccess={() => console.log("SUCCESS")}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={() => console.log("ERR")}
                >
                    {numPages &&
                        Array.from({ length: numPages }).map((num, index) => {
                            return <Page pageNumber={index + 1} />
                        })
                    }
                </Document>
            </Container>
        </>
    );
}