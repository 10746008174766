import { createPkcs7, appendPkcs7 } from './pkcs7Serviec';

class EimzoSign {
  createSign(docContent){
    return new Promise((resolve, reject) => {
      createPkcs7(docContent, (pkcs7, err) => {
        if (err) {
          reject(err);
        } else {
          resolve(pkcs7);
        }
      });
    });
  }

  appendSign(docContent) {
    return new Promise((resolve, reject) => {
      appendPkcs7(docContent, (pkcs7, err) => {
        if (err) {
          reject(err);
        } else {
          resolve(pkcs7);
        }
      });
    });
  }
}

const eimzoService = new EimzoSign();

export {
  eimzoService
}

export default EimzoSign;
