import * as con from 'Store/constant';
import requestState from 'Services/api/status';
const initialState = {
  rejectFail: {},
  rejectData: {},
  status: 'initial',
  massive: {
    success: [],
    error: [],
    loading: null
  },
  mode: 'one'
};

const rejectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case con.REJECT_DOCUMENT:
      return {
        ...state,
        status: requestState.loading,
        massive: {
          ...state.massive,
          loading: payload?.id
        },
        mode: payload?.mode || 'one'
      };
    case con.REJECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        rejectData: { message: payload?.message, id: payload?.id },
        status: requestState.success,
        ...(
          state.mode === 'multiple' ? {
            massive: {
              ...state.massive,
              loading:null,
              success: [...state.massive.success, payload]
            }
          } : {}
        )
      };
    case con.REJECT_DOCUMENT_FAIL:
      return {
        ...state,
        status: requestState.error,
        rejectFail: {
          message: payload?.message,
          id: payload?.id
        },
        ...(
          state.mode === 'multiple' ? {
            massive: {
              ...state.massive,
              loading:null,
              error: [...state.massive.error, payload]
            }
          } : {}
        )
      };
    case 'CLEAR_REJECT':
      return {
        ...state,
        status: 'initial'
      };
    case con.SET_INITIAL_SITUATION_REJECT:
      return initialState;
    default:
      return state;
  }
};

export default rejectReducer;
