import { useState } from 'react';
import { IconContext } from 'react-icons';
import { MdDateRange } from 'react-icons/all';
import {useParams} from "react-router-dom";
import { colors } from 'Style/colors';
import * as allState from 'Store/state';
import { isInvoiceHandler, isTaxEmpowerment, isAct, isContractHandler } from 'Utils/docTypes';
import { StyledBadge } from 'Modules/box/components/actionModalContent/button.style';
import { allStateOptions } from 'Components/molecules/statusBox/types';
import { numberWithCommas } from 'Utils/thousandSeparetor';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getShortenedMchj } from 'Utils/toMchj';
import { handleWaybillActionIcon } from 'Utils/handleActionIcon';

const location = window.location;

function _renderInfoBaseRow({ index, name, t, value }) {
  return (
    <>
      <div className="table-data ">
        {index}. {t(name)}
      </div>
      {name === 'box:receiverName' ? <div className="table-data w-auto">{getShortenedMchj(value)}</div> : <div className="table-data w-auto">{value}</div>}
    </>
  );
}

function _renderInfoBaseDateRow({ index, name, t, value }) {
  return (
    <>
      <IconContext.Provider value={{ style: { fontSize: '14', color: colors.primary, marginLeft: '5px', marginBottom: -2 } }}>
        <div className="table-data ">
          {index}. {t(name)} <MdDateRange />
        </div>
      </IconContext.Provider>
      <div className="table-data w-auto">{value}</div>
    </>
  );
}

function _renderInfoStateRow({ index, name, t, value }) {
  const {state: boxType} = useParams();
  let valueForLabel = value;

  // ~~~~~~~~~ inBox va outBox da waitingSignature ni dynamic qilish uchun qo'shildi ~~~~~~~~
  if (boxType === '1' && (value === 10 || value === 31 || value === 12)) {
    valueForLabel = 0.1;
  } else if (boxType === '2' && (value === 10 || value === 31 || value === 12)) {
    valueForLabel = 0.2;
  }

  return (
    <>
      <div className="table-data">
        {index}. {t(name)}
      </div>
      <StyledBadge style={{ backgroundColor: allStateOptions[value]?.color }}>
        {t(`common:state.${allStateOptions[valueForLabel]?.label}`)}
      </StyledBadge>
    </>
  );
}

function _renderInfoSumRow({ name, t, value, index }) {
  return (
    <>
      <div className="table-data">
        {index}. {t(name)}
      </div>
      <div className="table-data w-auto">{numberWithCommas(value)}</div>
    </>
  );
}

function ContractUrl({ name, t, value, index }) {
  const [copy, setCopy] = useState(false);

  return (
    <>
      <div className="table-data">
        {index}. {t(name)}
      </div>
      <CopyToClipboard text={`${location.origin}/public/document/${value}`} onCopy={() => setCopy(true)}>
        <span>{`${location.origin}/public/document/${value}`}</span>
      </CopyToClipboard>

      {copy && <span className="ml-1">скопировано</span>}
    </>
  );
}

class BaseBox {
  icons = {
    sign: false,
    edit: false,
    reject: false,
    delete: false,
    copy: false,
    view: true,
    info: true,
    scan: false,
    exChange: false,
    download: false,
    constructionObject: false,
    changeType: false,
    attachment: false,
    financierVerify: false,
    exportToExcel: false,
    notApproved: false
  };

  infos = [
    {
      name: 'box:documentId',
      key: 'id',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:documentId', t, value })
    },
    {
      name: 'box:documentTaxId',
      key: 'taxId',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:documentTaxId', t, value })
    },
    {
      name: 'box:documentNum',
      key: 'documentNumber',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:documentNum', t, value })
    },
    {
      name: 'box:documentDate',
      key: 'documentDate',
      render: (index, t, value) => _renderInfoBaseDateRow({ index, name: 'box:documentDate', t, value })
    },
    {
      name: 'box:docType',
      key: 'documentType',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:docType', t, value })
    },
    {
      name: 'box:senderTin',
      key: 'senderTin',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:senderTin', t, value })
    },
    {
      name: 'box:senderName',
      key: 'senderName',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:senderName', t, value })
    },
    {
      name: 'box:receiverTin',
      key: 'receiverTin',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:receiverTin', t, value })
    },
    {
      name: 'box:receiverName',
      key: 'receiverName',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:receiverName', t, value })
    },
    {
      name: 'box:thirdPartyTrustee',
      key: 'thirdPartyTrustee',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:thirdPartyTrustee', t, value })
    },
    {
      name: 'box:thirdPartyName',
      key: 'thirdPartyName',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:thirdPartyName', t, value })
    },
    {
      name: 'box:thirdPartyTin',
      key: 'thirdPartyTin',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:thirdPartyTin', t, value })
    },
    {
      name: 'box:contractNum',
      key: 'contractNumber',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:contractNum', t, value })
    },
    {
      name: 'box:contractDate',
      key: 'contractDate',
      render: (index, t, value) => _renderInfoBaseDateRow({ index, name: 'box:contractDate', t, value })
    },
    {
      name: 'box:constructionObject',
      key: 'constructionObject',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:constructionObject', t, value })
    },
    {
      name: 'box:description',
      key: 'description',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:description', t, value })
    },
    {
      name: 'box:notes',
      key: 'notes',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:notes', t, value })
    },
    {
      name: 'box:docSentDate',
      key: 'sentDate',
      render: (index, t, value) => _renderInfoBaseDateRow({ index, name: 'box:docSentDate', t, value })
    },
    {
      name: 'box:docAcceptDate',
      key: 'acceptedDate',
      render: (index, t, value) => _renderInfoBaseDateRow({ index, name: 'box:docAcceptDate', t, value })
    },
    {
      name: 'box:docSum',
      key: 'sum',
      render: (index, t, value) => _renderInfoSumRow({ name: 'box:docSum', t, value, index })
    },
    {
      name: 'box:currBranch',
      key: 'currentBranch',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:currBranch', t, value })
    },
    {
      name: 'box:currEmployee',
      key: 'currentEmployee',
      render: (index, t, value) => _renderInfoBaseRow({ index, name: 'box:currEmployee', t, value })
    },
    {
      name: 'box:docState',
      key: 'documentState',
      render: (index, t, value) => _renderInfoStateRow({ index, name: 'box:docState', t, value })
    },
    {
      name: 'box:docLink',
      key: 'diContractId',
      render(index, t, value) {
        return <ContractUrl name={this.name} index={index} t={t} value={value} />;
      }
    },
    {
      name: 'box:contractUrl',
      key: 'childInternalId',
      render(index, t, value) {
        return <ContractUrl name={this.name} index={index} t={t} value={value} />;
      }
    }
  ];

  actions = {
    view: 'view',
    sign: 'sign',
    reject: 'reject',
    delete: 'delete',
    copy: 'copy',
    info: 'info',
    scan: 'scan',
    exChange: 'exChange',
    download: 'download',
    edit: 'edit',
    constructionObject: 'constructionObject',
    changeType: 'changeType',
    attachment: 'attachment',
    financierVerify: 'financierVerify',
    exportToExcel: 'exportToExcel',
    sendToPublicView: 'sendToPublicView',
    notApproved: 'notApproved',
    aiChat: 'aiChat',
    approve: 'approve',
    disApprove: 'disApprove'
  };

  moduleType;

  tabIndex;

  set setTabIndex(index) {
    this.tabIndex = index;
  }

  getList() { }

  defaultGetList() { }

  getColumns() { }

  getAllowedActionKeys() { }

  getActiveIcons({ boxType, docType, docState, blocked, branchName }) {
    return {
      ...this.icons,
      scan: !blocked,
      exChange: !blocked,
      download: !blocked,
      constructionObject: !blocked,
      changeType: (docState !== allState.RECEIVER_ACCEPTED),
      delegate: blocked,
      financierVerify: !blocked,
      // sendToPublicView: (!blocked && boxType == 2),
      // ...(((isContractHandler(docType)) && { edit: true && !blocked })),
      ...(((isAct(docType)) && { copy: true && !blocked })),
      ...((isInvoiceHandler(docType) || isTaxEmpowerment(docType)) && { copy: true && !blocked }),
      ...((branchName !== "" ||
        docState === allState.SEND_TO_AGENT ||
        docState === allState.SEND_TO_RECEIVER ||
        docState === allState.AGENT_ACCEPTED ||
        handleWaybillActionIcon({ type: docType, state: docState }) ||
        docState === allState.SENDING || docState === 65 || docState === 33) && {
        reject: docState !== 33 || docState === 65 || branchName !== "" || (docState === allState.SEND_TO_RECEIVER || docState === allState.SEND_TO_AGENT || docState === allState.AGENT_ACCEPTED),
        sign: (branchName === "" || (docState === allState.SEND_TO_RECEIVER || docState === allState.SEND_TO_AGENT || docState === allState.AGENT_ACCEPTED)) && docState === 33 || docState !== 65 && !blocked

      }),
      attachment: !blocked
    };
  }
}

const baseBox = new BaseBox();

export { _renderInfoBaseRow, _renderInfoBaseDateRow, baseBox };

export default BaseBox;
