import styled from "styled-components";
import { colors } from 'Style/colors'

export const MainContainer = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    background:#F2F6FF;
    gap:30px;

    @media (max-width:800px){
        flex-direction:column-reverse;
    }
`;

export const PdfWrap = styled.div`
     width:calc(63% - 0px);
     min-height: calc(45vh - 1px);
    //  height:100%;
     display:flex;
     padding: 10px 10px;
     justify-content: center;
     align-items: center;
     border-radius: 8px;
     background: #FFF;

     @media (max-width:800px){
        width:97%;
        min-height: calc(20vh - 1px);
    }
`;

export const FormContainer = styled.form`
    width:37%;
    min-height: calc(45vh - 1px);
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    gap:10px;
    padding: 10px 20px;
    border-radius: 8px;
    background: #FFF;

    @media (max-width:800px){
        width:97%;
        min-height: calc(40vh - 1px);
    }
`;

export const Fields = styled.div`
    width:calc(100% - 0px);
    height:100%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    div {
        margin-bottom:0px;
        height:32px;

        label {
            width:100%;
            height:auto;
            font-size:11px;
            font-weight:400;
            line-height:14px;

            a:hover {
                text-decoration: underline;
            }
        }

        #offerAccept {
            width:7%;
            margin-left:2px;
            cursor:pointer;
        }

        input {
            height:32px;
            font-size:14px;
           
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0; 
        }

        input::placeholder {
            font-size: 12px; 
            // color:red;
         
        }

        .table-out-error-field {
            .ant-picker-input {
                color:red;
                input::placeholder {
                    color: red;
                }
             }
        }

        .table-out-error-field::placeholder {
             color: red;

             .ant-picker-input {
                input::placeholder {
                    font-size: 12px; 
                    color: red;
                }
             }
        }
          
    }
`;

export const FieldsTitle = styled.div`
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    height:20px !important;

    @media (max-width:800px){
        height:20px !important;
    }
`;

export const Button = styled.button`
    display: flex;
    width: 90%;
    padding: 8px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #0F5CEF;
    color: #FFF;
    border:1px solid #0F5CEF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; 
    cursor:pointer;

    margin-top:10px;

    ${(props) => props?.disabled ? `
    background: ${colors.disableColor};
    color: #FFF;
    border:1px solid ${colors.disableColor};
    cursor: not-allowed;
    ` : `
    
    &:hover {
        color: #0F5CEF;
        background: #FFF;
    }
    `};

   
`
