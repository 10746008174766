import React from 'react';
import { colors } from 'Style/colors';

const SVG = ({ style = {}, fill = colors.primary, width = '100%', onClick, className = '', viewBox = '0 0 40 26' }) => (
  <svg
    width={width}
    style={style}
    onClick={onClick}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M12.1639 17.026L11.6511 24.2386C12.3847 24.2386 12.7025 23.9235 13.0835 23.545L16.5232 20.2577L23.6506 25.4774C24.9578 26.2059 25.8788 25.8222 26.2314 24.2748L30.9098 2.35265L30.9111 2.35136C31.3257 0.419024 30.2123 -0.336601 28.9387 0.13744L1.43915 10.6658C-0.437645 11.3943 -0.409229 12.4406 1.1201 12.9146L8.15065 15.1014L24.4812 4.88302C25.2497 4.37411 25.9485 4.65569 25.3737 5.16461L12.1639 17.026Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
