import React from 'react'
import { SupportPopupWrapper, DropdownLink, SupportPopupInner } from './style';
import { Dropdown } from 'antd';
import CommonIcon from 'Assets/icons/common';
import CopyableText from 'Components/atoms/CopyableText';
import { useTranslation } from 'react-i18next';
import { cAlert } from 'Components/atoms/toast';
import ApiUser from 'Services/api/controller/user';
import { colors } from 'Style/colors';
import { useSelector } from 'react-redux';


const SupportPopup = () => {
  const { t } = useTranslation(['layout', 'translation']);
  const isNewUi = useSelector((state) => state.ui.isNewUi);

  const getTgToken = async () => {
    const { data, fail, message } = await ApiUser.getUserTgToken();
    if (!fail) {
      const link = document.createElement('a');
      link.href = `https://t.me/bdm_notification_bot?start=${data.data}`;
      link.target = '_blank';
      link.rel = 'noreferrer';
      link.click();
    } else {
      cAlert('error', message);
    }
  };

  const menu = () => {

    return (
      <SupportPopupInner isNewUi={isNewUi}>
        <div className='support-item'>
          <CommonIcon name='phone' className='support-item__img' fill={isNewUi ? colors.iconColor : colors.white} />
          <div className='support-item__content'>
            <span className='support-item__content-label'>
              {t('layout:navbar.phoneNumber')}
            </span>
            <CopyableText text={'+998951942424'} className='support-item__content-text' variant='tooltip'>
              +998 95 194 2424
            </CopyableText>
            <CopyableText style={{ marginTop: '3px' }} text={'+998956322424'} className='support-item__content-text' variant='tooltip'>
              +998 95 632 2424
            </CopyableText>
          </div>
        </div>
        <div className='support-item'>
          <CommonIcon name='telegram2' className='support-item__img' fill={isNewUi ? colors.iconColor : colors.white} />
          <div className='support-item__content'>
            <span className='support-item__content-label support-item__content-label--withHover'>
              <a href='https://t.me/bdm_24m_admin' target='_blank' rel="noreferrer" className='support-item__content-text'>
                {t('layout:navbar.telegram')} @BDM_24m
              </a>
            </span>

            <div className='support-item__content-label support-item__content-label--withHover' style={{ cursor: 'pointer' }} onClick={getTgToken}>
              {t('translation:getNotificationInTelegram')}
            </div>
          </div>
        </div>
      </SupportPopupInner>
    )
  };

  return (
    <SupportPopupWrapper>
      <Dropdown down placement="bottomCenter" overlay={menu} trigger={['click']}>
        <DropdownLink className="ant-dropdown-link tourSelector support-hint" onClick={(e) => e.preventDefault()}>
          <CommonIcon name={'support'} width="30" height="30" viewBox="0 0 18 20" className="support-image"
            alt="drop-down" />
        </DropdownLink>
      </Dropdown>
    </SupportPopupWrapper>
  )
}

export default SupportPopup;