import styled from "styled-components";
import { colors } from 'Style/colors'


// :root {
//     --entered: #dfe2e9c5;
//     --leaved: #F2F6FF;
//     --initial: #F2F6FF;

//     --drop-initial: #FFF;
//     --drop-entered: #dfe2e9;
//     --drop-leaved: #FFF;
//   }

export const DivWrapper = styled.div`

  
  .title {
    color: #0F5CEE;
    text-align: center;
    font-family: 'Inter';
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.427px;
  
    @media (max-width:800px) {
      font-size: 22px;
      line-height: 22px;
      margin-bottom: 0px;
    }
  }
  
  .instruction {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
  
  .note {
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.427px;
  }
  
  .note2 {
    color: #727272;
    text-align: center;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.056px;
  }
  
  .content {
    height: 100%;
    width: 100%;
    min-height: 48.832vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 44.226px 51.597px 44.226px 51.597px;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 7.371px;
    background: #F2F6FF;
  
    @media (max-width: 800px) {
      padding: 20px 10px;
      justify-content: space-evenly;
      min-height: 41.832vh;
      gap: 0px;
    }
  }
  
  .content.hasFile {
    padding: 4.226px 31.597px 24.226px 31.597px;
    align-items: flex-start;
  
    @media (max-width:800px) {
      padding: 12px 10px;
    }
  }
  
  .drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7.371px;
    border: 0.921px dashed #D7DBE1;
    margin: 0;
    width: 52%;
    min-height: 24vh;
    cursor: pointer;
    background-color: #FFF;
    text-align: center;
  
    @media (max-width:800px) {
      width: 74%;
      min-height: 20vh;
    }
  }
  
  .drop-container.dragged_item_over {
    background-color: #dfe2e9;
    border-radius: 10px;
    transform: scale(1.01, 1.01);
    transition: color 100ms, transform 100ms, border-radius 1s;
    box-shadow: 0px 6px 2px #dfe2e9;
    border: 4px dotted #dfe2e9;
  }
  
  .drop-container.dragged_item_leave {
    background-color: #FFF;
    transform: scale(1.01, 1.01);
    transition: color 100ms, transform 100ms;
    box-shadow: 0px 6px 2px #FFF;
    border: 4px dotted #FFF;
  }
  
  .drop-container.hasFile {
    min-height: 41vh;
    width: 100%;
    border-radius: 4px;
    padding: 0% 0%;
  }
  
  .preview-container {
    width: 80%;
    border-radius: 4px;
    padding: 2px;
    background: #F2F6FF;
    height: calc(43vh - 1px);
    overflow-y: scroll;
    overflow-x: hidden;
  
    @media (max-width:800px) {
      width: 100%;
      height: calc(23vh - 1px);
    }
  }
  
  .content.dragged_item_over {
    background-color: #dfe2e9c5;
    border-radius: 10px;
    transform: scale(1.01, 1.01);
    transition: color 100ms, transform 100ms, border-radius 1s;
    box-shadow: 0px 6px 2px #dfe2e9c5;
    border: 4px dotted #dfe2e9c5;
  }
  
  
  .content.dragged_item_leave {
    background-color: #F2F6FF;
    transform: scale(1.01, 1.01);
    transition: color 100ms, transform 100ms;
    box-shadow: 0px 6px 2px #F2F6FF;
    border: 4px dotted #F2F6FF;
  }
  
  .upload-icon {
    width: 50px;
    height: 50px;
    background: url("../../../../../Assets/images/upload.png") no-repeat center center;
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;
    color: #0F5CEE;
  }
  
  .drop-message {
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    text-align: center;
    font-family: 'Inter';
    font-size: 18px;
    gap: 10px;
    color: #000;
  }
  
  .file-display-container {
    position: fixed;
    width: 30%;
  }
  
  .file-status-bar {
    width: 100%;
    vertical-align: top;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    height: 50px;
  }
  
  .file-status-bar>div {
    overflow: hidden;
  }
  
  .file-type {
    display: inline-block !important;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    margin-top: 25px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
  }
  
  .file-wrapper {
    line-height: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    /* margin-right: 50px; */
  }
  
  .file-name {
    display: inline-block;
    vertical-align: top;
    width: 60%;
    margin-left: 50px;
    color: #4aa1f3;
  }
  
  .file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #ff8a00;
  }
  
  .file-error-message {
    color: #ff8a00;
  }
  
  .file-type-logo {
    width: 40px;
    height: 50px;
    background: url('Assets/images/generic.png') no-repeat center center;
    background-size: 100%;
    position: absolute;
  }
  
  .file-size {
    display: inline-block;
    vertical-align: top;
    color: #30693d;
    margin-left: 10px;
    margin-right: 5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
  }
  
  .file-remove {
    position: absolute;
    top: 15px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* align-self: center; */
    width: 10%;
    /* height: 60%; */
    /* padding: 4px; */
    cursor: pointer;
    /* margin-left: 10px; */
    border: 1px solid #2977FF;
    -webkit-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
  }
  
  .file-remove:hover {
    background-color: #2977FF;
    box-shadow: 0 0 20px rgba(33, 33, 33, 0.2);
    color: #fff;
  }
  
  .modal {
    z-index: 999;
    display: none;
    overflow: hidden;
  }
  
  .modal .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.66);
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .modal .modal-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .file-upload-btn {
    color: white;
    text-transform: uppercase;
    outline: none;
    background-color: #2977FF;
    font-weight: bold;
    padding: 8px 15px;
    margin-bottom: 5px;
  }
  
  #theFile {
    opacity: 0;
    height: 1px;
  }
  
  .uploadIcon svg {
    height: 80px;
    width: 100px;
    fill: #2977FF;
  }
`;