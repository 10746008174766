import React from 'react';

const SVG = ({ style = {}, fill = '#000', width = '100%', onClick, className = '', viewBox = '0 0 40 26' }) => (
  <svg
    width={width}
    style={style}
    onClick={onClick}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path d="M7.11824 5.31578H12V7.46201H7.11824V13.1035H4.88176V7.46201H0V5.31578H4.88176V0.103516H7.11824V5.31578Z" fill="white" />
  </svg>
);

export default SVG;
