import client from 'Services/api/client';

let BASE_URL = '/user';

const ApiUser = {
  async getUserData() {
    return await client(undefined, BASE_URL);
  },
  async getUserDetails() {
    return await client(undefined, `${BASE_URL}/details`);
  },
  async updateUserDetails(body) {
    return await client('PUT', BASE_URL, body);
  },
  async getPasswordData(tin) {
    return await client(undefined, `/user/requisite/from/tax/${tin}`);
  },
  async getUserTaxInfo(documentDate, tin) {
    if (tin?.length !== 14) return await client('GET', `/info/users/tax/?date=${documentDate}&tinOrPinfl=${tin}`);
    return {
      data: null,
      fail: true,
      message: ''
    };
  },
  async getCommisionerList(value) {
    return await client(undefined, `/user/commissioner/${value}`);
  },
  async getSignerFromLocalDatabase(value) {
    if (value?.length > 2) {
      return await client(undefined, `/user/requisite/list/${value}`);
    }
  },
  async getSignerFromTaxDatabase(tin) {
    return await client(undefined, `/user/requisite/from/tax/${tin}`);
  },
  async getRegions() {
    return await client(undefined, '/info/regions/list');
  },
  async getDistricts(regionId) {
    return await client(undefined, `/info/districts/list/${regionId}`);
  },
  async getRoutes(body) {
    return await client('GET', `/routes/documentType/all?routeType=${body.routeType}`);
  },
  async getLotBranches(buyerINN) {
    if (buyerINN) {
      return await client('GET', `/info/branch/from/tax/${buyerINN}`);
    }
  },
  async getIsBudget(buyerINN) {
    if (buyerINN) {
      return await client('GET', `/info/budget/${buyerINN}`);
    }
  },
  async getLotTypes() {
    return await client('GET', `/info/lot/types`);
  },
  async getLotTableData(lotId, buyerINN) {
    if (lotId && buyerINN) {
      return await client('GET', `/info/lot/${lotId}/${buyerINN}`);
    }
  },
  async getLotsWithType(lotNum, buyerINN) {
    if (lotNum && buyerINN) {
      return await client('GET', `/info/getlotswithtype/${lotNum}/${buyerINN}`);
    }
  },
  async getLgotaDataByCatalogCode(catalogCode, factureDate) {
    return await client('GET', `/benefit/check/benefit/mxikCode/${catalogCode}?factureDate=${factureDate}`);
  },
  async getLgotaDataByCompany(tin, factureDate, companyStatusCode) {
    return await client('GET', `/benefit/check/benefit/${tin}?factureDate=${factureDate}&companyStatusCode=${companyStatusCode}`);
  },
  async getCompanyStatusCode(tin) {
    if (tin) {
      return await client('GET', `/info/company/status/${tin}`);
    }
  },
  async getAnalyticsCompanyStatus(tin) {
    if (tin) {
      return await client('GET', `analytics/company/status/${tin}`);
    }
  },
  async isCompanyInBlackList(tin) {
    if (tin) {
      return await client('GET', `/info/company/is/blacklist/${tin}`);
    }
  },
  async getContractList(tin) {
    if (tin) {
      return await client('GET', `/contractlist/${tin}`);
    }
  },
  async getContractDataById(taxId) {
    if (taxId) {
      return await client('GET', `/contractlist/get/tax/${taxId}`);
    }
  },
  async getDataByWaybillTaxId(taxId) {
    if (taxId) {
      return await client('GET', `/waybills/${taxId}`);
    }
  },
  async getPaymentHistory() {
    return await client('GET', `user/payment/history`);
  },
  async getUserSpending(page) {
    return await client('GET', `user/expenses?page=${page}`);
  },

  async updateUserPlan(id, isSemiAnnualPlan, isAnnualPlan) {
    // TARIFNI O'ZGARTIRISH
    return await client('PUT', `/tariffs/change/${id}?semiAnnualPlan=${isSemiAnnualPlan}&annualPlan=${isAnnualPlan}`);
  },
  async getPlanList() {
    return await client('GET', `/tariffs/plans`);
  },
  async getOnePlan(id = 1) {
    return await client('GET', `/tariffs/${id}`);
  },
  async getSuperAdminSomething(tin) {
    // superAdmin/get-all/{tin}
    if (tin) {
      return await client('GET', `/superAdmin/get-all/${tin}`);
    }
  },
  async updateSuperAdminSomething(tin, body) {
    // superAdmin//edit-user-doc-types/{tin}
    return await client('PUT', `superAdmin/edit-user-doc-types/${tin}`, body);
  },
  async getAllContractList(body) {
    // /contractlist
    return await client('POST', `/contractlist`, body);
  },
  async getAllCounterAgentList(body = {}) {
    // /contractlist
    return await client('POST', `partners/get-all`, body);
  },
  async getContractByTax(taxId) {
    // /contractlist
    if (taxId) {
      return await client('GET', `/contractlist/synchronizer/${taxId}`);
    }
  },
  async getAllSpecificationlist(body) {
    // /contractlist
    return await client('POST', `/documents/get/specifications`, body);
  },
  async editOrCreateContractlist(body) {
    // /contractlist
    return await client('POST', `/contractlist/save`, body);
  },
  async deleteContractlistById(id) {
    // /contractlist
    return await client('DELETE', `/contractlist/delete/${id}`);
  },
  async uploadContractListsExcel(body, boxType) {
    return await client('POST', `/contractlist/upload-from-excel?boxType=${boxType}`, body, { 'Content-Type': 'multipart/form-data' }, undefined, 'multipart/form-data')
  },
  async getContractListsExcelTemplate() {
    return await client(undefined, '/contractlist/download-template/1', undefined, { 'Content-Type': 'blob' }, { responseType: 'blob' })
  },

  async getBDMOperator(tin) {
    return await client('POST', `/user/operators/${tin}`, tin);
  },
  async setBDMOperator(sign) {
    return await client('POST', `/user/provider/binduser`, { sign: sign });
  },
  async getPackageCode(mxikCode) {
    return await client('GET', `/info/get/package/${mxikCode}`);
  },
  async getTableColumnChecklist() {
    return await client('GET', `/control/column`);
  },
  async postTableColumnChecklist(body) {
    return await client('POST', `/control/column`, body);
  },
  async postTableFilterDefaults(body) {
    return await client('POST', `/filter-preferences/save`, body);
  },
  async getTableFilterDefaults({ boxType, part }) {
    if (boxType && part) {
      return await client('GET', `/filter-preferences?boxType=${boxType}&part=${part}`);
    }
  },
  async getUserTgToken() {
    return await client(undefined, `/user/generate/token/telegram`);
  },
  async getAiApi(body) {
    return await client('POST', `/ai/get/data`, body);
  },
  async setGuideline() {
    return await client(undefined, `/user/guideline/${true}`);
  },
  async checkInvestObject(buyerTin) {
    return await client(undefined, 'investment/check-investor/' + buyerTin);
  },
  async getInvestObjectName(investObjectId, buyerTin, buyerAccount) {
    return await client(undefined, `/investment/object-data?objectId=${investObjectId}&buyerTin=${buyerTin}&buyerAccount=${buyerAccount}`)
  },
  async createCounterAgent(body) {
    return await client('POST', `/partners/add-partner`, body);
  },
  async editCounterAgent(id, body) {
    return await client('PUT', `/partners/edit-partner-data/${id}`, body);
  },
  async getCounterAgentById(id) {
    return await client('GET', `/partners/get/${id}`);
  },
  async getBuyerName(tin) {
    return await client(undefined, `${BASE_URL}/search-user-data?tin=${tin}`)
  }
};

export default ApiUser;
