import React from 'react';
import { SubscriptionPlans } from "Modules/payment/components";
import AmoCrmForm from "../../amoCrmForm";

import brand1 from "../../images/Gold_step_invest_logo.png";
import akfa from "../../images/akfa-logo.png";
import artel from "../../images/artel.png"
import discover from "../../images/discover-logo.png";
import ipotekabank_01 from "../../images/ipotekabank_01.png";
import grand from "../../images/grand-road-tashkent-logo.png";
import mobiuz from "../../images/mobiuz-logo.png";
import durable from "../../images/durable-logo.png";
import dashBoardImg from "../../images/NewDashboard_24m.png";
import heroBg from "../../images/black-line.png";
import icon1 from "../../images/lock_icon_24m.png";
import icon2 from "../../images/transfer_logo_24m .png";
import icon3 from "../../images/members_icon_24m.png";
import icon4 from "../../images/notification_icon_24m.png";
import third from "../../images/laptop24.png";
import tree from "../../images/tree.svg";
import user from "../../images/user.png";
import star from "../../images/star.svg";
import docsImg from "../../images/docs-img.png";
import oneCorg from "../../images/1c_logo.png";
import oracleOrg from "../../images/oracle-org.png";
import sap from "../../images/sap.png";
import soliq from "../../images/soliq.png";
import bank from "../../images/bank-pro.png";
import FileUpload from './fileUpload';

const Main = () => {
  return (
    <main className="main-content">
      <section className={'hero'}>
        <div className={'container hero__container'}>
          <h1 className={'hero__title'}>
            <span>Цифровой</span> Обмен Документами
          </h1>
          <p className={'hero__subtitle'}>Каждый документ, подписанный через систему BDM, обретает юридическую силу.</p>
        </div>

        {/* <div className="hero__img-wrapper">
          <div className="hero__img-inner">
            <img className="hero__img" src={dashBoardImg} alt="New dashboard 24m.uz-обмен документами через эдо 24m.uz"
              width="1920" height="1080" />
          </div>
        </div> */}
        <FileUpload />


        <div className="hero__bg">
          <img className="hero__bg-img" src={heroBg} alt="black line image" width="1440" height="309" />
          <svg
            className="hero__star hero__star-blue"
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M30.1195 0.75L28.3031 10.167C27.4287 14.7001 28.6837 19.3838 31.7075 22.8724L37.9891 30.1195L28.5721 28.3031C24.0389 27.4287 19.3553 28.6837 15.8667 31.7075L8.61954 37.9891L10.436 28.5721C11.3104 24.0389 10.0554 19.3553 7.0316 15.8667L0.75 8.61955L10.167 10.436C14.7002 11.3104 19.3838 10.0554 22.8724 7.03161L30.1195 0.75Z"
              fill="#115CEF"></path>
          </svg>
          <svg
            className="hero__star hero__star-small"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.6603 1.19209e-07L13.2757 1.99354C12.5784 5.60871 13.5792 9.34391 15.9907 12.1261L17.3205 13.6603L15.327 13.2757C11.7118 12.5784 7.9766 13.5792 5.19444 15.9907L3.66025 17.3205L4.04479 15.327C4.74213 11.7118 3.74129 7.9766 1.32979 5.19444L-1.19209e-07 3.66025L1.99354 4.04479C5.60871 4.74213 9.34391 3.74129 12.1261 1.32979L13.6603 1.19209e-07Z"
              fill="#115CEF"></path>
          </svg>
          <svg
            className="hero__star hero__star-gray"
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M39.8852 0.1709L37.4079 13.0142C36.2456 19.0395 37.9137 25.2648 41.9329 29.9017L50.5 39.7856L37.6567 37.3083C31.6314 36.146 25.4061 37.8141 20.7692 41.8333L10.8852 50.4004L13.3626 37.5571C14.5248 31.5318 12.8568 25.3065 8.83761 20.6696L0.270506 10.7856L13.1138 13.263C19.1391 14.4252 25.3644 12.7572 30.0013 8.738L39.8852 0.1709Z"
              fill="#E2E2EA"></path>
          </svg>
        </div>


        <div className={'swiper-cont'}>
          <div className={'swiper'}>
            <div className={'swiper-wrapper'}>
              <div className={'swiper-slide'}>
                <img className={'hero__brands-img'} src={brand1} alt="Gold step invest logo for 24m.uz" width="808"
                  height="330" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={ipotekabank_01} alt="ipoteka partner with 24m.uz" width="808" height="330" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={akfa} alt="akfa logo for 24m.uz" width="2684" height="808" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={discover} alt="Discover invest partner with 24m.uz" width="808"
                  height="330" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={artel} alt="Discover invest partner with 24m.uz" width="808"
                  height="330" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={grand} alt="Grand Road Tashkent partner with 24m.uz" width="808"
                  height="330" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={mobiuz} alt="Mobiuz partner with 24m.uz" width="808"
                  height="330" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={durable} alt="Durable Beton partner with 24m.uz" width="808"
                  height="330" />
              </div>

              <div className={'swiper-slide'}>
                <img className={'hero__brands-img'} src={brand1} alt="Gold itep invest logo for 24m.uz" width="808"
                  height="330" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={ipotekabank_01} alt="ipoteka partner with 24m.uz" width="808" height="330" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={akfa} alt="akfa logo for 24m.uz" width="2684" height="808" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={discover} alt="Discover invest partner with 24m.uz" width="808"
                  height="330" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={artel} alt="Discover invest partner with 24m.uz" width="808"
                  height="330" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={grand} alt="Grand Road Tashkent partner with 24m.uz" width="808"
                  height="330" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={mobiuz} alt="Mobiuz partner with 24m.uz" width="808"
                  height="330" />
              </div>
              <div className="swiper-slide">
                <img className="hero__brands-img" src={durable} alt="Durable Beton partner with 24m.uz" width="808"
                  height="330" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={'second'} id={'secondSection'}>
        <div className={'container second__container'}>
          <h2 className={'second__title'}>
            Управление корпоративным <span>цифровым документооборотом</span> и финансами на едином удобном портале.
          </h2>
          <a
            className={'cta-btn second__cta-btn'}
            href={process.env.NODE_ENV === 'development' ? 'http://localhost:3000/login' : 'https://24m.uz/login'}
            data-header-link={'form-section'}>
            Переход на систему
          </a>
        </div>
      </section>
      <section className={'third'} id={'thirdSection'}>
        <div className={'container'}>
          <h2 className={'third__title'}>
            <span>BDM</span> дает
          </h2>
          <ul className={'third__list'}>
            <li className={'third__item'}>
              <div className={'third__item-img-wrapper'}>
                <img src={icon1} alt="обмен документами через эдо 24m.uz" />
              </div>
              <span>Безопасное сохранение данных</span>
            </li>
            <li className="third__item">
              <div className={'third__item-img-wrapper'}>
                <img src={icon2} alt="обмен документами через эдо 24m.uz" />
              </div>
              <span>Сортировка документов</span>
            </li>
            <li className="third__item">
              <div className={'third__item-img-wrapper'}>
                <img src={icon3} alt="обмен документами через эдо 24m.uz" />
              </div>
              <span>Разный уровень доступа</span>
            </li>
            <li className="third__item">
              <div className={'third__item-img-wrapper'}>
                <img src={icon4} alt="обмен документами через эдо 24m.uz" />
              </div>
              <span>Уведомление о статусе документа</span>
            </li>
          </ul>
        </div>
        <img className="third__img" src={third} alt="view 24m.uz with laptop" width="1920" height="1080" />
      </section>
      <section className={'fourth'} id={'fourthSection'}>
        <div className={'container fourth__container'}>
          <h2 className={'fourth__title'}>
            Мы в <span>цифрах</span>
          </h2>
        </div>
      </section>
      <section className={'fifth'} id={'fifthSection'}>
        <div className={'container fifth__container'}>
          <ul className={'fifth__list'}>
            <li className={'fifth__item'}>
              <div className={'fifth__card'}>
                <div className={'fifth__card-img-wrapper'}>
                  <span id={'counter-1'}>135</span>
                  <img className={'fifth__card-img'} src={tree} alt="Tree 24m.uz" width="54" height="54" />
                </div>
                <h4 className={'fifth__card-title'}>Все в одном месте</h4>
                <p className={'fifth__card-text'}>
                  <span>Деревьев</span> удалось сохранить, благодаря программе Business Documents Managment
                </p>
              </div>
            </li>
            <li className={'fifth__item'}>
              <div className={'fifth__card'}>
                <div className={'fifth__card-img-wrapper'}>
                  <span id={'counter-1'}>{'2 941'}</span>
                  <img className={'fifth__card-img'} src={user} alt="Bdm 24m.uz user icon" width="123" height="129" />
                </div>
                <h4 className={'fifth__card-title'}>Электронная подпись</h4>
                <p className={'fifth__card-text'}>
                  <span>Клиентов</span> экономят средства и время, пользуясь программой
                </p>
              </div>
            </li>
            <li className={'fifth__item'}>
              <div className={'fifth__card'}>
                <div className={'fifth__card-img-wrapper'}>
                  <span id={'counter-3'}>155</span>
                  <img className={'fifth__card-img'} src={star} alt="Star Yulduz звезда" width={'54'} height={'54'} />
                </div>
                <h4 className="fifth__card-title">Отзывы</h4>
                <p className="fifth__card-text">
                  Положительных <span>отзывов</span> о программе в соц сетях и на сайте
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section className={'sixth'} id={'sixthSection'}>
        <div className={'container sixth__container'}>
          <div className={'sixth__content'}>
            <h2 className={'sixth__title'}>
              С какими <span>документами</span> можно работать
            </h2>
            <ul className={'sixth__list'}>
              <li className={'sixth__item'}>Договор</li>
              <li className={'sixth__item'}>Доверенность</li>
              <li className={'sixth__item'}>Счет-фактура</li>
              <li className={'sixth__item'}>Акт выполненных работ</li>
              <li className={'sixth__item'}>Трехсторонний договор</li>
              <li className={'sixth__item'}>Свободный документ</li>
              <li className={'sixth__item'}>Заявки</li>
              <li className={'sixth__item'}>Спецификация</li>
              <li className={'sixth__item'}>Письмо</li>
              <li className={'sixth__item'}>+30 типов документы</li>

            </ul>
          </div>
        </div>
        <img className={'sixth__img'} src={docsImg} alt="document bdm 24" width="484" height="283" />
      </section>
      <section className={'seventh'} id={'seventhSextion'}>
        <div className={'container seventh__container'}>
          <h2 className={'seventh__title'}>
            <span>Интеграция</span>
          </h2>
          <ul className={'seventh__list'}>
            <li className={'seventh__item'}>
              <img src={sap} alt={'Sap with 24m.uz'} />
            </li>
            <li className={'seventh__item'}>
              <img src={oneCorg} alt="1c logo for 24m.uz" />
            </li>
            {/*<li className={'seventh__item'}>*/}
            {/*  <img src={oracleOrg} alt={'Integration Oracle with 24m.uz'}/>*/}
            {/*</li>*/}
            <li className={'seventh__item'}>
              <img src={soliq} alt={'soliq 24m.uz'} />
            </li>
            <li className={'seventh__item'}>
              <img src={bank} alt="bank logo for 24m.uz" />
              <span>14 Банки</span>
            </li>
          </ul>
        </div>
      </section>

      <section className={'pricing'} id={'pricingSection'} style={{ paddingBottom: '0px' }}>
        <div className={'container'}>
          <div className={'pricing__top'}>
            <h2 className={'pricing__title'}>
              Наши <span>Тарифы</span>
            </h2>
            <p className={'pricing__subtitle'}>Отправляйте документы сразу из вашей биллинговой системы - 1С,
              SAP</p>
          </div>

          <SubscriptionPlans isLanding={true} />
        </div>
      </section>

      {/* <section className={'form-section'} id={'form-section'}>
        <div className={'container form-section__container'}>
          <h2 className={'form-section__title'} style={{ fontSize: '52px' }}>
            Давайте
            <br /> знакомиться<span>?</span>
          </h2>
          <form className={'form-section__form'} name={'siteEmailForm'}>
            <div className={'form-section__inputs-wrapper'}>
              <label className={'form-section__label'}>
                <span className={'form-section__label-title'}>ФИО</span>
                <input className={'form-section__input'} name={'name'} type={'text'} placeholder={'Ваше ФИО'} />
                <span className={'error-span'}>Это поле обязательно к заполнению!</span>
              </label>
              <label className={'form-section__label'}>
                <span className={'form-section__label-title'}>Компания</span>
                <input className={'form-section__input'} name={'company'} type={'text'} placeholder={'Ваша компания'} />
                <span className={'error-span'}>Это поле обязательно к заполнению!</span>
              </label>
            </div>
            <div className={'form-section__inputs-wrapper'}>
              <label className={'form-section__label'}>
                <span className={'form-section__label-title'}>Рабочий Email</span>
                <input className={'form-section__input'} name={'email'} type={'text'} placeholder={'Ваш рабочий email'} />
                <span className={'error-span'}>Введите действующий адрес электронной почты!</span>
              </label>
              <label className={'form-section__label'}>
                <span className={'form-section__label-title'}>Номер телефона</span>
                <input className={'form-section__input'} name={'phone'} type={'number'} placeholder={'+998(94)123-45-67'} />
                <span className={'error-span'}>Это поле обязательно к заполнению!</span>
              </label>
            </div>
            <label className={'form-section__checkbox-wrapper'}>
              <input type={'checkbox'} name={'agreement'} />
              <span className={'error-span'}>Это поле обязательно к заполнению!</span>
              <span>
                Я согласен получать новости и рекламные рассылки об акциях и продукции BDM на условиях
                <a href={'#'}>Политики конфиденциальности</a>
              </span>
            </label>
            <p className={'form-section__text'}>
              Нажимая кнопку "Узнать подробнее" я принимаю условия{' '}
              <a href={'/privacy_policy.pdf'} target={'_blank'}>
                Соглашения об информационном взаимодействии
              </a>{' '}
              и выражаю согласие на обработку персональных данных на условиях этого соглашения
            </p>
            <button name={'submitBtn'} className="cta-btn form-section__submit-btn">
              <span>Узнать подробнее</span>
              <div className={'lds-spinner'} data-submitBtnLoader>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </button>
          </form>
          <AmoCrmForm />
        </div>
      </section> */}
    </main>
  );
};

export default Main;