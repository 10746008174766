import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import customDate from 'Services/time';
import { formatTime } from 'Services/time/formatTime';

// props type
import { TypeLogin } from './propsType';

// style
import {FormItem, StyledOption, StyledSelect, Text, WrapperDiv} from "./select.style";
import {ArrowSvg} from "Assets/svg";


const normalizerData = (data = []) => {
  let result = [];

  for (let i = 0; i < data.length; i++) {
    if (Object.keys(data[i]).includes('disk')) {
      let from = formatTime(data[i].validFrom, 'DD.MM.YYYY');
      let to = formatTime(data[i].validTo, 'DD.MM.YYYY');
      result.push({
        id: i,
        fullname: `${data[i].CN}`,
        tin: `${data[i].TIN}`,
        company: `${data[i].O}`,
        time: `${from} - ${to}`,
        seria: `${data[i].serialNumber}`,
        isDisabled: customDate.compareWithCurrentDate(to)
      });
    }
  }
  return result;
};


const MySelect = ({ data = [], handleChange, name = '', style = {}, ...rest }) => {
  const { t } = useTranslation(['login']);
  const normalizedData = useMemo(() => normalizerData(data), [data]);

  return (
    <FormItem name={name} label={rest.hasOwnProperty('label') && rest['label']}>
      <StyledSelect
        suffixIcon={<ArrowSvg />}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>option.value.toLowerCase().includes(input.toLowerCase())}
        placeholder={t('placeholderText')}
        onChange={(value) => handleChange(value, name)}
        notFoundContent={(<div style={{display:'flex', justifyContent:'center'}}>{t('payment.paymentHistorySection.noData')}</div>)}
      >
        {normalizedData.map((item, id) => {
          return (
            <StyledOption key={id} value={`${item.tin} ${item.seria} ${id} ${item.fullname} ${item.company}`} disabled={item.isDisabled}>
              <WrapperDiv>
                <div>
                  {(item.tin.length === 9) ?
                    <>
                      {(item.company.length > 29) ? <Text sm title={item.company}>{`${item.company.substring(0, 29)}...`}</Text> : <Text sm title={item.company}>{item.company}</Text>}
                      <Text esm color={'#5F6C94'} title={item.tin}>{`ИНН:  ${item.tin}`}</Text>
                    </> :
                    <>
                      {(item.fullname.length > 29) ? <Text sm title={item.fullname}>{`${item.fullname.substring(0, 29)}...`}</Text> : <Text sm title={item.fullname}>{item.fullname}</Text>}
                      <Text esm color={'#5F6C94'} title={item.tin}>{`ПИНФЛ:  ${item.tin}`}</Text>
                    </>
                  }
                </div>
                <Text className="date" esm withBorder>{`Срок: ${item.time.substring(12)}`}</Text>
              </WrapperDiv>

              {item.isDisabled && (
                <div>
                  <b style={{ color: 'red' }}>
                    {get(t('warning')?.split('-'), '[0]', '')} {item.time} {get(t('warning')?.split('-'), '[1]')}
                  </b>
                </div>
              )}
            </StyledOption>
          );
        })}
      </StyledSelect>
    </FormItem>
  );
};

MySelect.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(TypeLogin)),
  handleChange: PropTypes.func,
  name: PropTypes.string,
  style: PropTypes.object,
  rest: PropTypes.object
};

MySelect.whyDidYouRender = true;

export default memo(MySelect);
