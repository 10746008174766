import React, { lazy } from 'react';

const Forum = lazy(() => import('Pages/Forum'));
const Admin = lazy(() => import('Pages/Admin'));
const Parameters = lazy(() => import('Pages/Parameters'));
const Agreements = lazy(() => import('Pages/_Agreements_section'));
const Contracts = lazy(() => import('Pages/_Agreements_section/Contracts'));
const CounterAgents = lazy(() => import('Pages/_Agreements_section/CounterAgents'));
const CreateDocument = lazy(() => import('Pages/CreateDocument'));
const Inbox = lazy(() => import('Pages/Inbox'));
const Outbox = lazy(() => import('Pages/OutBox'));
const Home = lazy(() => import('Pages/Home/home'));
const Payment = lazy(() => import('Pages/Payment/payment'));
const Reports = lazy(() => import('Pages/Reports'));
const Settings = lazy(() => import('Pages/SettingsPage'));
const Di = lazy(() => import('Pages/Di'));
const InvoiceProduct = lazy(() => import('Pages/InvoiceProduct'));
const Test = lazy(() => import('Pages/Test/test'));
const NotFound = lazy(() => import('Pages/NotFoundDashboardPage'));
const BlackList = lazy(() => import('Pages/BlackList'));
const SmsPermissions = lazy(() => import('Pages/SmsPermissions'));
const SmsList = lazy(() => import('Pages/SmsList'));
const Attendance = lazy(() => import('Pages/Attendance'));
const DocumentClassification = lazy(() => import('Pages/DocumentClassification'));
const LogErrors = lazy(() => import('Pages/LogErrors'));
const AddDocumentToMashrut = lazy(() => import('Pages/AddDocumentToMashrut'));
const CheckCounterparty = lazy(() => import('Pages/CheckCounterparty'));
const AdminEditFiles = lazy(() => import('Pages/AdminEditFiles'));
const UpdateSMSPhoneNumber = lazy(() => import('Pages/AdminUpdateSMSPhoneNumber/UpdateSMSPhoneNumber'));
const AdminLogList = lazy(() => import('Pages/AdminLogList/main'));
const Didox = lazy(() => import('../../Modules/settings/components/didox'));
const HelpfulInfo = lazy(() => import('Pages/CatalogPages'));
const WithoutVatCatalogPages = lazy(() => import('Pages/CatalogPages/components/WithoutVatCatalogPages'));
const ZeroPercentVat = lazy(() => import('Pages/CatalogPages/components/ZeroPercentVat'));
const WithoutVatCompany = lazy(() => import('Pages/CatalogPages/components/WithoutVatCompany'));
const ZeroVatCompany = lazy(() => import('Pages/CatalogPages/components/ZeroVatCompany'));
const AccountProduct1 = lazy(() => import('Pages/CatalogPages/components/AccountProduct1'));
const AccountProduct2 = lazy(() => import('Pages/CatalogPages/components/AccountProduct2'));
const ListTaxOfEmployees = lazy(() => import('Pages/CatalogPages/components/ListTaxOfEmployees'));
const SyncFromTax = lazy(() => import('Pages/SyncFromTax'));
const BdmDocInfo = lazy(() => import('Pages/BdmDocInfo'));
const Finance = lazy(() => import('Pages/Finance'));
const JournalBank = lazy(() => import('Pages/Finance/Components/Journal/Bank'));
const JournalKassa = lazy(() => import('Pages/Finance/Components/Journal/Kassa'));
const JournalPlasticCard = lazy(() => import('Pages/Finance/Components/Journal/PlasticCard'));
const UploadByExcel = lazy(() => import('Pages/UploadByExcel'))

export const routes = [
  //Admin section
  // {
  //   path: '/dashboard/admin/actionsArchive',
  //   component: Admin,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/admin/allUsersActivity',
  //   component: Admin,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/admin/editFile',
  //   component: Admin,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/admin/fillUserBalance',
  //   component: Admin,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/admin/userDocuments',
  //   component: Admin,
  //   exact: true
  // },
  //Admin section

  //Agreements section
  // {
  //   path: '/dashboard/agreements/contracts',
  //   component: Agreements,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/agreements/counterparties',
  //   component: Agreements,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/agreements/employmentContracts',
  //   component: Agreements,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/agreements/myTrades',
  //   component: Agreements,
  //   exact: true
  // },
  //Agreements section

  //Forum section
  // {
  //   path: '/dashboard/forum',
  //   component: Forum,
  //   exact: true
  // },
  //Forum section

  //Parameters section
  // {
  //   path: '/dashboard/parameters/bankAccounts',
  //   component: Parameters,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/parameters/branches',
  //   component: Parameters,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/parameters/constructionObject',
  //   component: Parameters,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/parameters/employees',
  //   component: Parameters,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/parameters/lgota',
  //   component: Parameters,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/parameters/positions',
  //   component: Parameters,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/parameters/products',
  //   component: Parameters,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/parameters/subdivisions',
  //   component: Parameters,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/parameters/templates',
  //   component: Parameters,
  //   exact: true
  // },
  //Parameters section

  //Settings section
  // {
  //   path: '/dashboard/user/settings',
  //   component: Settings,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/user/settings/business_process',
  //   component: Settings,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/user/settings/currencies',
  //   component: Settings,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/user/settings/documents',
  //   component: Settings,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/user/settings/integrations',
  //   component: Settings,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/user/settings/measurements',
  //   component: Settings,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/user/settings/notifications',
  //   component: Settings,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/user/settings/personal_data',
  //   component: Settings,
  //   exact: true
  // },
  // {
  //   path: '/dashboard/user/settings/roles_powers',
  //   component: Settings,
  //   exact: true
  // },
  //Settings section

  //UsefulInfo section
  {
    path: '/dashboard/helpful-information',
    component: HelpfulInfo,
    exact: true
  },
  {
    path: '/dashboard/useful-info/getInfoAboutDoc',
    component: HelpfulInfo,
    exact: true
  },
  {
    path: '/dashboard/useful-info/orgWithoutVAT',
    component: HelpfulInfo,
    exact: true
  },
  {
    path: '/dashboard/useful-info/productsWithoutOffset',
    component: HelpfulInfo,
    exact: true
  },
  {
    path: '/dashboard/useful-info/productsWithoutVAT',
    component: HelpfulInfo,
    exact: true
  },
  {
    path: '/dashboard/useful-info/reimbursementServices',
    component: HelpfulInfo,
    exact: true
  },
  {
    path: '/dashboard/useful-info/taxOfficials',
    component: HelpfulInfo,
    exact: true
  },
  {
    path: '/dashboard/useful-info/zeroVatRateOrgs',
    component: HelpfulInfo,
    exact: true
  },
  {
    path: '/dashboard/useful-info/zeroVatRateServices',
    component: HelpfulInfo,
    exact: true
  },
  //UsefulInfo section

  {
    path: '/dashboard/create',
    component: CreateDocument,
    exact: true
  },
  {
    path: '/dashboard/inbox/:state',
    component: Inbox,
    exact: true
  },
  {
    path: '/dashboard/outbox/:state',
    component: Outbox,
    exact: true
  },
  {
    path: '/dashboard/home',
    component: Home,
    exact: true
  },
  {
    path: '/dashboard/payment',
    component: Payment,
    exact: true
  },
  {
    path: '/dashboard/user/settings',
    component: Settings,
    exact: true
  },
  {
    path: '/dashboard/reports',
    component: Reports,
    exact: true
  },
  {
    path: '/dashboard/invoiceProducts',
    component: InvoiceProduct,
    exact: true
  },
  {
    path: '/dashboard/di',
    component: Di,
    exact: true
  },
  {
    path: '/dashboard/blackList',
    component: BlackList,
    exact: true
  },
  {
    path: '/dashboard/smsPermissions',
    component: SmsPermissions,
    exact: true
  },
  {
    path: '/dashboard/smsList',
    component: SmsList,
    exact: true
  },
  {
    path: '/dashboard/attendance',
    component: Attendance,
    exact: true
  },
  {
    path: '/dashboard/documentClassification',
    component: DocumentClassification,
    exact: true
  },
  {
    path: '/dashboard/logErrors',
    component: LogErrors,
    exact: true
  },
  {
    path: '/dashboard/addDocumentToMashrut',
    component: AddDocumentToMashrut,
    exact: true
  },
  {
    path: '/dashboard/checkCounterparty',
    component: CheckCounterparty,
    exact: true
  },
  {
    path: '/dashboard/test',
    component: Test,
    exact: true
  },
  {
    path: '/dashboard/AdminEditFiles',
    component: AdminEditFiles,
    exact: true
  },
  {
    path: '/dashboard/UpdateSMSPhoneNumber',
    component: UpdateSMSPhoneNumber,
    exact: true
  },
  {
    path: '/dashboard/adminLogList',
    component: AdminLogList,
    exact: true
  },
  {
    path: '/dashboard/synchronizeFromDidox',
    component: Didox,
    exact: true
  },
  {
    path: '/dashboard/catalog-without-vat',
    component: WithoutVatCatalogPages,
    exact: true
  },
  {
    path: '/dashboard/catalog-zero-percent-vat',
    component: ZeroPercentVat,
    exact: true
  },
  {
    path: '/dashboard/catalog-without-vat-company',
    component: WithoutVatCompany,
    exact: true
  },
  {
    path: '/dashboard/catalog-zero-vat-company',
    component: ZeroVatCompany,
    exact: true
  },
  {
    path: '/dashboard/catalog-account-product-1',
    component: AccountProduct1,
    exact: true
  },
  {
    path: '/dashboard/catalog-account-product-2',
    component: AccountProduct2,
    exact: true
  },
  {
    path: '/dashboard/catalog-list-tax-of-employees',
    component: ListTaxOfEmployees,
    exact: true
  },
  {
    path: '/dashboard/synchronize-from-tax',
    component: SyncFromTax,
    exact: true
  },
  {
    path: '/dashboard/bdmDocInfo',
    component: BdmDocInfo,
    exact: true
  },
  {
    path: '/dashboard/finance',
    component: Finance,
    exact: true
  },
  {
    path: '/dashboard/agreements',
    component: Agreements,
    exact: true
  },
  {
    path: '/dashboard/upload-by-excel',
    component: UploadByExcel,
    exact: true
  }
  /* {
    path: '/dashboard/journal/bank',
    component: JournalBank,
    exact: true
  },
  {
    path: '/dashboard/journal/kassa',
    component: JournalKassa,
    exact: true
  },
  {
    path: '/dashboard/journal/plastic-card',
    component: JournalPlasticCard,
    exact: true
  } */
];
