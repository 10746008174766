import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import LanguageSelector from 'Components/molecules/languageSelector/languageSelector';
import Hints from 'Components/molecules/hints';

// style
import { NavbarContainer, Data, SectStyle, LangDiv, CopySpan } from './navbar.style';
import ProfileMenu from '../profileMenu/ProfileMenu';
import { Select } from 'antd';
import useFunc from './navFunc';
import { getShortenedMchj } from 'Utils/toMchj';
import SupportPopup from './SupportPopup';
import MoneyPopup from './MoneyPopup';
import Switch from 'Components/atoms/switch/index';
import Tooltip from 'Components/atoms/tooltip';
import ApiDocument from 'Services/api/controller/document';
import { CopyClipboardFill, SuccessfullWhite } from 'Assets/icons/common/copyClipboard';
import CopyToClipboard from 'react-copy-to-clipboard';
import useOutsideClick from 'Hooks/useClickOutside';
import notification from 'Components/atoms/toast';


const Navbar = (props) => {
  const [open, setOpen] = useState(false)
  const [hasCopied, setHasCopied] = useState({ employer: false, employee: false })
  const { t } = useTranslation(['layout']);
  const dispatch = useDispatch();
  const { Option } = Select;
  const [company, currComp, onSubmit] = useFunc();
  const zoomValue = useSelector((state) => state.ui.zoom);
  const user = useSelector((state) => state?.accountData?.user);
  const isNewUi = useSelector((state) => state.ui.isNewUi)
  const activeComp = company?.filter((item) => item?.employerTin === currComp)?.[0] || {};
  let location = useLocation();

  const ref = useOutsideClick(() => {
    setOpen(false);
  });

  useEffect(() => {
    document.body.style.zoom = zoomValue / 100;
  }, [zoomValue]);

  function getBranch(comp) {
    return comp.branchName && `(${comp.branchName})`;
  }

  const handleCopy = (label = 'employer') => {
    if (label === 'employee') {
      setHasCopied(prev => ({ ...prev, employee: true }))
    } else {
      setHasCopied(prev => ({ ...prev, employer: true }))
    }
    notification.setType('success').setMessage(t('navbar.copied') || 'copied').alert();

    setTimeout(() => {
      setHasCopied({ employer: false, employee: false })
    }, 1300)
  }

  function _renderOption(comp) {
    return (
      <Option key={Math.random} value={comp.employerTin}>
        <Data isNewUi={isNewUi}>
          <span>{getShortenedMchj(comp.employerName)}</span>
          <span>{comp.employerTin}</span>
          {comp.employerTin !== comp.employeeTin && (
            <>
              <span>
                {getShortenedMchj(comp.employeeName)} {getBranch(comp)}{' '}
              </span>
              <span>{comp.employeeTin}</span>
            </>
          )}
        </Data>
      </Option>
    );
  }

  function _renderValue(comp) {
    if (comp)
      return (
        <Data isValue onClick={() => setOpen(!open)} ref={ref}>
          <span>{getShortenedMchj(comp.employerName)}</span>
          <CopySpan>
            {comp.employerTin}
            {comp.employerTin &&
              <span onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
                handleCopy('employer')
              }
              }>
                <CopyToClipboard text={comp.employerTin} >
                  {hasCopied?.employer ?
                    <SuccessfullWhite /> :
                    <CopyClipboardFill />
                  }
                </CopyToClipboard>
              </span>
            }

          </CopySpan>
          {comp.employerTin !== comp.employeeTin && (
            <>
              <span>{getShortenedMchj(comp.employeeName)} {getBranch(comp)} </span>
              <CopySpan>
                {comp.employeeTin}
                <span
                  onClick={(e) => {
                    e.stopPropagation()
                    setOpen(false)
                    handleCopy('employee')
                  }}>
                  <CopyToClipboard text={comp.employeeTin}>
                    {hasCopied?.employee ? <SuccessfullWhite /> : <CopyClipboardFill />}
                  </CopyToClipboard>
                </span>
              </CopySpan>
            </>
          )}
        </Data>
      );
  }

  useEffect(() => {
    if (user?.isCard === 1) {
      dispatch({ type: 'IS_NEW_UI', payload: false })
    } else {
      dispatch({ type: 'IS_NEW_UI', payload: true })
    }
  }, [user])

  const uiSwitcher = () => {

    ApiDocument.switchUi().then((res) => {
      if (res?.data?.data?.isCard === 0) {
        dispatch({ type: 'IS_NEW_UI', payload: true })
      } else if (res?.data?.data?.isCard === 1) {
        dispatch({ type: 'IS_NEW_UI', payload: false })
      } else {
        setTimeout(() => dispatch({ type: 'IS_NEW_UI', payload: !isNewUi }), 100)
      }
    })
  }

  return (
    <Fragment>
      <NavbarContainer>
        <SectStyle>
          <Select
            open={open}
            className={'branch-hint'}
            notFoundContent={false}
            value={{ label: _renderValue(activeComp), value: activeComp }}
            showArrow={!(company.length <= 1)}
            onSelect={onSubmit}
            dropdownStyle={{ borderRadius: isNewUi ? '8px' : '0px', top: '20px' }}
            dropdownClassName={isNewUi ? 'white-dropdown' : 'dark-dropdown'}>
            {company
              ?.filter((item) => item.employerTin !== currComp)
              .map((comp) => {
                return _renderOption(comp);
              })}
          </Select>
        </SectStyle>
        <SupportPopup />
        {/* <MoneyPopup /> */}
        {location.pathname === "/dashboard/home" && <Hints />}
        <ProfileMenu />
        <LanguageSelector />
        <Tooltip isNewUi={isNewUi} title={isNewUi ? t('navbar.switchToOldTooltip') : t('navbar.switchToNewTooltip')} placement={'bottomRight'}>
          <Switch className='isNewUi-hint' withAnimation={true} checked={isNewUi} withWhiteBorder onChange={uiSwitcher} style={{ margin: '20px' }} />
        </Tooltip>
      </NavbarContainer>
    </Fragment>
  );
};

export { Navbar };
