import * as con from 'Store/constant';
import client from 'Services/api/client';
import * as url from 'Services/api/endPoints';
const getUrl = (id, boxType) => {
  switch (id) {
    case 0:
      return url.DOCUMENT_MENU_ITEMS;
    case 1:
    case 2:
      return `${url.BOX_MENU_ITEMS}${id}`;
    case 4:
      return `${url.REPORTS_MENU_ITEMS}?boxtype=${boxType}`;
    default:
      return '';
  }
};

export const onFetchMenuItems = (id, boxType) => {
  return async (dispatch) => {
    dispatch({ type: con.FETCH_MENU_ITEMS });

    const { data, fail } = await client(undefined, getUrl(id, boxType));

    if (!fail) {
      dispatch({ type: id !== 4 ? con.FETCH_MENU_ITEMS_SUCCESS : con.FETCH_REPORTS_MODULE_ITEMS_SUCCESS, payload: data });
    } else {
      dispatch({ type: con.FETCH_MENU_ITEMS_FAIL });
    }

    // setTimeout(() => {
    //   dispatch({ type: con.MENU_SET_INITIAL_STATUS });
    // }, 10);
  };
};
