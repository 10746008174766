import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import * as _ from '../../container/auth.style';
const ApprovePage = ({ register, getSMSagain, isEmail, errors }) => {
  const [seconds, setSeconds] = useState(60);
  const [passwordEye, setPasswordEye] = useState(true);
  const eye = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
        fill="rgba(167,167,167,1)"
      />
    </svg>
  );
  const openPassword = () => {
    setPasswordEye((prev) => !prev);
  };
  const handleClick = () => {
    getSMSagain();
    setSeconds(60);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    if (seconds < 1) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [seconds]);

  return (
    <div id="approve_page">
      <_.Text esmw color={isEmail ? '#ff0000' : '#000017'}>
        {isEmail ? 'На указанную почту отправлен код подтверждения' : 'На указанный номер отправлен код подтверждения'}
      </_.Text>
      <_.FormItem>
        <_.StyledInput
          type={'number'}
          name="code"
          ref={register({ required: true })}
          placeholder={'Введите код подтверждения'}
          style={{ marginTop: '10px' }}
        />
        {errors.code && <_.StyledErrorText>Это поле не может быть пустым!</_.StyledErrorText>}
      </_.FormItem>
      <Row>
        <Col span={12}>
          {/*<_.Text esmw color={'#000017'}>*/}
          {/*  Новый пароль*/}
          {/*</_.Text>*/}
          <_.FormItem style={{ position: 'relative' }}>
            <_.StyledInput
              name="newPassword"
              ref={register({ required: true })}
              placeholder={'Новый пароль'}
              style={{ marginTop: '10px' }}
              type={passwordEye ? 'password' : 'text'}
            />
            {errors.newPassword && <_.StyledErrorText>Это поле не может быть пустым!</_.StyledErrorText>}
            <span onClick={openPassword} style={{ position: 'absolute', zIndex: 10, top: '26px', right: '24px' }}>
              {eye()}
            </span>
          </_.FormItem>
        </Col>
        <Col span={11} offset={1}>
          {/*<_.Text esmw color={'#000017'}>*/}
          {/*  Подтвердите новый пароль*/}
          {/*</_.Text>*/}
          <_.FormItem style={{ position: 'relative' }}>
            <_.StyledInput
              name="repeatPassword"
              ref={register({ required: true })}
              placeholder={'Подтвердите новый пароль'}
              style={{ marginTop: '10px' }}
              type={passwordEye ? 'password' : 'text'}
            />
            {errors.repeatPassword && <_.StyledErrorText>Это поле не может быть пустым!</_.StyledErrorText>}
            <span onClick={openPassword} style={{ position: 'absolute', zIndex: 10, top: '26px', right: '24px' }}>
              {eye()}
            </span>
          </_.FormItem>
        </Col>
      </Row>

      <_.AgainButton onClick={handleClick} disabled={seconds === 0 ? false : true}>
        {'Отправить ещё раз?'} {`(${seconds})`}
      </_.AgainButton>
      <_.FormItem>
        <_.StyledButton htmlType="submit">{'Подтвердить'}</_.StyledButton>
      </_.FormItem>
    </div>
  );
};

export default ApprovePage;
