import React from 'react';
import StyledModal from 'Components/atoms/modal';
import logout from 'Utils/logout';
import { useTranslation } from 'react-i18next';
import Button from '../button';
import { Modal } from 'antd';

const TITLE = {
  ru: 'Сессия E-IMZO завершилась, пожалуйста, войдите снова, чтобы продолжить.',
  latin: 'E-IMZO sessiyasi yakunlandi, davom ettirish uchun qayta login qiling.',
  cyrillic: 'E-IMZO сессияси якунланди, давом эттириш учун қайта логин қилинг'
};
const BTN_TEXT = {
  ru: 'Выйти',
  latin: 'Chiqish',
  cyrillic: 'Чиқиш'
};

let isCalledLogoutModal = false;
let timeOutLogoutModal = 0;

export function showEimzoSessionEndModal() {
  if (isCalledLogoutModal && !(Date.now() - timeOutLogoutModal > 20000)) return;

  isCalledLogoutModal = true;
  timeOutLogoutModal = Date.now();

  const lng = localStorage.getItem('i18nextLng') || 'ru';
  Modal.warning({
    bodyStyle: { display: 'flex', alignItems: 'center' },
    title: <h3>{TITLE[lng]}</h3>,
    okText: BTN_TEXT[lng],
    onOk: logout
  });
}

// NOTE - ANTD notification modalini ishlatdim, logika qoshilib ketadigan bo'lsa modalga olib o'tamiz
const EimzoSessionEndModal = () => {
  const { t } = useTranslation(['errorMsg', 'layout']);
  const isUnauthorizedModalVisible = 0;

  const Actions = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Button onClick={() => logout()}>{t('layout:profileMenu.quit')}</Button>
      </div>
    );
  };

  return (
    <StyledModal
      visible={isUnauthorizedModalVisible}
      footer={null}
      title={null}
      style={{ padding: 0, transition: 'transform 0.08s ease' }}
      closeIcon={<div />}
      zIndex={1020}
      onClose={() => {}}>
      <h2 align="center">{t('errorMsg:eimzoSessionEnd')}</h2>
      <Actions />
    </StyledModal>
  );
};

export default EimzoSessionEndModal;
