import React, { memo } from 'react';
import { useSelector } from 'react-redux';


import { ButtonComponent } from './button';

const Btn = (props) => {
  const isNewUi = useSelector((state) => state?.ui?.isNewUi)
  return <ButtonComponent {...props} isNewUi={isNewUi} />;
};

export default memo(Btn);
