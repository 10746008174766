import React, { memo, Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
// ANCHOR redux selectors
import { selectActiveSideBar } from 'Components/molecules/menu/menuReducer';
// style
import { StyledAside, StyledSideBarBtn, StyledSideBarBox, StyledLogoutBtn } from './style';
import CommonIcon from 'Assets/icons/common';
import { useTranslation } from 'react-i18next';
import logout from 'Utils/logout';
import { Modal } from 'antd';

const NewSideBarComponent = (props) => {
  const { parentLinks, onToggle, isOpen } = props;
  const { t } = useTranslation(['layout', 'modalMessages', 'translation']);

  const isSearch = useSelector((state) => state.outDocuments.isSearching);

  const pending = useSelector((state) => state.outDocuments.pending);

  const isNewUi = useSelector((state) => state.ui.isNewUi)

  const isDisable = useMemo(() => isSearch || pending, [isSearch, pending]);

  const active = useSelector(selectActiveSideBar);

  const onLogout = () => {
    Modal.confirm({
      width: 500,
      content: <h3 style={{ fontWeight: 500, color: '#222', fontSize: 18, lineHeight: 1.5 }}>
        {t('modalMessages:sureLogout')}
      </h3>,
      onOk: () => logout(),
      okText: t('translation:yes'),
      cancelText: t('translation:no'),
      cancelButtonProps: {
        type: 'danger',
        style: {
          width: '80px',
          height: '36px'
        }
      },
      okButtonProps: {
        type: 'primary',
        style: {
          width: '80px',
          height: '36px'
        }
      }
    })
  };

  const _renderSideBarBox = (children, text, id) => (
    <StyledSideBarBox isNewUi={isNewUi} onClick={isDisable ? () => { } : () => props.onClick(id)} isActive={active === id} isDisable={isDisable}>
      <div>{children}</div>
      <div>{text}</div>
    </StyledSideBarBox>
  );

  const onMouseEnterAside = () => {
    if ((window?.location?.pathname === "/dashboard/create" ||
      (window?.location?.pathname === "/dashboard/reports" && window?.location?.search === "?tab=1") ||
      window?.location?.pathname === '/dashboard/agreements' ||
      window?.location?.pathname === '/dashboard/agreements/counter-agents' ||
      window?.location?.pathname === '/dashboard/finance')) {
      onToggle(true)
    }
  }

  return (
    <StyledAside onMouseEnter={onMouseEnterAside} isNewUi={isNewUi}>
      <div className="sidebar">
        {parentLinks.map((parentLink, index) => (
          <Fragment key={index}>{_renderSideBarBox(parentLink.children, parentLink.text, parentLink.id)}</Fragment>
        ))}
      </div>

      {/* {(window?.location?.pathname === "/dashboard/create") && (
        <StyledSideBarBtn className={'chevron-hint'} onClick={onToggle}>
          {isOpen ? <FiChevronLeft size="20px" /> : <FiChevronRight size="20px" />}
        </StyledSideBarBtn>
      )} */}
      <StyledLogoutBtn isNewUi={isNewUi} onClick={onLogout}>
        <div><CommonIcon width={'30'} height={'30'} viewBox="0 0 35 30" fill={'#fff'} name={'multimedia'} /></div>
        <div>{t('layout:profileMenu.quit')}</div>
      </StyledLogoutBtn>
    </StyledAside>
  );
};

export default memo(NewSideBarComponent);
