import React from 'react';
import { Collapse } from 'antd';
import * as _ from '../style';
import { BasicIcon, TrueIcon, FalseIcon, ChevronRight, InfinityForTariffSvg } from '../icons';
import './style.css';

const { Panel } = Collapse;

const ComparesList = ({ isActive, isLanding }) => {
  return (
    <_.ComparesList className={isLanding && 'compares-list'}>
      <_.StyledCollapse defaultActiveKey={['1']} expandIcon={({ isActive }) => <ChevronRight style={{ transform: isActive ? 'rotate(90deg)' : 'rotate(0deg)', transition: 'transform .24s' }} />} className={isLanding && 'compares-list-wrapper'}>
        <Panel header="Получение документов" key="1">
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Массовая обработка входящих документов</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Интеграция – didox.uz, 1C, Банк и др.</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Лимит по количеству документов</_.PanelItemTextContent>
            </_.PanelItemText>
            {/*<_.OptionLabel style={{ display: 'flex', flexDirection: 'column' }} isActive={isActive}>*/}
            {/*  <div>{'200 шт'} </div>*/}
            {/*  <span style={{ fontSize: '11px', lineHeight: '15px' }}>{'(Если количество документов превышает 200 шт, то рассчитывается от 300 сум за каждый новый документ)'}</span>*/}
            {/*</_.OptionLabel>*/}
            <_.OptionLabel isActive={isActive}>{<InfinityForTariffSvg isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<InfinityForTariffSvg isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<InfinityForTariffSvg isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
        </Panel>

        <Panel header="Отправка документов" key="2">
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Отправка документа</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Отправка документа в роуминг (my.soliq.uz)</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive} style={{ marginRight: '-13px' }}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}<span style={{ fontSize: '25px', marginTop: '-25px' }}>*</span></_.OptionLabel>
            <_.OptionLabel isActive={isActive} isLanding={isLanding}>
              <p>300 сум</p>
            </_.OptionLabel>
            <_.OptionLabel isActive={isActive} isLanding={isLanding}>
              <p>300 сум</p>
            </_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Отправка трехсторонних документов</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {'500 сум'}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {'500 сум'}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Отправка документов по шаблону</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Массовая обработка исходящих документов</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Отправка документа загрузив с Excel</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>
                Отправка документа в интеграции с другими программами 1С, SAP и через API
              </_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Копия документа</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Изменение не отправленного документа</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Создать документ на основание другого документа</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Лимит количеству документов</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel style={{ display: 'flex', flexDirection: 'column' }} isActive={isActive}>
              <div><span style={{ fontSize: '25px', marginTop: '-25px' }}>*</span>50 шт</div>
              <span style={{ fontSize: '11px', lineHeight: '15px' }}>{'(Если количество документов превышает 50 шт, то рассчитывается от 300 сум за каждый новый документ)'}</span>
            </_.OptionLabel>
            {/*<_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>*/}
            <_.OptionLabel isActive={isActive}> {<InfinityForTariffSvg isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}> {<InfinityForTariffSvg isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
        </Panel>

        <Panel header="AI поддержка" key="3">
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Подбор ИКПУ код товаров и услуг</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive} isLanding={isLanding}>
              <p>50 000 сум</p>
            </_.OptionLabel>
            <_.OptionLabel isActive={isActive} isLanding={isLanding}>
              <p>50 000 сум</p>
            </_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          {/* <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Юридическое заключение договорам Chat GPT</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive} isLanding={isLanding}>
              {<FalseIcon isLanding={isLanding} isActive={isActive} />}
            </_.OptionLabel>
            <_.OptionLabel isActive={isActive} isLanding={isLanding}>
              <p>50 000 сум</p>
            </_.OptionLabel>
            <_.OptionLabel isActive={isActive} isLanding={isLanding}>
              <p>50 000 сум</p>
            </_.OptionLabel>
          </_.PanelItem> */}
          {/* <_.PanelBorder></_.PanelBorder> */}
          {/* <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Вопросы по законадательству Chat GPT</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive} isLanding={isLanding}>
              {<FalseIcon isLanding={isLanding} isActive={isActive} />}
            </_.OptionLabel>
            <_.OptionLabel isActive={isActive} isLanding={isLanding}>
              <p>100 000 сум</p>
            </_.OptionLabel>
            <_.OptionLabel isActive={isActive} isLanding={isLanding}>
              <p>100 000 сум</p>
            </_.OptionLabel>
          </_.PanelItem> */}
        </Panel>

        <Panel header="Функциональные возможности" key="4">
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Сформировать реестр документов</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Сформировать реестр ЭСФ с товарной позицией</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Каталог товаров и услуг</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Проверка контрагента по ИНН</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Черный список контрагентов</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Сортировка документов ИКПУ кодам</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Внутренние документы между сотрудниками</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
        </Panel>

        <Panel isLanding={isLanding} header="Командная работа" key="5">
          <_.PanelItem isLanding={isLanding}>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Подразделений (отделы)</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Сотрудников</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Должности сотрудников</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Полномочия каждого сотрудника</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Бизнес процесс (маршруты каждого документа)</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Объекты предприятия</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
          <_.PanelBorder></_.PanelBorder>
          <_.PanelItem>
            <_.PanelItemText>
              <_.PanelItemTextContent isLanding={isLanding}>Филиалы</_.PanelItemTextContent>
            </_.PanelItemText>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<FalseIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
            <_.OptionLabel isActive={isActive}>{<TrueIcon isLanding={isLanding} isActive={isActive} />}</_.OptionLabel>
          </_.PanelItem>
        </Panel>
      </_.StyledCollapse>
    </_.ComparesList>
  );
};

export default ComparesList;
