// ANCHOR npm packages
import React, { useState, useEffect, useCallback, lazy, Suspense, useMemo } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import last from 'lodash.last';
// ANCHOR hard data
import * as reports from 'Components/molecules/menu/hardMenuItems';
// ANCHOR custom components
import LayoutComponent from './layout';
import Btn from 'Components/atoms/button';
// ANCHOR redux middleware
import { getUserData } from './action';

// ANCHOR redux selectors
import { selectActiveSideBar } from 'Components/molecules/menu/menuReducer';

// ANCHOR redux constant
import * as con from 'Store/constant';

// ANCHOR constant

import { parentLinks as mainLinks } from 'Components/organisms/links/side.bar';
import browserStorage from 'Services/browserStorage';

import ApiSettings from 'Services/api/controller/settings';
import { onFetchClientProducts } from 'Pages/_Parameters_section/Products/reducer/actions';
import useOutsideClick from 'Hooks/useClickOutside';

// import Modal from 'Components/atoms/modal';

// style
const Modal = lazy(() => import('Components/atoms/modal'));

// import {ThemeContext} from 'Context/theme ';

const haveMenu = [1, 5, 10, 9];

const getParam = () => parseInt(last(window.location.pathname.split('/')));

const LayoutDashboard = (props) => {
  // NOTE redux store data

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const advertismentBlockWith = 18;

  const isSubSidebar = useSelector((state) => state?.menues?.isSubsidebar);

  const [isAdvertisment, setIsAdvertisment] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const activeIndex = useSelector(selectActiveSideBar);

  const part = useSelector((state) => state.outDocuments.part);

  // NOTE document types for all algorithms

  // NOTE sidebar menu current module which is  color blue tab

  const currentModule = useMemo(() => mainLinks.find((link) => link.id === activeIndex && link.link === location.pathname), [
    activeIndex,
    location
  ]);

  // NOTE dashboard content width

  let layoutWidth = useMemo(
    () =>
      isSubSidebar && currentModule?.isSubSideBar
        ? isAdvertisment
          ? `${100 - 5 - 15 - advertismentBlockWith}%`
          : '80%'
        : isAdvertisment
          ? `${100 - 5 - advertismentBlockWith}%`
          : '95%',
    [isSubSidebar, isAdvertisment]
  );

  // NOTE fetch all data for inbox outbox component

  // NOTE Controle layout width
  useEffect(() => {
    dispatch({ type: con.LAYOUT_WIDTH, payload: parseInt(layoutWidth.match(/\d{2}/)[0]) });
  }, [isSubSidebar, layoutWidth]);

  // NOTE

  useEffect(() => {
    if (activeIndex === 4) {
      dispatch({ type: con.SET_INITIAL_VALUES_FOR_MULTIPLE_TYPES, payload: reports.reportMenuTypes });
    }

    if (activeIndex === 2 || activeIndex === 3) {
      // setIsAdvertisment(true);
    } else {
      setIsAdvertisment(false);
    }
  }, [activeIndex]);

  // NOTE user get data when dashboard mount

  useEffect(() => {
    dispatch(getUserData());
    dispatch(onFetchClientProducts());
  }, []);

  // NOTE clear

  useEffect(() => {
    if (currentModule?.isSubSideBar) {
      dispatch({ type: con.CHANGE_ACTIVE_SIDEBAR, payload: activeIndex });
      if (currentModule.isHide) {
        dispatch({ type: con.SHOW_AND_HIDE_SUBSIDEBAR, payload: false });
      } else {
        dispatch({ type: con.SHOW_AND_HIDE_SUBSIDEBAR, payload: true });
      }
    } else {
      dispatch({ type: con.CHANGE_ACTIVE_SIDEBAR, payload: currentModule?.id || getParam() + 1 || 0 });
      dispatch({ type: con.SHOW_AND_HIDE_SUBSIDEBAR, payload: false });

      browserStorage.set('activeIndex', currentModule?.id || getParam() || 0);
    }
  }, [location, dispatch]);

  // NOTE MENU items fetchs when inbox or outbox mount

  // NOTE  control sidebarbox

  const setBlackBackground = useCallback(
    (id) => {
      let currentModule = mainLinks.find((mainLink) => mainLink.id === id);
      if (id === activeIndex) {
        if (currentModule?.isSubSideBar) {
          dispatch({ type: con.SHOW_AND_HIDE_SUBSIDEBAR, payload: !isSubSidebar });
        }
        return;
      }

      if (activeIndex === 2 || activeIndex === 3) {
        dispatch({ type: con.SET_INITIAL_PART });
      }
      browserStorage.set('activeIndex', id);

      dispatch({ type: con.CHANGE_ACTIVE_SIDEBAR, payload: id });

      history.push(currentModule.link);
    },
    [activeIndex, isSubSidebar, part, dispatch, history]
  );

  // NOTE controller advertisment

  const onHideAdvertisment = useCallback(() => {
    setIsAdvertisment(false);
    setTimeout(() => {
      let toggle = document.getElementById('toggle');
      if (toggle) {
        toggle.style.display = 'none';
      }
    }, 100);

    setTimeout(() => {
      setIsModal(true);
    }, 300);
  }, [isAdvertisment, isModal]);

  // NOTE controller modal;

  const onCloseModal = useCallback(() => {
    setIsModal(false);

    let toggle = document.getElementById('toggle');
    if (toggle) {
      toggle.style.display = 'block';
    }
    setIsAdvertisment(true);
  }, [isModal, isAdvertisment]);

  const onToggle = useCallback((value) => {
    if (!haveMenu.includes(activeIndex)) return;
    dispatch({ type: con.SHOW_AND_HIDE_SUBSIDEBAR, payload: value });
  }, [activeIndex]);

  const onOkhandler = () => {
    setIsAdvertisment(false);
    setIsModal(false);
  };

  const subSidebarRef = useOutsideClick(() => {
    dispatch({ type: con.SHOW_AND_HIDE_SUBSIDEBAR, payload: false });
  });

  return (
    <>
      {isModal && (
        <Suspense fallback={() => <></>}>
          <Modal onClose={onCloseModal} visible={true}>
            <div>Havolalar</div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Btn onClick={onOkhandler}>Ok</Btn>
            </div>
          </Modal>
        </Suspense>
      )}
      <LayoutComponent
        advertismentBlockWith={advertismentBlockWith}
        onToggle={onToggle}
        layoutWidth={layoutWidth}
        isOpen={isSubSidebar}
        setBlackBackground={setBlackBackground}
        isSubSideBar={isSubSidebar}
        isAdvertisment={isAdvertisment}
        onHideAdvertisment={onHideAdvertisment}
        subSidebarRef={subSidebarRef}>
        {props.children}
      </LayoutComponent>
    </>
  );
};

LayoutDashboard.whyDidYouRender = true;

export default LayoutDashboard;
