function toSearch(value, inputValue) {
  try {
    if (!inputValue) return true;

    if (!value) {
      value = '';
    }
    inputValue = inputValue.trim();

    return value.toLowerCase().includes(inputValue.trim().toLowerCase());
  } catch (error) {
    return false;
  }
}

export default toSearch;
