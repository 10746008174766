import client from 'Services/api/client';

const ApiBranch = {
  async getEmployees(branchId) {
    if (branchId && branchId != null){
      return await client(undefined, `/employees/branch/${branchId}`);
    } 
    return { fail: true, message:'', data:{} }
  },
  async getBrachs() {
    return await client(undefined, '/branches');
  },
  async exChangeEmployee({ documentId, employeeId, branchId}) {
    return await client('POST', `/edocuments/${documentId}/change/${employeeId}/${branchId}/employee`);
  },
  async getBranches(body) {
    return await client(undefined, `/branches?keyword=${body?.keyword || ''}`);
  },
  async addBranch(body) {
    return await client('POST', '/branches', body);
  },
  async updateBranch(body) {
    return await client('PUT', '/branches', body);
  },
  async deleteBranch(id) {
    return await client('DELETE', `/branches/${id}`);
  },
  async getInitialBranch({ id, routeType }) {
    return await client(undefined, `/edocuments/next/department/${id}?routeType=${routeType}`);
  },
  async getBranch(tin) {
    return await client(undefined, `/info/${tin}/branches`);
  }
};

export default ApiBranch;
