import styled from 'styled-components';
import { Select } from 'antd';
import { colors } from 'Style/colors';

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LangContainer = styled(FlexBox)`
    .ant-select-selector{
      color: white;
    }
`;

export const SelectLanguageContainer = styled(Select)`

  & > .ant-select-selector {
    /* Adapt the colors based on primary prop */
    background: transparent!important;
    color: #000017;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    border: none !important;
    padding: 0px 5px !important;
  }

  & > .ant-select-selection-search-input {
    border: none !important;
    color: white !important;
    box-shadow: none !important;
  }
`;

export const OptionCustomStyle = {
  textTransform: 'none',
  display: 'flex',
  justifyContent: 'center',
  padding: '0px 5px',
  alignItems: 'center',
}
