import React, { memo, useState } from 'react';
import { Select } from 'antd';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { onFetchMenuItems } from 'Components/molecules/menu/action';
import i18n from 'i18n';
import { useLocation } from "react-router-dom";
import browserStorage from 'Services/browserStorage';
import { SET_NEW_LANGUAGE } from 'Store/constant';
/* ---------------------------------- style --------------------------------- */
import { LangContainer, SelectLanguageContainer } from './lang.selector.style';
import { images } from './img.route';

const { Option } = Select;

const LanguageSelector = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const activeSideBar = useSelector((state) => state.menues.activeSideBar);

  const changeLanguage = (value) => {
    browserStorage.set('lang', value);

    dispatch(onFetchMenuItems(activeSideBar - 1));

    if (location.pathname.includes('create')) {
      // window.location.reload()
      setTimeout(() => i18n.changeLanguage(value), 500)
    } else {
      i18n.changeLanguage(value);
    }

    ReactGA.event({
      category: 'Language Switch',
      action: `Switch to ${value}`
    });
    let actualLang = browserStorage.get('i18nextLng');
    dispatch({ type: SET_NEW_LANGUAGE, payload: actualLang });
  };

  return (
    <LangContainer>
      <SelectLanguageContainer className={'lang-hint'} defaultValue={browserStorage.get('lang') || 'ru'} onChange={changeLanguage}>
        {images.map((item, key) => {
          const { text, value } = item;
          return (
            <Option value={value} key={key} style={{ textTransform: 'uppercase', display: 'flex', justifyContent: 'center' }}>
              {text}
            </Option>
          );
        })}
      </SelectLanguageContainer>
    </LangContainer>
  );
};

export default memo(LanguageSelector);
