import React, { useState, useLayoutEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyClipboard, Successfull } from 'Assets/icons/common/copyClipboard';

import { StyledTextWrapper } from './style';
import { useTranslation } from 'react-i18next';
import Tooltip from 'Components/atoms/tooltip';


/**
 * This component provides the ability to copy prop text to the clipboard, use this where need to copy text.
 * @text string | number
 * @variant 'tooltip' | 'icon'
 * @copyDelay number | 1000
 * @children React.Node
 */
const CopyableText = ({ children, text, copyDelay = 1000, variant = 'tooltip | icon', ...props }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(0);

  useLayoutEffect(() => {
    const id = setTimeout(() => {
      setCopied(0);
    }, copyDelay);
    return () => clearTimeout(id);
  }, [copied, copyDelay]);

  const WithIcon = (
    <CopyToClipboard text={text} onCopy={() => setCopied(p => ++p)}>
      <Tooltip placement="top" title={copied ? 'Copied' : 'Copy'}>
        <StyledTextWrapper {...props} copied={!!copied}>
          {children}&nbsp;
          {copied ? <Successfull style={{ width: '12px', height: '12px' }} /> : <CopyClipboard style={{ width: '12px', height: '12px' }} />}
        </StyledTextWrapper>
      </Tooltip>
    </CopyToClipboard>
  )
  const WithTooltip = (
    <CopyToClipboard text={text} onCopy={() => setCopied(p => ++p)}>
      <StyledTextWrapper {...props} copied={!!copied}>
        {children}

        <span className='copied_status text_copy'>
          {t('translation:copy')}
        </span>
        <span className='copied_status text_copied'>
          {t('translation:copySuccess')}
        </span>
      </StyledTextWrapper>
    </CopyToClipboard>
  )

  let Component = null;
  if (variant === 'tooltip') {
    Component = WithTooltip;
  } else if (variant === 'icon') {
    Component = WithIcon;
  }

  return Component;
};

export default CopyableText;
