import React from 'react';
import * as _ from '../style';

const ComparesHeader = ({ isLanding }) => {
  return (
    <_.ComparesHeader>
      <div>
        <_.ComparesHeaderText isLanding={isLanding}> Сравнение тарифов</_.ComparesHeaderText>
      </div>
      <_.ComparesHeaderItem>
        <_.ComparesHeaderItemHeaderText>Individual</_.ComparesHeaderItemHeaderText>
        <_.ComparesHeaderItemFooterText isLanding={isLanding}>Бесплатно</_.ComparesHeaderItemFooterText>
      </_.ComparesHeaderItem>
      <_.ComparesHeaderItem>
        <_.ComparesHeaderItemHeaderText>Business</_.ComparesHeaderItemHeaderText>
        <_.ComparesHeaderItemFooterText>50 000 сум</_.ComparesHeaderItemFooterText>
      </_.ComparesHeaderItem>
      <_.ComparesHeaderItem>
        <_.ComparesHeaderItemHeaderText>Enterprise</_.ComparesHeaderItemHeaderText>
        <_.ComparesHeaderItemFooterText>Contract</_.ComparesHeaderItemFooterText>
      </_.ComparesHeaderItem>
    </_.ComparesHeader>
  );
};

export default ComparesHeader;
