import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useDebounceTyping from 'Hooks/useDebounceTyping';
import BaseStyledFlex from 'Common/Style/styledFlex';
import BaseStyledInput from 'Common/Style/styledInput';
import BaseStyledPageTitle from 'Common/Style/styledPageTitle';
import Button from 'Components/atoms/button';

const StyledInput = styled(BaseStyledInput)`
  margin-right:10px;
`;

const Header = (props) => {
  const { t } = useTranslation(['settings']);
  const { value, setValue, onChange } = useDebounceTyping(props.onFilterRequest);//added setValue for resetFilter

  return (
    <BaseStyledFlex className="justify-content-space-between mb" marginBottom="0.2rem">
      <BaseStyledPageTitle>{props.title}</BaseStyledPageTitle>

      <BaseStyledFlex className="ml-auto">
        <StyledInput placeholder={t('search')} name="keyword" value={value} onChange={onChange} />
        {props.children}
        {/* Note!!! bu yerda props.isResetdan maqsad filtlarni Reset qiladigan qizil buttonni qo'shish */}
        {props.isReset && (
          <Button onClick={() => { props.resetFilter(); setValue('') }} className="ml-1" color="red">
            {t('box:resetFilter')}
          </Button>
        )}
      </BaseStyledFlex>
    </BaseStyledFlex>
  );
};

export default Header;
