import React from 'react';
import { colors } from 'Style/colors';

const SVGComponent = ({
  style = {},
  fill,
  width = 26,
  height = 26,
  onClick,
  className = '',
  viewBox = '-3.5 0 22 17.5'
}) => (
  <svg
    style={style}
    viewBox={viewBox}
    onClick={onClick}
    width={width}
    height={height}
    fill={fill ? fill : colors.sideBarIconColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.857 0V6.857H0V9.143H6.857V16H9.143V9.143H16V6.857H9.143V0H6.857Z"
      fill={fill ? fill : colors.sideBarIconColor}
    />
  </svg>
);
export default SVGComponent;