import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import MySelect from 'Modules/auth/components/select/select';
import { MyFallbackComponent } from 'Components/atoms/errorBoundry/ErrorBoundry';
import { Login as EImzoLogin } from 'Services/e-imzo/login';
import LanguageSelector from '../languageSelector/languageSelector';
import notification from 'Components/atoms/toast';
import browserStorage from 'Services/browserStorage';
import useFetch from 'Hooks/useFetch';
import { AUTH_LOGIN_BY_EMAIL, AUTH_LOGIN_BY_KEY } from 'Services/api/endPoints';
import requestState from 'Services/api/status';
import MyInput from '../components/input';
import * as con from 'Store/constant';

import { AuthMainSvg, HeadPhoneSvg, HeroLogoSvg, KeySvg, LockSvg, EmailSvg } from 'Assets/svg';
import * as _ from './auth.style';
import Registration from '../components/registration';
import Restore from '../components/restore';
import { BsArrowLeft } from "react-icons/all";
import SecondLeftSide from "Modules/auth/components/SecondLeftSide";
import RightSide from "../components/RightSide";
import { useDispatch } from 'react-redux';

const LoginComponent = () => {
  const { t } = useTranslation(['login']);
  const dispatch = useDispatch();

  const [apiData, login] = useFetch();
  const [data, setData] = useState([]);
  const [radioValue, setRadioValue] = useState('byKey');
  const [errorStatus, setErrorStatus] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [isRestore, setIsRestore] = useState(false);
  const [isRegistration, setIsRegistration] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [passwordEye, setPasswordEye] = useState(true);
  const handleCondition = (e) => {
    //Note! handleCondition funksiyasi 3 hil holatdan biriga: Login, Registratsiya yoki Parol tiklashga o'tsih uchun kerak, e 1 ga teng bo'lsa registratsiya, 2 ga teng bo'lsa Login, 3 ga teng bo'lsa parol tiklash holatlariga o'tadi
    if (e === 1) {
      setIsRegistration(true);
      setIsRestore(false);
      setIsLogin(false);
    } else if (e === 2) {
      setIsRegistration(false);
      setIsRestore(false);
      setIsLogin(true);
    } else if (e === 3) {
      setIsRegistration(false);
      setIsRestore(true);
      setIsLogin(false);
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleChange = (data) => { };
  const onFinish = async (values) => {
    if (!values.login) {
      const res = await login('POST', AUTH_LOGIN_BY_EMAIL, { userName: email, password });

      if (res?.fail) {
        notification.setType('error').setMessage(res.message).alert();
      }
    } else {
      let selectedTin = values.login.split(' ')[0],
        selectedSeria = values.login?.split(' ')[1];

      const user = data.find((item) => item.TIN === selectedTin && item['serialNumber'] === selectedSeria);

      EImzoLogin.init(
        4,
        async function (data, id = undefined, errorMessage) {
          if (errorMessage) {
            return setErrorStatus(errorMessage);
          }

          browserStorage.set('id', id);

          await login('POST', AUTH_LOGIN_BY_KEY, { sign: data });
          setErrorStatus('');
        },
        user
      );
    }
  };

  const onFinishFailed = (errorInfo) => { };

  useEffect(() => {
    EImzoLogin.init(2, function (data) {
      setData((state) => [...state, data]);
    });
  }, []);

  useEffect(() => {
    if (errorStatus === 'error_password') {
      notification.setType('error').setMessage(t('errorPassword')).alert();
    }
    if (errorStatus === 'error_browser') {
      notification.setType('error').setMessage(t('errorEimzo')).alert();
    }
  }, [errorStatus]);

  useEffect(() => {
    if (!browserStorage.get('token')) {
      dispatch({ type: con.FIRST_LOGIN, payload: true });
    }
  }, [])

  useEffect(() => {
    if (apiData.status === requestState.error) {
      notification.setType('error').setMessage(get(apiData, 'error.message')).alert();
    }
    if (apiData.status === requestState.success) {
      browserStorage.set('token', apiData.data);
    }
  }, [apiData]);

  if (apiData.status === requestState.success || browserStorage.get('token')) return <Redirect to="/dashboard/home" />;

  const handleRadio = (e) => {
    setRadioValue(e.target.value);
  };

  const eye = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
        fill="rgba(167,167,167,1)"
      />
    </svg>
  );
  const openPassword = () => {
    setPasswordEye((prev) => !prev);
  };
  return (
    <>
      <_.LoginContent id="login">
        <_.StyledRow>
          <_.LoginLeftCol>
            <SecondLeftSide />
          </_.LoginLeftCol>
          <_.LoginRightCol>
            <RightSide isLogin={isLogin} isRegistration={isRegistration} isRestore={isRestore} handleRadio={handleRadio}
              radioValue={radioValue} onFinish={onFinish} onFinishFailed={onFinishFailed}
              handleChange={handleChange} data={data} email={email} handleEmailChange={handleEmailChange}
              passwordEye={passwordEye} openPassword={openPassword} handlePasswordChange={handlePasswordChange}
              handleCondition={handleCondition} apiData={apiData} password={password} eye={eye} />
          </_.LoginRightCol>
        </_.StyledRow>
      </_.LoginContent>
    </>
  );
};

LoginComponent.propTypes = {
  loginData: PropTypes.shape({
    pending: PropTypes.bool,
    fail: PropTypes.bool,
    login: PropTypes.shape({
      accessToken: PropTypes.string,
      refreshToken: PropTypes.string,
      tokenType: PropTypes.string
    })
  }),
  loginFunc: PropTypes.func,
  t: PropTypes.func
};

const Login = LoginComponent;

export default memo(Login);
