import styled from "styled-components";
import { colors } from 'Style/colors';
import { Menu } from "antd";

const SupportPopupWrapper = styled.div`
  svg {
    color: #fff;
  }
`;

const SupportPopupInner = styled(Menu)`
  display: flex;
  flex-direction: column;
  top: 0.7rem;
  background-color: ${(props) => props?.isNewUi ? colors.dropDownBgColor : colors.headerBackground};
  border-radius: 0px 0px 8px 8px;
  box-shadow: ${colors.dropDownShadow};
  color:${(props) => props?.isNewUi ? colors.textColor : colors.white} !important;

  .support-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 9px 16px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.33); // NOTE - DARK_BLUE_BG
    }

    &__img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      path {
        fill: inherit !important;
        color: inherit !important;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
    &__content-label {
      letter-spacing: 0.4px;
      &--withHover {
        position: relative;
        left: -2px;
        display: block;
        border-radius: 2px;
        padding: 2px;
        font-weight: 500;
      }
      &--withHover:hover {
        color: ${colors.primary};
      }
      &--withHover .support-item__content-text:hover {
        color: ${colors.primary};
      }
    }

    &__content-text {
      font-weight: 500;
      letter-spacing: 0.15px;
      font-size: 16px;
      line-height: 20px;
      color: ${(props) => props?.isNewUi ? colors.textColor : colors.white};
    }
  }
`;

const DropdownLink = styled.p`
  display: flex;
  align-self: center;
  margin-bottom: 0;
  margin: 10px;
  padding: 10px;
  transition: background-color 0.3s ease;

  & > .ant-dropdown-link {
    margin: 0;
    padding: 0;
    color: white !important;
    &:active > img,
    &:focus > img,
    &:visited > img {
      color: red !important;
    }
  }

  & > img {
    height: 22px;
    width: 35px;
    color: white !important;
    cursor: pointer;
    &:active,
    &:visited,
    &:focus {
      color: dodgerblue !important;
    }
  }

  & > .support-image {
    margin: 0;
    padding: 0;
    width: auto;
    height: 25px;
  }

  &:hover {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.12);
    cursor:pointer;
  }

  // & > .support-image:hover {
  //   path {
  //     fill:  ${colors.iconHoverColor};
  //     cursor: pointer;
  //   }
  // }

  // & > .support-image:active,
  // & > .support-image:visited,
  // & > .support-image:focus {
  //   color:  ${colors.iconHoverColor} !important;
  // }
`;

export {
  SupportPopupWrapper,
  SupportPopupInner,
  DropdownLink,
};