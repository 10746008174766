import React from 'react';

import Add from './add';
import Garbage from './garbage';
import Hints from './hints';
import Info from './info';
import Logo from './logo';
import Telegram from './telegram';
import Telegram2 from './telegram2';
import User from './user';
import Business from './business';
import Balance from './balance';
import CreditCard from './creditcard';
import Multimedia from './multimedia';
import Settings from './settings';
import TechnicalSupport from './technicalSupport';
import Wallet from './wallet';
import Create from './create';
import StatusInfo from './statusInfo';
import SupportSvg from './support';
import PhoneSvg from './phone';
import Edit from './edit'

const CommonIcon = (props) => {
  switch (props.name) {
    case 'add':
      return <Add {...props} />;
    case 'garbage':
      return <Garbage {...props} />;
    case 'hints':
      return <Hints {...props} />;
    case 'info':
      return <Info {...props} />;
    case 'status-info':
      return <StatusInfo {...props} />;
    case 'logo':
      return <Logo {...props} />;
    case 'telegram':
      return <Telegram {...props} />;
    case 'telegram2':
      return <Telegram2 {...props} />;
    case 'user':
      return <User {...props} />;
    case 'business':
      return <Business {...props} />;
    case 'balance':
      return <Balance {...props} />;
    case 'credit_cart':
      return <CreditCard {...props} />;
    case 'multimedia':
      return <Multimedia {...props} />;
    case 'settings':
      return <Settings {...props} />;
    case 'technical-support':
      return <TechnicalSupport {...props} />;
    case 'wallet':
      return <Wallet {...props} />;
    case 'create':
      return <Create {...props} />;
    case 'support':
      return <SupportSvg {...props} />;
    case 'phone':
      return <PhoneSvg {...props} />;
    case 'edit':
      return <Edit {...props} />;
    default:
      return;
  }
};

export default CommonIcon;
