import React from 'react';
import {Helmet} from "react-helmet";

const MyHelmet = ({title, content, href}) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {href && <link rel="canonical" href={href}/>}
      {content && <meta name="description" content={content}/>}
    </Helmet>
  );
};

export default MyHelmet;