export const images = [
  {
    id: 1,
    value: 'ru',
    text: 'Ру',
    alt: 'Ру'
  },
  {
    id: 2,
    value: 'cyrillic',
    text: 'Ўз',
    alt: 'Ўз'
  },
  {
    id: 3,
    value: 'latin',
    text: `O'z`,
    alt: `O'z`
  }
];
