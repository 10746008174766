import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import {Drawer} from 'antd';
import {selectAccountData} from "Store/selectors/selectors";
import Modal from 'Components/atoms/modal';
import {BankIcon, CardIcon} from './icon'

import {ClickCard, ClickForm, Label, TypeCard, Wrapper} from './style'
import clickLogo from 'Assets/images/clickLogoIcon.png';
import {CopyClipboard, Successfull} from "Assets/icons/common/copyClipboard";

const data = [
  {text: "Юридическое лицо: OОО \"THE MIND\""},
  {text: "ИНН: 305858074", copyText:"305858074"},
  {text: " Основной банк: АКВ Каріtal Bank"},
  {text: "Расчетный счет: 20208000600942169002", copyText:"20208000600942169002"},
  {text: "МФО: 00445", copyText:"00445"},
  {text: "Детали платежа: 00668", copyText:"00668"},
  {text: "Оплата 100% за подписку сайта"},
  {text: "24m.uz (электронного документа оборота)"},
  {text: "согласно оферты от 15.11.2018"},
]

const Payment = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cardModal, setCardModal] = useState(false)
    const [clickModal, setClickModal] = useState(false)
    const [paymentAmount, setPaymentAmount] = useState(0)
    const [copiedIndex, setCopiedIndex] = useState(null);
    const accountData = useSelector(selectAccountData);
    const { t } = useTranslation(['login']);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // CARD MODAL
    const showCardModal = () => {
        setCardModal(true);
    };
    const hideCardModal = () => {
        setCardModal(false);
    };

    // CLICK MODAL
    const showClickModal = () => {
        setClickModal(true);
    };
    const hideClickModal = () => {
        setClickModal(false);
    };


    const handleInput = (e) => {
        setPaymentAmount(e.target.value);
    }

    const renderIcon = (index) => (
      <div>{copiedIndex === index ? <Successfull style={{ height: '15px', margin: '0 0 8px 5px' }} /> : <CopyClipboard style={{ margin: '0 0 8px 5px' }} />}</div>
    );

    return (
        <Wrapper>
            <TypeCard onClick={() => showModal()}>
                <Label> {t('payment.paymentSection.payWithBank')}</Label>
                <BankIcon width={160} height={148} />
            </TypeCard>
            <TypeCard onClick={() => showCardModal()}>
                <Label style={{ margin: '0px' }}>{t('payment.paymentSection.payWithCard')}</Label>
                <CardIcon width={160} height={160} />
            </TypeCard>
          <Modal
            title={t('payment.paymentSection.payWithBank')}
            visible={isModalOpen}
            color={"black"}
            onClose={handleCancel}
          >
            {data.map((obj, index) => (
              <CopyToClipboard key={index} text={obj.copyText}>
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => {setCopiedIndex(index); setTimeout(() => setCopiedIndex(null), 1000)}}>
                  <p>{obj.text}</p>
                  {obj.copyText && renderIcon(index)}
                </div>
              </CopyToClipboard>
            ))}
          </Modal>

            <Drawer
                title={t('payment.paymentSection.payWithCard')}
                visible={cardModal}
                color={"black"}
                onClose={hideCardModal}
                placement={'right'}
                width={600}
            >
                <ClickCard style={{ cursor: 'pointer' }} onClick={() => { hideCardModal(); showClickModal(); }} >
                    <img width={160} height={160} style={{ borderRadius: '25px' }} src={clickLogo} alt="click payment for bdm" />
                    <p style={{ padding: '5px' }} >{t('payment.paymentSection.paymentByClick')}</p>
                </ClickCard>
            </Drawer>
            <Modal
                title={t('payment.paymentSection.paymentByClick')}
                visible={clickModal}
                color={"black"}
                onClose={hideClickModal}
            >

                <ClickForm>
                    <Label >Суммы выплат от 1,000.00 до 9,000,000.00!</Label>
                    <form
                        target={"_blank"}
                        action={"https://my.click.uz/services/pay"}
                        method={"get"}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                    >
                        <input type={"hidden"} name={"merchant_id"} value={"14671"} />
                        <input type={"hidden"} name={"merchant_user_id"} value={"23375"} />
                        <input type="hidden" name="service_id" value={"20255"} />
                        <input type="hidden" name="transaction_param" value={accountData?.tin} />
                        <input type="hidden" name="return_url" value="https://24m.uz/home/userFunds/payment" />
                        <div>
                            <img style={{ borderRadius: '25px', margin: '20px', height: '200px', width: '200px' }} src={clickLogo} alt="click payment for bdm" />
                        </div>
                        <span style={{ display: 'block' }}>
                        </span>
                        <label style={{
                            display: `inline-block`,
                            maxWidth: '100%',
                            marginBottom: '5px',
                            fontWeight: '700',
                        }}
                            for="amountClick">
                            {' Введите сумму платежа: '}
                            <span>{paymentAmount}</span>
                            {' сум'}
                        </label>
                        <input
                            style={{
                                width: '100%',
                                height: '32px',
                                color: '#979da2',
                                border: '1px solid #c6c6c6',
                                padding: '5px 10px !important',
                                borderRadius: '4px',
                                transition: 'all 0.3s',

                            }}
                            id="amountClick"
                            type="number"
                            placeholder="Оплата"
                            onChange={(e) => handleInput(e)}
                        // onInput={this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')}
                        />
                        <input
                            type="text"
                            style={{ display: 'none' }}
                            name="amount"
                            value={paymentAmount}
                        // onChange={(e) => handleInput(e)}
                        // onInput={this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')}
                        />
                        <div onClick={hideClickModal}>
                            <button
                                data-el_is_click={"1"}
                                style={{
                                    cursor: 'pointer',
                                    padding: '6px 50px',
                                    position: 'relative',
                                    display: 'inline-block',
                                    minHeight: '32px',
                                    color: '#FFFFFF',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    marginRight: '10px',
                                    lineHeight: '1.5',
                                    textAlign: 'center',
                                    textDecoration: 'none',
                                    border: '0',
                                    backgroundColor: 'blue',
                                    margin: '10px',
                                }}

                            >Оплата
                            </button>
                        </div>
                    </form>
                </ClickForm>
            </Modal>
        </Wrapper >
    );
}

export default Payment;
