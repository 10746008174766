import store from 'Store/store';
import * as con from 'Store/constant';

export const addNotification = (data) => ({type: con.ADD_NOTIFICATION, payload: data});

export const removeNotification = (id) => ({type: con.REMOVE_NOTIFICATION, payload: id});

export const DispatchNotification = {
  add (data) {
    if(!data.text) return;
    store.dispatch(addNotification({...data}));
  },
  error (data) {
    if(!data.text) return;
    store.dispatch(addNotification({...data, type: 'error'}));
  },
  remove (id) {
    store.dispatch(removeNotification(id));
  },
  errConnection () {
    store.dispatch(addNotification({...getErrorConnectionData(), type: 'error'}));
  }
};



// NOTE - serverdan javob kelmasa chiqadigan special error modal
let timeOut = 0;
export const isServerError = (e) => {
  try {
    if(e?.message === 'manual_abort_request') return;
    if(e?.code === 'ECONNABORTED' || e?.message?.includes?.('Network Error')) {
      const isTimeoutEnd = Date.now() - timeOut;
      if(isTimeoutEnd > 20000) {
        timeOut = Date.now();
        DispatchNotification.errConnection();
      }
    };
  } catch (_err) {}
};

const getErrorConnectionData = () => {
  const lang = localStorage.getItem('i18nextLng');

  switch (lang) {
    case 'ru': {
      return {title: 'Ошибка сети', text: 'Потеряно соединение с сервером, пожалуйста, повторите попытку позже'};
    }
    case 'latin': {
      return {title: 'Tarmoq xatosi', text: 'Server bilan aloqa uzildi, birozdan keyin qayta urinib ko\'ring'};
    }
    case 'cyrillic': {
      return {title: 'Тармоқ хатоси', text: 'Сервер билан алоқа узилди, бироздан кейин қайта уриниб кўринг'};
    }
    default: {
      return {title: 'Ошибка сети', text: 'Потеряно соединение с сервером, пожалуйста, повторите попытку позже'};
    }
  }
}
// here are possible solutions.