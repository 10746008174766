import styled from 'styled-components';
import { colors } from 'Style/colors';

export const StyledRow = styled.div`
  /* transition: width 300ms; */
  background: white;
  color: black;
  line-height: 43px;
  font-size:13px;
  cursor: pointer;
  border-bottom:1.5px solid ${colors.settingTableBorderColor};
  // box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);

   background-color: ${(props) => props.isEven ? `${colors.evenRowBgColor}` : `${colors.white}`} ;

  &.active,
  &.effect-row:hover {
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3), inset 1px 3px 1px rgba(60, 64, 67, 0.15);
    z-index: 1;
    cursor: pointer;
  }
  & > div {
    text-align: center;
    margin:0px;
    &:nth-child(1) {
      text-align: center;
      padding-top: ${(props) => props.padding || '8px'};
      width: 5%;
      margin:0px;
    }
    &:nth-child(2) {
      width: 7%;
    }
    &.text-align-right {
      text-align: right;
    }
  }
`;
