export const vatRates = [
  {
    name: '12%',
    id: 12
  },
  {
    name: '15%',
    id: 15
  },
  {
    name: '0%',
    id: 0
  },
  {
    name: 'Без НДС',
    id: -1
  }
];

export const productOrigin = [
  {
    name: '',
    id: ''
  },
  {
    name: 'ownProduction',
    id: 1
  },
  {
    name: 'purchaseAndSale',
    id: 2
  },
  {
    name: 'provisionOfServices',
    id: 3
  },
  {
    name: 'notParticipating',
    id: 4
  }
];

export const oneSideSelect = [
  {
    name: 'На физ. лицо',
    id: 1
  },
  {
    name: 'На экспорт',
    id: 2
  },
  {
    name: 'На импорт',
    id: 3
  },
  {
    name: 'Реализация, связанная с гос. секретом',
    id: 4
  }
];

// export const useTranslateData = () => {
//   const {t} = useTranslation()
//
//   const productOrigin = [
//     {
//       name: '',
//       id: ''
//     },
//     {
//       name: 'Собственное производство',
//       id: 1
//     },
//     {
//       name: 'Купля-продажа',
//       id: 2
//     },
//     {
//       name: 'Оказание услуг',
//       id: 3
//     },
//     {
//       name: 'Не участвую',
//       id: 4
//     }
//   ];
//
//   return {productOrigin}
// }