const getHeight = (property) => {
    switch (property) {
      case 'tin':
      case 'name':
      case 'name2':
      case 'tin2':
      case 'typeName':
        return 370;
      case 'number':
      case 'sum':
      case 'ageInDays':
      case 'contractDate':
      case 'contractState':
        return 170;
      case 'date':
      case 'contract':
      case "dateReceived":
        return 180;
      case 'dateAndNumber':
        return 280;
      case 'branchName':
        return 188;
      case 'filialNum':
        return 188;
      case 'partnerTin':
      case 'partnerName':
        return 130;
      default:
        return 370;
    }
  };
  
  const getWidth = (property) => {
    switch (property) {
      case 'tin':
      case 'tin2':
      case 'sellerName':
      case 'buyerName':
        return 300;
      default:
        return 280;
    }
  };


const getIdentifyProperty = (selectedProperty) => {
    if (selectedProperty.startsWith('tin') || selectedProperty === 'sellerName' || selectedProperty === 'buyerName') return 'tin';
    if (selectedProperty === 'constructionObject' || selectedProperty === 'subtypeName') return 'id';
    return 'code';
  };

  export {
      getHeight,
      getWidth,
      getIdentifyProperty
  }

