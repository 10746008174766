
export const SoliqErrorHandler = {
  checkIsSoliqError (e_text = '') {
    try {
      if(typeof e_text === 'string') {
        return e_text.includes('my.soliq.uz ');
      }
      return false;
    } catch (e) {
      return false;
    }
  },

  getSoliqErrorText(e_text) {
    try {
      if (!this.checkIsSoliqError(e_text)) return e_text;
      const jsonString = e_text.replace('my.soliq.uz ', '');
      const errSoliqObj = JSON.parse(jsonString) || {};
      const normalText = errSoliqObj.getProp('ErrorMessage', jsonString)
      return normalText;
    } catch (e) {
      return e_text;
    }
  }
}

