export const onFlatMenuList = (currentItems, arg, part) => {
  if (part === 2 || part === 3) {
    arg = arg.filter((argument) => argument.part === part) || [];
  }

  for (let item of arg) {
    if (!item?.children) {
      // delete item.type;
      let currentItem = { ...item };
      currentItems = [...currentItems, currentItem];
      arg = arg.filter((i) => i !== item);
    } else {
      arg = arg.filter((i) => i !== item);
      return onFlatMenuList(currentItems, [...arg, ...item?.children]);
    }
  }

  return currentItems;
};
